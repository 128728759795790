import React, { useState, useRef, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useOutletContext,
} from "react-router-dom";
import CustomEmailEditor from "../customEmailEditor";
import {
  getKeyByValue,
  getUserAccessInfo,
  utf8_to_b64,
  inputSpaceValidator,
} from "../../utils/index";
import { APP_DATA } from "../../app_data/index";
import {
  createNewEmailTemplateAPI,
  updateEmailTemplateAPI,
} from "../../apis/emailTemplateAPIs";
import { getEmailTemplatesByIdAPI } from "../../apis/workflowAPIs";

export default function EmailTemplatesCreate() {
  const { openToast } = useOutletContext();
  const customEditor = useRef(null);
  const formData = useRef({ favorites: true });
  const saveBtn = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [templateData, setTemplateData] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { state } = location;
    setAction(state.action);
    if (state.action === "create") {
      setTemplateData(state.data);
      setLoading(false);
    } else if (["edit", "view"].includes(state.action)) {
      getTemplateInfoById(state?.data?.id);
    }
  }, []);

  const formChangeHandler = (e) => {
    setTemplateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    formData.current[e.target.id] = e.target.value;
  };

  const getTemplateInfoById = async (id) => {
    if (id) {
      const template = await getEmailTemplatesByIdAPI(id);
      if (template.status) {
        let data = {};
        if (template?.data && Object.keys(template.data)?.length)
          for (const f in template.data) {
            const k = getKeyByValue(APP_DATA.APIFieldMappings.template, f);
            if (k) {
              data[k] = template.data[f];
            }
          }
        setTemplateData(data);
      }
      setLoading(false);
    }
  };

  const handleCreateTemplate = async (editorData) => {
    const formData = { ...templateData, ...editorData };
    let formattedData = {};
    for (const f in formData) {
      formattedData[APP_DATA.APIFieldMappings.template[f]] = formData[f];
    }
    formattedData["created_by"] = getUserAccessInfo()?.user_id;

    const template = await createNewEmailTemplateAPI(formattedData);
    if (template.status) {
      navigate({
        pathname: "/app/templates",
        search: createSearchParams({
          referrer: "create",
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
      saveBtn.current?.classList?.remove("btnLoader");
      openToast(`Template ${templateData?.templateName} is created`, "success");
    } else {
      openToast(
        `Failed to create the template ${templateData?.templateName}`,
        "error"
      );
      saveBtn.current?.classList?.remove("btnLoader");
    }
  };

  const handleUpdateTemplate = async (editorData) => {
    let finalValidation = true;

    const data = { ...formData.current, ...editorData };
    let formattedData = {};


    for (const f in data) {
      if (!data[f] && APP_DATA.templateInput.requiredFields.includes(f)) {
        finalValidation = false;
        if(APP_DATA.templateFieldMapping[f])
        { openToast(
          ` ${APP_DATA.templateFieldMapping[f]} cannot be blank`,
          "error"
        );}
      } else if (inputSpaceValidator(data[f])) {
        finalValidation = false;
        if(APP_DATA.templateFieldMapping[f])
        { openToast(
          ` ${APP_DATA.templateFieldMapping[f]} cannot contain spaces`,
          "error"
        );}
      } 
      formattedData[APP_DATA.APIFieldMappings.template[f]] = data[f];
    }
    formattedData["updated_by"] = getUserAccessInfo()?.user_id;
    if (finalValidation) {
      const template = await updateEmailTemplateAPI(
        location.state.data.id,
        formattedData
      );
      if (template.status) {
        navigate({
          pathname: "/app/templates",
          search: createSearchParams({
            referrer: "edit",
            tNode: utf8_to_b64(String(Date.now())),
          }).toString(),
        });
        openToast(
          `Template ${templateData?.templateName} is edited`,
          "success"
        );
      } else {
        openToast(
          `Failed to edit the template ${templateData?.templateName}`,
          "error"
        );
        saveBtn.current?.classList?.remove("btnLoader");
      }
    } else {
      saveBtn.current?.classList?.remove("btnLoader");
    }
  };

  const updateEditorBody = (editorData) => {
    if (action === "create") {
      handleCreateTemplate(editorData);
    } else {
      handleUpdateTemplate(editorData);
    }
  };

  const handleSave = () => customEditor.current?.onTriggerSave();

  const handleCancel = () => {
    return navigate({
      pathname: "/app/templates",
      search: createSearchParams({
        referrer: "back",
        tNode: utf8_to_b64(String(Date.now())),
      }).toString(),
    });
  };

  const handleSaveLoader = () => {
    saveBtn.current?.classList?.add("btnLoader");
  };

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            height: "350px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 8,
            alignItems: "center",
          }}
        >
          <div
            className="loaderTheme-sm"
            style={{
              borderTop: "3px solid #0071bc",
            }}
          ></div>
        </div>
      ) : (
        <>
          <div className="crm-header-wrpr">
            <div className="header_lhs">
              <i
                className="crm-back-btn"
                onClick={() => navigate(-1, { replace: true })}
              ></i>
              <div className="tmplt-info">
                <div className="name-input">
                  <i className="tmplt-icn"></i>
                  <input
                    type="text"
                    className="crm-form-ctrl template-name"
                    id="templateName"
                    value={templateData.templateName}
                    autocomplete="off"
                    placeholder="Enter Template Name "
                    onChange={["edit"].includes(action) ? formChangeHandler : null}
                    disabled={["view","create"].includes(action)}
                  />
                  <span className="label">{templateData.module}</span>
                </div>
                <input
                  type="text"
                  className="crm-form-ctrl template-subject"
                  id="subject"
                  value={templateData.subject}
                  autocomplete="off"
                  placeholder="Enter Template Subject "
                  onChange={["edit"].includes(action)? formChangeHandler : null}
                  disabled={["view","create"].includes(action)}
                />
              </div>
            </div>
            <div className="header_rhs">
              {/* <i className="attachment-icon"></i> */}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
              {/* <button type="button" className="btn btn-secondary">
            Preview
          </button> */}
              {!["view"].includes(action) && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                  ref={saveBtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          {/* <div className="tmplt-cntr">
        <div className="tmplt-rw">
          <div className="tmplt-hdng">
            <p>Basic</p>
            <i className="arw"></i>
          </div>
          <div className="gallery">
            <div className="tmplt-catg">
              <div className="img-cnt blank"></div>
              <div className="catg-name">
                <p className="title">Blank</p>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary"
                  onclick="location.href='email-template-editor.html'"
                >
                  Select
                </button>
              </div>
            </div>
            <div className="tmplt-catg">
              <div className="img-cnt">
                <img src="https://www.idrive.com/crm/webconsole/assets/images/one_column.svg" />
              </div>
              <div className="catg-name">
                <p className="title">One column</p>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary"
                  onclick="location.href='email-template-editor.html'"
                >
                  Select
                </button>
              </div>
            </div>
            <div className="tmplt-catg">
              <div className="img-cnt">
                <img src="https://www.idrive.com/crm/webconsole/assets/images/two_column.svg" />
              </div>
              <div className="catg-name">
                <p className="title">Two column</p>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary"
                  onclick="location.href='email-template-editor.html'"
                >
                  Select
                </button>
              </div>
            </div>
            <div className="tmplt-catg">
              <div className="img-cnt">
                <img src="https://www.idrive.com/crm/webconsole/assets/images/one-column-with-images.svg" />
              </div>
              <div className="catg-name">
                <p className="title">One column with images</p>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary"
                  onclick="location.href='email-template-editor.html'"
                >
                  Select
                </button>
              </div>
            </div>
            <div className="tmplt-catg">
              <div className="img-cnt">
                <img src="https://www.idrive.com/crm/webconsole/assets/images/two-column-with-images.svg" />
              </div>
              <div className="catg-name">
                <p className="title">Two column with images</p>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary"
                  onclick="location.href='email-template-editor.html'"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
          <div style={{ pointerEvents: action === "view" ? "none" : "" }}>
            <CustomEmailEditor
              data={{
                html: templateData.html,
                htmlJson: templateData.htmlJson,
              }}
              ref={customEditor}
              functions={{ updateEditorBody, handleSaveLoader }}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
}
