import React from "react";

const DashboardManagerView = () => {
  return (
    <React.Fragment>
      <div className="crm-sctn-cntr wdgt-sctn">
        <div className="sctn-lhs">
          <div className="crm-widgets">
            <div className="wdgt-col">
              <div className="wdgt-lft">
                <div className="wdgt-hdng">Deals Created This Month</div>
                {/* <div className="crm-noDataCont">
                                    <div className="">
                                        <span className="crm-noDataImg nowdgtdata"></span>
                                    </div>
                                </div> */}
                <div className="wdgt-count">
                  <span className="value">12</span>
                  <div className="wdgt-perc">
                    <i className="crm-action-up-arw"></i>
                    <span className="per">70%</span>
                  </div>
                </div>
                <div className="wdgt-lstmnth">
                  <span className="hdr">Last Month:</span>
                  <span className="value">15</span>
                </div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-thumbs-up"></i>
              </div>
            </div>
            <div className="wdgt-col">
              <div className="wdgt-lft">
                <div className="wdgt-hdng">Deals Closing This Month</div>
                <div className="wdgt-count">09</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-thumbs-upclosed"></i>
              </div>
            </div>
            <div className="wdgt-col">
              <div className="wdgt-lft">
                <div className="wdgt-hdng">Revenue This Month</div>
                <div className="wdgt-count">
                  <span className="value">$9,000</span>
                  <div className="wdgt-perc">
                    <i className="crm-action-up-arw"></i>
                    <span className="per">90%</span>
                  </div>
                </div>
                <div className="wdgt-lstmnth">
                  <span className="hdr">Last Month:</span>
                  <span className="value">0</span>
                </div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-dollar"></i>
              </div>
            </div>
            <div className="wdgt-col">
              <div className="wdgt-lft">
                <div className="wdgt-hdng">Overdue Tasks</div>
                <div className="wdgt-count">5</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-clock"></i>
              </div>
            </div>
          </div>
          <div className="crm-grid-stack qutr">
            <h2>
              <span>Last 4 Quarter Performance Overview</span>
              {/* <i className="crm-action-settings">
                                <ul className="crm-dropdown-action" id="">
                                    <li><span className="crm-action-edit">Edit</span></li>
                                </ul>
                            </i>  */}
            </h2>
            <div className="crm-table cst-mgn quatrperf-tbl">
              <div className="crm-tb-hed">
                <div className="crm-tb-rw">
                  <div className="crm-td crm-td-subject crm-sort sort-active-up ml">
                    <span></span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>FY 2022-Q1</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>FY 2022-Q2</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>FY 2022-Q3</span>
                  </div>
                </div>
              </div>
              <div className="crm-tb-bdy">
                <div className="crm-tb-rw">
                  <div className="crm-td crm-td-subject ml">
                    <span>
                      <strong>Leads created</strong>
                    </span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>20</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>35</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>15</span>
                  </div>
                </div>
                <div className="crm-tb-rw">
                  <div className="crm-td crm-td-subject ml">
                    <span>
                      <strong>Deals created</strong>
                    </span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>20</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>35</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>15</span>
                  </div>
                </div>
                <div className="crm-tb-rw">
                  <div className="crm-td crm-td-subject ml">
                    <span>
                      <strong>Deals won</strong>
                    </span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>20</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>35</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>15</span>
                  </div>
                </div>
                <div className="crm-tb-rw">
                  <div className="crm-td crm-td-subject ml">
                    <span>
                      <strong>Revenue</strong>
                    </span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>$ 50,000</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>$ 70,000</span>
                  </div>
                  <div className="crm-td crm-td-fyq">
                    <span>$ 20,000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sctn-rhs flexcol wdgt-rhs">
          <div className="crm-grid-stack deals-stack mt0">
            <h2>My Pipeline Deals by Stage</h2>
            <div className="crm-noDataCont">
              <div className="">
                <span className="crm-noDataImg"></span>
              </div>
              <p className="crm-noDataText">No data available</p>
            </div>
          </div>
          <div className="crm-grid-stack deals-stack">
            <h2>Top 10 Reps By Tasks This Month</h2>
            <div className="count-list cstm-pdng">
              <ul>
                <li>
                  <div className="award-info">
                    <i className="crm-action-award"></i>
                    <span className="name">Alice Maria</span>
                  </div>
                  <div className="progress-bar-wrap">
                    <div className="progress-container">
                      <div className="progress-bar">
                        <span
                          className="progress orange"
                          style={{width: "40%"}}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="count-no">45</div>
                </li>
                <li>
                  <span className="name">Kris Marrier</span>
                  <div className="progress-bar-wrap">
                    <div className="progress-container">
                      <div className="progress-bar">
                        <span className="progress red" style={{width: "70%"}}></span>
                      </div>
                    </div>
                  </div>
                  <div className="count-no">75</div>
                </li>
                <li>
                  <span className="name">David John</span>
                  <div className="progress-bar-wrap">
                    <div className="progress-container">
                      <div className="progress-bar">
                        <span className="progress blue" style={{width: "30%"}}></span>
                      </div>
                    </div>
                  </div>
                  <div className="count-no">25</div>
                </li>
                <li>
                  <span className="name">Lynda</span>
                  <div className="progress-bar-wrap">
                    <div className="progress-container">
                      <div className="progress-bar">
                        <span
                          className="progress violet"
                          style={{width: "60%"}}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="count-no">60</div>
                </li>
                <li>
                  <span className="name">Shane William</span>
                  <div className="progress-bar-wrap">
                    <div className="progress-container">
                      <div className="progress-bar">
                        <span className="progress pink" style={{width: "75%"}}></span>
                      </div>
                    </div>
                  </div>
                  <div className="count-no">75</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="crm-sctn-cntr three-sctn">
                <div className="sctn-lhs flexrow">
                    <div className="crm-grid-stack mt0">
                        <h2 className="title">Leads by Source This Month</h2>
                        <div className="crm-noDataCont">
                            <div className="">
                                <span className="crm-noDataImg"></span>
                            </div>
                            <p className="crm-noDataText">No data available</p>
                        </div>
                    </div>
                    <div className="crm-grid-stack mt0">
                        <h2 className="title">Top 20 Accounts By Revenue This Year</h2>
                        <div className="crm-table cst-mgn topacnts-tbl">
                            <div className="crm-tb-hed">
                                <div className="crm-tb-rw">
                                        <div className="crm-td crm-td-acntname">
                                            <span className="spcng">Account Name</span>
                                        </div>
                                        <div className="crm-td crm-td-amt">
                                            <span>Amount</span>
                                        </div>
                                </div>
                            </div>
                            <div className="crm-tb-bdy">
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">IDrive</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div>   
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Apple</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$50,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Microsoft</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Google</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$80,000</span>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sctn-rhs">
                    <div className="crm-grid-stack mt0">
                        <h2 className="title">Pipeline By Reps This Quarter</h2>
                        <div className="crm-table cst-mgn topacnts-tbl">
                            <div className="crm-tb-hed">
                                <div className="crm-tb-rw">
                                        <div className="crm-td crm-td-acntname">
                                            <span className="spcng">Deal Owner</span>
                                        </div>
                                        <div className="crm-td crm-td-amt">
                                            <span>Amount</span>
                                        </div>
                                </div>
                            </div>
                            <div className="crm-tb-bdy">
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">John</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div>   
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Alice Maria</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$50,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Shane William</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Lynda</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$80,000</span>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="crm-sctn-cntr three-sctn">
                <div className="sctn-lhs flexrow">
                    <div className="crm-grid-stack mt0">
                        <h2 className="title">Top 10 Reps By Tasks This Month</h2>
                        <div className="crm-table cst-mgn topacnts-tbl">
                            <div className="crm-tb-hed">
                                <div className="crm-tb-rw">
                                        <div className="crm-td crm-td-acntname">
                                            <span className="spcng">Deal Owner</span>
                                        </div>
                                        <div className="crm-td crm-td-amt">
                                            <span>Amount</span>
                                        </div>
                                </div>
                            </div>
                            <div className="crm-tb-bdy">
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">John</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div>   
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Alice Maria</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$50,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Shane William</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Lynda</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$80,000</span>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                    <div className="crm-grid-stack mt0">
                        <h2 className="title">Top 10 Reps By Revenue This Quarter</h2>
                        <div className="crm-table cst-mgn topacnts-tbl">
                            <div className="crm-tb-hed">
                                <div className="crm-tb-rw">
                                        <div className="crm-td crm-td-acntname">
                                            <span className="spcng">Deal Owner</span>
                                        </div>
                                        <div className="crm-td crm-td-amt">
                                            <span>Amount</span>
                                        </div>
                                </div>
                            </div>
                            <div className="crm-tb-bdy">
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">John</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div>   
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Alice Maria</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$50,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Shane William</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$60,000</span>
                                    </div>
                                </div> 
                                <div className="crm-tb-rw">
                                    <div className="crm-td crm-td-acntname">
                                        <span className="spcng">Lynda</span>
                                    </div>
                                    <div className="crm-td crm-td-amt">
                                        <span>$80,000</span>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sctn-rhs">
                    <div className="crm-grid-stack mt0 deals-type">
                        <div className="crm-tabs">
                            <div className="crm-tab-links">
                                <ul>
                                    <li>
                                        <a href="#" title="Open Deals" className="crm-tblinks active" onclick="openTab(event, 'openDeals')">Open Deals</a>
                                    </li>
                                    <li>
                                        <a href="#" title="Closed Deals" className="crm-tblinks" onclick="openTab(event, 'closedDeals')">Closed Deals</a>
                                    </li>
                                    <li>
                                        <a href="#" title="Big Deals Lost" className="crm-tblinks" onclick="openTab(event, 'bigDealsLost')">Big Deals Lost</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="crm-tabs-contctnr">
                                <div className="crm-tab-content active" id="openDeals">
                                    <h2 className="title">Open Deals This Month</h2>
                                    <div className="crm-table cst-mgn topacnts-tbl">
                                        <div className="crm-tb-hed">
                                            <div className="crm-tb-rw">
                                                    <div className="crm-td crm-td-acntname">
                                                        <span className="spcng">Deal Name</span>
                                                    </div>
                                                    <div className="crm-td crm-td-amt">
                                                        <span>Amount</span>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="crm-tb-bdy">
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Commercial Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div>   
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Morlong Associates</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$50,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Feltz Printing Services</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Printing Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$80,000</span>
                                                </div>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div className="crm-tab-content" id="closedDeals">
                                    <h2 className="title">Closed Deals This Month</h2>
                                    <div className="crm-table cst-mgn topacnts-tbl">
                                        <div className="crm-tb-hed">
                                            <div className="crm-tb-rw">
                                                    <div className="crm-td crm-td-acntname">
                                                        <span className="spcng">Deal Name</span>
                                                    </div>
                                                    <div className="crm-td crm-td-amt">
                                                        <span>Amount</span>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="crm-tb-bdy">
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Commercial Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div>   
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Morlong Associates</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$50,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Feltz Printing Services</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Printing Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$80,000</span>
                                                </div>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div className="crm-tab-content" id="bigDealsLost">
                                    <h2 className="title">Big Deals Lost This Month</h2>
                                    <div className="crm-table cst-mgn topacnts-tbl">
                                        <div className="crm-tb-hed">
                                            <div className="crm-tb-rw">
                                                    <div className="crm-td crm-td-acntname">
                                                        <span className="spcng">Deal Name</span>
                                                    </div>
                                                    <div className="crm-td crm-td-amt">
                                                        <span>Amount</span>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="crm-tb-bdy">
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Commercial Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div>   
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Morlong Associates</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$50,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Feltz Printing Services</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$60,000</span>
                                                </div>
                                            </div> 
                                            <div className="crm-tb-rw">
                                                <div className="crm-td crm-td-acntname">
                                                    <span className="spcng">Printing Press</span>
                                                </div>
                                                <div className="crm-td crm-td-amt">
                                                    <span>$80,000</span>
                                                </div>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </React.Fragment>
  );
};

export default DashboardManagerView;
