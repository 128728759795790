import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const saveInputConfigAPI = (forModule, reqPayload) => {
  let configUrl;

  switch (forModule) {
    case "leads":
      configUrl = "/leads/lead_config_update/";
      break;
    case "deals":
      configUrl = "/deals/deal_config_update/";
      break;
    case "accounts":
      configUrl = "/accounts/account_config_update/";
      break;
    case "contacts":
      configUrl = "/contacts/contact_config_update/";
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL + configUrl,
        { config_fields: reqPayload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getSavedInputConfigAPI = (forModule) => {
  let configUrl;

  switch (forModule) {
    case "leads":
      configUrl = "/leads/fetch_lead_config/";
      break;
    case "deals":
      configUrl = "/deals/fetch_deal_config/";
      break;
    case "accounts":
      configUrl = "/accounts/fetch_account_config/";
      break;
    case "contacts":
      configUrl = "/contacts/fetch_contact_config/";
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + configUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
