import React from "react";
import InputText from "../inputs/inputText";
import InputDropdownSelect from "../inputs/inputDropdownSelect";

export default function LeadInputs(props) {
  return (
    <React.Fragment>
      <div className="field-type">
        <div
          className="first-col col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="firstName"
          data-real_input_name="First name"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>First Name</span>
          <InputText />
        </div>
        <div
          className="second-col col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="lastName"
          data-real_input_name="Last name"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Last Name</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="leadRole"
          data-real_input_name="Role"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Role</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="email"
          data-real_input_name="Email"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Email</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="leadPhone"
          data-real_input_name="Phone"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Phone</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="leadMobile"
          data-real_input_name="Mobile"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Mobile</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="shippingCountry"
          data-real_input_name="Country"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Country</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="shippingState"
          data-real_input_name="State"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>State</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="shippingCity"
          data-real_input_name="City"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>City</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="shippingPincode"
          data-real_input_name="Pin/Zip code"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Pin/Zip code</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="shippingStreet"
          data-real_input_name="Street"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Street</span>
          <InputText />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_6"
          data-real_input_id="industry"
          data-real_input_name="Industry"
          onDragStart={(e) => props.functions.handleDragStart(e, 6)}
        >
          <span>Industry</span>
          <InputDropdownSelect />
        </div>
        <div
          className="col"
          draggable="true"
          id="single-line_1"
          data-real_input_id="description"
          data-real_input_name="Additional notes"
          onDragStart={(e) => props.functions.handleDragStart(e, 1)}
        >
          <span>Note</span>
          <InputText />
        </div>
      </div>
    </React.Fragment>
  );
}
