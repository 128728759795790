import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useOutside } from "../../customHooks/useOutside";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import PageNavigator from "./pageNavigator";
import { utf8_to_b64 } from "../../utils";

const NewPagination = forwardRef(({ totalRecords, functions }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [pSize, setPSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const currentPage = useRef({ value: 1, prevPageSize: 10 });

  const sizeSelector = useRef(null);

  useImperativeHandle(ref, () => ({
    //resetting to default page after any activity
    resetDefaultPage() {
      if (activePage !== 1) {
        setActivePage(1);
        currentPage.current.value = 1;
      }
    },
  }));

  const handlepageSelector = () => {
    document.getElementById("pageSelector").classList.toggle("open");
  };

  useOutside(sizeSelector, () => {
    document.getElementById("pageSelector").classList.remove("open");
  });

  const handleDefaultPagination = async (e) => {
    setActivePage(1);
    currentPage.current.value = 1;
    setPSize(parseInt(e.target.dataset.size));
    functions.handlePaginatedFetchData({
      offset: 0,
      limit: parseInt(e.target.dataset.size),
    });
    document.getElementById("pageSelector").classList.toggle("open");
    document
      .getElementById("size-" + e.target.dataset.size)
      .classList.toggle("selected");
    document
      .getElementById("size-" + currentPage.current.prevPageSize)
      .classList.remove("selected");
    currentPage.current.prevPageSize = e.target.dataset.size;

    //need to optimize the reloading of data in the view and then enable this
    // navigate({
    //   pathname: location.pathname,
    //   search: createSearchParams({
    //     records: e.target.dataset.size,
    //     tNode: utf8_to_b64(String(Date.now())),
    //   }).toString(),
    // });
  };

  const handlePageChange = (e) => {
    const id = String(e.target.id).split("_")[1];

    currentPage.current.value = parseInt(id);
    functions.handlePaginatedFetchData({
      limit: pSize,
      offset: parseInt(id) * pSize - pSize,
    });
    setTimeout(() => {
      setActivePage(currentPage.current.value);
    }, 200);
  };

  const handlePageNav = (type) => {
    if (activePage > 0) {
      let id = 0;
      if (type === "n") {
        id = activePage + 1;
      } else if (type === "p") {
        id = activePage - 1;
      } else {
        return;
      }
      const e = {
        target: {
          id: "navigate_" + id,
        },
      };
      handlePageChange(e);
    }
  };

  const handlePageNavigation = (pageNumber) => {
    if (pageNumber === currentPage.current.value) return;
    currentPage.current.value = parseInt(pageNumber);
    functions.handlePaginatedFetchData({
      offset: pageNumber * pSize - pSize,
      limit: pSize,
    });
    setTimeout(() => {
      setActivePage(currentPage.current.value);
    }, 200);
  };

  return (
    <React.Fragment>
      <div className="crm-pagination">
        <div className="select-row">
          <div className="crm-form-grp">
            <div className="crm-custom-select-wrap">
              <div
                id="pageSelector"
                className="custom-select"
                ref={sizeSelector}
              >
                <div
                  className="crm-custom-select-value"
                  onClick={() => handlepageSelector()}
                >
                  <span>
                    {pSize} records <span className="per-page">per page</span>
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  <span
                    id="size-10"
                    data-size="10"
                    className="crm-custom-option selected"
                    onClick={(e) => handleDefaultPagination(e)}
                  >
                    10 records per page
                  </span>
                  <span
                    id="size-20"
                    data-size="20"
                    className="crm-custom-option"
                    onClick={(e) => handleDefaultPagination(e)}
                  >
                    20 records per page
                  </span>
                  <span
                    id="size-50"
                    data-size="50"
                    className="crm-custom-option"
                    onClick={(e) => handleDefaultPagination(e)}
                  >
                    50 records per page
                  </span>
                  <span
                    id="size-100"
                    data-size="100"
                    className="crm-custom-option"
                    onClick={(e) => handleDefaultPagination(e)}
                  >
                    100 records per page
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav>
          {activePage != 1 ? (
            <a className="crm-lft-arw" onClick={() => handlePageNav("p")}>
              <span className="crm-action-left-arw"></span>
            </a>
          ) : (
            <a className="crm-lft-arw">
              <span className="crm-action-left-arw"></span>
            </a>
          )}
          <a className="num">
            {totalRecords
              ? `${activePage} / ${Math.ceil(totalRecords / pSize)}`
              : "- / --"}
          </a>
          {totalRecords && activePage != Math.ceil(totalRecords / pSize) ? (
            <a className="crm-rgt-arw" onClick={() => handlePageNav("n")}>
              <span className="crm-action-right-arw"></span>
            </a>
          ) : (
            <a className="crm-rgt-arw">
              <span className="crm-action-right-arw"></span>
            </a>
          )}
        </nav>

        <PageNavigator
          currentPage={activePage}
          maxPageSize={Math.ceil(totalRecords / pSize)}
          functions={{ handlePageNavigation: handlePageNavigation }}
        />
      </div>
    </React.Fragment>
  );
});

export default NewPagination;
