import React, { useEffect, useRef, useState } from "react";
import { APP_DATA } from "../../../app_data";
import { echo, forEach, getObjValue, leadsFormatter } from "../../../utils";
import NoResults from "../../common/noResults";
import DataTableLoader from "../../common/dataTableLoader";
import createResizer from "../../../utils/resizerHandler";
import { getModuleDataTypesAPI } from "../../../apis/workflowAPIs";
import { Tooltip } from "react-tooltip";

export default function LeadsTable(props) {
  const selectAllCheck = useRef(null);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [columnInfo, setColumnInfo] = useState([]);
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    if (props.updateData.length) {
      getModuleDataTypesAPI(props.module)
        .then((res) => {
          setColumnInfo(res.data);
          const displayName = res.data?.reduce((acc, curr) => {
            acc[curr.field] = curr.display_name;
            return acc;
          }, {});
          unCheckRecords();
          setDisplayName(displayName);
          setTimeout(() => {
            createResizer();
          }, 200);
        })
        .catch((err) => {
          setColumnInfo([]);
          setDisplayName({});
        });
    }
  }, [props.updateData]);

  const unCheckRecords = () => {
    if (props.deleteIdsList.current?.length > 0) {
      props.deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      props.deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!props.deleteIdsList.current.includes(parseInt(e.target.id))) {
        props.deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (props.deleteIdsList.current?.length === props.updateData?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = props.deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        props.deleteIdsList.current.splice(i, 1);
      }
      if (props.deleteIdsList.current?.length !== props.updateData?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (props.deleteIdsList.current.length > 0) {
      props.setBtnDelete(true);
    } else {
      props.setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  return (
    <div className="tbl-wrpr mass-update">
      {!props.loading &&
        props.updateData?.length > 0 &&
        Object.keys(displayName).length > 0 && (
          <React.Fragment>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                // onClick={
                //   leadsPermission?.view
                //     ? () =>
                //         handleRSidebarManageColumns("manageColumns", {
                //           applyManageColumn,
                //           clearManageColumn,
                //           columns: columnInfo,
                //           module: "leads",
                //           sessModule: "leadsColumn",
                //         })
                //     : null
                // }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </React.Fragment>
        )}
      <div className="crm-table cst-mrgn mtbl mass-update">
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-fixed-field">
              {props.updateData?.length > 0 &&
                Object.keys(displayName).length > 0 &&
                !props.loading && (
                  <div className="crm-td crm-td-leads">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        className="crm-checkmark"
                        ref={selectAllCheck}
                        id="leads_check"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={props.updateData?.length ? false : true}
                      />
                      <label htmlFor="leads_check"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "lead_fullname"
                            ? "sort-highlight"
                            : ""
                        }
                        id="lead"
                        // onClick={(e) => {
                        //   handleSort(e, "lead_fullname", "leadFullName");
                        // }}
                      >
                        {/* Lead name */}
                        {displayName["lead_fullname"]}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "lead_fullname"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="leadFullName"
                      // onClick={(e) =>
                      //   handleSort(e, "lead_fullname", "leadFullName")
                      // }
                    ></i>
                  </div>
                )}
            </div>
            <div className="crm-scroll-field">
              {!props.loading &&
                props.updateData?.length > 0 &&
                Object.keys(displayName).length > 0 &&
                Object.keys(props.updateData[0]).map(
                  (heading, index) =>
                    !APP_DATA.hiddenColumns.leads.includes(heading) && (
                      <div
                        id={`col-${index - 1}`}
                        className={`crm-td r  ${
                          sortInfo.sortKey === heading
                            ? sortInfo.sortBy === "desc"
                              ? "crm-sort sort-active"
                              : "crm-sort sort-active-up"
                            : ""
                        }`}
                        //TODO add key in all the modules
                      >
                        <span
                        // onClick={(e) =>
                        //   handleSort(e, heading, `col-${index - 1}`)
                        // }
                        >
                          {echo(displayName[heading])}
                        </span>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="crm-tb-bdy">
          {!props.loading ? (
            Object.keys(displayName).length > 0 &&
            props.updateData.length > 0 ? (
              props.updateData.map((l, i) => (
                <div className="crm-tb-rw">
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-leads d-flex">
                      <input
                        type="checkbox"
                        id={l.lead_id}
                        onChange={(e) => {
                          handleDeleteCheck(e);
                        }}
                        className="crm-checkmark ids"
                      />
                      <label className="email-id" title="" htmlFor={l.lead_id}>
                        <span>
                          {echo(l.lead_firstname + " " + l.lead_lastname)}
                        </span>
                      </label>
                      {/* <div className="action-rw"></div> */}
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {props.updateData?.length > 0 &&
                      Object.keys(props.updateData[0])?.map(
                        (heading, i) =>
                          !APP_DATA.hiddenColumns.leads.includes(heading) && (
                            <div
                              key={i}
                              data-colpos={i - 1}
                              className={`crm-td `}
                            >
                              <span>
                                {!APP_DATA.formattingColumns.leads.includes(
                                  heading
                                )
                                  ? echo(getObjValue(l, heading))
                                  : leadsFormatter(
                                      heading,
                                      getObjValue(l, heading)
                                    )}
                              </span>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <NoResults message={props.noResultMessage} />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-leads"} module="lead" />
          )}
        </div>
      </div>
    </div>
  );
}
