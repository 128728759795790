import axios from "axios";
import { APISuccessResponse, axiosPrivate, getAuthToken } from ".";
import { API_URL } from ".";
import CONSTANTS from "../app_data/constants";

export const getAllLeads = async () => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/leads")
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getLeadsLimitNoffset = async (
  offset = 0,
  limit = 10,
  leadFilter,
  leadOwner,
  orderBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(
        `/leads/offset?offset=${offset}&limit=${limit}&count=${true}&lead_filter=${
          leadFilter ? leadFilter : "All Leads"
        }${leadOwner ? `&lead_owner=${leadOwner}` : ""}&lead_field=${
          sortField ? sortField : "Created date"
        }${orderBy ? `&order_by=${orderBy}` : ""}`
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getLeadsLimitOffset = async (
  offset = 0,
  limit = 10,
  count = false,
  leadFilter,
  leadOwner,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(
        `/leads/offset?offset=${offset}&limit=${limit}&count=${true}&lead_filter=${
          leadFilter ? leadFilter : "All Leads"
        }${leadOwner ? `&lead_owner=${leadOwner}` : ""}&lead_field=${
          sortField ? sortField : "Created date"
        }${sortBy ? `&order_by=${sortBy}` : ""}`
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getFilteredLeads = async (searchText) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/leads/name/" + searchText)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getLeadsByName = async (leadName, excludeConverted) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(
        `/leads/name/${leadName}${
          excludeConverted ? `?exclude_converted=${excludeConverted}` : ""
        }`
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewLead = async (reqPayload, webform = false) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post("/leads/", reqPayload, {
        headers: {
          Authorization: webform
            ? "Bearer " + CONSTANTS.encryptAppData()
            : getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateLeadById = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put("/leads/" + id, reqPayload)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteLead = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .delete("/leads/?deleted_by=" + userId, {
        data: reqPayload,
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getLeadById = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/leads/" + id)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const sendEmailAPI = async (leadId,address) => {
  return new Promise ((resolve, reject) => {
    axios
      .post(API_URL + `/leads/send_email/?lead_id=${leadId}`,address, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async(res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: []});
      })
  })
}