import React from "react";

export default function SingleLineText(props) {
  const inputProperties = {
    id: props.conf.id,
    name:props.conf.name,
    label: props.conf.label,
    required: props.conf.required,
    realId:props.conf.real_id,
    realName:props.conf.real_name
  };

  return (
    <React.Fragment>
      <div className={inputProperties.required ? "crm-form-field req" : "crm-form-field"}>
        <input
          type="text"
          placeholder=" "
          id={inputProperties.id}
          name={inputProperties.name}
          className="crm-form-ctrl floating-input"
          onChange={(e) => props.formChangeHandler(e)}
          defaultValue={props.defaultValue}
          data-real_id = {inputProperties.realId}
          data-real_name={inputProperties.realName}
        />
        <label
          htmlFor={inputProperties.id}
          placeholder={inputProperties.label}
          className="floating-label"
        >
          {inputProperties.label}
          {/* <sup>{inputProperties.required ? "*" : ""}</sup> */}
        </label>
        <span className="crm-inp-error" id={`err_${inputProperties.id}`}></span>
      </div>
    </React.Fragment>
  );
}
