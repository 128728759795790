import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useLocation } from "react-router";
import { getProfileById, updateProfileById } from "../apis/profileAPI";
import NoResults from "../components/common/noResults";
import DataTableLoader from "../components/common/dataTableLoader";
import { APP_DATA } from "../app_data";
import { echo } from "../utils";

const PermissionView = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { handleRSidebar, openToast } = useOutletContext();

  const [profile, setProfile] = useState(null);
  const [userAccess, setUserAccess] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasScroll,setHasScroll] = useState(false);

  useEffect(() => {
    if (state?.id) {
      getProfile(state.id);
    }
  }, [state?.id]);

  const getProfile = (id) => {
    setLoading(true);
    getProfileById(id)
      .then((res) => {
        if (res.status) {
          setProfile(res.data);
          setUserAccess(res.data?.profile_user_access || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setProfile(null);
        console.log(err);
      });
  };


  useEffect(() => {
    const element = document.querySelector(".crm-tb-bdy");
    if (element) {
      const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  }, [userAccess]);

  const onPermissionChange = (e) => {
    const module = e.target.getAttribute("data-module");
    if (userAccess?.length > 0) {
      const updatedUserAccess = structuredClone(userAccess);
      let el = updatedUserAccess.find((u) => u.module === module);
      if (el?.permission?.hasOwnProperty(e.target.name)) {
        if (e.target.name === "view" && e.target.checked) {
          el.permission[e.target.name] = e.target.checked;
          el.status = e.target.checked;
          setUserAccess(updatedUserAccess);
          return;
        }
        el.permission[e.target.name] = e.target.checked;
        if (
          !el.permission.edit &&
          !el.permission.create &&
          !el.permission.delete
        ) {
          el.permission.view = false;
          el.status = false;
        } 
        else{
          el.permission.view = true;
          el.status = true;
        }
        setUserAccess(updatedUserAccess);
      }
    }
  };

  const onModuleAccessChange = (e) => {
    const module = e.target.getAttribute("data-module");
    if (userAccess?.length > 0) {
      const updatedUserAccess = structuredClone(userAccess);
      let el = updatedUserAccess.find((u) => u.module === module);
      if (el?.hasOwnProperty("status")) {
        el.status = e.target.checked;
        if (!e.target.checked) {
          el.permission = {
            create: false,
            edit: false,
            delete: false,
            view: false,
          };
        }
        else {
          el.permission = {
            create: true,
            edit: true,
            delete: true,
            view: true,
          };
        }
        setUserAccess(updatedUserAccess);
      }
    }
  };

  const handleSave = (e) => {
    document.getElementById(e.target.id).classList.add("btnLoader");
    const payload = { profile_user_access: userAccess };
    updateProfileById(state.id, payload)
      .then((res) => {
        if (res.status) {
          setIsEdit(false);
          getProfile(state.id);
          openToast(APP_DATA.messages.notification, "success");
        }
        document.getElementById(e.target.id).classList.remove("btnLoader");
      })
      .catch((err) => {
        console.log(err);
        document.getElementById(e.target.id).classList.remove("btnLoader");
        openToast("Something went wrong", "error");
      });
  };

  const handleCancel = () => {
    if (profile?.profile_user_access) {
      setUserAccess(profile?.profile_user_access);
    }
    setIsEdit(false);
  };

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i
            className="crm-back-btn"
            data-original-title="Back"
            data-placement="bottom"
            onClick={() => navigate(-1,{replace:true})}
          ></i>
          <div className="header_title">
            <span className="title">Profile</span> 
            <span className="title-info">
              {profile?.profile_name && `(${profile.profile_name})`}
            </span>
          </div>
        </div>
        <div className="header_rhs">
          {!APP_DATA.restrictedProfiles.includes(state?.profile_name) &&
            !isEdit && (
              <button
                type="button"
                className="btn btn-secondary edit-optn"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </button>
            )}
          {!isEdit && (
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  handleRSidebar("createProfile", {
                    id: state.id,
                    profileName: profile?.profile_name,
                  });
                }}
              >
                Clone
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  handleRSidebar("viewUsers", {
                    isView: true,
                    id: state.id,
                    name: profile?.profile_name,
                  });
                }}
              >
                View Users
              </button>
            </>
          )}
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div className="header_title">
            <strong>Basic Permissions</strong>{" "}
            <span className="">
              {profile?.profile_description
                ? `(${profile.profile_description})`
                : ""}
            </span>
          </div>
        </div>
      </div>
      <div className="prf-info">
        <div className={
          isEdit
          ?"crm-table prf-tbl cst-mrgn"
          :"crm-table prf-tbl cst-mrgn disabled"}>
          <div className="crm-tb-hed">
            <div className="crm-tb-rw">
              <div className="crm-td">
                <span>Modules</span>
              </div>
            </div>
          </div>
          <div className={"crm-tb-bdy"}>
            {!loading ? (
              userAccess?.length > 0 ? (
                userAccess.map((p, index,arr) => (
                  <div className="crm-tb-rw" key={index}>
                    <div className="crm-td profiles">
                      <input
                        type="checkbox"
                        className="crm-checkmark"
                        id={p.module}
                        checked={p.status}
                        data-module={p.module}
                        onChange={(e) => {
                          onModuleAccessChange(e);
                        }}
                        disabled={!isEdit}
                      />
                      <label htmlFor={p.module}>
                        <span>{echo(p?.module)}</span>
                      </label>
                    </div>
                    <div className="crm-td actions">
                      <div className="prvlg-row">
                        {isEdit && (
                          <ul className={`crm-dropdown-action ${ index >=arr.length-4 && hasScroll ? "showdrpTop":""}`} id="">
                            <p>Actions</p>
                            <li>
                              <input
                                type="checkbox"
                                className="crm-checkmark"
                                id={"view" + p.module}
                                name="view"
                                data-module={p.module}
                                checked={p.permission?.view}
                                onChange={(e) => onPermissionChange(e)}
                                disabled={!isEdit}
                              />
                              <label htmlFor={"view" + p.module}>
                                <span>View records</span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                className="crm-checkmark"
                                id={"create" + p.module}
                                name="create"
                                data-module={p.module}
                                checked={p.permission?.create}
                                onChange={(e) => onPermissionChange(e)}
                                disabled={!isEdit}
                              />
                              <label htmlFor={"create" + p.module}>
                                <span>Create Records</span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                className="crm-checkmark"
                                id={"edit" + p.module}
                                name="edit"
                                data-module={p.module}
                                checked={p.permission?.edit}
                                onChange={(e) =>
                                  onPermissionChange(e, p?.module)
                                }
                                disabled={!isEdit}
                              />
                              <label htmlFor={"edit" + p.module}>
                                <span>Edit owned records</span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                className="crm-checkmark"
                                id={"delete" + p.module}
                                name="delete"
                                data-module={p.module}
                                checked={p.permission?.delete}
                                onChange={(e) => onPermissionChange(e)}
                                disabled={!isEdit}
                              />
                              <label htmlFor={"delete" + p.module}>
                                <span>Delete owned records</span>
                              </label>
                            </li>
                          </ul>
                        )}
                        <div
                          className={`each-privilege ${
                            !p.permission?.view ? "disabled" : ""
                          }`}
                        >
                          <span>View</span>
                        </div>
                        <div
                          className={`each-privilege ${
                            !p.permission?.create ? "disabled" : ""
                          }`}
                        >
                          <span>Create</span>
                        </div>
                        <div
                          className={`each-privilege ${
                            !p.permission?.edit ? "disabled" : ""
                          }`}
                        >
                          <span>Edit</span>
                        </div>
                        <div
                          className={`each-privilege ${
                            !p.permission?.delete ? "disabled" : ""
                          }`}
                        >
                          <span>Delete</span>
                        </div>
                        <div className="prvg-options">
                          <a className="down-arrow"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NoResults messages={"permission"} />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-prfname"}
                module={"profiles"}
              />
            )}
          </div>
          {isEdit && (
            <div className="crm-form-foot">
              <div className="crm-btn">
                <div className="crm-btn-left">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => handleSave(e)}
                    id="profileSave"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PermissionView;
