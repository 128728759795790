import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getAllCalls = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/calls/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getCallsLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = false,
  callFilter,
  callOwner,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/calls/offset?offset=${offset}&limit=${limit}&count=${count}&call_filter=${
            callFilter ? callFilter : "All Calls"
          }${callOwner ? `&call_owner=${callOwner}` : ""}&call_field=${
            sortField ? sortField : "Created date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const scheduleNewCall = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/calls/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getCallById = (callId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/calls/" + callId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteCall = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/calls/?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateCallById = (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/calls/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewCall = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/calls", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
