import React, { forwardRef, useEffect, useRef, useState } from "react";
import { getUsersByName, getUsersLimitNoffset } from "../../../apis/usersAPI";
import { useOutside } from "../../../customHooks/useOutside";

const UserEmailSelector = (props) => {
  const [users, setUsers] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [usersFound, setUserFound] = useState(true);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [defaultEmailIds, setDefaultEmailIds] = useState([]);
  const [parentLabel, setParentLabel] = useState(props.settings["label"]);

  const userSearchView = useRef(null);
  const userSeachInputDropdown = useRef(null);
  const [selected, setSelected] = useState(
    structuredClone({ [props.settings["label"]]: [] })
  );

  useEffect(() => {
    setParentLabel(props.settings["label"]);
    setDefaultEmailIds(props.defaultEmailIds);
    setLoadedUsers(props.loadedUsers);
  }, [props.settings["label"], props.defaultEmailIds, props.loadedUsers]);

  useEffect(() => {
    if (props.settings["mode"] === "edit") {
      loopFurnishAndAppend();
    }
  }, [defaultEmailIds, loadedUsers]);

  const getUsers = async (limit = 20, search = "") => {
    setUsers([]);
    let users = {};
    if (limit) {
      users = await getUsersLimitNoffset(0, limit, false, "Active Users");
    } else {
      users = await getUsersByName(search);
    }

    if (users.status) {
      if (!users.data.length) {
        setUserFound(false);
      } else {
        setUserFound(true);
      }
      setUsers(users.data);
    }
  };

  const handleDropdownSearch = (e, type = "default") => {
    /**
     * making sure api gets called only once
     * by default it will provide 20 or specified number of records
     * if not found, user can type in the input field and get the desired lead
     */
    const realId = String(e.target.id).split("-")[0];
    const inputValue = String(e.target.value).trim();
    if (inputValue === "") {
      type = "default";
    }
    switch (realId) {
      case "userEmail":
        userSearchView.current.style.display = "block";
        if (!users.length && type === "default" && inputValue === "") {
          getUsers(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else if (type === "default" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else {
          return;
        }
        break;
      default:
        break;
    }
  };

  const selectDropdown = async (e) => {
    /**
     * pass the select event through form change handler
     * and accordingly manipulate elements
     */
    const type = parentLabel;
    const forIndex = parseInt(props.forIndex);
    const evId = String(e.target.id).split("_")[1];

    if (e.target.checked) {
      const selectedCopy = { ...selected };
      const usersInfo = users.find((l) => l.user_id === parseInt(evId));
      const usersData = {
        for: type,
        id: usersInfo.user_id,
        email: usersInfo.user_email,
        name: usersInfo.user_firstname + " " + usersInfo.user_lastname,
      };
      const dnode = await furnishNode(usersData, forIndex);
      if (dnode.status && typeof dnode.node === "object") {
        selectedCopy[type].push(evId);
        props.functions.userSelectionManipulator(
          { index: forIndex, type: props.settings["recipient"], id: evId },
          "add"
        );
        document
          .getElementById("userBox" + type + "-" + forIndex)
          .appendChild(dnode.node);
        document.getElementById(
          "err_email_" + String(type).toLocaleLowerCase() + "-" + props.forIndex
        ).style.display = "none";
      }
      setSelected(selectedCopy);
      setUserIds(selectedCopy[parentLabel]);
      document.getElementById(
        "userEmail-" + parentLabel + props.forIndex
      ).value = "";
    } else {
      const te = {
        target: {
          id: type + "iSpan_" + evId,
          email: type + "iSpan_" + evId,
        },
      };
      await removeSelectedLD(te, type);
    }
  };

  const furnishNode = async (nodeData, forIndex) => {
    return new Promise((resolve, reject) => {
      try {
        const selectedCopy = { ...selected };
        if (!selectedCopy[nodeData.for].includes(String(nodeData.id))) {
          const spanNode = document.createElement("span");
          const iNode = document.createElement("i");
          spanNode.className = "appendItem";
          spanNode.id = nodeData.for + forIndex + "span_" + nodeData.id;
          iNode.className = "itemClose";
          iNode.id = nodeData.for + "i_" + nodeData.id;
          iNode.addEventListener("click", (e) => {
            const id = String(e.target.id).split("_")[1];
            const el = document.getElementById(
              nodeData.for + forIndex + "span_" + id
            );
            const index = selectedCopy[nodeData.for].indexOf(id);
            props.functions.userSelectionManipulator(
              { index: forIndex, type: props.settings["recipient"], id: id },
              "remove"
            );
            if (index > -1) {
              const selectedCheckbox = document.getElementById(
                forIndex + `inputCheck${nodeData.for}_` + id
              );
              selectedCopy[nodeData.for].splice(index, 1);
              if (selectedCheckbox) {
                selectedCheckbox.checked = false;
              }
              el.remove();
              setSelected(selectedCopy);
            }
          });
          spanNode.innerText = nodeData.email;
          spanNode.title = nodeData.name;
          spanNode.append(iNode);
          resolve({ status: true, node: spanNode });
        } else {
          resolve({ status: false, node: null });
        }
      } catch (error) {
        console.log(error);
        reject({ status: false, error: error });
      }
    });
  };

  const removeSelectedLD = (e, from) => {
    let midRId = `inputCheck${from}_`;
    return new Promise((resolve, reject) => {
      try {
        const selectedCopy = { ...selected };
        const id = String(e.target.id).split("_")[1];
        const el = document.getElementById(
          from + props.forIndex + "span_" + id
        );
        const index = selectedCopy[from].indexOf(id);
        props.functions.userSelectionManipulator(
          { index: props.forIndex, type: props.settings["recipient"], id: id },
          "remove"
        );
        if (index > -1) {
          const selectedCheckbox = document.getElementById(
            props.forIndex + midRId + id
          );
          selectedCopy[from].splice(index, 1);
          if (selectedCheckbox) {
            selectedCheckbox.checked = false;
          }
          el.remove();
          setSelected(selectedCopy);
        }
        setUserIds(selectedCopy[parentLabel]);
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });
  };

  const loopFurnishAndAppend = () => {
    if (defaultEmailIds.length && loadedUsers.length) {
      defaultEmailIds.map(async (eId, i) => {
        const usersInfo = loadedUsers.find((l) => l.user_email === String(eId));
        const usersData = {
          for: parentLabel,
          id: String(usersInfo.user_id),
          email: usersInfo.user_email,
          name: usersInfo.user_firstname + " " + usersInfo.user_lastname,
        };
        const selectedCopy = { ...selected };
        const dnode = await furnishNode(usersData, props.forIndex);
        if (dnode.status && typeof dnode.node === "object") {
          selectedCopy[parentLabel].push(usersData.id);
          props.functions.userSelectionManipulator(
            {
              index: props.forIndex,
              type: props.settings["recipient"],
              id: usersData.id,
            },
            "add"
          );
          document
            .getElementById("userBox" + parentLabel + "-" + props.forIndex)
            .appendChild(dnode.node);
          setSelected(selectedCopy);
        }
        setUserIds(selectedCopy[parentLabel]);
      });
    }
  };

  useOutside(userSeachInputDropdown, () => {
    userSearchView.current.style.display = "none";
  });

  return (
    <React.Fragment>
      <div className="shareSec" ref={userSeachInputDropdown}>
        <div className="shareBox">
          <div
            className="append_box"
            id={"userBox" + parentLabel + "-" + props.forIndex}
            style={{ display: "block" }}
          ></div>
          <div className="shareBox-input">
            <input
              type="text"
              name="email"
              placeholder=""
              multiple={true}
              autoComplete="off"
              id={"userEmail-" + parentLabel + props.forIndex}
              className="input-hidden floating-input"
              onClick={(e) => handleDropdownSearch(e)}
              onChange={(e) => handleDropdownSearch(e, "search")}
            />
            <label
              alt="userEmail"
              htmlFor={"userEmail-" + parentLabel + props.forIndex}
              placeholder="Add email"
              className="floating-label"
            >
              {parentLabel}
            </label>
          </div>
        </div>
        <div
          ref={userSearchView}
          className="crm-auto-sgst mul"
          key={String(userIds.join("-"))}
        >
          <ul>
            {users.length ? (
              users.map((user, i) => (
                <React.Fragment>
                  <li key={"list-user" + parentLabel + i + props.forIndex}>
                    <div className="checkbox-wrap char">
                      <input
                        id={
                          props.forIndex +
                          parentLabel +
                          "inputCheckusers_" +
                          user.user_id
                        }
                        type={"checkbox"}
                        onChange={(e) => selectDropdown(e)}
                        defaultChecked={selected[parentLabel].includes(
                          String(user.user_id)
                        )}
                        className="crm-checkmark"
                      />
                      <label
                        htmlFor={
                          props.forIndex +
                          parentLabel +
                          "inputCheckusers_" +
                          user.user_id
                        }
                      >
                        <div className="profile-icn">
                          <span className="char-a">
                            {String(user.user_firstname)
                              .substring(0, 1)
                              .toUpperCase()}
                          </span>
                        </div>
                        <div className="user-details">
                          <span className="usrname">
                            {user.user_firstname + " " + user.user_lastname}
                          </span>
                          <span className="email-id">
                            {user.user_email || "not available"}
                          </span>
                        </div>
                      </label>
                    </div>
                  </li>
                </React.Fragment>
              ))
            ) : usersFound ? (
              <i className="drpdwn-loader show"></i>
            ) : (
              <div className="no-data">
                <p>Users not found</p>
              </div>
            )}
          </ul>
        </div>
      </div>
      <span
        className="crm-inp-error"
        id={
          "err_email_" +
          String(parentLabel).toLocaleLowerCase() +
          "-" +
          props.forIndex
        }
      ></span>
    </React.Fragment>
  );
};

export default UserEmailSelector;
