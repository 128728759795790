import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const createNewEmailTemplateAPI = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/email_templates/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({status:false,data:[]});
      });
  });
};

export const getEmailTemplatesAPI = (selectFilter, sortBy, sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/email_templates/?email_template_field=${
        sortField ? sortField : "Created date"
      }${sortBy ? `&order_by=${sortBy}` : ""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({status:false,data:[]});
      });
  });
};

export const deleteEmailTemplateAPI = (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/email_templates?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({status:false,data:[]});
      });
  });
};

export const updateEmailTemplateAPI = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/email_templates/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({status:false,data:[]});
      });
  });
};

export const getEmailTemplateByModule = (module, status = true) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL + `/email_templates/module?module=${module}&active=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
          Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({status:false,data:[]});
      });
  });
};


export const getEmailTemplatesOffsetAPI = (
  offset = 0,
  limit = 10,
  templateFilter,
  templateOwner,
  sortBy,
  sortField
) => {
  console.log(
  offset,
  limit ,
  templateFilter,
  templateOwner,
  sortBy,
  sortField)
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/email_templates/offset?offset=${offset}&limit=${limit}&count=${true}&email_template_filter=${templateFilter}${
            templateOwner ? `&email_template_owner=${templateOwner}` : ""
          }&email_template_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};