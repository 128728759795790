import React from "react";

export default function MultiLineText(props) {
  const inputProperties = {
    id: props.conf.id,
    name: props.conf.name,
    label: props.conf.label,
    required: props.conf.required,
    realId:props.conf.real_id,
    realName:props.conf.real_name
  };

  return (
    <React.Fragment>
      <div className="crm-form-field">
        <textarea
          placeholder={inputProperties.label}
          rows="6"
          onChange={(e) => props.formChangeHandler(e)}
          id={inputProperties.id}
          defaultValue={props.defaultValue}
          data-real_id = {inputProperties.realId}
          data-real_name={inputProperties.realName}
        ></textarea>
        <span className="crm-inp-error" id={`err_${inputProperties.id}`}></span>
      </div>
    </React.Fragment>
  );
}
