import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router";
import {
  echo,
  logoForExtension,
  sentenceCaseFormatter,
  groupExtensionByCategory,
  getUserAccessInfo, 
} from "../../utils";
import { getAllExtensions, getExtensionByName } from "../../apis/extensionAPI";
import { APP_DATA } from "../../app_data";

const AllExtensions = forwardRef(({ setView},ref) => {
  const navigate = useNavigate();
  const { handleRSidebar, openToast } = useOutletContext();

  const [extension, setExtension] = useState({});
  const [permission,setPermission] = useState({});

  useEffect(() => {
    fetchAllExtension();
    setPermission(getUserAccessInfo("Extensions")?.permission||{})
  }, []);

  const fetchAllExtension = async () => {
    const extentions = await getAllExtensions();
    if (extentions.status) {
      const extentionData = groupExtensionByCategory(extentions.data);
      setExtension(extentionData);
    } else {
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const extensionRhsPane = (name) => {
    switch (name) {
      case "Zoom Meeting":
        return "activateCredExtension";
      case "Microsoft Teams Meeting":
        return "activateTokenExtension";
      case "Slack":
        return "activateChannelExtension";
      case "Google Meet":
        return "activateFileExtension";
      default:
        return null;
    }
  };

  useImperativeHandle(ref, () => ({
     handleSearch: async (qString) => {
      console.log(qString)
      if(qString?.length) {
          const searched = await getExtensionByName(qString, false );
          console.log(searched)
          if (searched.status) {
            const extentionData = groupExtensionByCategory(searched.data);

            setExtension(extentionData);
          } else {
            setExtension([]);
          }
      } else {
        fetchAllExtension();
      }
    }

  }))



  return (
    <>
      <div
        className="crm-tab-content"
        id="allExtTab"
        style={{ display: "block" }}
      >
        {extension && Object.keys(extension)?.length > 0 ? (
          Object.keys(extension)?.map((c, index, arr) => (
            <React.Fragment key={"ext-" + index}>
              <div className="ext-wrap">
                <div className="ext-hdr">
                  <div className="desc">
                    <h2>{sentenceCaseFormatter(c)}</h2>
                    <p className="sub-title">
                      Make your marketing more inventive and interactive
                    </p>
                  </div>
                  <div className="action">
                    {/* <button type="button" className="btn btn-secondary">
                      View All
                    </button> */}
                  </div>
                </div>

                <div className="ext-content">
                  {extension?.hasOwnProperty(c) && extension[c]?.length > 0
                    ? extension[c].map((e) => (
                        <div className="app-plchldr">
                          <div
                            style={{
                              display: "flex",
                              justifycontent: "center",
                              // alignContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <img
                              style={{ width: "120px", height: "75px" }}
                              src={logoForExtension(e?.name, e?.id)}
                            />
                          </div>
                          {/* <span
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                            className="app-name"
                          >
                            {echo(e.name)}
                          </span> */}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={permission.create ? () =>{
                              handleRSidebar(extensionRhsPane(e?.name), {
                                id: e.id,
                                setView: setView,
                              })}:null
                            }
                            disabled={e?.active||!permission.create}
                          >
                            Install
                          </button>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              {index < arr?.length - 1 && <hr className="hr-line" />}
            </React.Fragment>
          ))
        ) : (
          <p>No extensions found</p>
        )}
      </div>
    </>
  );
});

export default AllExtensions;
