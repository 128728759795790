import React, { useState, useEffect } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import { updateExtensionById } from "../../apis/extensionAPI";
import { echo, logoForExtension } from "../../utils";
import { getExtensionById } from "../../apis/extensionAPI";

const InstalledCred = ({ id, active }) => {
  const { state } = useLocation();
  const { openModal } = useOutletContext();

  const [view, setView] = useState("allExtensions");
  const [extension, setExtension] = useState(null);
  const [enabled, setEnabled] = useState("false");

  useEffect(() => {
    if (id) {
      getExtension(id);
    }
  }, [id]);

  const getExtension = (id) => {
    getExtensionById(id)
      .then((res) => {
        if (res.status) {
          setExtension(res.data);
        }
      })
      .catch((err) => {
        setExtension(null);
        console.log(err);
      });
  };

  const handleEnable = (id) => {
    const enable = updateExtensionById(id);
    if (enable.status) {
      setEnabled(false);
    } else {
      setEnabled(true);
      return;
    }
  };

  const handleDisablePopUp = () => {
    openModal("extensionDisableModal", {
      title: "Disbale",
      module: "extensions",
    });
  };

  return (
    <>
      <div className="appdtls-content">
        <div className="jst-instld active">
          <div className="app-dtlsbox">
            <div className="lft">
              <i className={`app-icon ${logoForExtension(extension?.id)}`}></i>
              <div className="info">
                <p className="title">{echo(extension?.name)}</p>
                <p className="desc">{echo(extension?.description)}</p>
              </div>
            </div>
            <div className="rgt">
              <button
                type="button"
                className={`btn btn-primary enbl-btn ${
                  active === false
                } : btn btn-danger disable-btn ${active === true}`}
                id="enableBtn"
                onClick={
                  active === false
                    ? () => handleDisablePopUp()
                    : () => handleEnable()
                }
              >
                {active == true ? "Enable" : "Disable"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstalledCred;
