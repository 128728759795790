import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
} from "react";

const DownloadViewer = forwardRef((props, ref) => {
  const downloadViewer = useRef(null);

  const [comData, setCompData] = useState({});

  useImperativeHandle(ref, () => ({
    fetching(data = {}) {
      setCompData(data);
      downloadViewer.current.style.display = "block";
      document.getElementById("probar").classList.add("anipro");
    },
    preparing(data = {}) {
      setCompData(data);
    },
    downloaded(data = {}) {
      setCompData(data);
      document.getElementById("probar").classList.remove("anipro");
      setTimeout(() => {
        downloadViewer.current.style.display = "none";
      }, 5000);
    },
    closeViwer: () => {
      downloadViewer.current.style.display = "none";
    },
  }));

  const closeViwer = () => {
    downloadViewer.current.style.display = "none";
  };

  return (
    <div
      ref={downloadViewer}
      className="crm-file-dwnld"
      style={{ display: "none" }}
    >
      <div className="fhdr">
        <div className="hdr-lft">
          <p className="title">Exporting {comData.moduleName} as CSV</p>
        </div>
        <div className="hdr-rgt">
          <i className="close-icon" onClick={() => closeViwer()}></i>
        </div>
      </div>
      <div className="fbody">
        <div className="progress-bar-wrap">
          <div className="progress-container">
            <div className="progress-bar">
              <span
                id="probar"
                className="progress anipro"
                style={{ width: "100%" }}
              ></span>
            </div>
            <p>
              <span className="txt">{comData.title}</span>
              {/* <span className="progress-percent">100%</span> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DownloadViewer;
