import React from 'react'
import ContentLoader from "react-content-loader"

export default function BarChartLoader(props) {
  return (
    <React.Fragment>
        <ContentLoader 
            speed={2}
            width={props?.width||504.9}
            height={245.1}
            viewBox="0 0 504.9 245.1"
            backgroundColor="#f3f3f3"
            foregroundColor="#ccc"
            {...props}
        >
            <path d="M 66 219.1 H 42 v -29 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 29 z M 106 219.1 H 82 v -56 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 56 z M 145 219.1 h -24 v -56 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 56 z M 184 219.1 h -24 v -47 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 47 z M 224 219.1 h -24 v -207 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 207 z M 263 219.1 h -24 v -72 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 72 z M 302 219.1 h -24 v -80 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 80 z M 342 219.1 h -24 v -131 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 131 z M 381 219.1 h -24 v -165 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 165 z M 421 219.1 h -24 v -181 c 0 -2.2 1.8 -4 4 -4 h 20 v 185 z M 460 219.1 h -24 v -207 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 207 z M 499 219.1 h -24 v -89 c 0 -2.2 1.8 -4 4 -4 h 16 c 2.2 0 4 1.8 4 4 v 89 z" /> 
            <path d="M 21 13.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 21 55.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 21 98.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 21 140.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 21 181.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 21 224.1 H 3 c -1.1 0 -2 -0.9 -2 -2 v -8 c 0 -1.1 0.9 -2 2 -2 h 18 c 1.1 0 2 0.9 2 2 v 8 c 0 1.1 -0.9 2 -2 2 z M 141 244.1 H 47 c -3.3 0 -6 -2.7 -6 -6 s 2.7 -6 6 -6 h 94 c 3.3 0 6 2.7 6 6 s -2.7 6 -6 6 z M 260 244.1 h -94 c -3.3 0 -6 -2.7 -6 -6 s 2.7 -6 6 -6 h 94 c 3.3 0 6 2.7 6 6 s -2.7 6 -6 6 z M 379 244.1 h -94 c -3.3 0 -6 -2.7 -6 -6 s 2.7 -6 6 -6 h 94 c 3.3 0 6 2.7 6 6 s -2.7 6 -6 6 z M 498 244.1 h -94 c -3.3 0 -6 -2.7 -6 -6 s 2.7 -6 6 -6 h 94 c 3.3 0 6 2.7 6 6 s -2.7 6 -6 6 z" />
        </ContentLoader>
    </React.Fragment>
  )
}
