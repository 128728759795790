import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  getEmailTemplatesAPI,
  getEmailTemplatesOffsetAPI,
  updateEmailTemplateAPI,
} from "../apis/emailTemplateAPIs";
import NoResults from "../components/common/noResults";
import {
  debounceOptFunc,
  echo,
  getUserAccessInfo,
  getSortBy,
  overrideDefaultEvent,
  organiseColumns,
  getManagedColumns,
  getObjValue,
  forEach,
  sentenceCaseFormatter,
} from "../utils";
import { APP_DATA } from "../app_data";
import createResizer from "../utils/resizerHandler";
import DataTableLoader from "../components/common/dataTableLoader";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NewPagination from "../components/common/newPagination";
import { Tooltip } from "react-tooltip";
import { searchThroughAPIs, searchAPI } from "../apis";
import { useOutside } from "../customHooks/useOutside";

export default function EmailTemplatesView() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    openModal,
    getUserInfo,
    openToast,
    handleRSidebar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);

  const searchRef = useRef(null);
  const deleteIdsList = useRef([]);
  const selectAllCheck = useRef(null);
  const modules = useRef(APP_DATA.modules);
  const formData = useRef(
    structuredClone(APP_DATA.emailTemplateInput.createEmailTemplate)
  );
  const pagination = useRef(null);
  const selectFilter = useRef(null);

  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createForm, setCreateForm] = useState(false);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [permission, setPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [columnInfo, setColumnInfo] = useState([]);
  const [displayName, setDisplayName] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("All Templates");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    setPermission(getUserAccessInfo("Email Templates").permission || {});
    getModuleDataTypesAPI("email_templates")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName);
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  useEffect(() => {
    getEmailTempates(selectedFilter);
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    createResizer();
  }, [emailTemplate, location.search, loading]);

  const closeCreateForm = (e) => {
    setCreateForm(!createForm);
    formData.current = structuredClone(
      APP_DATA.emailTemplateInput.createEmailTemplate
    );
  };

  const formChangeHandler = (e) => {
    formData.current[e.target.id] = e.target.value;
    if (e.target.value) {
      document.getElementById("err_" + e.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + e.target.id).style.display = "block";
    }
  };

  const createEmailTemplateInit = () => {
    let finalValidation = 1;
    for (const f in formData.current) {
      if (formData.current[f] === "" || formData.current[f] === undefined) {
        finalValidation &= 0;
        document.getElementById("err_" + f).style.display = "block";
      } else {
        document.getElementById("err_" + f).style.display = "none";
      }
    }
    if (finalValidation) {
      navigate("/app/templates/easyCreate", {
        state: {
          data: { ...formData.current, active: true, favorites: true },
          action: "create",
        },
      });
    }
  };

  const getEmailTempates = async (selectFilter, sortBy, sortField) => {
    setLoading(true);
    pagination?.current?.resetDefaultPage();
    const templates = await getEmailTemplatesOffsetAPI(
      0,
      pageData.limit,
      selectFilter,
      APP_DATA.fixedFiltersOwnerFields.templates.includes(selectFilter)
        ? getUserAccessInfo()?.user_id
        : "",
      sortBy,
      sortField
    );
    if (templates.status) {
      setEmailTemplate(
        organiseColumns(
          templates.data.email_templates,
          getManagedColumns("templatesColumn")
        )
      );
      setTotalRecords(templates.data.total_count);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setEmailTemplate([]);
    }
    setLoading(false);
  };

  const deleteTemplate = (id) => {
    openModal("deleteModal", {
      data: deleteIdsList.current,
      redirect: "templates",
      msgInfo:
        deleteIdsList.current.length === 1
          ? emailTemplate?.find((et) => deleteIdsList.current.includes(et.id))
          : null,
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === emailTemplate?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== emailTemplate?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      setBtnDelete(false);
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const activeDeactiveEmailTemplate = async (e) => {
    let pl = {};
    const id = String(e.target.id).split("_")[1];
    if (e.target.checked) {
      pl = {
        active: true,
        updated_by: getUserInfo().user_id,
      };
    } else {
      pl = {
        active: false,
        updated_by: getUserInfo().user_id,
      };
    }
    const rupdated = await updateEmailTemplateAPI(id, pl);
    if (rupdated.status) {
      openToast(APP_DATA.messages.notification, "success");
    } else {
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const templates = await searchAPI(
          "email_templates",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (templates.status) {
          setTotalRecords(templates.data.total_count);
          setEmailTemplate(
            organiseColumns(
              templates.data.email_templates,
              getManagedColumns("templatesColumn")
            )
          );
        } else {
          setEmailTemplate([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else {
      //default get data
      const templates = await getEmailTemplatesOffsetAPI(
        pageData.offset,
        pageData.limit,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.templates.includes(selectedFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (templates.status) {
        setTotalRecords(templates.data.total_count);
        setEmailTemplate(
          organiseColumns(
            templates.data.email_templates,
            getManagedColumns("templatesColumn")
          )
        );
      } else {
        setEmailTemplate([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  //searching function
  async function handleSearch(qstring) {
    setLoading(true);
    pagination?.current?.resetDefaultPage();

    clearFixedFilter();

    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));
    clearSort();
    unCheckRecords();

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs("emailTemplate", qstring);
      if (searched.status) {
        setEmailTemplate(searched.data.email_templates);
        setTotalRecords(searched.data.total_count);
      } else {
        setTotalRecords(0);
        setEmailTemplate([]);
      }
      setLoading(false);
    } else {
      setFilter(false);
      getEmailTempates(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
  }
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "tname") {
      return;
    }

    if (e.target.id === "template") {
      overrideDefaultEvent(e);
    }
    unCheckRecords();
    const element = document.getElementById(id);
    const fieldKey = columnInfo.find((c) => c.field === field);
    const sortBy = getSortBy(element);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      getEmailTempates(selectedFilter, sortBy, fieldKey.display_name);
    }
  };

  const getDataForManageColumns = async () => {
    getEmailTempates(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("templatesColumn")) {
      sessionStorage.removeItem("templatesColumn");
    }
    getDataForManageColumns();
  };

  const handleMakeFavourite = async (e) => {
    try {
      const evId = parseInt(String(e.target.id).split("_")[1]);
      const payLoad = {
        updated_by: getUserInfo().user_id,
        favorites: !JSON.parse(e.target.dataset.fav),
      };
      const updated = await updateEmailTemplateAPI(evId, payLoad);
      if (updated.status) {
        if (updated.data.favorites) {
          openToast("Template marked as favorite.", "success");
          document.getElementById(e.target.id).dataset.fav = "true";
          document.getElementById(e.target.id).classList.add("filled");
        } else {
          openToast("Template marked as not favorite.", "success");
          document.getElementById(e.target.id).dataset.fav = "false";
          document.getElementById(e.target.id).classList.remove("filled");
        }
      } else {
        openToast(APP_DATA.messages.error_message, "error");
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnViewEdit = (id, action) => {
    switch (action) {
      case "edit":
      case "view":
        return navigate("/app/templates/easyCreate", {
          state: { data: { id }, action },
        });
    }
  };

  const handleFixedFilter = (e) => {
    const filterValue = e.target.getAttribute("data-value");
    selectFilter.current.classList.add("open");
    if (filterValue) {
      setSelectedFilter(filterValue);
      getEmailTempates(filterValue, sortInfo.sortBy, sortInfo.sortField);
      selectFilter.current.classList.remove("open");
      searchRef.current.value = "";
      setFilter(true);
      unCheckRecords();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Templates");
      setFilter(false);
    }
  };

  useOutside(selectFilter,()=>{
    selectFilter.current.classList.remove("open");
  });


  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i className="crm-backbtn"></i>
          <h1 className="header_title">Email Templates</h1>
        </div>
        <div className="header_rhs">
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  permission?.view ? "custom-select" : "custom-select disabled"
                }
                ref={selectFilter}
                onClick={permission?.view ? handleFixedFilter : null}
              >
                <div className="crm-custom-select-value">
                  <span id="selected_filter">
                    {sentenceCaseFormatter(selectedFilter)}
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.templates.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search templates"
                ref={searchRef}
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                disabled={!permission.view}
              />
              <span className="btn-ico-srch"></span>
              {/* <i className="btn-ico-close"></i> */}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              permission.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={permission.create ? () => setCreateForm(true) : null}
            >
              <i className="plus-icn"></i>
              <span>Create Template</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                btnDelete && permission.delete
                  ? "crm-btn-grp crt-dlt"
                  : "crm-btn-grp crt-dlt disabled"
              }
              onClick={
                btnDelete && permission.delete ? () => deleteTemplate() : null
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>
        {permission?.view &&
          emailTemplate?.length > 0 &&
          Object.keys(displayName).length > 0 && (
            <div className="menu-rgt">
              <div className="total_count">
                <div className="total_title">Total templates: </div>
                <div className="total_number">
                  <strong>{totalRecords}</strong>
                </div>
              </div>
              <NewPagination
                ref={pagination}
                totalRecords={totalRecords}
                functions={{
                  handlePaginatedFetchData,
                }}
              />
            </div>
          )}
      </div>

      {/* table section  */}
      <div className={`tbl-wrpr`}>
        {emailTemplate?.length > 0 &&
          permission?.view &&
          !loading &&
          Object.keys(displayName).length > 0 && (
            <>
              <div className="crm-action">
                <a
                  id="m-managecolumn"
                  className="crm-action-filter"
                  onClick={
                    permission?.view
                      ? () =>
                          handleRSidebarManageColumns("manageColumns", {
                            applyManageColumn,
                            clearManageColumn,
                            columns: columnInfo,
                            module: "templates",
                            sessModule: "templatesColumn",
                          })
                      : null
                  }
                ></a>
              </div>
              <Tooltip
                place={"left"}
                anchorId={"m-managecolumn"}
                content="Manage columns"
              />
            </>
          )}
        <div className="crm-table cst-mrgn">
          <div className="crm-tb-hed">
            {emailTemplate?.length > 0 &&
              permission.view &&
              Object.keys(displayName).length > 0 &&
              !loading && (
                <div className="crm-tb-rw" id={"resizeMe"}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-name">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark"
                          ref={selectAllCheck}
                          id="emailt_check"
                          onClick={(e) => handleSelectAll(e)}
                          disabled={emailTemplate?.length ? false : true}
                        />
                        <label htmlFor="emailt_check"></label>
                      </div>
                      <div className="name">
                        <span
                          className={
                            sortInfo.sortKey === "name" ? "sort-highlight" : ""
                          }
                          // id="templateName"
                          onClick={(e) => {
                            handleSort(e, "name", "templateName");
                          }}
                        >
                          {echo(displayName["name"])}
                        </span>
                      </div>
                      <i
                        className={
                          sortInfo.sortKey === "name"
                            ? sortInfo.sortBy === "desc"
                              ? "sort sort-down"
                              : "sort sort-up"
                            : ""
                        }
                        id="templateName"
                        onClick={(e) => handleSort(e, "name", "templateName")}
                      ></i>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {emailTemplate?.length > 0 &&
                      permission?.view &&
                      Object.keys(displayName).length > 0 &&
                      !loading &&
                      Object.keys(emailTemplate[0]).map(
                        (heading, index) =>
                          !APP_DATA.hiddenColumns.templates.includes(
                            heading
                          ) && (
                            <div
                              id={`col-${index}`}
                              className={`crm-td r  ${
                                sortInfo.sortKey === heading
                                  ? sortInfo.sortBy === "desc"
                                    ? "crm-sort sort-active"
                                    : "crm-sort sort-active-up"
                                  : ""
                              }`}
                            >
                              <span
                                onClick={(e) =>
                                  handleSort(e, heading, `col-${index}`)
                                }
                              >
                                {echo(displayName[heading])}
                              </span>
                            </div>
                          )
                      )}
                  </div>
                </div>
              )}
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              emailTemplate.length > 0 && permission.view ? (
                Object.keys(displayName).length > 0 &&
                emailTemplate.map((et, i) => (
                  <React.Fragment key={"et" + i}>
                    <div className="crm-tb-rw">
                      <div className="crm-fixed-field">
                        <div className="crm-td crm-td-name">
                          <div className="check-box">
                            <input
                              id={et.id}
                              type="checkbox"
                              className="crm-checkmark ids"
                              onChange={(e) => {
                                handleDeleteCheck(e);
                              }}
                            />
                            <label htmlFor={et.id}></label>
                          </div>
                          <div className="name">
                            <i
                              id={"fav_" + et.id}
                              data-fav={et.favorites}
                              className={
                                et.favorites ? "star-icon filled" : "star-icon"
                              }
                              onClick={(e) => handleMakeFavourite(e)}
                            ></i>
                            <span className="link">{echo(et.name)}</span>
                            <p
                              className="preview-link hide"
                              onClick={() => handleOnViewEdit(et.id, "view")}
                            >
                              <i className="eye-icon"></i>
                              <a>Preview</a>
                            </p>
                          </div>
                          {permission.edit && (
                            <div className="action-rw">
                              <i
                                className="crm-edit"
                                onClick={() => handleOnViewEdit(et.id, "edit")}
                              ></i>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="crm-scroll-field">
                        {emailTemplate?.length > 0 &&
                          Object.keys(emailTemplate[0])?.map(
                            (heading, i) =>
                              !APP_DATA.hiddenColumns.templates.includes(
                                heading
                              ) && (
                                <div data-colpos={i} className={`crm-td `}>
                                  {!APP_DATA.formattingColumns.templates.includes(
                                    heading
                                  ) ? (
                                    <span>
                                      {echo(getObjValue(et, heading))}
                                    </span>
                                  ) : (
                                    <div
                                      data-colpos={i}
                                      className="crm-td crm-td-status"
                                    >
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          id={"switch_" + et.id}
                                          defaultChecked={et.active}
                                          onChange={(e) =>
                                            activeDeactiveEmailTemplate(e)
                                          }
                                          disabled={!permission.edit}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  )}
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <NoResults permission={!permission.view} filter={filter} />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-name"}
                cols="100000"
                module="email-template"
              />
            )}
          </div>
        </div>
      </div>

      {/* email template form */}
      {createForm ? (
        <React.Fragment>
          <div
            id="createTemplateModal"
            style={{ display: "block" }}
            className="overlay create-template"
          >
            <div className="crm-pop-vt">
              <div className="crm-pop-hztl">
                <div className="crm-popup crm-popup-md rhs-popvt">
                  <form>
                    <div className="crm-pop-header">
                      <h2>Create Email Templates</h2>
                      <a
                        className="close crm-close"
                        onClick={(e) => closeCreateForm(e)}
                      ></a>
                    </div>
                    <div className="crm-popup-content">
                      <div className="crm-form-row">
                        <div className="crm-form-grp">
                          <div className="crm-form-grp">
                            <div className="crm-form-field">
                              <select
                                id="module"
                                className="crm-form-ctrl floating-select"
                                onChange={(e) => formChangeHandler(e)}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {modules.current.map((m, i) => (
                                  <option
                                    key={"option-module" + i}
                                    value={m.value}
                                  >
                                    {m.name}
                                  </option>
                                ))}
                              </select>
                              <label
                                alt="Module"
                                htmlFor="module"
                                placeholder="Module"
                                className="floating-label"
                              >
                                Module
                              </label>
                              <span className="crm-inp-error" id="err_module">
                                Module cannot be blank
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="crm-form-row">
                        <div className="crm-form-grp">
                          <div className="crm-form-grp">
                            <div className="crm-form-field">
                              <input
                                type="text"
                                id="templateName"
                                placeholder=" "
                                autocomplete="off"
                                className="crm-form-ctrl floating-input"
                                onChange={(e) => formChangeHandler(e)}
                              />
                              <label
                                alt="Template Name"
                                for="templateName"
                                placeholder="Template Name"
                                className="floating-label"
                              >
                                Template Name
                              </label>
                              <span
                                className="crm-inp-error"
                                id="err_templateName"
                              >
                                Template Name cannot be blank
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="crm-form-row">
                        <div className="crm-form-grp">
                          <div className="crm-form-grp">
                            <div className="crm-form-field">
                              <input
                                type="text"
                                id="subject"
                                placeholder=" "
                                autocomplete="off"
                                className="crm-form-ctrl floating-input"
                                onChange={(e) => formChangeHandler(e)}
                              />
                              <label
                                alt="Template Subject"
                                for="subject"
                                placeholder="Template Subject"
                                className="floating-label"
                              >
                                Template Subject
                              </label>
                              <span className="crm-inp-error" id="err_subject">
                                Template Subject cannot be blank
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-pop-footer no-border">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => createEmailTemplateInit()}
                      >
                        Next
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => closeCreateForm(e)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
