import React, { useRef } from "react";
import DatePicker from "react-multi-date-picker";
import { APP_DATA } from "../../app_data";

export default function BulkInputFieldsMapper(props) {
  const nonValueOps = useRef(["empty", "nonEmpty"]);
  switch (props.placeholder.fieldType) {
    case "string":
      return (
        <>
          <input
            type={"text"}
            data-type="value"
            className="crm-form-ctrl floating-input"
            id={"value-" + props.i}
            disabled={nonValueOps.current.includes(props.placeholder.condition)}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => props.handlers.selectValues(e)}
            value={props.placeholder.value}
          />
          <label
            htmlFor={"value-" + props.i}
            placeholder="Search value"
            className="floating-label"
          >
            Search value
          </label>
          <span
            className="crm-inp-error"
            id={
              props.parent === "search" ? `err_value_` + props.i : "err_value"
            }
          ></span>
        </>
      );
    case "numeric":
      if (props.placeholder.field === "call_duration") {
        return (
          <>
            <input
              data-type="value"
              type="time"
              className="crm-form-ctrl floating-input"
              id={"value-" + props.i}
              disabled={nonValueOps.current.includes(
                props.placeholder.condition
              )}
              autoComplete="off"
              placeholder=" "
              onChange={(e) => props.handlers.selectValues(e)}
              value={props.placeholder.value}
            />
            <label
              htmlFor={"value-" + props.i}
              placeholder="Search value"
              className="floating-label"
            >
              Search value
            </label>
            <span
              className="crm-inp-error"
              id={
                props.parent === "search" ? `err_value_` + props.i : "err_value"
              }
            ></span>
          </>
        );
      }
      return (
        <>
          <input
            data-type="value"
            type="number"
            className="crm-form-ctrl floating-input"
            id={"value-" + props.i}
            disabled={nonValueOps.current.includes(props.placeholder.condition)}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => props.handlers.selectValues(e)}
            min={0}
            onKeyDown={function (e) {
              if (APP_DATA.invalidNumberChars.includes(e.key)) {
                e.preventDefault();
                return false;
              }
            }}
            value={props.placeholder.value}
          />
          <label
            htmlFor={"value-" + props.i}
            placeholder="Search value"
            className="floating-label"
          >
            Search value
          </label>
          <span
            className="crm-inp-error"
            id={
              props.parent === "search" ? `err_value_` + props.i : "err_value"
            }
          ></span>
        </>
      );
    case "picklist":
      return (
        <>
          <select
            data-type="value"
            className="crm-form-ctrl floating-select"
            id={"value-" + props.i}
            disabled={nonValueOps.current.includes(props.placeholder.condition)}
            onChange={(e) => props.handlers.selectValues(e)}
            value={props.placeholder.value}
          >
            <option value="">Please select an option</option>
            {props.placeholder.variablesInputs.enums?.length &&
              props.placeholder.variablesInputs.enums?.map((o) => (
                <option
                  value={o}
                  selected={props.placeholder.value.includes(o)}
                >
                  {o}
                </option>
              ))}
          </select>

          <label
            alt="Value"
            htmlFor={"value-" + props.i}
            placeholder="Value"
            className="floating-label"
          ></label>
          <span
            className="crm-inp-error"
            id={
              props.parent === "search" ? `err_value_` + props.i : "err_value"
            }
          ></span>
        </>
      );
    case "date":
      if (props.placeholder.condition === "customRange") {
        return (
          <>
            <DatePicker
              range
              data-type="value"
              id={"value-" + props.i}
              disabled={nonValueOps.current.includes(
                props.placeholder.condition
              )}
              style={{
                height: "42px",
                width: "410px",
              }}
              className="crm-form-ctrl floating-input"
              placeholder="yyyy/mm/dd ~ yyyy/mm/dd"
              onChange={(v) => props.handlers.setCRDatevalue(v, props.i)}
            />
            <span
              className="crm-inp-error"
              id={
                props.parent === "search" ? `err_value_` + props.i : "err_value"
              }
            ></span>
          </>
        );
      }
      return (
        <>
          <input
            type="date"
            max={props.placeholder.fieldType === "date" && "9999-12-12"}
            data-type="value"
            className="crm-form-ctrl floating-input"
            id={"value-" + props.i}
            disabled={nonValueOps.current.includes(props.placeholder.condition)}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => props.handlers.selectValues(e)}
            value={props.placeholder.value}
          />
          <label
            htmlFor={"value-" + props.i}
            placeholder="Search value"
            className="floating-label"
          >
            Search date
          </label>
          <span
            className="crm-inp-error"
            id={
              props.parent === "search" ? `err_value_` + props.i : "err_value"
            }
          ></span>
        </>
      );
    default:
      return (
        <>
          <input
            type="text"
            placeholder=" "
            data-type="value"
            autocomplete="off"
            id={"value-" + props.i}
            className="crm-form-ctrl floating-input"
            readOnly={true}
            value={props.placeholder.value}
          />
          <label
            htmlFor={"value-" + props.i}
            placeholder="Search value"
            className="floating-label"
          >
            Search value
          </label>
          <span
            className="crm-inp-error"
            id={
              props.parent === "search" ? `err_value_` + props.i : "err_value"
            }
          ></span>
        </>
      );
  }
}
