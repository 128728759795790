import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getOpenDeals = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/open_deals?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getUntouchedDeals = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/untouched_deals?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getCallCount = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/today_calls_count?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getMyLeadsCount = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/today_leads_count?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getDealCount = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/deals_count?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getLeadCount = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/leads_count?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getTasks = async (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/tasks?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getTodaysLeads = (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/leads?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getDealsCountByStage = (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/deals_count_by_stage?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getCalendarMeetings = (owner = "", month, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/dashboard/calendar?owner=${owner}&month=${month}&year=${year}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getMeetings = (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/today_meetings?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getWeeklyDeals = (owner = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/dashboard/weekly_deals?owner=${owner}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
