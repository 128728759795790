export const MASS_UPDATE = {
  field: "",
  value: "",
  condition: "",

  fieldType: "",
  operatorType: "",
  variablesInputs: {
    enums: [],
    operators: [],
  },
};
