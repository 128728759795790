import { API_URL } from ".";
import { axiosPrivate } from ".";
import { APISuccessResponse, getAuthToken } from ".";

export const getAllCampaigns = async () => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/campaigns")
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getCampaignsLimitOffset = async (
  offset = 0,
  limit = 10,
  leadFilter,
  leadOwner,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(
        `/campaigns/offset?offset=${offset}&limit=${limit}&count=${true}&campaign_filter=${
          leadFilter ? leadFilter : "All Campaigns"
        }${leadOwner ? `&campaign_owner=${leadOwner}` : ""}&campaign_field=${
          sortField ? sortField : "Created date"
        }${sortBy ? `&order_by=${sortBy}` : ""}`
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getCampaignsByName = async (campaignName) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/campaigns/name/" + campaignName)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewCampaign = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post("/campaigns/", reqPayload)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateCampaignById = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put("/campaigns/" + id, reqPayload)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteCampaign = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .delete("/campaigns/?deleted_by=" + userId, {
        data: reqPayload,
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getCampaignById = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get("/campaigns/id/" + id)
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const campaignAssignAPI = (campaignId, reqPayload) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(
        `/campaigns/assign?campaign_id=${campaignId}`,
        reqPayload
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const campaignUnassignAPI = (campaignId, reqPayload) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(
        `/campaigns/unassign?campaign_id=${campaignId}`,
        reqPayload
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
