import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getAllMeetings = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/meetings")
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getMeetingsLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = false,
  meetingFilter,
  createdBy,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/meetings/offset?offset=${offset}&limit=${limit}&count=${count}&meeting_filter=${
            meetingFilter ? meetingFilter : "All Meetings"
          }${createdBy ? `&created_by=${createdBy}` : ""}&meeting_field=${
            sortField ? sortField : "Created date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewMeeting = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/meetings", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteMeeting = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/meetings/?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const meetingAPI = async (meetType, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/utils/video_conference?meet=" + meetType, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const updateMeetingById = (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/meetings/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getMeetingById = (meetingId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/meetings/" + meetingId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
