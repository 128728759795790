import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getDataForCustomFilters = async (
  module,
  count,
  offset = 0,
  limit = 10,
  body,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/custom_filter?module=${module}&count=${count}&offset=${offset}&limit=${limit}&field=${
            sortField ? sortField : "Created date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getDataForDateFilters = async (
  module,
  count,
  offset = 0,
  limit = 10,
  dateFilter,
  sortBy,
  sortField,
  from,
  to
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/date_filter?module=${module}&count=${count}&offset=${offset}&limit=${limit}&date__filter=${
            dateFilter ? dateFilter : "All_Days"
          }${from ? `&from_date=${from}` : ""}${
            to ? `&to_date=${to}` : ""
          }&field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};
