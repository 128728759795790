import React from "react";

export default function InputDate() {
  return (
    <React.Fragment>
      <div
        className="crm-form-field"
        style={{ display: "none" }}
        id={"inputType_7"}
      >
        <input
          type="date"
          max="9999-12-12"
          className="crm-form-ctrl floating-input"
          placeholder="date input"
        />
        <label className="floating-label">Date</label>
        <div className="crm-action">
          <a className="crm-action-opt crm-action-settings">
            <ul className="crm-dropdown-action ul">
              <li>
                <span className="crm-action-edit" data-link="edit">
                  Edit Properties
                </span>
              </li>
              <li>
                <span className="crm-action-delete" data-link="remove">
                  Remove
                </span>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
