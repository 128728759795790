import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BulkInputFieldsMapper from "./bulkInputFieldsMapper";
import { customFilterFieldValidator } from "../../utils";

export default function BulkSelectProperties(props) {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const nonValueOps = useRef(["empty", "nonEmpty"]);
  const [tempDateRange, setDateRange] = useState({
    start_value: "",
    end_value: "",
  });

  const handlers = {
    selectProperty: async (e) => {
      try {
        const fn = String(e.target.id).split("-")[0];
        const value = String(e.target.value).split("-")[0];
        const dataType = String(e.target.value).split("-")[1];
        const index = parseInt(String(e.target.id).split("-")[1]);
        if (e.target.value === "") {
          props.functions.selectProperty(index, "", dataType);
          return;
        } else {
          props.functions.selectProperty(index, value, dataType);
        }
        document.getElementById("err_" + fn + "_" + index).style.display =
          "none";
      } catch (e) {
        console.log(e);
      }
    },
    selectOperators: (e) => {
      try {
        const fn = String(e.target.id).split("-")[0];
        const value = String(e.target.value).split("-")[0];
        const index = parseInt(String(e.target.id).split("-")[1]);
        if (e.target.value === "") {
          props.functions.selectOperator(index, "");
          return;
        } else {
          props.functions.selectOperator(index, value);
        }

        document.getElementById("err_" + fn + "_" + index).style.display =
          "none";
        if (nonValueOps.current.includes(value)) {
          document.getElementById("err_value_" + index).style.display = "none";
        }
      } catch (e) {
        console.log(e);
      }
    },
    selectValues: (e) => {
      try {
        let finalValidation = 1;
        const fn = String(e.target.id).split("-")[0];
        const index = parseInt(String(e.target.id).split("-")[1]);
        const value = e.target.value;
        const tempEvent = {
          target: {
            value: value,
            datatype: props.placeholder.fieldType,
            id: props.placeholder.field + "_value",
          },
        };
        const validationResponse = customFilterFieldValidator(tempEvent);
        if (!validationResponse.valid) {
          finalValidation &= 0;
          document.getElementById("err_" + fn + "_" + index).innerText =
            validationResponse["error_message"];
          document.getElementById("err_" + fn + "_" + index).style.display =
            "block";
        } else {
          document.getElementById("err_" + fn + "_" + index).innerText = "";
          document.getElementById("err_" + fn + "_" + index).style.display =
            "none";
        }

        if (finalValidation) {
          props.functions.selectValues(index, value);
        } else {
          props.functions.selectValues(index, "");
        }
      } catch (e) {
        console.log(e);
      }
    },
    setCRDatevalue: (v, index) => {
      let dateRange = {};
      document.getElementById("err_value_" + index).style.display = "none";
      if (v.length > 1) {
        dateRange = {
          ...tempDateRange,
          end_value: v[1].format(),
        };
        setDateRange((p) => ({ ...p, end_value: v[1].format() }));
        props.functions.selectValues(index, dateRange);
      } else {
        setDateRange((p) => ({ ...p, start_value: v[0].format() }));
      }
    },
  };

  return (
    <React.Fragment key={"sdfsdf" + props.i}>
      <div className="crm-form-grp">
        <div className="crm-form-field col-3">
          <select
            data-type="field"
            id={"field-" + props.i}
            className="crm-form-ctrl floating-select"
            onChange={(e) => handlers.selectProperty(e)}
          >
            {props.moduleFields.length ? (
              <optgroup label="Available properties">
                {props.moduleFields.map((dt, k) => (
                  <React.Fragment>
                    {k === 0 ? <option value={""}>Select</option> : null}
                    <option
                      key={"opt_dt" + k}
                      value={dt.field + "-" + dt.datatype}
                      selected={props.placeholder.field === dt.field}
                    >
                      {dt.display_name}
                    </option>
                  </React.Fragment>
                ))}
              </optgroup>
            ) : (
              <>
                <option>Loading...</option>
              </>
            )}
          </select>
          <label
            alt="Properties"
            htmlFor=""
            placeholder="Properties"
            className="floating-label"
          >
            Properties
          </label>
          <span id={"err_field_" + props.i} className="crm-inp-error"></span>
        </div>
        <div className="crm-form-field col-3">
          <select
            data-type="condition"
            id={"condition-" + props.i}
            className="crm-form-ctrl floating-select"
            onChange={(e) => handlers.selectOperators(e)}
          >
            {props.placeholder.variablesInputs?.operators.length ? (
              <optgroup label="Available properties">
                {props.placeholder.variablesInputs.operators.map((dt, k) => (
                  <React.Fragment>
                    {k === 0 ? <option value={""}>Select</option> : null}
                    <option
                      key={"opt_dt" + k}
                      value={dt.operator + "-ops"}
                      selected={props.placeholder.condition === dt.operator}
                    >
                      {dt.display_name}
                    </option>
                  </React.Fragment>
                ))}
              </optgroup>
            ) : (
              <>
                <option>Please select one property</option>
              </>
            )}
          </select>
          <label
            alt="Properties"
            htmlFor=""
            placeholder="Properties"
            className="floating-label"
          >
            Operators
          </label>
          <span
            id={"err_condition_" + props.i}
            className="crm-inp-error"
          ></span>
        </div>

        <div className="crm-form-field col-6">
          <BulkInputFieldsMapper
            {...props}
            parent={"search"}
            handlers={handlers}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
