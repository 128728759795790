import React from 'react'
import ContentLoader from "react-content-loader"

export default function ChartLegendLoader(props) {
  return (
    <React.Fragment>
        <ContentLoader
          speed={2}
          viewBox="0 0 229 150"
          height={130}
          backgroundColor="#f3f3f3"
          foregroundColor="#ccc"
          {...props}
        >
          <circle cx="10" cy="20" r="8" />
          <rect x="25" y="15" rx="5" ry="5" width="100" height="10" />
          <circle cx="10" cy="50" r="8" />
          <rect x="25" y="45" rx="5" ry="5" width="100" height="10" />
          <circle cx="10" cy="80" r="8" />
          <rect x="25" y="75" rx="5" ry="5" width="100" height="10" />
          <circle cx="10" cy="110" r="8" />
          <rect x="25" y="105" rx="5" ry="5" width="100" height="10" />
        </ContentLoader>
    </React.Fragment>
  )
}