import React, { useEffect, useRef } from "react";
import { useState } from "react";
import NoResults from "../components/common/noResults";
import AllExtensions from "../components/extensions/allExtensions";
import InstalledExtension from "../components/extensions/installedExtension";
import { getUserAccessInfo } from "../utils";

const ExtensionView = () => {
  const extensionRef = useRef(null);

  const [view, setView] = useState("allExtensions");
  const [extensionPermission, setExtensionPermission] = useState({});
  const [unInstalledView, setunInstalledView] = useState("");
  const [extensions, setExtensions] = useState({});

  useEffect(() => {
    const extensions = getUserAccessInfo("Extensions");
    if (extensions?.permission) {
      setExtensionPermission(extensions.permission);
    } else {
      setExtensionPermission({});
    }
  }, []);

  const renderExtensionView = (view) => {
    switch (view) {
      case "allExtensions":
        return <AllExtensions setView={setView} ref={extensionRef}/>;
      case "installed":
        return <InstalledExtension setView={setView} ref={extensionRef} />;
      // case "updates":
      //   return <UpdatesExtension />;
      default:
        return null;
    }
  };

  const handleSearch = (e) => {
    extensionRef.current.handleSearch(e.target.value)
  }

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Extensions</h1>
        </div>
        <div className="header_rhs">
          <div className="search_bar">
            <input
              type="text"
              placeholder="Search apps"
              disabled={!extensionPermission?.view}
              onChange={(e) => handleSearch(e)}
            />
            <span className="btn-ico-srch"></span>
          </div>
        </div>
      </div>
      {extensionPermission?.view ? (
        <div className="ext-info">
          <div className="header-tabs">
            <div className="crm-tabs">
              <div className="crm-tab-links">
                <ul>
                  <li>
                    <a
                      // title="All Extensions"
                      className={`crm-tblinks ${
                        view === "allExtensions" ? "active" : ""
                      }`}
                      onClick={() => {
                        setView("allExtensions");
                      }}
                    >
                      All extensions
                    </a>
                  </li>
                  <li>
                    <a
                      // title="Installed"
                      className={`crm-tblinks ${
                        view === "installed" ? "active" : ""
                      }`}
                      onClick={() => {
                        setView("installed");
                      }}
                    >
                      Installed
                    </a>
                  </li>
                  {/* <li>
                  <a
                    href="#"
                    title="Updates"
                    className={`crm-tblinks ${view==="updates" ? "active":""}`}
                    onClick={()=>{setView("updates")}}
                  >
                    Updates
                  </a>
                </li> */}
                </ul>
              </div>
              <div className="crm-tabs-contctnr">
                {renderExtensionView(view)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoResults permission={!extensionPermission?.view} />
      )}
    </>
  );
};

export default ExtensionView;
