import React, { useState, useEffect, useRef } from "react";
import { FunnelChart } from "react-funnel-pipeline";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getCalendarMeetings,
  getCallCount,
  getMyLeadsCount,
  getOpenDeals,
  getTasks,
  getTodaysLeads,
  getUntouchedDeals,
  getDealsCountByStage,
  getMeetings,
  getDealCount,
  getLeadCount,
  getWeeklyDeals,
} from "../../apis/dashboardAPI";
import CalendarView from "../calendar/calendar";
import ChartLegendLoader from "../analytics/chartLegendLoader";
import FunnelChartLoader from "../analytics/funnelChartLoader";
import {
  UTCConverterMeeting,
  getUserAccessInfo,
  dateFormatter,
  overrideDefaultEvent,
  dealsFormatter,
} from "../../utils";
import NoDashboardResults from "../common/noDashboardResult";
import { echo, getClassByStatus } from "../../utils";
import { APP_DATA } from "../../app_data";
import "react-funnel-pipeline/dist/index.css";
import { updateTaskById } from "../../apis/taskAPIs";

const progressBarColour = ["orange", "red", "blue", "violet", "pink", "brown"];

export default function DashboardDefaultView(props) {
  const navigate = useNavigate();
  const { handleRSidebar, getExtraData } = useOutletContext();

  const [view, setView] = useState("leadCounts");
  const [deals, setDeals] = useState([]);
  const [loader, setLoader] = useState({
    tasks: true,
    leads: true,
    deals: true,
    meetings: true,
  });
  const [dealsCount, setDealsCount] = useState("--");
  const [dealCount, setDealCount] = useState([]);
  const [leadCount, setLeadCount] = useState([]);
  const [untouchedDealsCount, setUntouchedDealsCount] = useState("--");
  const [callsCount, setCallsCount] = useState("--");
  const [myLeadsCount, setMyLeadsCount] = useState("--");
  const [tasks, setTasks] = useState([]);
  const [leads, setLeads] = useState([]);
  const [funnelData, setFunnelData] = useState([{}]);
  const [markDates, setMarkDates] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    const { user_id, user_access } = getUserAccessInfo();
    if (user_id) {
      getCountData(user_id);
      getMyTasks(user_id);
      getMyLeads(user_id);
      getDealsCount(user_id);
      getCalendarData(
        user_id,
        new Date().getMonth() + 1,
        new Date().getFullYear()
      );
      getTodaysMeetings(user_id);
      getLeadDealCounts(user_id);
      getDealsByWeek(user_id);
    }
    const permission = user_access?.reduce((acc, curr) => {
      acc[curr.module?.toLowerCase()] = {
        create: curr.permission.create,
        edit: curr.permission.edit,
        view: curr.permission.view,
      };
      return acc;
    }, {});
    setPermission(permission);
  }, []);

  const getLeadDealCounts = (owner) => {
    getLeadCount(owner)
      .then((leads) => {
        if (leads?.status) {
          setLeadCount(leads?.data);
        }
      })
      .catch((err) => {
        setLeadCount([]);
      });

    getDealCount(owner)
      .then((deals) => {
        if (deals?.status) {
          setDealCount(deals?.data);
        }
      })
      .catch((err) => {
        setDealCount([]);
      });
  };

  const getDealsByWeek = (owner) => {
    getWeeklyDeals(owner)
      .then((deals) => {
        if (deals?.status) {
          setDeals(deals?.data);
        }
        setLoader((prev) => ({ ...prev, deals: false }));
      })
      .catch((err) => {
        setLoader((prev) => ({ ...prev, deals: false }));
        setDeals([]);
      });
  };

  const getCountData = (owner) => {
    getOpenDeals(owner)
      .then((deals) => {
        if (deals?.status) {
          setDealsCount(deals?.data?.count);
        }
      })
      .catch((err) => {
        setDealsCount("--");
      });

    getUntouchedDeals(owner)
      .then((untouchedDeals) => {
        if (untouchedDeals?.status) {
          setUntouchedDealsCount(untouchedDeals?.data?.count);
        }
      })
      .catch((err) => {
        setDealsCount("--");
      });

    getCallCount(owner)
      .then((call) => {
        if (call?.status) {
          setCallsCount(call?.data?.count);
        }
      })
      .catch((err) => {
        setCallsCount("--");
      });

    getMyLeadsCount(owner)
      .then((leads) => {
        if (leads?.status) {
          setMyLeadsCount(leads?.data?.count);
        }
      })
      .catch((err) => {
        setCallsCount("--");
      });
  };

  const getMyTasks = (owner) => {
    getTasks(owner)
      .then((tasks) => {
        if (tasks.status) {
          setTasks(tasks.data);
        }
        setLoader((prev) => ({ ...prev, tasks: false }));
      })
      .catch((err) => {
        setTasks([]);
        setLoader((prev) => ({ ...prev, tasks: false }));
      });
  };

  const getMyLeads = (owner) => {
    getTodaysLeads(owner)
      .then((leads) => {
        if (leads?.status) {
          setLeads(leads?.data);
        }
        setLoader((prev) => ({ ...prev, leads: false }));
      })
      .catch((err) => {
        setLeads([]);
        setLoader((prev) => ({ ...prev, leads: false }));
      });
  };

  const getDealsCount = (owner) => {
    getDealsCountByStage(owner)
      .then((deals) => {
        if (deals?.status) {
          const finalFunnelData = {
            dataset: deals?.data,
            backgroundColor: APP_DATA.funnelChartColors,
          };
          setFunnelData(finalFunnelData);
        }
        setLoader((prev) => ({ ...prev, pipelineDeals: false }));
      })
      .catch((err) => {
        setFunnelData([{}]);
        setLoader((prev) => ({ ...prev, pipelineDeals: false }));
      });
  };

  const getCalendarData = (owner, month, year) => {
    getCalendarMeetings(owner, month, year)
      .then((dates) => {
        if (dates.status) {
          setMarkDates(dates.data);
        }
      })
      .catch((err) => {
        setMarkDates([]);
      });
  };

  const getTodaysMeetings = (owner) => {
    getMeetings(owner)
      .then((meet) => {
        if (meet?.status) {
          setMeetings(meet.data);
        }
        setLoader((prev) => ({ ...prev, meetings: false }));
      })
      .catch((err) => {
        setLoader((prev) => ({ ...prev, meetings: false }));
        setMeetings([]);
      });
  };

  const onCalendarNavChange = ({ activeStartDate }) => {
    getCalendarData(
      getUserAccessInfo()?.user_id,
      new Date(activeStartDate).getMonth() + 1,
      new Date(activeStartDate).getFullYear()
    );
  };

  const navigateFromWidgets = (card, id, e = {}) => {
    if (
      e.target?.classList?.contains("taskCheck") ||
      e.target?.classList?.contains("crm-checkmark")
    ) {
      return;
    }
    switch (card) {
      case "mydeals":
        if (permission?.deals?.view) {
          navigate("/app/deals", {
            state: {
              filter: APP_DATA.fixedFilters.deals[2],
            },
          });
        }
        break;
      case "deals":
        if (permission?.deals?.view) {
          navigate("/app/deals", {
            state: {
              filter: APP_DATA.fixedFilters.deals[1],
            },
          });
          break;
        }
      case "calls":
        if (permission?.calls?.view) {
          navigate("/app/calls", {
            state: {
              filter: APP_DATA.fixedFilters.calls[1],
            },
          });
          break;
        }
      case "leads":
        if (permission?.leads?.view) {
          navigate("/app/leads", {
            state: {
              filter: APP_DATA.fixedFilters.leads[1],
            },
          });
        }
        break;
      case "meetings":
        if (permission?.meetings?.view) {
          navigate("/app/meetings", {
            state: {
              id,
            },
          });
        }
        break;
      case "tasks":
        if (permission?.tasks?.view) {
          navigate("/app/tasks", {
            state: {
              id,
            },
          });
        }
        break;
      case "todaysleads":
        if (permission?.leads?.view) {
          navigate("/app/leads", {
            state: {
              id,
            },
          });
        }
        break;
      case "weekdeals":
        if (permission?.deals?.view) {
          navigate("/app/deals", {
            state: {
              id,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const taskOnChange = (e, id) => {
    if (permission?.tasks?.edit) {
      let payload = {};

      if (e.target.checked) {
        payload.task_status = "Completed";
      } else {
        payload.task_status = "In progress";
      }
      updateTaskById(id, payload)
        .then((res) => {
          getMyTasks(getUserAccessInfo()?.user_id);
        })
        .catch((err) => {});
    }
  };

  return (
    <React.Fragment>
      <div className="crm-sctn-cntr first">
        <div className="sctn-lhs">
          <div className="crm-widgets">
            <div
              className="wdgt-col"
              onClick={() => navigateFromWidgets("mydeals")}
            >
              <div className="wdgt-lft">
                <div className="wdgt-hdng">My open deals</div>
                <div className="wdgt-count">{dealsCount}</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-thumbs-up"></i>
              </div>
            </div>
            <div
              className="wdgt-col"
              onClick={() => navigateFromWidgets("deals")}
            >
              <div className="wdgt-lft">
                <div className="wdgt-hdng">My deals</div>
                <div className="wdgt-count">{untouchedDealsCount}</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-thumbs-down"></i>
              </div>
            </div>
            <div
              className="wdgt-col"
              onClick={() => navigateFromWidgets("calls")}
            >
              <div className="wdgt-lft">
                <div className="wdgt-hdng">My calls</div>
                <div className="wdgt-count">{callsCount}</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-call"></i>
              </div>
            </div>
            <div
              className="wdgt-col"
              onClick={() => navigateFromWidgets("leads")}
            >
              <div className="wdgt-lft">
                <div className="wdgt-hdng">My leads</div>
                <div className="wdgt-count">{myLeadsCount}</div>
              </div>
              <div className="wdgt-rgt">
                <i className="crm-action-funnel"></i>
              </div>
            </div>
          </div>
          <div className="crm-grid-stack">
            <div className="mod-hdr">
              <h2>My today's tasks</h2>
              {tasks?.length > 0 && (
                <div>
                  <button
                    type="button"
                    className={
                      permission?.tasks.create
                        ? "btn btn-primary crt-btn"
                        : "btn btn-primary crt-btn disabled"
                    }
                    onClick={
                      permission?.tasks.create
                        ? () => handleRSidebar("createTask")
                        : null
                    }
                  >
                    <i className="plus-icn"></i>
                    Create Tasks
                  </button>
                </div>
              )}
            </div>
            <div className="crm-table cst-mgn tasks-tbl noscrl">
              {tasks?.length > 0 && (
                <div className="crm-tb-hed">
                  <div className="crm-tb-rw">
                    <div className="crm-td crm-td-subject crm-sort sort-active-up crm-radio-container ml">
                      <span className="spcng">Subject</span>
                    </div>
                    <div className="crm-td crm-td-closedate">
                      <span>Close date</span>
                    </div>
                    <div className="crm-td crm-td-priority">
                      <span>Priority</span>
                    </div>
                    <div className="crm-td crm-td-status">
                      <span>Status</span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`crm-tb-bdy ${
                  !loader?.tasks && tasks?.length === 0 ? "no-results-tbl" : ""
                }`}
                style={{ height: "350px" }}
              >
                {loader?.tasks ? (
                  <div
                    style={{
                      height: "350px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 8,
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="loaderTheme-sm"
                      style={{
                        borderTop: "3px solid #0071bc",
                      }}
                    ></div>
                  </div>
                ) : tasks?.length > 0 ? (
                  tasks.map((t, i) => (
                    <div
                      className={`crm-tb-rw`}
                      key={i}
                      onClick={(e) => {
                        navigateFromWidgets("tasks", t.task_id, e);
                      }}
                    >
                      <div className="crm-td crm-td-subject ml">
                        <input
                          type="checkbox"
                          className="crm-checkmark"
                          id={t?.task_subject}
                          checked={
                            t?.task_status === "Completed" ? true : false
                          }
                          onChange={(e) => taskOnChange(e, t.task_id)}
                        />
                        <label className="taskCheck" htmlFor={t?.task_subject}>
                          <span>{echo(t?.task_subject)}</span>
                        </label>
                      </div>
                      <div className="crm-td crm-td-closedate">
                        <span>
                          {dateFormatter(t.task_closing_date, "dashboard")}
                        </span>
                      </div>
                      <div className="crm-td crm-td-priority">
                        <span>{echo(t?.task_priority)}</span>
                      </div>
                      <div className="crm-td crm-td-status">
                        <span
                          className={`status ${getClassByStatus(
                            t?.task_status
                          )}`}
                        >
                          {echo(t?.task_status)}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDashboardResults
                    module={"Tasks"}
                    functions={{ handleRSidebar }}
                    permission={permission?.tasks?.create}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sctn-rhs">
          <div className="crm-grid-stack mt0">
            <div className="mod-hdr">
              <h2 className="title">My today's meeting</h2>
              {meetings?.length > 0 && (
                <div>
                  <button
                    type="button"
                    className={
                      permission.meetings?.create
                        ? "btn btn-primary crt-btn"
                        : "btn btn-primary crt-btn disabled"
                    }
                    onClick={
                      permission?.meetings?.create
                        ? () => handleRSidebar("createMeeting")
                        : null
                    }
                  >
                    <i className="plus-icn"></i>Create Meeting
                  </button>
                </div>
              )}
            </div>
            <div className="mtng-stack noscrl" style={{ height: "500px" }}>
              {loader?.meetings ? (
                <div
                  style={{
                    height: "350px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 8,
                    alignItems: "center",
                  }}
                >
                  <div
                    className="loaderTheme-sm"
                    style={{
                      borderTop: "3px solid #0071bc",
                    }}
                  ></div>
                </div>
              ) : meetings?.length > 0 ? (
                meetings.map((m, i) => (
                  <div
                    className="crm-mtng-info"
                    key={i}
                    onClick={() => {
                      navigateFromWidgets("meetings", m.meeting_id);
                    }}
                  >
                    <div className="mtng-cal">
                      <i className="crm-action-cal-icon"></i>
                    </div>
                    <div
                      className="mtng-rhs-info"
                      onClick={() => {
                        navigateFromWidgets("meetings", m.meeting_id);
                      }}
                    >
                      <div className="info">
                        <p title="">
                          <strong>{echo(m.meeting_title)}</strong>
                        </p>
                        <span>
                          {dateFormatter(m.meeting_from) +
                            " - " +
                            dateFormatter(m.meeting_to)}
                        </span>
                      </div>
                      {/* <div className="mtng-more-info">
                        <button className="btn btn-secondary">More Info</button>
                      </div> */}
                    </div>
                  </div>
                ))
              ) : (
                <NoDashboardResults
                  module={"Meetings"}
                  functions={{ handleRSidebar }}
                  permission={permission?.meetings?.create}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-sctn-cntr">
        <div className="sctn-lhs">
          <div className="crm-grid-stack mt0">
            <div className="leads-hdr">
              <h2>My leads</h2>
              {leads?.length > 0 && (
                <div>
                  <button
                    type="button"
                    className={
                      permission.leads?.create
                        ? "btn btn-primary crt-lead"
                        : "btn btn-primary crt-lead disabled"
                    }
                    onClick={
                      permission?.leads?.create
                        ? () => handleRSidebar("createLead")
                        : null
                    }
                  >
                    <i className="plus-icn"></i>Create Lead
                  </button>
                </div>
              )}
            </div>
            <div className="crm-table cst-mgn leads-tbl noscrl">
              {leads?.length > 0 && (
                <div className="crm-tb-hed">
                  <div className="crm-tb-rw">
                    <div className="crm-td crm-td-lead-dtls crm-sort sort-active-up">
                      <span className="spcng">Lead Details</span>
                    </div>
                    <div className="crm-td crm-td-cmpny">
                      <span>Company</span>
                    </div>
                    <div className="crm-td crm-td-cntct">
                      <span>Contact</span>
                    </div>
                    <div className="crm-td crm-td-mailid">
                      <span>Mail id</span>
                    </div>
                    <div className="crm-td crm-td-leadsource">
                      <span>Lead source</span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`crm-tb-bdy ${
                  !loader?.leads && leads?.length === 0 ? "no-results-tbl" : ""
                }`}
                style={{ height: "350px" }}
              >
                {loader?.leads ? (
                  <div
                    style={{
                      height: "350px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 8,
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="loaderTheme-sm"
                      style={{
                        borderTop: "3px solid #0071bc",
                      }}
                    ></div>
                  </div>
                ) : leads?.length > 0 ? (
                  leads.map((l, i) => (
                    <div
                      className="crm-tb-rw"
                      key={i}
                      onClick={() => {
                        navigateFromWidgets("todaysleads", l.lead_id);
                      }}
                    >
                      <div className="crm-td crm-td-lead-dtls">
                        <div className="spcng">
                          <div className="char-a name-icon">
                            <div className="alphbt">
                              {echo(l?.lead_name[0]?.toUpperCase())}
                            </div>
                          </div>
                          <span className="value">{echo(l?.lead_name)}</span>
                        </div>
                      </div>
                      <div className="crm-td crm-td-cmpny">
                        <span>{echo(l?.lead_company)}</span>
                      </div>
                      <div className="crm-td crm-td-cntct">
                        <span>{echo(l?.lead_mobile)}</span>
                      </div>
                      <div className="crm-td crm-td-mailid">
                        <span>{echo(l?.lead_email)}</span>
                      </div>
                      <div className="crm-td crm-td-leadsource">
                        <span>{echo(l?.lead_source)}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDashboardResults
                    module={"Leads"}
                    functions={{ handleRSidebar }}
                    permission={permission?.leads?.create}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sctn-rhs">
          <div className="crm-grid-stack mt0 calndr noscrl">
            <h2 className="title">Meeting overview</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "25px 0px",
              }}
            >
              <CalendarView
                markDates={markDates}
                onCalendarNavChange={onCalendarNavChange}
              />
            </div>
            <div style={{ borderBottom: "1px solid #edf2f4" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                margin: "20px 15px",
                alignSelf: "center",
              }}
            >
              {APP_DATA?.calendarTimingInfo.map((c, i) => (
                <div key={i}>
                  <span
                    style={{
                      display: "inline-block",
                      height: "9px",
                      width: "9px",
                      backgroundColor: c.colour,
                      borderRadius: "50%",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "11px",
                      color: "#6B7076",
                      margin: "4px 3px",
                    }}
                  >
                    {c?.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-sctn-cntr">
        <div className="sctn-lhs">
          <div className="crm-grid-stack mt0">
            <div className="mod-hdr">
              <h2>My deals this week</h2>
              {deals?.length > 0 && (
                <div>
                  <button
                    type="button"
                    className={
                      permission?.deals?.create
                        ? "btn btn-primary crt-btn"
                        : "btn btn-primary crt-btn disabled"
                    }
                    onClick={
                      permission?.deals?.create
                        ? () => handleRSidebar("createDeal")
                        : null
                    }
                  >
                    <i className="plus-icn"></i>Create Deals
                  </button>
                </div>
              )}
            </div>
            <div className="crm-table cst-mgn mydeals-tbl noscrl">
              {deals?.length > 0 && (
                <div className="crm-tb-hed">
                  <div className="crm-tb-rw">
                    <div className="crm-td crm-td-dealname">
                      <span className="spcng">Deal name</span>
                    </div>
                    <div className="crm-td crm-td-amt">
                      <span>Amount</span>
                    </div>
                    <div className="crm-td crm-td-stage">
                      <span>Stage</span>
                    </div>
                    <div className="crm-td crm-td-acntname">
                      <span>Account name</span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`crm-tb-bdy ${
                  !loader?.deals && deals?.length === 0 ? "no-results-tbl" : ""
                }`}
                style={{ height: "350px" }}
              >
                {loader?.deals ? (
                  <div
                    style={{
                      height: "350px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 8,
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="loaderTheme-sm"
                      style={{
                        borderTop: "3px solid #0071bc",
                      }}
                    ></div>
                  </div>
                ) : deals?.length > 0 ? (
                  deals.map((d) => (
                    <div
                      className="crm-tb-rw"
                      onClick={() =>
                        navigateFromWidgets("weekdeals", d.deal_id)
                      }
                    >
                      <div className="crm-td crm-td-dealname">
                        <span className="spcng">{echo(d?.deal_name)}</span>
                      </div>
                      <div
                        className="crm-td crm-td-amt"
                        id={"dealAmount"}
                        name={"deal_amount"}
                      >
                        <span>
                          {dealsFormatter(
                            "deal_amount",
                            d.deal_amount,
                            getExtraData()?.currency_symbol
                          )}
                        </span>
                      </div>
                      <div className="crm-td crm-td-stage">
                        <span>{echo(d?.deal_stage)}</span>
                      </div>
                      <div className="crm-td crm-td-acntname">
                        <span>{echo(d?.deal_account_name)}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDashboardResults
                    module={"Deals"}
                    functions={{ handleRSidebar }}
                    permission={permission?.deals?.create}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sctn-rhs flexcol">
          <div className="crm-grid-stack mt0 deals-stack">
            <div className="crm-tabs">
              <div className="crm-tab-links field-tab-lnks">
                <ul>
                  <li>
                    <a
                      href=""
                      title="Leads counts"
                      className={`crm-tblinks ${
                        view === "leadCounts" ? "active" : ""
                      }`}
                      onClick={(e) => {
                        overrideDefaultEvent(e);
                        setView("leadCounts");
                      }}
                    >
                      Leads counts
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      title="Deals counts"
                      className={`crm-tblinks ${
                        view === "dealCounts" ? "active" : ""
                      }`}
                      onClick={(e) => {
                        overrideDefaultEvent(e);
                        setView("dealCounts");
                      }}
                    >
                      Deals counts
                    </a>
                  </li>
                </ul>
              </div>
              <div className="crm-tabs-contctnr">
                <div className={`crm-tab-content active`} id={view}>
                  {leadCount?.length || dealCount?.length ? (
                    <div className="count-list">
                      <ul>
                        {view === "leadCounts" && leadCount?.length > 0
                          ? leadCount.map((l, i) => (
                              <li>
                                {i === 0 && (
                                  <i className="crm-action-award"></i>
                                )}
                                <span className="name">
                                  {echo(l?.lead_owner_name)}
                                </span>
                                <div className="progress-bar-wrap">
                                  <div className="progress-container">
                                    <div className="progress-bar">
                                      <span
                                        className={`progress ${
                                          progressBarColour[
                                            i % progressBarColour?.length
                                          ]
                                        }`}
                                        style={{
                                          width: l.percentage
                                            ? `${l.percentage}%`
                                            : "0%",
                                        }}
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="count-no">{l.count}</div>
                              </li>
                            ))
                          : dealCount?.length > 0 &&
                            dealCount.map((d, i) => (
                              <li>
                                {i === 0 && (
                                  <i className="crm-action-award"></i>
                                )}
                                <span className="name">
                                  {echo(d?.deal_owner_name)}
                                </span>
                                <div className="progress-bar-wrap">
                                  <div className="progress-container">
                                    <div className="progress-bar">
                                      <span
                                        className={`progress ${
                                          progressBarColour[
                                            i % progressBarColour?.length
                                          ]
                                        }`}
                                        style={{
                                          width: d.percentage
                                            ? `${d.percentage}%`
                                            : "0%",
                                        }}
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="count-no">{d?.count}</div>
                              </li>
                            ))}
                      </ul>
                    </div>
                  ) : (
                    <NoDashboardResults />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="crm-grid-stack deals-stack"
            style={{ overflow: "auto" }}
          >
            <h2 className="title">My pipeline deals by stage</h2>
            <div style={{ padding: "25px" }}>
              {loader?.pipelineDeals ? (
                <div style={{ height: 150, display: "flex", width: "100%" }}>
                  <div>
                    <FunnelChartLoader />
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <ChartLegendLoader />
                  </div>
                </div>
              ) : funnelData?.dataset?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      height: 200,
                      width: "50%",
                      margin: "0px 40px",
                    }}
                  >
                    <FunnelChart
                      showNames={false}
                      showValues={true}
                      chartWidth={200}
                      data={funnelData?.dataset}
                      getToolTip={(row) => row.name + ": " + row.value}
                      pallette={funnelData.backgroundColor}
                      getRowStyle={() => {
                        return {
                          padding:
                            funnelData?.dataset?.length === 1 ? "25px" : "10px",
                          fontSize: "10px",
                        };
                      }}
                    />
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <ul>
                      {funnelData?.dataset.map((d, i) => (
                        <li
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: 3,
                          }}
                        >
                          <div
                            style={{
                              minWidth: 15,
                              height: 15,
                              marginRight: 5,
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor: `${
                                funnelData.backgroundColor[
                                  i % funnelData?.backgroundColor?.length
                                ]
                              }`,
                            }}
                          ></div>
                          {d?.name + " " + `(${d?.value})`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <NoDashboardResults />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
