import React from "react";

export default function ContactStaticFields(props) {
  return (
    <React.Fragment>
      <div className="crm-form-row">
        <h3>Contact Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Contact Owner" className="floating-label">
                Contact Owner
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select"></select>
              <i className="blur-arw"></i>
              <label placeholder="Contact source" className="floating-label">
                Contact source
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="contactSource"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="First name" className="floating-label">
                First name
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Last name" className="floating-label">
                Last name
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Role" className="floating-label">
                Role
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Email" className="floating-label">
                Email
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Phone" className="floating-label">
                Phone
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Mobile" className="floating-label">
                Mobile
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Website" className="floating-label">
                Website
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Linkedin" className="floating-label">
                Linkedin
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <h3>Account Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Account" className="floating-label">
                Account
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="crm-form-row">
        <h3>Billing Address</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Country" className="floating-label">
                Country
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="State" className="floating-label">
                State
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="City" className="floating-label">
                City
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Zip code" className="floating-label">
                Zip code
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Street" className="floating-label">
                Street
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <h3>Shipping Address</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Country" className="floating-label">
                Country
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="State" className="floating-label">
                State
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="City" className="floating-label">
                City
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Zip code" className="floating-label">
                Zip code
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Street" className="floating-label">
                Street
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <h3>Additional Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp">
            <div className="crm-form-field">
              <textarea
                placeholder="Additional"
                rows="6"
                readOnlydisabled={true}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
