import React, { useEffect } from "react";
import DateInput from "./dateInput";
import DropdownSelect from "./dropdownSelect";
import MultiLineText from "./multiLineText";
import SingleLineText from "./singleLineText";

export default function WebFormFieldMapper(props) {
  const returnInputTypes = (type) => {
    switch (type) {
      case "single-line":
        return (
          <SingleLineText
            conf={{ ...props.inputProperties }}
            formChangeHandler={props.formChangeHandler}
            defaultValue={props?.defaultValue}
          />
        );
      case "multi-line":
        return (
          <MultiLineText
            conf={{ ...props.inputProperties }}
            formChangeHandler={props.formChangeHandler}
            defaultValue={props?.defaultValue}
          />
        );
      case "dropdown-select":
        return (
          <DropdownSelect
            conf={{ ...props.inputProperties }}
            formChangeHandler={props.formChangeHandler}
            defaultValue={props?.defaultValue}
          />
        );
      case "date-input":
        return (
          <DateInput
            conf={{ ...props.inputProperties }}
            formChangeHandler={props.formChangeHandler}
            defaultValue={props?.defaultValue}
          />
        );
      case "phone-input":
        return (
          <SingleLineText
            conf={{ ...props.inputProperties }}
            formChangeHandler={props.formChangeHandler}
            defaultValue={props?.defaultValue}
          />
        );
      default:
        return null;
    }
  };
  return <React.Fragment>{returnInputTypes(props.type)}</React.Fragment>;
}
