import React, { useRef, useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import {
  createNewWFRule,
  getWFOperatorsAPI,
  getWFRuleByIdAPI,
  updateWFRuleAPI,
} from "../../apis/workflowAPIs";
import { APP_DATA } from "../../app_data";
import {
  inputFieldValidator,
  inputSpaceValidator,
  removeSessionWFData,
  utf8_to_b64,
} from "../../utils";
import ActionPreference from "./actionPreference";
import ActionsToPerform from "./actionsToPerform";
import SetConditions from "./setConditions";
import FullPageLoader from "../common/fullPageLoader";

export default function WorkflowRules() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const { handleRSidebar, openToast, getUserInfo } = useOutletContext();

  const formData = useRef(
    structuredClone(APP_DATA.workflowRules.wfAPIMapping.real)
  );

  const targetModule = useRef(null);
  const setConditions = useRef(null);
  const actionsToPerform = useRef(null);
  const actionPreference = useRef(null);

  const staticModules = useRef(APP_DATA.modules);

  const [wfrId, setWfrId] = useState(null);
  const [remoteWfr, setRemoteWfr] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [mode, setMode] = useState(urlSearch.get("mode"));
  const [ruleExtraData, setRuleExtraData] = useState({});
  const [modules, setModules] = useState(staticModules.current[0]);
  const [operators, setOperators] = useState({});
  const [primaryActionBtns, setPrimaryActionbtns] = useState(false);

  useEffect(() => {
    if (mode === "create") {
      navigate({
        pathname: "/app/workflows/create",
        search: createSearchParams({
          mode: urlSearch.get("mode"),
          target: "create-wf-" + modules.value,
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
      targetModule.current = modules.value;
      getModuleWFSettings(targetModule.current);
      wfSettingFunctions.settingAgrregator("module", {
        value: targetModule.current,
      });
      removeSessionWFData("wf");
      setTimeout(() => {
        setDataLoaded(true);
      }, 1500);
    } else {
      navigate({
        pathname: "/app/workflows/create",
        search: createSearchParams({
          mode: urlSearch.get("mode"),
          target: "edit-wf-" + urlSearch.get("module"),
          wfr: utf8_to_b64(urlSearch.get("wfr")),
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
      targetModule.current = urlSearch.get("module");
      getModuleWFSettings(targetModule.current);
      // wfSettingFunctions.settingAgrregator("module", {
      //   value: targetModule.current,
      // });
      if (!wfrId) {
        setWfrId(parseInt(urlSearch.get("wfr")));
      }
      getRemoteWFRules(parseInt(urlSearch.get("wfr")));
    }
  }, []);

  useEffect(() => {
    setupEditMode();
  }, [remoteWfr]);

  const setupEditMode = () => {
    if (remoteWfr) {
      const thisPageInfo = ["module", "name", "description"];
      thisPageInfo.map((pi, i) => {
        wfSettingFunctions.settingAgrregator(pi, {
          value: remoteWfr[pi],
        });
      });
      // console.log(remoteWfr)
    }
  };

  const getRemoteWFRules = async (id) => {
    const rWfr = await getWFRuleByIdAPI(id);
    if (rWfr.status) {
      setRemoteWfr(rWfr.data.rule);
      setRuleExtraData(rWfr.data.extra_data);
      window.sessionStorage.setItem(
        "wf:remote",
        JSON.stringify(rWfr.data.rule)
      );
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    } else {
      openToast(remoteWfr.error.error_name);
    }
  };

  const getModuleWFSettings = async (targetModule) => {
    const wfOp = await getWFOperatorsAPI();
    if (wfOp.status) {
      setOperators(wfOp.data);
    } else {
      //TODO alert and take reload
    }
  };

  const handleModuleSwitch = async (e) => {
    navigate({
      pathname: "/app/workflows/create",
      search: createSearchParams({
        mode: urlSearch.get("mode"),
        target: `${urlSearch.get("mode")}-wf-${e.target.value}`,
        tNode: utf8_to_b64(String(Date.now())),
      }).toString(),
    });
    targetModule.current = e.target.value;
    setModules(staticModules.current.find((m) => m.value === e.target.value));
    getModuleWFSettings(targetModule.current);
    wfSettingFunctions.settingAgrregator("module", {
      value: targetModule.current,
    });
  };

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);

    if (validationResponse.valid) {
      wfSettingFunctions.settingAgrregator(event.target.id, {
        value: event.target.value,
      });
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      wfSettingFunctions.settingAgrregator(event.target.id, {
        value: undefined,
      });
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const wfSettingFunctions = {
    settingAgrregator: async (settingType, settingsObj = {}) => {
      switch (settingType) {
        case "module":
        case "name":
        case "description":
        case "match_type":
        case "event":
          formData.current[settingType] = settingsObj.value;
          break;
        case "actions":
          if (formData.current[settingType].length) {
            formData.current[settingType] = [];
            formData.current[settingType].push(settingsObj);
          } else {
            formData.current[settingType].push(settingsObj);
          }
          break;
        case "conditions":
          if (formData.current[settingType].length) {
            formData.current[settingType] = [];
            formData.current[settingType].push(settingsObj);
          } else {
            formData.current[settingType].push(settingsObj);
          }
          break;
        //settings to add
        default:
          break;
      }
    },
    openRHMenu: (viewName) => handleRSidebar(viewName),
  };

  const handleCreateWFRule = async (e) => {
    let finalValidation = 1;
    setPrimaryActionbtns(true);
    document.getElementById(e.target.id).classList.add("btnLoader");

    //reset actions if any
    if (window.sessionStorage.getItem("wf:actions")) {
      window.sessionStorage.removeItem("wf:actions");
    }
    //reset conditions if any
    if (window.sessionStorage.getItem("wf:conditions")) {
      window.sessionStorage.removeItem("wf:conditions");
    }

    for (const f in formData.current) {
      const spaceValidator = inputSpaceValidator(formData.current[f]);
      if (f === "active") continue;
      if (f == "actions") {
        if (!actionsToPerform.current.handleActionData()) {
          finalValidation = 0;
        }
      } else if (f === "conditions") {
        if (!setConditions.current.validateConditions()) {
          finalValidation = 0;
        }
      } else if (
        formData.current[f] == "" ||
        formData.current[f] == undefined ||
        spaceValidator
      ) {
        if (f === "description") {
          continue;
        }
        document.getElementById("err_" + f).innerText = spaceValidator
          ? "This field cannot contain spaces"
          : APP_DATA.errorMessage.requiredField;
        finalValidation = 0;
        document.getElementById("err_" + f).style.display = "block";
      } else {
        continue;
      }
    }

    if (finalValidation) {
      formData.current.actions = JSON.parse(
        window.sessionStorage.getItem("wf:actions")
      );

      formData.current.conditions = JSON.parse(
        window.sessionStorage.getItem("wf:conditions")
      );

      if (mode != "edit") {
        if (e.target.dataset.action === "save") {
          formData.current["active"] = false;
        }

        formData.current["created_by"] = getUserInfo().user_id;

        const newRule = await createNewWFRule(formData.current);
        if (newRule.status) {
          navigate({
            pathname: "/app/workflows",
            search: createSearchParams({
              referrer: "create-wf-success-" + newRule.status,
              tNode: utf8_to_b64(String(Date.now())),
            }).toString(),
          });
          openToast(
            `Workflow ${formData.current["name"]} is created`,
            "success"
          );

          clearWFTempData();
          removeSessionWFData("wf");
        } else {
          openToast(
            `Failed to create the workflow ${formData.current["name"]} `,
            "error"
          );
        }
      } else {
        formData.current["updated_by"] = getUserInfo().user_id;

        const updatedRule = await updateWFRuleAPI(wfrId, formData.current);
        if (updatedRule.status) {
          navigate({
            pathname: "/app/workflows",
            search: createSearchParams({
              referrer: "create-wf-success-" + updatedRule.status,
              tNode: utf8_to_b64(String(Date.now())),
            }).toString(),
          });
          openToast(
            `Workflow ${formData.current["name"]} is updated`,
            "success"
          );

          clearWFTempData();
          removeSessionWFData("wf");
        } else {
          setPrimaryActionbtns(false);
          openToast(
            `Failed to update the workflow ${formData.current["name"]} `,
            "error"
          );
          document.getElementById(e.target.id).classList.remove("btnLoader");
          return;
        }
      }
    } else {
      setPrimaryActionbtns(false);
      document.getElementById(e.target.id).classList.remove("btnLoader");
      return;
    }
  };

  const clearWFTempData = () => {
    actionsToPerform.current.resetFormData();
    setConditions.current.resetFormData();
    formData.current = Object.assign(
      {},
      APP_DATA.workflowRules.wfAPIMapping.real
    );
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Workflow Rules</h1>
        </div>
        <div className="header_rhs"></div>
      </div>

      <div className="wflow-info">
        {dataLoaded ? (
          <React.Fragment>
            <div className="crm-form-content">
              <h2>Create a new workflow</h2>
              <div className="wflw-lft">
                <form>
                  <React.Fragment>
                    <div className="crm-form-row cstmwdth">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field req">
                            <select
                              id="module"
                              className="crm-form-ctrl floating-select"
                              onChange={(e) => handleModuleSwitch(e)}
                              // value={remoteWfr ? remoteWfr.module : ""}
                              disabled={mode === "edit"}
                            >
                              {remoteWfr ? (
                                <option value={remoteWfr.module} disabled>
                                  {remoteWfr.module}
                                </option>
                              ) : null}
                              {staticModules.current.map((m, i) => (
                                <option
                                  key={"wf-properties_" + i}
                                  value={m.value}
                                  selected={
                                    remoteWfr
                                      ? remoteWfr.module === m.value
                                      : false
                                  }
                                >
                                  {m.name}
                                </option>
                              ))}
                            </select>
                            <label
                              alt="Module"
                              htmlFor="module"
                              placeholder="Module"
                              className="floating-label"
                            >
                              Module
                            </label>
                            <span
                              className="crm-inp-error"
                              id="err_module"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-form-row cstmwdth">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field req">
                            <input
                              type="text"
                              className="crm-form-ctrl floating-input"
                              id="name"
                              autoComplete="off"
                              placeholder=" "
                              onChange={(e) => formChangeHandler(e)}
                              defaultValue={remoteWfr ? remoteWfr.name : ""}
                            />
                            <label
                              alt="Rule Name"
                              htmlFor="name"
                              placeholder="Rule Name"
                              className="floating-label"
                            >
                              Rule name
                            </label>
                            <span
                              className="crm-inp-error"
                              id="err_name"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-form-row cstmwdth">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field">
                            <textarea
                              placeholder="Description"
                              rows="6"
                              id="description"
                              onChange={(e) => formChangeHandler(e)}
                              defaultValue={
                                remoteWfr ? remoteWfr.description : ""
                              }
                            ></textarea>
                            <span
                              className="crm-inp-error"
                              id="err_description"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                  <div className="wflow-steps-wrpr">
                    <div className="wflow-steps">
                      <ActionPreference
                        ref={actionPreference}
                        remoteWFR={{ ...remoteWfr }}
                        ruleExtraData={{ ...ruleExtraData }}
                        functions={{ ...wfSettingFunctions }}
                      />

                      <SetConditions
                        ref={setConditions}
                        operators={operators}
                        remoteWFR={{ ...remoteWfr }}
                        ruleExtraData={{ ...ruleExtraData }}
                        functions={{ ...wfSettingFunctions }}
                      />

                      <ActionsToPerform
                        ref={actionsToPerform}
                        remoteWFR={{ ...remoteWfr }}
                        ruleExtraData={{ ...ruleExtraData }}
                        functions={{ ...wfSettingFunctions }}
                      />
                      {/* <SetActionTime /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="crm-form-foot">
              <div className="crm-btn">
                <div className="crm-btn-left">
                  {mode != "edit" ? (
                    <React.Fragment>
                      <button
                        id="btnSave"
                        type="button"
                        data-action="save"
                        className="btn btn-primary lead-save"
                        onClick={(e) => handleCreateWFRule(e)}
                        disabled={primaryActionBtns}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        id="btnSaveNew"
                        data-action="save&active"
                        className="btn btn-secondary lead-savesec"
                        onClick={(e) => handleCreateWFRule(e)}
                        disabled={primaryActionBtns}
                      >
                        Save and Activate
                      </button>
                    </React.Fragment>
                  ) : (
                    <button
                      id="btnSave"
                      type="button"
                      data-action="save"
                      className="btn btn-primary lead-save"
                      onClick={(e) => handleCreateWFRule(e)}
                      disabled={primaryActionBtns}
                    >
                      Save
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => navigate("/app/workflows")}
                    disabled={primaryActionBtns}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <FullPageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
