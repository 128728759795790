export default class WorkerLoader {
  // constructor(workerFunction) {
  //   const workerCode = workerFunction.toString();
  //   const workerBlob = new Blob([`(${workerCode})()`]);
  //   return new SharedWorker(URL.createObjectURL(workerBlob));
  // }
  constructor() {
    return new SharedWorker(`${process.env.PUBLIC_URL}/appWorkers.worker.js`, {
      name: "authenticator",
    });
  }
}
