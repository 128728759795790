import React, { useEffect } from "react";

const useKeyEvent = (onKeyPress, keyCode) => {
  useEffect(() => {
    const handleKey = (event) => {
      if (event.keyCode === keyCode) {
        onKeyPress();
      }
    };

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

};

export default useKeyEvent;
