import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const ConditionValueAdder = forwardRef((props, ref) => {
  const [cValues, setCValues] = useState([]);
  const [operator, setOperator] = useState("");
  const nonValueOps = useRef(["empty", "nonEmpty"]);

  useEffect(() => {
    if (props.mode === "edit") {
      if (nonValueOps.current.includes(props.conditionProps.operator)) {
        setOperator(props.conditionProps.operator);
      } else {
        loopFurnishAndAppend();
        setCValues(props.conditionProps.value);
      }
    }
  }, [cValues]);

  useImperativeHandle(ref, () => ({
    updateOperator(operator) {
      setOperator(operator);
    },
  }));

  const loopFurnishAndAppend = () => {
    const i = props.i;
    const j = props.j;
    cValues.map(async (value) => {
      const conId = i + j + "--" + Math.floor(Math.random() * 100);
      const dd = {
        name: value,
        id: conId,
        for: "cond-" + conId,
        forIndex: j,
        forConditionIndex: i,
      };
      const n = await furnishNode(dd);
      const pn = document.getElementById("addedValues_" + i + j);
      if (!document.getElementById(dd.id) && n.status) {
        pn.appendChild(n.node);
      }
    });
  };

  const furnishNode = async (nodeData) => {
    return new Promise((resolve, reject) => {
      try {
        const spanNode = document.createElement("span");
        const iNode = document.createElement("i");
        spanNode.className = "appendItem";
        spanNode.id = nodeData.for + "span_" + nodeData.id;
        iNode.className = "itemClose";
        iNode.id = nodeData.for + "i_" + nodeData.id;
        iNode.dataset.forgi = nodeData.forIndex;
        iNode.dataset.forci = nodeData.forConditionIndex;
        iNode.addEventListener("click", (e) => {
          const id = String(e.target.id).split("_")[1];
          const el = document.getElementById(nodeData.for + "span_" + id);
          const removableValue = {
            forgi: e.target.dataset.forgi,
            forci: e.target.dataset.forci,
          };
          props.functions
            .conditionValueSplicer(removableValue, el.innerText)
            .then(() => {
              el.remove();
            })
            .catch((e) => {
              console.log(e);
            });
        });
        spanNode.innerText = nodeData.name;
        spanNode.append(iNode);
        resolve({ status: true, node: spanNode });
      } catch (error) {
        reject({ status: false, error: error });
      }
    });
  };

  return (
    <React.Fragment>
      {!nonValueOps.current.includes(operator) ? (
        <div className="crm-form-field">
          <div className="shareSec">
            <div className="shareBox">
              <div
                className="append_box"
                data-forgi={props.j}
                data-forci={props.i}
                id={"addedValues_" + props.i + props.j}
                style={{ display: "block" }}
              ></div>
              <div className="shareBox-input">
                <input
                  type="text"
                  data-forgi={props.j}
                  data-forci={props.i}
                  placeholder=" "
                  autoComplete="off"
                  id={"value-" + props.i + props.j}
                  onChange={(e) => props.functions.handleAddValues(e)}
                  className="input-hidden floating-input"
                  onKeyDown={(e) => props.functions.getAndInsertAddedValues(e)}
                />
                <label
                  htmlFor={"value-" + props.i}
                  placeholder="Enter values"
                  className="floating-label"
                >
                  Enter values
                </label>
              </div>
            </div>
          </div>
          <span
            style={{
              display: "block",
              color: "#ccc",
            }}
          >
            hint- Press Enter to add values
          </span>
          <span
            id={"err_value-" + props.i + props.j}
            className="crm-inp-error"
          ></span>
        </div>
      ) : null}
    </React.Fragment>
  );
});

export default ConditionValueAdder;
