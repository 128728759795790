import React, { useEffect, useState } from "react";
import {
  echo,
  getFileExt,
  fileSizeFromBytes,
} from "../../utils";
import NoResults from "../common/noResults";
import { downloadContractFiles } from "../../apis/contractAPIs";
import DataTableLoader from "../common/dataTableLoader";

export default function ListTableView(props) {
  useEffect(() => {
    console.log(props);
  }, []);

  const deleteContractFile = async (e) => {
    const dData = [e.target.dataset.fileos];
    props.handleDeleteContractFile(dData);
  };

  const downloadFile = async (e) => {
    const tfile = e.target.dataset.fileos;
    window.open(tfile, "_blank");
    return;

    const theFile = await downloadContractFiles(tfile);
    console.log(theFile, "aasdasdasd");
    if (theFile.constructor.name === "Blob") {
      const el = document.getElementById(
        String(e.target.id).split("_")[0] + "_reald"
      );
      el.href = URL.createObjectURL(theFile);
      el.download = e.target.dataset.fileos;
      el.click();
    } else {
      return;
    }
  };
  const handleCollapseExpand = (e) => {
    const el = document.getElementById(e.target.id);
    if (Array.from(el.classList).includes("toggle")) {
      el.classList.remove("toggle");
      document.getElementById(e.target.id + "files").style.display = "block";
    } else {
      el.classList.add("toggle");
      document.getElementById(e.target.id + "files").style.display = "none";
    }
  };

  return (
    <React.Fragment>
      <div className="crm-table cst-mrgn list-table">
        <div className="crm-tb-hed">
          {props?.data && Object.keys(props.data)?.length>0 && <div className="crm-tb-rw">
            <div className="crm-td crm-td-cntrctname">
              <span
                className={
                  props.sortInfo.sortKey === "contracts_name"
                    ? "sort-highlight"
                    : ""
                }
                id="cn"
                onClick={(e) =>
                  props.handleSort(e, "Contracts name", "contracts_name")
                }
              >
                Contracts name
              </span>
              <i
                style={{ margin: "5px 0px 0px" }}
                className={
                  props.sortInfo.sortKey === "contracts_name"
                    ? props.sortInfo.sortBy === "desc"
                      ? "sort sort-down"
                      : "sort sort-up"
                    : ""
                }
                id="contracts_name"
                onClick={(e) =>
                  props.handleSort(e, "Contracts name", "contracts_name")
                }
              ></i>
            </div>
            <div
              className={`crm-td crm-td-crtdby
            ${
              props.sortInfo.sortKey === "created_by"
                ? props.sortInfo.sortBy === "desc"
                  ? "crm-sort sort-active"
                  : "crm-sort sort-active-up"
                : ""
            }`}
              id="created_by"
              onClick={(e) => props.handleSort(e, "Created by", "created_by")}
            >
              <span>Created by</span>
            </div>
            <div
              className={`crm-td crm-td-lastmod
             ${
               props.sortInfo.sortKey === "last_modified"
                 ? props.sortInfo.sortBy === "desc"
                   ? "crm-sort sort-active"
                   : "crm-sort sort-active-up"
                 : ""
             }`}
              id="last_modified"
              onClick={(e) =>
                props.handleSort(e, "Updated date", "last_modified")
              }
            >
              <span>Last modified</span>
            </div>
            <div
              className={`crm-td crm-td-filesize
             ${
               props.sortInfo.sortKey === "file_size"
                 ? props.sortInfo.sortBy === "desc"
                   ? "crm-sort sort-active"
                   : "crm-sort sort-active-up"
                 : ""
             }`}
              id="file_size"
              onClick={(e) =>
                props.handleSort(e, "Contracts file size", "file_size")
              }
            >
              <span>File size</span>
            </div>
            <div className="crm-td crm-action"></div>
          </div>}
        </div>
        <div className="crm-tb-bdy">
          {!props.loading ? (
            Object.keys(props.data).length ? (
              Object.keys(props.data).map((i, j) => (
                <React.Fragment>
                  <div
                    id={i + j}
                    className="crm-tb-rw crm-grprw"
                    onClick={(e) => handleCollapseExpand(e)}
                  >
                    <p>{i}</p>
                  </div>
                  <div className="crm-grpitm" id={i + j + "files"}>
                    {i.length
                      ? props.data[i].map((fileInfo, k) => (
                          <div className="crm-tb-rw">
                            <div className="crm-td crm-td-cntrctname">
                              <i
                                className={
                                  String(
                                    getFileExt(fileInfo.contract_file_name)
                                  ).toLocaleLowerCase() == ".csv"
                                    ? "crm-csv-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".jpeg" ||
                                      String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".jpg" ||
                                      String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".png"
                                    ? "crm-file-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".xls" ||
                                      String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".xlsx"
                                    ? "crm-xls-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".doc" ||
                                      String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".docx"
                                    ? "crm-doc-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".pdf"
                                    ? "crm-pdf-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".ods"
                                    ? "crm-ods-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".odt"
                                    ? "crm-odf-icon"
                                    : String(
                                        getFileExt(fileInfo.contract_file_name)
                                      ).toLocaleLowerCase() == ".txt"
                                    ? "crm-txt-icon"
                                    : "crm-invfile-icon"
                                }
                              ></i>
                              <span>{echo(fileInfo.contract_file_name)}</span>
                            </div>
                            <div className="crm-td crm-td-crtdby">
                              <span>{echo(fileInfo.created_by_name)}</span>
                            </div>
                            <div className="crm-td crm-td-lastmod">
                              <span>{echo(fileInfo.created_at)}</span>
                            </div>
                            <div className="crm-td crm-td-filesize">
                              <span>
                                {echo(
                                  fileSizeFromBytes(
                                    parseInt(fileInfo.contracts_file_size)
                                  )
                                )}
                              </span>
                            </div>
                            {props.permission.view ? (
                              <div className="crm-td crm-action">
                                <a
                                  className="crm-action-opt crm-action-settings"
                                  id=""
                                >
                                  <ul className="crm-dropdown-action" id="">
                                    <li>
                                      <span
                                        className="crm-action-download"
                                        onClick={(e) => downloadFile(e)}
                                        id={fileInfo.uuid + "_downloadLink"}
                                        data-fileos={fileInfo.contract_file_url}
                                      >
                                        Download
                                      </span>
                                      <a
                                        id={fileInfo.uuid + "_reald"}
                                        style={{ display: "none" }}
                                      ></a>
                                    </li>
                                    {props.permission.delete ? (
                                      <li className="divider">
                                        <span
                                          className="crm-action-delete"
                                          onClick={(e) => deleteContractFile(e)}
                                          id={fileInfo.uuid + "_deleteLink"}
                                          data-fileos={
                                            fileInfo.contract_file_os_name
                                          }
                                        >
                                          Delete
                                        </span>
                                      </li>
                                    ) : null}
                                  </ul>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <NoResults permission={!props.permission.view} filter={props.filter}/>
            )
          ) : (
            <DataTableLoader
              mClass={"crm-td crm-td-cntrctname"}
              cols="100000"
              module="contract"
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
