import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getRecycleBinByModule = async (module,sortBy,sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/recycle_bin/module?module_name=${module}&field=${
            sortField ? sortField : "Deleted date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`
          ,{
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const restoreRecycleBinByModule = async (module, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + `/recycle_bin/restore?module_name=${module}`,
        reqPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteBinRecordByModule = async (module, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        API_URL + `/recycle_bin/delete?module_name=${module}`,
        {
          data: reqPayload,
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const emptyRecycleBin = async () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `/recycle_bin/empty_recycle_bin`,null,{
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
