import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getLeadsLimitNoffset, getLeadsLimitOffset } from "../apis/leadAPI";
import NoResults from "../components/common/noResults";
import { Tooltip } from "react-tooltip";
import {
  echo,
  getDate,
  forEach,
  getSortBy,
  getObjValue,
  echoPrimary,
  leadsFormatter,
  debounceOptFunc,
  organiseColumns,
  getUserAccessInfo,
  getManagedColumns,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import { useOutside } from "../customHooks/useOutside";
import Range from "../components/datepicker/range";
import CustomFilter from "../components/filters/customFilter";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import {
  downloadCSVExportedFiles,
  exportDataInCSV,
  getExportFileCustomFilter,
  getExportFileDateFilter,
  getExportFileFixedFilter,
  getExportFileSearchFilter,
  searchAPI,
  searchThroughAPIs,
} from "../apis";
import createResizer from "../utils/resizerHandler";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import { APP_DATA } from "../app_data";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";
import SendEmail  from "../components/sendEmailModule/sendEmail";

const MODULE_NAME = "leads";

export default function LeadsView() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    openModal,
    openToast,
    closeModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);

  const searchRef = useRef(null);
  const pagination = useRef(null);
  const datePicker = useRef(null);
  const deleteIdsList = useRef([]);
  const dropdownMenu = useRef(null);
  const selectFilter = useRef(null);
  const customFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);

  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All Leads");
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [leadsPermission, setLeadsPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [filter, setFilter] = useState(false);
  const [newLead, setNewLead] = useState(null);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName, setDisplayName] = useState({});
  const [showSendEmail, setSendEmail] =useState(false)
  const [leadInfo, setLeadInfo] = useState("");

  useEffect(() => {
    const leads = getUserAccessInfo("Leads");

    if (leads?.permission) {
      setLeadsPermission(leads?.permission);
    } else {
      setLeadsPermission({});
    }
    getModuleDataTypesAPI("leads")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName);
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
    if (location.state?.id) {
      handleVECMenu(location.state?.id, "vec");
      navigate(location.pathname, {});
    }
  }, []);

  useEffect(() => {
    const leadsFilter = JSON.parse(sessionStorage.getItem("leadsFilter"));
    if (
      leadsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(leadsFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: leadsFilter,
      }));
      getLeadsForCustomFilter(
        appliedFilters,
        true,
        0,
        10,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      if (location.state?.filter?.value) {
        setFilter(true);
        getLeads(location.state.filter.value);
        setSelectedFilter(location.state.filter.value);
        document.getElementById("selected_filter").innerText =
          APP_DATA.fixedFilters.leads.find(
            (c) => c.value === location.state.filter.value
          )?.name;
        //we're navigating to clear state so that prev filter is not maintained
        navigate(location.pathname, {});
      } else {
        handlePaginatedFetchData(pageData);
      }
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    createResizer();
  }, [leads, location.search, loading]);

  const getLeads = async (selectFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1

    pagination?.current?.resetDefaultPage();
    setLoading(true);

    const leads = await getLeadsLimitOffset(
      0,
      pageData.limit,
      true,
      selectFilter,
      APP_DATA.fixedFiltersOwnerFields.leads.includes(selectFilter)
        ? getUserAccessInfo()?.user_id
        : "",
      sortBy,
      sortField
    );
    if (leads.status) {
      if (leads.data?.hasOwnProperty("leads")) {
        setLeads(
          organiseColumns(leads.data.leads, getManagedColumns("leadsColumn"))
        );
      } else {
        setLeads([]);
        setTotalRecords(0);
      }

      if (leads.data?.hasOwnProperty("total_count")) {
        setTotalRecords(leads.data.total_count);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === leads?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== leads?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const leads = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (leads.status) {
        if (leads?.data) {
          setLeads(
            organiseColumns(leads.data, getManagedColumns("leadsColumn"))
          );
        } else {
          setLeads([]);
        }
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const leads = await searchAPI(
          "leads",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (leads.status) {
          setTotalRecords(leads.data.total_count);
          setLeads(
            organiseColumns(leads.data.leads, getManagedColumns("leadsColumn"))
          );
        } else {
          setLeads([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getLeadsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getLeadsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      //default get data
      const leads = await getLeadsLimitNoffset(
        pageData.offset,
        pageData.limit,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.leads.includes(selectedFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (leads.status) {
        setTotalRecords(leads.data.total_count);
        setLeads(
          organiseColumns(leads.data.leads, getManagedColumns("leadsColumn"))
        );
      } else {
        setLeads([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  //searching function
  async function handleSearch(qstring) {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs(
        "leads",
        qstring,
        0,
        pageData.limit
      );
      if (searched.status) {
        setLeads(
          organiseColumns(searched.data.leads, getManagedColumns("leadsColumn"))
        );
        setTotalRecords(searched.data.total_count);
      } else {
        setLeads([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getLeads();
      setFilter(false);
    }
  }
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const getLeadsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const leads = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (leads.status) {
        if (leads?.data?.leads?.length > 0) {
          setLeads(
            organiseColumns(leads.data.leads, getManagedColumns("leadsColumn"))
          );
        } else {
          setLeads([]);
        }
        if (leads.data?.hasOwnProperty("total_count")) {
          setTotalRecords(leads.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLeads([]);
      setLoading(false);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const handleFixedFilter = (e) => {
    const filterValue = e.target.getAttribute("data-value");
    const filterName = e.target.getAttribute("data-name");
    selectFilter.current.classList.add("open");

    if (filterValue != "" && filterValue != undefined) {
      document.getElementById("selected_filter").innerText = filterName;
      setSelectedFilter(filterValue);
      clearAll();
      clearDateFilter();
      getLeads(filterValue, sortInfo.sortBy, sortInfo.sortField);
      selectFilter.current.classList.remove("open");
      searchRef.current.value = "";
      setFilter(true);
      unCheckRecords();
    }
  };

  const clearDateFilter = (leads) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (leads) {
      getLeads();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Leads");
      document.getElementById("selected_filter").innerText = "All leads";
      setFilter(false);
    }
  };

  const handleDatePicker = (value) => {
    clearAll();
    clearFixedFilter();
    searchRef.current.value = "";
    unCheckRecords();
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getLeadsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end, label) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getLeadsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const getLeadsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const leads = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (leads.status) {
        if (leads?.data?.leads?.length > 0) {
          setLeads(
            organiseColumns(leads.data.leads, getManagedColumns("leadsColumn"))
          );
        } else {
          setLeads([]);
        }
        if (leads.data?.hasOwnProperty("total_count")) {
          setTotalRecords(leads.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLeads([]);
      setLoading(false);
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    openFilterSidebar(false);
    if (selectedFilter) {
      clearFixedFilter();
    }
    searchRef.current.value = "";
    clearDateFilter();
    unCheckRecords();
    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    getLeadsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  const clearAll = (leads) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      if (leads) {
        getLeads("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }
    if (sessionStorage.getItem("leadsFilter")) {
      sessionStorage.removeItem("leadsFilter");
    }
  };

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getLeadsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getLeadsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getLeadsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getLeads(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("leadsColumn")) {
      sessionStorage.removeItem("leadsColumn");
    }
    getDataForManageColumns();
  };

  const handleVECMenu = (recordId, actionType) => {
    if (actionType === "vec") {
      handleAllMenuSideBar("vecLead", {
        id: recordId,
        type: "vec",
        functions:{
          sendMail
        }
      });
    } else {
      handleAllMenuSideBar("vecLead", {
        id: recordId,
        type: "edit",
        functions:{
          sendMail
        }
      });
      return;
    }
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "leads_check") {
      return;
    }

    if (e.target.id === "lead") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getLeadsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getLeadsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getLeadsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getLeads(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Leads",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });

      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }

      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file..",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      setBtnDelete(false);
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const highLightNewLead = (id) => {
    //here we're preserving the newly created leadid to highlight the record for 10s
    setNewLead(id);
    setTimeout(() => {
      setNewLead(null);
    }, 10000);
  };

 const sendMail = (value, leadInfo) => {
    setSendEmail(value);
    setLeadInfo(leadInfo);
  };
    
  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Leads</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by:</span>{" "}
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <= 1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 && (
                  <span
                    onClick={(e) => {
                      openFilterSidebar(true);
                    }}
                  >
                    {" "}
                    and <a>more</a>
                  </span>
                )}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by:</span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  leadsPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                ref={selectFilter}
                onClick={leadsPermission?.view ? handleFixedFilter : null}
              >
                <div className="crm-custom-select-value">
                  <span id="selected_filter">All leads</span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.leads.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-name={item.name}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search leads"
                ref={searchRef}
                disabled={!leadsPermission?.view}
                onChange={(e) => handleDebouncedSearch(e.target.value)}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                leadsPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id="filter"
              onClick={
                leadsPermission?.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                data-placement="bottom"
                data-original-title="Filter by Fields"
                className="crm-action-field-filter cm-tooltip"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                leadsPermission?.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                leadsPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              >
                November 2, 2022 - December 1, 2022
              </span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            {
              <div
                id="date-picker"
                className="daterangepicker ltr show-ranges opensright range"
                style={{
                  display: showDatePicker ? "block" : "none",
                  top: "98.1406px",
                  left: "auto",
                  right: "0px",
                }}
                ref={datePicker}
              >
                <Range
                  functions={{ handleDatePicker, handleCallback }}
                  datePickerFilter={datePickerFilter}
                  showCalendar={showDatePicker}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              leadsPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                leadsPermission?.create
                  ? () => handleRSidebar("createLead", { highLightNewLead })
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Lead</span>
            </div>
            <div
              className="rhs-prt"
              onMouseLeave={() => (dropdownMenu.current.style.display = "none")}
            >
              <i
                className="drpdwn-icn"
                onMouseEnter={() =>
                  (dropdownMenu.current.style.display = "block")
                }
              ></i>
              <div className="drpdwn-values" ref={dropdownMenu}>
                <span
                  className="import-lead"
                  onClick={
                    leadsPermission?.create
                      ? () => handleRSidebar("importLead")
                      : null
                  }
                >
                  Import Leads
                </span>
              </div>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            {/* delete action */}
            <button
              className={
                btnDelete && leadsPermission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && leadsPermission?.delete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "leads",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? leads?.find((l) =>
                                deleteIdsList.current.includes(l.lead_id)
                              )
                            : null,
                      })
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>
            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                <span
                  className={`${
                    totalRecords && leadsPermission.edit ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && leadsPermission.edit
                      ? () =>
                          navigate("/app/leads/bulkupdate?mode=bu&target=leads")
                      : null
                  }
                >
                  Mass Update
                </span>
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && leadsPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && leadsPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
                {/* <span
                  className={`${
                    totalRecords && leadsPermission.view ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && leadsPermission.view
                      ? () => sendMail(true)
                      : null
                  }
                >
                  Send Mail
                </span> */}
              </div>
            </button>
          </div>
        </div>
        {leadsPermission?.view &&
          leads?.length > 0 &&
          Object.keys(displayName).length > 0 && (
            <div className="menu-rgt">
              <div className="total_count">
                <div className="total_title">Total leads: </div>
                <div className="total_number">
                  <strong>{totalRecords}</strong>
                </div>
              </div>
              <NewPagination
                ref={pagination}
                totalRecords={totalRecords}
                functions={{
                  handlePaginatedFetchData: handlePaginatedFetchData,
                }}
              />
            </div>
          )}
      </div>

      {/* table section */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {leads?.length > 0 &&
          leadsPermission?.view &&
          Object.keys(displayName).length > 0 && (
            <React.Fragment>
              <div className="crm-action">
                <a
                  id="m-managecolumn"
                  className="crm-action-filter"
                  onClick={
                    leadsPermission?.view
                      ? () =>
                          handleRSidebarManageColumns("manageColumns", {
                            applyManageColumn,
                            clearManageColumn,
                            columns: columnInfo,
                            module: "leads",
                            sessModule: "leadsColumn",
                          })
                      : null
                  }
                ></a>
              </div>
              <Tooltip
                place={"left"}
                anchorId={"m-managecolumn"}
                content="Manage columns"
              />
            </React.Fragment>
          )}

        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {leads?.length > 0 &&
                  leadsPermission?.view > 0 &&
                  Object.keys(displayName).length > 0 &&
                  !loading && (
                    <div className="crm-td crm-td-leads">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark"
                          ref={selectAllCheck}
                          id="leads_check"
                          onClick={(e) => handleSelectAll(e)}
                          disabled={leads?.length ? false : true}
                        />
                        <label htmlFor="leads_check"></label>
                      </div>
                      <div className="name">
                        <span
                          className={
                            sortInfo.sortKey === "lead_fullname"
                              ? "sort-highlight"
                              : ""
                          }
                          id="lead"
                          onClick={(e) => {
                            handleSort(e, "lead_fullname", "leadFullName");
                          }}
                        >
                          {/* Lead name */}
                          {displayName["lead_fullname"]}
                        </span>
                      </div>
                      <i
                        className={
                          sortInfo.sortKey === "lead_fullname"
                            ? sortInfo.sortBy === "desc"
                              ? "sort sort-down"
                              : "sort sort-up"
                            : ""
                        }
                        id="leadFullName"
                        onClick={(e) =>
                          handleSort(e, "lead_fullname", "leadFullName")
                        }
                      ></i>
                    </div>
                  )}
              </div>
              <div className="crm-scroll-field">
                {leads?.length > 0 &&
                  leadsPermission?.view &&
                  Object.keys(displayName).length > 0 &&
                  !loading &&
                  Object.keys(leads[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.leads.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.leads[heading]
                              ? APP_DATA.APIColumnMappings.leads[heading]
                              : heading?.split("_").join(" ")} */}
                            {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              leadsPermission?.view &&
              Object.keys(displayName).length > 0 &&
              leads?.length > 0 ? (
                leads.map((l, i) => (
                  <div
                    className={
                      l.lead_id === newLead
                        ? `crm-tb-rw highlight`
                        : `crm-tb-rw`
                    }
                  >
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-leads d-flex">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={l.lead_id}
                            onChange={(e) => {
                              handleDeleteCheck(e);
                            }}
                          />
                          <label
                            className="email-id"
                            title=""
                            htmlFor={l.lead_id}
                          ></label>
                        </div>
                        <div
                          className="name lead-name"
                          onClick={
                            leadsPermission?.view
                              ? () => handleVECMenu(l.lead_id, "vec")
                              : null
                          }
                        >
                          <span className="link">
                            {echoPrimary(l.lead_firstname) +
                              " " +
                              echoPrimary(l.lead_lastname)}
                          </span>
                        </div>
                        <div className="action-rw">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              handleRSidebar("convertLead", {
                                id: l.lead_id,
                                name: l.lead_firstname + " " + l.lead_lastname,
                              })
                            }
                            disabled={
                              !leadsPermission?.edit ||
                              l.lead_status === "Converted"
                                ? true
                                : false
                            }
                          >
                            Convert
                          </button>
                          <i
                            id={"edit-title" + i}
                            className={
                              leadsPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              leadsPermission?.edit
                                ? () => handleVECMenu(l.lead_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        leadsPermission?.view
                          ? () => handleVECMenu(l.lead_id, "vec")
                          : null
                      }
                    >
                      {leads?.length > 0 &&
                        Object.keys(leads[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.leads.includes(heading) && (
                              <div
                                data-colpos={i}
                                className={`crm-td `}
                                key={i}
                              >
                                <span>
                                  {!APP_DATA.formattingColumns.leads.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(l, heading))
                                    : leadsFormatter(
                                        heading,
                                        getObjValue(l, heading)
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!leadsPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader mClass={"crm-td crm-td-leads"} module="lead" />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"leads"}
            />
          )}
        </div>
      )}
      {showSendEmail && (
        <div
          className="crm-composemail-sec"
          style={{ display: showSendEmail ? "block" : "none" }}
        >
          <SendEmail 
            functions={{sendMail}}
            leadInfo={leadInfo}
          />
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
