import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { getUserAccessInfo, echo, logoForExtension } from "../utils";
import { useOutletContext } from "react-router";
import { getExtensionById } from "../apis/extensionAPI";

const DetailExtensionView = ({ id, setView, preserveEnableStatus }) => {
  const navigate = useNavigate();
  const { handleRSidebar, openModal } = useOutletContext();
  const { state } = useLocation();

  const [permission, setExtensionPermission] = useState({});
  const [extension, setExtension] = useState(null);

  useEffect(() => {
    const extensions = getUserAccessInfo("Extensions");
    if (extensions?.permission) {
      setExtensionPermission(extensions.permission);
    } else {
      setExtensionPermission({});
    }  
  }, []);

  useEffect(() => {
    if (id) {
      getExtension(id);
    }
  }, [id]);

  const getExtension = (id) => {
    getExtensionById(id)
      .then((res) => {
        if (res.status) {
          setExtension(res.data);
        }
      })
      .catch((err) => {
        setExtension(null);
        console.log(err);
      });
  };

  const extensionRhsPane = (name) => {
    switch (name) {
      case "Zoom Meeting":
        return "activateCredExtension";
      case "Microsoft Teams Meeting":
        return "activateTokenExtension";
      case "Slack":
        return "activateChannelExtension";
      case "Google Meet":
        return "activateFileExtension";
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className="crm-tab-content"
        id="allExtTab"
        style={{ display: "block" }}
      >
        <div className="extcol">
          <div className="extcol-sctn">
            <div className="about-app">
              <div className="lft-sctn">
                <i className="crm-action-back" onClick={() => setView("installed")}></i>
                {/* <i className="crm-action-back" onClick={() => navigate(-0)}></i> */}

                <div className="logo-sctn">
                  <i
                    className={`app-icon ${logoForExtension(extension?.id)}`}
                  ></i>
                </div>
              </div>
              <div className="rgt-sctn">
                <p className="title">{echo(extension?.name)}</p>
                <p className="desc">{echo(extension?.description)}</p>
                <button
                  type="button"
                  className={permission.edit ? "btn btn-primary actvt-btn":"btn btn-primary actvt-btn disabled"}
                  onClick={permission.edit ? () =>
                    extension?.active === true
                      ? openModal("extensionDisableModal", {
                          id: extension.id,
                          name: extension.name,
                          setView,
                        })
                      : handleRSidebar(extensionRhsPane(extension?.name), {
                          id: id,
                          setView: setView,
                        }):null
                  }
                >
                  {extension?.active === true ? "Deactivate" : "Activate"}
                </button>
              </div>
            </div>
          </div>
          <div className="extcol-rgt"></div>
        </div>
      </div>
    </>
  );
};

export default DetailExtensionView;
