import React from "react";

export default function LocalSuspense() {
  // TODO add a custome timeout for smooth transition b/w the suspence and lazycomp
  return (
    <React.Fragment>
      <div className="loader" style={{ display: "block" }}></div>
    </React.Fragment>
  );
}
