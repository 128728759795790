import React from 'react'
import ContentLoader from "react-content-loader"

export default function FunnelChartLoader(props) {
    return (
        <ContentLoader 
            speed={2}
            width={217}
            height={224}
            viewBox="0 0 217 224"
            backgroundColor="#f3f3f3"
            foregroundColor="#ccc"
            {...props}
        >
            <path d="M 78 156.9 h 61 l -8.9 18.1 v 26.1 l -43 22 v -47.9 z M 158.3 117.9 l -18.3 37 H 76.9 l -18.3 -37 z M 39.4 78.9 h 138.2 l -18.3 37 H 57.6 z M 20.1 39.9 h 176.8 l -18.3 37 H 38.4 z M 216.2 0.9 l -18.3 37 H 19.1 L 0.8 0.9 z" />
    </ContentLoader>
  )
}
