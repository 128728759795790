import React, { useEffect, useState, useRef } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { inputFieldValidator } from "../../utils";
import { APP_DATA } from "../../app_data";
import { getModuleDataTypesAPI } from "../../apis/workflowAPIs";
import { getEmailTemplateByModule } from "../../apis/emailTemplateAPIs";
import TextEditor from "../workflows/textEditor";
import { getUsersLimitNoffset, getUsersByName } from "../../apis/usersAPI";
import { getUserAccessInfo, debounceOptFunc, search } from "../../utils";
import { getCompany } from "../../apis/companyAPI";
import SubjectInput from "../workflows/comps/SubjectInput";
import { sendEmailAPI } from "../../apis/leadAPI";
import { searchThroughAPIs } from "../../apis";
import { useOutside } from "../../customHooks/useOutside";

const SendEmail = (props) => {
  const { openModal } = useOutletContext();
  const location = useLocation();
  const openMenu = useRef(null);

  const emailSubjectRef = useRef(null);
  const emailBodyEditor = useRef(null);
  const staticModules = useRef(APP_DATA.modules);
  const [selected, setSelected] = useState({
    emailTo: [],
    emailCc: [],
    emailBcc: [],
    selectTemplate: [],
    emailSubject: "",
    emailBody: "",
    attachments: [],
    scheduledAt: {},
  });
  const emailTemplateView = useRef(null);
  const userSearchViewTo = useRef(null);
  const userSearchViewCc = useRef(null);
  const userSearchViewBcc = useRef(null);
  const templateDropdown = useRef(null);
  const preservedData = useRef({});

  const [users, setUsers] = useState([]);
  const [moduleField, setModuleFields] = useState([]);
  const [cursorPosition, setCursorposition] = useState({ subject: 0, body: 0 });
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [remoteEmailSettings, setRemoteEmailSettings] = useState({});
  const [usersFound, setUserFound] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [defaultEmailIds, setDefaultEmailIds] = useState([]);
  const [searchSelector, setSearchSelector] = useState({});
  const [templateFound, setTemplateFound] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [type, setType] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [localeFile, setLocalFile] = useState([]);
  const [leadDetails, setLeadDetails] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [showEditor, setShowEditor] = useState(true);

  useEffect(() => {
    if (selected.emailTo?.length > 0 && selected.emailSubject?.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [selected]);

  useEffect(() => {
    const companyInfo = getUserAccessInfo("Company");
    if (companyInfo?.permission) {
      setPermissions(companyInfo?.permission);
    } else {
      setPermissions({});
    }
    setCompanyDetails();
    getCompanyInfo();
  }, [location.search]);

  useEffect(() => {
    getEmailTempates("leads");
    const selectEmailTemplate = document.getElementById("selectTemplate");
    if (selectEmailTemplate) {
      selectEmailTemplate.selectedIndex = 0;
    }
    setLeadDetails(props.leadInfo);
    if (props?.leadInfo?.email) {
      setSelected((prev) => {
        const emailTo = [];
        emailTo.push(props.leadInfo.email);
        return {
          ...prev,
          emailTo,
        };
      });
    }
  }, []);

  useEffect(() => {
    getModuleProperties("leads");
  }, [remoteEmailSettings]);

  const getCompanyInfo = async () => {
    const company = await getCompany();
    if (company.status) {
      setCompanyDetails(company.data);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    } else {
      // openToast("Network problem detected,Please check", "error");
    }
  };

  const getEmailTempates = async (moduleName) => {
    const templates = await getEmailTemplateByModule("leads");
    if (templates.status) {
      setEmailTemplates(templates.data);
      setTemplateFound(true);
      preservedData.current["templates"] = templates.data;
    }
  };

  const getModuleProperties = async (moduleName) => {
    const pr = await getModuleDataTypesAPI(moduleName);
    if (pr.status) {
      const tempCustomFilter = [];
      pr.data.map((property) => {
        if (property.custom_filter) {
          tempCustomFilter.push(property);
        }
      });
      setModuleFields(tempCustomFilter);
    } else {
      props.contextMenu.openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const handleSendMail = async (e) => {
    const address = {
      email_to: selected.emailTo,
      email_cc: selected.emailCc,
      email_bcc: selected.emailBcc,
      email_subject: selected.emailSubject,
      email_body: emailBodyEditor.current?.getInnerText() || selected.emailBody,
      scheduled_at: selected.scheduledAt,
      attachments: selected.attachments,
    };
    document.getElementById(e.target.id).classList.add("btnLoader");
    await sendEmailAPI(props.leadInfo.id, address);
    props.functions.sendMail(false);
  };

  const setAttachments = (attachment, localeFile) => {
    const selectedCopy = { ...selected };
    selectedCopy.attachments.push(attachment);
    setSelected(selectedCopy);
    setLocalFile((prev) => [...prev, localeFile]);
  };

  const scheduleDateTime = (scheduleDate) => {
    selected.scheduledAt = scheduleDate;
  };

  const formChangeHandler = async (event) => {
    const elm = document.getElementById("err_" + event.target.id);
    if (event.target.id === "email_subject") {
      calculatePosition(event);
      elm.style.display = "none";
      setSelected((prev) => ({
        ...prev,
        emailSubject: event.target.value,
      }));
    } else if (event.target.id === "email_body") {
      calculatePosition(event);
      elm.style.display = "none";
      setSelected((prev) => ({
        ...prev,
        emailBody: event.target.value,
      }));
    } else {
      return;
    }
  };

  const formChange = async (event) => {
    const validationResponse = await inputFieldValidator(event);
    if (validationResponse.valid) {
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const getUsers = async (limit = 20, search = "") => {
    let users = {};
    if (limit > 0) {
      users = await getUsersLimitNoffset(0, limit, false, "Active Users");
    } else {
      users = await getUsersByName(search);
    }
    if (users.status) {
      setTimeout(() => {
        if (!users.data.length) {
          setUserFound(false);
        } else {
          setUsers(users.data);
          setUserFound(true);
        }
      }, 500);
    }
  };

  const resetSelectTemplate = (e) => {
    const evId = e.target.id.split("_")[1];
    setSearchSelector((prev) => ({
      ...prev,
      [evId]: "",
    }));
    setEmailTemplates([]);
    setSelected((prev) => ({
      ...prev,
      emailSubject: "",
      emailBody: "",
    }));
    setRemoteEmailSettings({});
    if (document.getElementById("selectTemplate")) {
      document.getElementById("selectTemplate").value = "";
    }
    emailSubjectRef.current?.updateEmailSubject("");
    setShowEditor(true);
  };

  const insert = (str, index, value) => {
    return str?.substr(0, index) + value + str?.substr(index);
  };

  const addPlaceholder = (e) => {
    let v;
    const where = String(e.target.id).split("-")[0];
    const fp = `{${String(e.target.id).split("-")[1]}} `;
    if (where === "sub") {
      v = emailSubjectRef.current.getInnerText();
      v = insert(v, cursorPosition.subject, fp);
      emailSubjectRef.current.updateEmailSubject(v);
    } else {
      v = emailBodyEditor.current.getInnerText();
      v = insert(v, cursorPosition.body, fp);
      emailBodyEditor.current.updateEmailBody(v);
    }
  };

  const calculatePosition = (e) => {
    if (e.target.id === "email_subject") {
      setCursorposition((p) => ({ ...p, subject: e.target.selectionStart }));
    } else {
      if (e.target.selectionStart) {
        setCursorposition((p) => ({ ...p, body: e.target.selectionStart }));
      }
    }
  };

  const handleCursorMovement = (e) => {
    if (["38", "40", "37", "39"].includes(e.keyCode)) {
      calculatePosition(e);
    }
  };

  const handleViewBCC = (e) => {
    const el = document.getElementById("bcc_opener");
    el.style.display = "block";
  };

  const handleSelectFile = () => {
    openModal("emailAttachFile", {
      functions: { setAttachments },

      // module: "createLead",
      // moduleName: "leads",
    });
  };

  const handleScheduleMail = () => {
    openModal("scheduleMailModal", {
      functions: { scheduleDateTime },
      // moduleName: "leads",
    });
  };

  //searching function
  async function handleSearch(qstring) {
    if (qstring.length) {
      //need to search with page limit and offset
      const searched = await searchThroughAPIs("emailTemplate", qstring);
      if (searched.status) {
        setEmailTemplates(searched.data.email_templates);
      } else {
        setEmailTemplates([]);
      }
    } else {
      getEmailTempates();
    }
  }

  const searchEmailTemplate = debounceOptFunc(handleSearch);

  const handleDropdownSearch = (e, type = "default") => {
    /**
     * making sure api gets called only once
     * by default it will provide 20 or specified number of records
     * if not found, user can type in the input field and get the desired lead
     */
    const realId = String(e.target.id);
    const inputValue = String(e.target.value).trim();
    if (inputValue === "") {
      type = "default";
      setSearchSelector((prev) => ({
        ...prev,
        [e.target.id]: null,
      }));
    }
    if (searchSelector[e.target.id]) {
      return;
    }
    setUsers([]);
    switch (realId) {
      case "userEmailTo":
        userSearchViewTo.current.style.display = "block";
        if (type === "default" && inputValue === "") {
          getUsers(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else if (type === "default" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else {
          return;
        }
        setType("emailTo");
        break;
      case "userEmailCc":
        userSearchViewCc.current.style.display = "block";
        if (type === "default" && inputValue === "") {
          getUsers(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else if (type === "default" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else {
          return;
        }
        setType("emailCc");
        break;
      case "userEmailBcc":
        userSearchViewBcc.current.style.display = "block";
        if (type === "default" && inputValue === "") {
          getUsers(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else if (type === "default" && inputValue.length >= 1) {
          setUsers([]);
          getUsers(0, inputValue);
        } else {
          return;
        }
        setType("emailBcc");
        break;
      case "selectTemplate":
        emailTemplateView.current.style.display = "block";
        if (type === "default" && inputValue === "") {
          getEmailTempates(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setEmailTemplates([]);
          search(preservedData.current.templates, "name", inputValue)
            .then((res) => {
              if (res.status && res.data.length) {
                setEmailTemplates(res.data);
              } else {
                setEmailTemplates([]);
                setTemplateFound(false);
              }
            })
            .catch((err) => {
              setEmailTemplates([]);
            });
        } else if (type === "default" && inputValue.length >= 1) {
          setEmailTemplates([]);
          getEmailTempates(0, inputValue);
        } else {
          return;
        }
      default:
        break;
    }
  };

  const selectDropdown = async (e, type) => {
    /**
     * pass the select event through form change handler
     * and accordingly manipulate elements
     */
    let tempEvent = {};
    const evId = String(e.target.id).split("_")[1];
    switch (type) {
      case "emailTo":
        if (e.target.checked) {
          const usersInfo = users.find((l) => l.user_id === parseInt(evId));
          const usersData = {
            for: type,
            id: usersInfo.user_id,
            email: usersInfo.user_email,
            name: usersInfo.user_firstname + " " + usersInfo.user_lastname,
          };
          const selectedCopy = { ...selected };
          const dnode = await furnishNode(usersData);
          if (dnode.status && typeof dnode.node === "object") {
            selectedCopy[type].push(usersInfo.user_email);
            document.getElementById("_emailBoxTo").appendChild(dnode.node);
            const te = {
              target: {
                id: "userEmailTo",
                value: selectedCopy[type],
              },
            };
            formChange(te);
            const el = document.getElementById("err_userEmailTo");
            el.innerText = "";
            el.style.display = "none";
            setSelected(selectedCopy);
            document.getElementById("userEmailTo").value = "";
            // document.getElementById(
            // "err_email_" + String(type).toLocaleLowerCase() + "-"
            // ).style.display = "none";
          }
          // setUserIds(selected.current[parentLabel]);
        } else {
          const te = {
            target: {
              id: type + "Span_" + evId,
              email: type + "Span_" + evId,
            },
          };
          await removeSelectedLD(te, type);
        }
        // setType("userEmailTo")
        break;
      case "emailCc":
        if (e.target.checked) {
          const usersInfo = users.find((l) => l.user_id === parseInt(evId));
          const usersData = {
            for: type,
            id: usersInfo.user_id,
            email: usersInfo.user_email,
            name: usersInfo.user_firstname + " " + usersInfo.user_lastname,
          };
          const selectedCopy = { ...selected };
          const dnode = await furnishNode(usersData);
          if (dnode.status && typeof dnode.node === "object") {
            selectedCopy[type].push(usersInfo.user_email);

            document.getElementById("_emailBoxCc").appendChild(dnode.node);
            const te = {
              target: {
                id: "userEmailCc",
                value: selectedCopy[type],
              },
            };
            formChange(te);
            const el = document.getElementById("err_userEmailCc");
            el.innerText = "";
            el.style.display = "none";
            setSelected(selectedCopy);
            document.getElementById("userEmailCc").value = "";
          }
        } else {
          const te = {
            target: {
              id: type + "Span_" + evId,
              email: type + "Span_" + evId,
            },
          };
          await removeSelectedLD(te, type);
        }
        break;
      case "emailBcc":
        if (e.target.checked) {
          const usersInfo = users.find((l) => l.user_id === parseInt(evId));
          const usersData = {
            for: type,
            id: usersInfo.user_id,
            email: usersInfo.user_email,
            name: usersInfo.user_firstname + " " + usersInfo.user_lastname,
          };
          const selectedCopy = { ...selected };
          const dnode = await furnishNode(usersData);
          if (dnode.status && typeof dnode.node === "object") {
            selectedCopy[type].push(usersInfo.user_email);

            document.getElementById("_emailBoxBcc").appendChild(dnode.node);
            const te = {
              target: {
                id: "userEmailCc",
                value: selectedCopy[type],
              },
            };
            formChange(te);
            const el = document.getElementById("err_userEmailBcc");
            el.innerText = "";
            el.style.display = "none";
            setSelected(selectedCopy);
            document.getElementById("userEmailBcc").value = "";
          }
        } else {
          console.log("uncheck");
          const te = {
            target: {
              id: type + "Span_" + evId,
              email: type + "Span_" + evId,
            },
          };
          await removeSelectedLD(te, type);
        }
        break;
      case "selectTemplate":
        const tempInfo = emailTemplates.find((l) => l.id === parseInt(evId));
        setRemoteEmailSettings(tempInfo);
        document.getElementById("selectTemplate").value = tempInfo.name;
        document.getElementById("selectTemplate").focus();
        // selected[type] = tempInfo.id;
        emailTemplateView.current.style.display = "none";
        const selectedCopy = { ...selected };
        selectedCopy.emailSubject = tempInfo.subject;
        selectedCopy.emailBody = tempInfo.body;
        setSelected(selectedCopy);
        setShowEditor(false);
        setSearchSelector((prev) => ({
          ...prev,
          ["selectTemplate"]: tempInfo.name,
        }));
        break;
      case "templateSelect":
        tempEvent = {
          target: {
            name: "select_template",
            innerText: e.target.innerText,
            id: String(e.target.id).split("_")[0],
            value: parseInt(e.target.id.split("_")[1]),
          },
        };
        formChange(tempEvent);
        document.getElementById(tempEvent.target.id).value =
          tempEvent.target.innerText;
        setSearchSelector((prev) => ({
          ...prev,
          [tempEvent.target.id]: tempEvent.target.innerText,
        }));
        emailTemplateView.current.style.display = "none";
        break;
    }
  };

  const furnishNode = async (nodeData) => {
    return new Promise((resolve, reject) => {
      try {
        const selectedCopy = { ...selected };
        if (!selectedCopy[nodeData.for].includes(String(nodeData.id))) {
          const spanNode = document.createElement("span");
          const iNode = document.createElement("i");
          spanNode.className = "appendItem";
          spanNode.id = nodeData.for + "span_" + nodeData.id;
          iNode.className = "itemClose";
          iNode.id = nodeData.for + "i_" + nodeData.id;
          iNode.addEventListener("click", (e) => {
            const id = String(e.target.id).split("_")[1];
            const el = document.getElementById(nodeData.for + "span_" + id);
            const email = el.getAttribute("data-email");
            const index = selectedCopy[nodeData.for].indexOf(email);
            if (index > -1) {
              const selectedEmail = document.getElementById(
                `inputCheckusers_${nodeData.for}` + id
              );
              selectedCopy[nodeData.for].splice(index, 1);
              if (selectedEmail) {
                selectedEmail.checked = false;
              }
              el.remove();
            }
          });
          spanNode.innerText = nodeData.email;
          spanNode.title = nodeData.name;
          spanNode.setAttribute("data-email", nodeData.email);
          spanNode.append(iNode);
          setSelected(selectedCopy);
          resolve({ status: true, node: spanNode });
        } else {
          resolve({ status: false, node: null });
        }
      } catch (error) {
        console.log(error);
        reject({ status: false, error: error });
      }
    });
  };

  const removeSelectedLD = (e, from) => {
    return new Promise((resolve, reject) => {
      try {
        const selectedCopy = { ...selected };
        const id = String(e.target.id).split("_")[1];
        const el = document.getElementById(from + "span_" + id);
        const index = selectedCopy[from].indexOf(el.name);
        if (index > -1) {
          el.remove();
          selectedCopy[from].splice(index, 1);
        }
        setSelected(selectedCopy);
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });
  };

  const closeEmail = () => {
    props.functions.sendMail(false);
  };

  const removeAttachments = (name) => {
    const localeFileCopy = [...localeFile];
    const selectedCopy = { ...selected };
    const index = localeFileCopy.findIndex((l) => l.name === name);
    if (index > -1) {
      localeFileCopy.splice(index, 1);
      setLocalFile(localeFileCopy);
      selectedCopy.attachments.splice(index, 1);
      setSelected((prev) => ({ ...prev, selectedCopy }));
    }
  };

  useOutside(openMenu, () => {
    openMenu.current.classList.remove("active");
  });

  useOutside(templateDropdown, () => {
    emailTemplateView.current.style.display = "none";
  });

  const removeDefaultEmail = (id, email) => {
    const ele = document.getElementById(id);
    if (ele) {
      ele.remove();
      setSelected((prev) => {
        const emailTo = [...selected.emailTo];
        const index = emailTo.indexOf(email);
        if (index > -1) {
          emailTo.splice(index, 1);
        }
        return {
          ...prev,
          emailTo,
        };
      });
    }
  };

  return (
    <React.Fragment>
      {/* <div className="crm-composemail-sec"> */}
      <div className="crm-mail-wrap">
        <div className="popup-bottom">
          <div className="header">
            <div className="share-link">
              <h4>Send Mail</h4>
            </div>
            <div className="crm-minmax-icons">
              {/* <div className="minimize-icon-wrap" onClick={minimizeWindow}>
                    <span className="minimize-icon"></span>
                  </div>
                  <div className="accord-icon-wrap" onClick={maximizeWindow}>
                    <span className="accord-icon"></span>
                  </div> */}
              <div className="close-icon-wrap" onClick={() => closeEmail()}>
                <span className="close-icon"></span>
              </div>
            </div>
          </div>

          <div className="email-bdy show active">
            <div className="flexbox from-wrap">
              <div className="fromcol">
                <div className="char-a name-icon">
                  <div className="alphbt">
                    {companyDetails?.company_sender_email
                      ?.charAt(0)
                      ?.toUpperCase()}
                  </div>
                </div>
                <span className="value">
                  {companyDetails?.company_sender_email}
                </span>
              </div>
              <div className="email-type">
                {/* <div className="selected-emailtemp">
                      <span className="appendItem">
                      {(remoteEmailSettings?.name)}
                      <i className="eyeIcon"></i>
                      <i className="itemClose"></i>
                      </span>
                      </div> */}
                <div className="select-temp">
                  <div className="crm-form-row">
                    <div className="crm-form-grp">
                      <div className="crm-form-field" ref={templateDropdown}>
                        <input
                          type="text"
                          className="crm-form-ctrl floating-input"
                          id="selectTemplate"
                          autocomplete="off"
                          placeholder=" "
                          onMouseDown={(e) => {
                            handleDropdownSearch(e);
                          }}
                          onChange={(e) => handleDropdownSearch(e, "search")}
                          readOnly={searchSelector["selectTemplate"]}
                        />
                        <label
                          htmlFor="selectTemplate"
                          placeholder="Select Template"
                          className="floating-label"
                        >
                          Select Template
                        </label>
                        {!searchSelector["selectTemplate"] ? (
                          <i className="crm-search-icon"></i>
                        ) : (
                          <i
                            className="crm-action-close"
                            id={"icon_selectTemplate"}
                            onClick={(e) => resetSelectTemplate(e)}
                          ></i>
                        )}
                        {/* <i className="crm-search-icon"></i> */}
                        {/* <span className="crm-inp-error">
                              Please select a user to transfer
                            </span> */}
                        <div className="crm-auto-sgst" ref={emailTemplateView}>
                          <ul>
                            {emailTemplates?.length && templateFound ? (
                              emailTemplates?.map((et, i) => (
                                <li
                                  key={"templatesList_" + i}
                                  id={`selectTemplate_${et.id}`}
                                  style={{}}
                                  onClick={(e) =>
                                    selectDropdown(e, "selectTemplate")
                                  }
                                >
                                  <span
                                    id={`selectTemplate_${et.id}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      padding: "0.56rem 0.62rem",
                                    }}
                                  >
                                    {et.name}
                                  </span>
                                </li>
                              ))
                            ) : templateFound ? (
                              <i className="drpdwn-loader show"></i>
                            ) : (
                              <div
                                style={{
                                  height: 60,
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 8,
                                }}
                              >
                                <p>No template found</p>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flexbox to-wrap">
              <div className="tocol">
                <span className="tolabel">To</span>
                <div className="emailIdlist-row">
                  <div className="addEmail" spellCheck="false" tabIndex={0}>
                    <div
                      className="append_box"
                      id={"_emailBoxTo"}
                      style={{ display: "block" }}
                    >
                      {leadDetails?.email && (
                        <span class="appendItem" id="defaultEmail">
                          {leadDetails?.email}
                          <i
                            class="itemClose"
                            onClick={() =>
                              removeDefaultEmail(
                                "defaultEmail",
                                leadDetails.email
                              )
                            }
                          ></i>
                        </span>
                      )}
                    </div>
                    <div className="shareBox-input">
                      <input
                        type="text"
                        autocomplete="off"
                        name="email"
                        className="input-hidden floating-input"
                        onClick={(e) => handleDropdownSearch(e)}
                        placeholder=""
                        multiple={true}
                        id={"userEmailTo"}
                        onChange={(e) => handleDropdownSearch(e, "search")}
                      />
                      <label
                        alt="EmailTo"
                        htmlFor="userEmailTo"
                        className="floating-label"
                        placeholder="Add email"
                      ></label>
                    </div>
                    <div
                      className="crm-auto-sgst mul"
                      ref={userSearchViewTo}
                      onMouseLeave={() =>
                        (userSearchViewTo.current.style.display = "none")
                      }
                      key={String(userIds.join("-"))}
                    >
                      <ul>
                        {type === "emailTo" && usersFound ? (
                          users.map((user, i) => (
                            <React.Fragment>
                              <li
                                key={"list-user" + i}
                                id={"userLi_" + user.user_id}
                              >
                                <div className="checkbox-wrap">
                                  <input
                                    id={"inputCheckusers_" + user.user_id}
                                    type={"checkbox"}
                                    name={user.user_email}
                                    onChange={(e) =>
                                      selectDropdown(e, "emailTo")
                                    }
                                    defaultChecked={
                                      selected.emailTo.includes(user.user_email)
                                        ? true
                                        : false
                                    }
                                    className="crm-checkmark"
                                  />
                                  <label
                                    htmlFor={"inputCheckusers_" + user.user_id}
                                  >
                                    <div className="profile-icn">
                                      <span className="char-a">
                                        {String(user.user_firstname)
                                          .substring(0, 1)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                    <div className="user-details">
                                      <span className="usrname">
                                        {user.user_firstname +
                                          " " +
                                          user.user_lastname}
                                      </span>
                                      <span className="email-id">
                                        {user.user_email || "not available"}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </li>
                            </React.Fragment>
                          ))
                        ) : (
                          <div
                            style={{
                              height: 60,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 8,
                            }}
                          >
                            <div
                              className="loaderTheme-sm"
                              style={{
                                borderTop: "3px solid #0071bc",
                              }}
                            ></div>
                          </div>
                        )}
                      </ul>
                    </div>
                    <span className="crm-inp-error" id={"err_userEmailTo"}>
                      To email cannot be blank
                    </span>
                  </div>
                </div>
              </div>
              <div className="bccEle">
                <span className="bcc" onClick={(e) => handleViewBCC(e)}>
                  Bcc
                </span>
              </div>
            </div>
            {/* <!--cc row--> */}
            <div className="flexbox to-wrap">
              <div className="ccrow">
                <span className="tolabel">CC</span>
                <div className="emailIdlist-row">
                  <div className="addEmail">
                    <div
                      className="append_box"
                      id={"_emailBoxCc"}
                      style={{ display: "block" }}
                    ></div>
                    <div className="shareBox-input">
                      <input
                        type="text"
                        autocomplete="off"
                        name="email"
                        className="input-hidden floating-input"
                        onClick={(e) => handleDropdownSearch(e)}
                        placeholder=""
                        multiple={true}
                        id={"userEmailCc"}
                        onChange={(e) => handleDropdownSearch(e, "search")}
                      />
                    </div>
                    <div
                      className="crm-auto-sgst mul"
                      ref={userSearchViewCc}
                      onMouseLeave={() =>
                        (userSearchViewCc.current.style.display = "none")
                      }
                      key={String(userIds.join("-"))}
                    >
                      <ul>
                        {type === "emailCc" && usersFound ? (
                          users.map((user, i) => (
                            <React.Fragment>
                              <li key={"list-user" + i}>
                                <div className="checkbox-wrap char">
                                  <input
                                    id={"inputCheckusers_" + user.user_id}
                                    type={"checkbox"}
                                    onChange={(e) =>
                                      selectDropdown(e, "emailCc")
                                    }
                                    defaultChecked={
                                      selected.emailCc.includes(user.user_email)
                                        ? true
                                        : false
                                    }
                                    className="crm-checkmark"
                                  />
                                  <label
                                    htmlFor={"inputCheckusers_" + user.user_id}
                                  >
                                    <div className="profile-icn">
                                      <span className="char-a">
                                        {String(user.user_firstname)
                                          .substring(0, 1)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                    <div className="user-details">
                                      <span className="usrname">
                                        {user.user_firstname +
                                          " " +
                                          user.user_lastname}
                                      </span>
                                      <span className="email-id">
                                        {user.user_email || "not available"}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </li>
                            </React.Fragment>
                          ))
                        ) : (
                          <div
                            style={{
                              height: 60,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 8,
                            }}
                          >
                            <div
                              className="loaderTheme-sm"
                              style={{
                                borderTop: "3px solid #0071bc",
                              }}
                            ></div>
                          </div>
                        )}
                      </ul>
                    </div>
                    <span className="crm-inp-error" id={"err_userEmailCc"}>
                      Cc email cannot be blank
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--eof cc row--> */}
            <div className="flexbox to-wrap bcc-wrap" id={"bcc_opener"}>
              <div className="bccrow">
                <span className="tolabel">BCC</span>
                <div className="emailIdlist-row">
                  <div className="addEmail">
                    <div
                      className="append_box"
                      id={"_emailBoxBcc"}
                      style={{ display: "block" }}
                    ></div>
                    <div className="shareBox-input">
                      <input
                        type="text"
                        autocomplete="off"
                        name="email"
                        className="input-hidden floating-input"
                        onClick={(e) => handleDropdownSearch(e)}
                        placeholder=""
                        multiple={true}
                        id={"userEmailBcc"}
                        onChange={(e) => handleDropdownSearch(e, "search")}
                      />
                    </div>
                    <div
                      className="crm-auto-sgst mul"
                      ref={userSearchViewBcc}
                      onMouseLeave={() =>
                        (userSearchViewBcc.current.style.display = "none")
                      }
                      key={String(userIds.join("-"))}
                    >
                      <ul>
                        {type === "emailBcc" && usersFound ? (
                          users.map((user, i) => (
                            <React.Fragment>
                              <li key={"list-user" + i}>
                                <div className="checkbox-wrap char">
                                  <input
                                    id={"inputCheckusers_" + user.user_id}
                                    type={"checkbox"}
                                    onChange={(e) =>
                                      selectDropdown(e, "emailBcc")
                                    }
                                    defaultChecked={
                                      selected.emailBcc.includes(
                                        user.user_email
                                      )
                                        ? true
                                        : false
                                    }
                                    className="crm-checkmark"
                                  />
                                  <label
                                    htmlFor={"inputCheckusers_" + user.user_id}
                                  >
                                    <div className="profile-icn">
                                      <span className="char-a">
                                        {String(user.user_firstname)
                                          .substring(0, 1)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                    <div className="user-details">
                                      <span className="usrname">
                                        {user.user_firstname +
                                          " " +
                                          user.user_lastname}
                                      </span>
                                      <span className="email-id">
                                        {user.user_email || "not available"}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </li>
                            </React.Fragment>
                          ))
                        ) : (
                          <div
                            style={{
                              height: 60,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 8,
                            }}
                          >
                            <div
                              className="loaderTheme-sm"
                              style={{
                                borderTop: "3px solid #0071bc",
                              }}
                            ></div>
                          </div>
                        )}
                      </ul>
                    </div>
                    <span className="crm-inp-error" id={"err_userEmailBcc"}>
                      Bcc email cannot be blank
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--subject line--> */}
            {showEditor && (
              <div className="flexbox to-wrap">
                <div className="subRow">
                  <div className="addEmail subjectLine">
                    <SubjectInput
                      ref={emailSubjectRef}
                      id="email_subject"
                      functions={{
                        fc: formChangeHandler,
                        cp: calculatePosition,
                        hc: handleCursorMovement,
                      }}
                      defaultValue={remoteEmailSettings?.subject}
                    />
                    <label
                      alt="Subject"
                      htmlFor="email_subject"
                      placeholder="Subject"
                      className="floating-label"
                    >
                      {" "}
                      {/* Subject{" "} */}
                    </label>
                    <span className="crm-inp-error" id={"err_email_subject"}>
                      Template subject cannot be blank
                    </span>
                    <div className="slct-hdr">
                      <span className="plus"></span>
                      <span className="txt">Placeholder</span>
                      <ul className="options" id="">
                        {moduleField.length ? (
                          moduleField.map((mf, i) => (
                            <li key={"mp-" + i}>
                              <span
                                id={"sub-" + mf.field}
                                onClick={(e) => addPlaceholder(e)}
                              >
                                {mf.display_name}
                              </span>
                            </li>
                          ))
                        ) : (
                          <li>
                            <span>No propetires available</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flexbox to-wrap emailBdy">
              {showEditor ? (
                <div
                  className="crm-editorEle"
                  contentEditable="true"
                  spellCheck="false"
                >
                  <TextEditor
                    id={"emailBody"}
                    ref={emailBodyEditor}
                    functions={{
                      fc: formChangeHandler,
                      cp: calculatePosition,
                    }}
                    defaultValue={remoteEmailSettings?.body}
                  />
                  <span className="crm-inp-error" id={"err_emailBody"}>
                    Email body cannot be blank
                  </span>
                  <div className="slct-hdr">
                    <span className="plus"></span>
                    <span className="txt">Placeholder</span>
                    <ul className="options" id="">
                      {moduleField.length ? (
                        moduleField.map((mf, i) => (
                          <li key={"mpb" + i}>
                            <span
                              id={"body-" + mf.field}
                              onClick={(e) => addPlaceholder(e)}
                            >
                              {mf.display_name}
                            </span>
                          </li>
                        ))
                      ) : (
                        <li>
                          <span>No propetires available</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: remoteEmailSettings.body,
                  }}
                />
                // <div className="flexbox to-wrap emailBdy">
                //   <div className="crm-editorEle">
                //   </div>
                // </div>
              )}

              <div className="attachment-sec">
                {localeFile?.length > 0 &&
                  localeFile.map((d, i) => (
                    <div className="fil-blk">
                      <React.Fragment>
                        <div className="filnam-size">
                          <span className="filename">{d.name}</span>
                          <span className="filsize">{d.size}</span>
                        </div>
                        <div className="clsLoader-ele">
                          <span
                            className="itemClose"
                            onClick={() => removeAttachments(d.name)}
                          ></span>
                        </div>
                      </React.Fragment>
                    </div>
                  ))}
              </div>
            </div>

            {/* <!--eof subject body--> */}
          </div>

          {/* <!--start of footer--> */}
          <div className="comp-footer-row active">
            <div className="send-ele">
              <button
                id="btnSend"
                type="button"
                className="btn btn-primary"
                data-action="send"
                onClick={(e) => handleSendMail(e)}
                disabled={btnDisable}
              >
                Send
              </button>
            </div>

            <div className="attach-ele">
              <i className="attach-icn" onClick={handleSelectFile}></i>
            </div>
            <div className="schedule-ele">
              <i className="schedule-icn" onClick={handleScheduleMail}></i>
            </div>
          </div>
          {/* <!--eof start of footer--> */}
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default SendEmail;
