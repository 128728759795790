import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { APP_DATA } from "../../app_data";
import { isValidURL } from "../../utils";

const ActionWebhook = forwardRef((props, ref) => {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const [mode, setMode] = useState(urlSearch.get("mode"));
  const [remoteWFR, setRemoteWFR] = useState(null);
  const [authType, setAuthType] = useState("api_key");
  const [includeBody, setIncludeBody] = useState(false);
  const [requiresAuth, setRequiesAuth] = useState(false);
  const [requiresHeader, setRequiresHeader] = useState(false);
  const [remoteWebhookSettings, setRemoteWebhookSettings] = useState(null);

  const formData = useRef(
    structuredClone(
      APP_DATA.workflowRules.actions.find((wf) => wf.type == "webhook_trigger")
    )
  );

  useEffect(() => {
    try {
      if (mode === "edit") {
        const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
        setRemoteWFR(rWFR);
      }
    } catch (e) {
      console.log(e);
    }
  }, [location.search]);

  useEffect(() => {
    setupEditMode();
  }, [remoteWFR]);

  const checkExistanceOfRemoteWebhook = (rWFR) => {
    try {
      if (rWFR.actions[props.forIndex].type === "webhook_trigger") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
  const setupEditMode = () => {
    const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
    if (rWFR) {
      if (rWFR.hasOwnProperty("actions")) {
        if (rWFR.actions.length && checkExistanceOfRemoteWebhook(rWFR)) {
          setRemoteWebhookSettings(rWFR.actions[props.forIndex]);
          formData.current.properties.url =
            rWFR.actions[props.forIndex].properties["url"];
          if (
            Object.keys(rWFR.actions[props.forIndex].properties["content"])
              .length
          ) {
            setIncludeBody(true);
          }
          if (
            Object.keys(rWFR.actions[props.forIndex].properties["auth"]).length
          ) {
            setAuthType(
              rWFR.actions[props.forIndex].properties["auth"].auth_type
            );
            setRequiesAuth(true);
          }

          if (
            Object.keys(
              rWFR.actions[props.forIndex].properties["custom_headers"]
            ).length > 1
          ) {
            setRequiresHeader(true);
          }
        } else {
          setMode("create");
        }
      } else {
        setMode("create");
      }
    } else {
      setMode("create");
    }
  };

  const getProtocolFromURL = (url) => {
    const protocol = url.match(/^https?:\/\//)[0];
    return protocol;
  };
  const getURLfromURL = (url) => {
    return url.replace(/^https?:\/\//, "");
  };

  useImperativeHandle(ref, () => ({
    validate(index) {
      let finalValidation = 1;
      for (const f in formData.current.properties) {
        let elm = document.getElementById("err_" + f + "-" + index);
        if (f === "url") {
          if (!isValidURL(document.getElementById("url").value)) {
            finalValidation = 0;
            elm.style.display = "block";
            elm.innerText = "Enterd URL is not valid, please check";
          } else if (!formData.current.properties.url) {
            finalValidation = 0;
            elm.style.display = "block";
            elm.innerText = "Enterd URL is not valid, please check";
          } else {
            elm.innerText = "";
            elm.style.display = "none";
          }
        } else if (f === "auth") {
          if (requiresAuth) {
            for (const f in formData.current.properties.auth) {
              let elm = document.getElementById("err_" + f + "-" + index);
              if (
                formData.current.properties.auth[f] === "" ||
                formData.current.properties.auth[f] === undefined
              ) {
                finalValidation = 0;
                elm.style.display = "block";
                elm.innerText = APP_DATA.errorMessage.requiredField;
              }
            }
          }
        } else if (f === "custom_headers") {
          if (requiresHeader) {
            for (const f in formData.current.properties.custom_headers) {
              let elm = document.getElementById("err_" + f + "-" + index);
              if (
                formData.current.properties.custom_headers[f] === "" ||
                formData.current.properties.custom_headers[f] === undefined
              ) {
                finalValidation = 0;
                elm.style.display = "block";
                elm.innerText = APP_DATA.errorMessage.requiredField;
              }
            }
          }
        } else {
        }
      }

      if (props.scheduling) {
        if (props.scheduling.minutes < 5) {
          if (props.scheduling.days || props.scheduling.hours) {
            document.getElementById(
              "err_delay_" + props.forIndex
            ).style.display = "none";
          } else {
            finalValidation &= 0;
            document.getElementById(
              "err_delay_" + props.forIndex
            ).style.display = "block";
          }
        }
      }
      if (finalValidation) {
        formData.current.properties.scheduled_at = props.scheduling;
        if (requiresAuth) {
          formData.current.properties.auth["auth_type"] = authType;
        }
        if (window.sessionStorage.getItem("wf:actions")) {
          let prev = JSON.parse(window.sessionStorage.getItem("wf:actions"));
          prev.push(formData.current);
          window.sessionStorage.setItem("wf:actions", JSON.stringify(prev));
        } else {
          window.sessionStorage.setItem(
            "wf:actions",
            JSON.stringify([formData.current])
          );
        }
        return true;
      } else {
        return false;
      }
    },
    resetFormData() {
      formData.current = structuredClone(
        APP_DATA.workflowRules.actions.find(
          (wf) => wf.type == "webhook_trigger"
        )
      );
    },
  }));

  const webhookSetting = (e) => {
    if (e.target.id === "reqAuth") {
      if (e.target.checked) {
        setRequiesAuth(true);
        formData.current.properties.auth = { api_key: "", secret: "" };
      } else {
        setRequiesAuth(false);
        formData.current.properties.auth = {};
      }
    } else if (e.target.id === "reqHeader") {
      if (e.target.checked) {
        setRequiresHeader(true);
        formData.current.properties.custom_headers = {
          header_key: "",
          header_value: "",
        };
      } else {
        setRequiresHeader(false);
        formData.current.properties.custom_headers = {};
      }
    } else {
      return;
    }
  };

  const handleRequestSettings = (e) => {
    let elm = document.getElementById(
      "err_" + e.target.id + "-" + props.forIndex
    );

    if (e.target.id === "url") {
      formData.current.properties[e.target.id] =
        document.getElementById("protocol").value + e.target.value;
      if (!isValidURL(e.target.value)) {
        elm.innerText = "Enterd URL is not valid, please check";
        elm.style.display = "block";
      } else {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "protocol") {
      formData.current.properties["url"] =
        e.target.value + document.getElementById("url").value;
    } else if (e.target.id === "api_key") {
      formData.current.properties.auth = {
        [e.target.id]: e.target.value,
        secret: formData.current.properties.auth["secret"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "secret") {
      formData.current.properties.auth = {
        [e.target.id]: e.target.value,
        api_key: formData.current.properties.auth["api_key"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "user_name") {
      formData.current.properties.auth = {
        [e.target.id]: e.target.value,
        password: formData.current.properties.auth["password"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "password") {
      formData.current.properties.auth = {
        [e.target.id]: e.target.value,
        user_name: formData.current.properties.auth["user_name"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "header_key") {
      formData.current.properties.custom_headers = {
        [e.target.id]: e.target.value,
        header_value:
          formData.current.properties.custom_headers["header_value"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "header_value") {
      formData.current.properties.custom_headers = {
        [e.target.id]: e.target.value,
        header_key: formData.current.properties.custom_headers["header_key"],
      };
      if (e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
      }
    } else if (e.target.id === "request_type") {
      if (e.target.value != "GET") {
        setIncludeBody(true);
      } else {
        setIncludeBody(false);
      }
      formData.current.properties[e.target.id] = e.target.value;
    } else if (e.target.id === "req_body") {
      let elm = document.getElementById("err_req_body" + "-" + props.forIndex);
      if (!e.target.value) {
        elm.innerText = "";
        elm.style.display = "none";
        return;
      }
      try {
        var obj = JSON.parse(e.target.value);
        var pretty = JSON.stringify(obj, undefined, 4);
        document.getElementById(e.target.id).value = pretty;
        elm.innerText = "";
        elm.style.display = "none";
        formData.current.properties.content = JSON.parse(pretty);
      } catch (e) {
        formData.current.properties.content = {};
        elm.innerText = e.message;
        elm.style.display = "block";
      }
    } else {
      formData.current.properties[e.target.id] = e.target.value;
    }
  };

  const handleAuthTypeSelection = (e) => {
    if (e.target.value === "api_key") {
      setAuthType(e.target.value);
      formData.current.properties.auth = {};
    } else {
      setAuthType(e.target.value);
      formData.current.properties.auth = {};
    }
  };

  return (
    <React.Fragment>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="wflow-rptsctn">
            <div className="req-proto">
              <div className="crm-form-field mgn">
                <select
                  className="crm-form-ctrl floating-select"
                  id="request_type"
                  onClick={(e) => handleRequestSettings(e)}
                >
                  {remoteWebhookSettings ? (
                    <optgroup label="Remote Settings">
                      <option value={null} disabled selected>
                        {remoteWebhookSettings.properties["request_type"]}
                      </option>
                    </optgroup>
                  ) : null}
                  <optgroup label="Available methods">
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="PATCH">PATCH</option>
                    <option value="DELETE">DELETE</option>
                  </optgroup>
                </select>
                <label
                  alt="Request Type"
                  htmlFor=""
                  placeholder="Request Type"
                  className="floating-label"
                >
                  Request Type
                </label>
                <span
                  className="crm-inp-error"
                  id={"err_request_type-" + props.forIndex}
                ></span>
              </div>
              <div className="crm-form-field mgn">
                <select
                  className="crm-form-ctrl floating-select"
                  id="protocol"
                  defaultValue={"https://"}
                  onChange={(e) => handleRequestSettings(e)}
                >
                  {remoteWebhookSettings ? (
                    <optgroup label="Remote settings">
                      <option disabled selected>
                        {getProtocolFromURL(
                          remoteWebhookSettings.properties["url"]
                        )}
                      </option>
                    </optgroup>
                  ) : null}
                  <optgroup label="Available protocols">
                    <option value="http://">http://</option>
                    <option value="https://">https://</option>
                  </optgroup>
                </select>
                <label
                  htmlFor="protocol"
                  placeholder=""
                  className="floating-label"
                >
                  Protocol
                </label>
              </div>
            </div>

            <div className="crm-form-field req mgn">
              <input
                type="url"
                className="crm-form-ctrl floating-input"
                id="url"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleRequestSettings(e)}
                defaultValue={
                  remoteWebhookSettings
                    ? getURLfromURL(remoteWebhookSettings.properties["url"])
                    : ""
                }
              />
              <label htmlFor="url" placeholder="URL" className="floating-label">
                URL
              </label>
              <p>Eg- www.example.com/nice/url?q=query </p>
              <span
                className="crm-inp-error"
                id={"err_url-" + props.forIndex}
              ></span>
            </div>

            {includeBody ? (
              <div className="crm-form-row">
                <textarea
                  placeholder="Request body"
                  rows="10"
                  id="req_body"
                  onChange={(e) => handleRequestSettings(e)}
                  defaultValue={
                    remoteWebhookSettings
                      ? JSON.stringify(
                          remoteWebhookSettings.properties["content"],
                          undefined,
                          4
                        )
                      : ""
                  }
                />
                <span
                  className="crm-inp-error"
                  id={"err_req_body-" + props.forIndex}
                ></span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="wflow-rptsctn">
            <div className="crm-form-field">
              <label className="switch">
                <input
                  id="reqAuth"
                  type="checkbox"
                  checked={requiresAuth}
                  onChange={(e) => webhookSetting(e)}
                />
                <span className="slider round"></span>
              </label>
              <span className="switch-label">Requires Authentication</span>
            </div>
            {requiresAuth ? (
              <div className="info">
                <p className="auth-title">Authentication</p>
                <div className="crm-radio-container">
                  <label className="crm-radio-check rgt-mgn">
                    <span>I have API Key</span>
                    <input
                      type="radio"
                      className="crm-form-ctrl"
                      name={"auth_type" + props.forIndex}
                      value={"api_key"}
                      onClick={(e) => handleAuthTypeSelection(e)}
                      checked={authType === "api_key"}
                    />
                    <span className="crm-radio-checkmark"></span>
                  </label>
                  <label className="crm-radio-check rgt-mgn">
                    <span>I have username &amp; password</span>
                    <input
                      type="radio"
                      className="crm-form-ctrl"
                      name={"auth_type" + props.forIndex}
                      value={"basic"}
                      onClick={(e) => handleAuthTypeSelection(e)}
                      checked={authType === "basic"}
                    />
                    <span className="crm-radio-checkmark"></span>
                  </label>
                </div>
                {authType === "api_key" ? (
                  <React.Fragment>
                    <div className="crm-form-field req key-field">
                      <input
                        type="text"
                        className="crm-form-ctrl floating-input"
                        id="api_key"
                        autoComplete="off"
                        placeholder=" "
                        onChange={(e) => handleRequestSettings(e)}
                        defaultValue={
                          remoteWebhookSettings
                            ? remoteWebhookSettings.properties["auth"].api_key
                            : ""
                        }
                      />
                      <label
                        alt="Key"
                        htmlFor="api_key"
                        placeholder="Key"
                        className="floating-label"
                      >
                        Key
                      </label>
                      <span
                        className="crm-inp-error"
                        id={"err_api_key-" + props.forIndex}
                      ></span>
                    </div>

                    <div className="crm-form-field req key-field">
                      <input
                        type="text"
                        className="crm-form-ctrl floating-input"
                        id="secret"
                        autoComplete="off"
                        placeholder=" "
                        onChange={(e) => handleRequestSettings(e)}
                        defaultValue={
                          remoteWebhookSettings
                            ? remoteWebhookSettings.properties["auth"].secret
                            : ""
                        }
                      />
                      <label
                        alt="Key"
                        htmlFor="secret"
                        placeholder="Secret code"
                        className="floating-label"
                      >
                        Secret code
                      </label>
                      <span
                        className="crm-inp-error"
                        id={"err_secret-" + props.forIndex}
                      ></span>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="crm-form-field req key-field">
                      <input
                        type="text"
                        className="crm-form-ctrl floating-input"
                        id="user_name"
                        autoComplete="off"
                        placeholder=" "
                        onChange={(e) => handleRequestSettings(e)}
                        defaultValue={
                          remoteWebhookSettings
                            ? remoteWebhookSettings.properties["auth"].user_name
                            : ""
                        }
                      />
                      <label
                        htmlFor="user_name"
                        placeholder="User name or id"
                        className="floating-label"
                      >
                        User name or id
                      </label>
                      <span
                        className="crm-inp-error"
                        id={"err_user_name-" + props.forIndex}
                      ></span>
                    </div>
                    <div className="crm-form-field req key-field">
                      <input
                        type="text"
                        className="crm-form-ctrl floating-input"
                        id="password"
                        autoComplete="off"
                        placeholder=" "
                        onChange={(e) => handleRequestSettings(e)}
                        defaultValue={
                          remoteWebhookSettings
                            ? remoteWebhookSettings.properties["auth"].password
                            : ""
                        }
                      />
                      <label
                        alt="Key"
                        htmlFor="password"
                        placeholder="Key"
                        className="floating-label"
                      >
                        Password
                      </label>
                      <span
                        className="crm-inp-error"
                        id={"err_password-" + props.forIndex}
                      ></span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="wflow-rptsctn">
            <div className="crm-form-field">
              <label className="switch">
                <input
                  id="reqHeader"
                  type="checkbox"
                  checked={requiresHeader}
                  onChange={(e) => webhookSetting(e)}
                />
                <span className="slider round"></span>
              </label>
              <span className="switch-label">Add Custom Header</span>
            </div>
            {requiresHeader ? (
              <div className="info">
                <div className="crm-form-field desc-fld">
                  <React.Fragment>
                    <div className="req-header">
                      <div className="crm-form-field req key-field">
                        <input
                          type="text"
                          className="crm-form-ctrl floating-input"
                          id="header_key"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) => handleRequestSettings(e)}
                          defaultValue={
                            remoteWebhookSettings
                              ? remoteWebhookSettings.properties[
                                  "custom_headers"
                                ].header_key
                              : ""
                          }
                        />
                        <label
                          alt="Key"
                          htmlFor="header_key"
                          placeholder="Key"
                          className="floating-label"
                        >
                          Key
                        </label>
                        <span
                          className="crm-inp-error"
                          id={"err_header_key-" + props.forIndex}
                        ></span>
                      </div>

                      <div className="crm-form-field req key-field">
                        <input
                          type="text"
                          className="crm-form-ctrl floating-input"
                          id="header_value"
                          autoComplete="off"
                          placeholder=" "
                          onChange={(e) => handleRequestSettings(e)}
                          defaultValue={
                            remoteWebhookSettings
                              ? remoteWebhookSettings.properties[
                                  "custom_headers"
                                ].header_value
                              : ""
                          }
                        />
                        <label
                          alt="Key"
                          htmlFor="header_value"
                          placeholder="header_value code"
                          className="floating-label"
                        >
                          Header value
                        </label>
                        <span
                          className="crm-inp-error"
                          id={"err_header_value-" + props.forIndex}
                        ></span>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default ActionWebhook;
