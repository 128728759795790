import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import {
  utf8_to_b64,
  inputFieldValidator,
  checkPasswordStrength,
} from "../utils";
import jwt_docode from "jwt-decode";
import {
  updatePasswordAPI,
  validatePasswordResetTokenAPI,
} from "../apis/authAPIs";

export default function ResetPasswordView() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const userInfo = useRef(null);
  const passwordField = useRef();
  const formData = useRef(structuredClone({ password: "", passwordAgain: "" }));

  const [token, setToken] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [validating, setValidating] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(true);
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    const t = urlSearch.get("t");
    const decodedToken = jwt_docode(t);
    userInfo.current = decodedToken.data;
    //token Operation
    setToken(t);
    validateToken(t);
  }, []);

  const validateToken = async (t) => {
    const validated = await validatePasswordResetTokenAPI(t);
    if (validated.status && validated.data["status"]) {
      setTimeout(() => {
        setValidating(false);
        setTokenExpired(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setValidating(false);
        setTokenExpired(true);
      }, 2000);
    }
  };

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);
    if (validationResponse.valid) {
      formData.current[event.target.id] = event.target.value;
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
      if (event.target.id != "password") return;
      if (checkPasswordStrength(event.target.value)) {
        setPasswordValid(true);
        document.getElementById("psi").style.backgroundColor = "#276927";
      } else {
        setPasswordValid(false);
        document.getElementById("psi").style.display = "block";
        document.getElementById("psi").style.backgroundColor = "#c92c2c";
      }
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const handleUpdatePassword = async () => {
    let finalValidation = 1;
    if (
      !passwordValid &&
      formData.current["password"] &&
      formData.current["passwordAgain"]
    ) {
      setLoaded(false);
      document.getElementById("err_passwordAgain").innerText =
        "Please make sure the password includes a minimum of one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters in length.";
      document.getElementById("err_passwordAgain").style.display = "block";
      return;
    }
    for (const f in formData.current) {
      if (formData.current[f] === undefined || formData.current[f] === "") {
        document.getElementById("err_" + f).innerText =
          "Passwords can not be blank";
        document.getElementById("err_" + f).style.display = "block";
        finalValidation = 0;
      } else {
        document.getElementById("err_" + f).innerText = "";
        document.getElementById("err_" + f).style.display = "none";
      }
    }
    if (finalValidation) {
      setLoaded(true);
      if (formData.current["password"] === formData.current["passwordAgain"]) {
        const resetData = {
          user_id: userInfo.current.user_id,
          link_type: userInfo.current.link_type,
          user_password: formData.current["password"],
        };
        const updatePassword = await updatePasswordAPI(resetData);
        if (updatePassword.status) {
          window.localStorage.removeItem("a_d");
          //TODO we also remove the cache
          setPasswordReset(true);
          setTimeout(() => {
            navigate({
              pathname: "/",
              search: createSearchParams({
                referrer: "create",
                tNode: utf8_to_b64(String(Date.now())),
              }).toString(),
            });
          }, 3000);
        } else {
          //TODO need to check when password reset failes
          alert("Unable to reset password, Please try again");
          setLoaded(false);
        }
      } else {
        document.getElementById("err_passwordAgain").innerText =
          "Passwords didn't match, Please try again!";
        document.getElementById("err_passwordAgain").style.display = "block";
        setLoaded(false);
        return;
      }
    } else {
      return;
    }
  };

  const handleShowPassword = (state) => {
    if (state === "show") {
      passwordField.current.type = "text";
      document.getElementById("btnShowHidePwd").classList.add("crm-show-pwd");
      document
        .getElementById("btnShowHidePwd")
        .classList.remove("crm-hide-pwd");
    } else {
      passwordField.current.type = "password";
      passwordField.current.type = "password";
      document
        .getElementById("btnShowHidePwd")
        .classList.remove("crm-show-pwd");
      document.getElementById("btnShowHidePwd").classList.add("crm-hide-pwd");
    }
  };

  return (
    <React.Fragment>
      <div className="signup-hdr login">
        <div className="crm-container">
          <div className="hdr-lft">
            <a className="crm-logo"></a>
            <ul className="crm-hdr-menu">
              <li>
                <a>Features</a>
              </li>
              <li>
                <a>Pricing</a>
              </li>
              <li>
                <a>Download</a>
              </li>
              <li>
                <a>FAQs</a>
              </li>
              <li>
                <a>Support</a>
              </li>
            </ul>
          </div>
          <div className="hdr-rgt">
            <Link to={"/"} className="btn btn-secondary">
              Sign in
            </Link>
          </div>
        </div>
      </div>

      <section className="signup-section login">
        <div className="signup-main">
          <div className="crm-outer-form">
            {!validating ? (
              !tokenExpired ? (
                !passwordReset ? (
                  <form noValidate="" className="">
                    <div className="crm-outer-head">
                      <h1>Reset your password</h1>
                    </div>
                    <fieldset className="resetpwd">
                      <div className="crm-form-row">
                        {!validating ? (
                          <p className="resetpwd-info">
                            Enter a new password for your{" "}
                            <strong>{userInfo.current["user_email"]}</strong>{" "}
                            account.
                          </p>
                        ) : null}
                        <div className="crm-form-grp crm-frm-100">
                          <div className="crm-form-field">
                            <p
                              id="psi"
                              style={{ display: "none" }}
                              className="pwd-strength good"
                            >
                              {passwordValid ? "Strong" : "Weak"} password
                            </p>
                            <input
                              type="password"
                              className="crm-form-ctrl floating-input"
                              id="password"
                              placeholder=" "
                              onChange={(e) => formChangeHandler(e)}
                              ref={passwordField}
                            />
                            <label
                              htmlFor="password"
                              className="floating-label"
                              placeholder="New Password"
                            >
                              New Password
                            </label>
                            <span
                              className="crm-inp-error"
                              id="err_password"
                            ></span>
                            <div
                              id="btnShowHidePwd"
                              className="crm-hide-pwd pwd"
                              onClick={() => handleShowPassword("hide")}
                              onMouseDown={() => handleShowPassword("show")}
                            ></div>
                          </div>
                        </div>
                        <div className="crm-form-grp crm-frm-100 crm-form-password">
                          <div className="crm-form-field">
                            <input
                              type="password"
                              id="passwordAgain"
                              maxlength="60"
                              className="crm-form-ctrl floating-input"
                              placeholder=" "
                              onChange={(e) => formChangeHandler(e)}
                            />
                            <label
                              htmlFor="passwordAgain"
                              className="floating-label"
                              placeholder="Confirm Password"
                            >
                              Confirm Password
                            </label>
                            <span
                              className="crm-inp-error"
                              id="err_passwordAgain"
                            >
                              Confirm Password cannot be blank
                            </span>
                          </div>
                        </div>
                        <div className="crm-form-grp">
                          {!loaded ? (
                            <button
                              type="button"
                              className="btn btn-primary create-acnt"
                              onClick={() => handleUpdatePassword()}
                            >
                              Update Password
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary create-acnt"
                            >
                              Please wait...
                            </button>
                          )}
                        </div>
                      </div>
                    </fieldset>
                  </form>
                ) : (
                  <form noValidate="" className="">
                    <div className="crm-outer-head">
                      <h1>Reset password was successfull</h1>
                    </div>
                    <fieldset className="forgotpwd">
                      <div className="crm-form-row">
                        <div className="successmsg">
                          <p>
                            Please wait while we redirect you to login page...{" "}
                          </p>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                )
              ) : (
                <form noValidate="" className="">
                  <div className="crm-outer-head">
                    <h1>Link expired!</h1>
                  </div>
                  <fieldset className="forgotpwd">
                    <div className="crm-form-row">
                      <div className="successmsg">
                        <p>
                          This password reset link is expired! Click{" "}
                          <Link to={"/forgot-password"}>here</Link> get a new
                          link.
                        </p>
                      </div>
                    </div>
                  </fieldset>
                </form>
              )
            ) : (
              <form noValidate="" className="">
                <div className="crm-outer-head">
                  <h1>Validating credentials</h1>
                </div>
                <fieldset className="forgotpwd">
                  <div className="crm-form-row">
                    <div className="successmsg">
                      <p>Please wait... </p>
                    </div>
                  </div>
                </fieldset>
              </form>
            )}
          </div>
        </div>
      </section>

      <div className="signup-footer">
        <p>©IDrive Inc.</p>
      </div>
    </React.Fragment>
  );
}
