import React, { useState, useEffect, useRef } from "react";
import { RightMenuLoader } from "../common/contentLoader";
import NoResults from "../common/noResults";
import {
  columnNameFormatter,
  customFilterFieldValidator,
  echo,
  generateFilterPayload,
  groupByProperty,
} from "../../utils";
import { APP_DATA } from "../../app_data";
import { getWFOperatorsAPI } from "../../apis/workflowAPIs";
import { getEnumsByModule } from "../../apis/enumAPI";
import useKeyEvent from "../../customHooks/useKeyEvent";
import DatePicker from "react-multi-date-picker";
import { Tooltip } from "react-tooltip";
import CustomFilterInputMapper from "./customFilterInputMapper";
import { inputValueConverter } from '../../utils/index';

export default function CustomFilter({
  preservedFilter,
  functions,
  columnInfo,
  module,
}) {
  const custom = useRef(null);

  const [showInput, setShowInput] = useState({});
  const [customFilter, setCustomFilter] = useState([]);
  const [operators, setOperators] = useState([]);
  const [loader, setLoader] = useState(true);
  const [crValue, setCrValue] = useState({});
  const [crField, setCrField] = useState([]);
  const [ddOption, setddOption] = useState({});

  useEffect(() => {
    const cr = [];
    const crValue = {};
    if (preservedFilter?.length > 0) {
      preservedFilter.forEach((it) => {
        if (document.getElementById(it.id)) {
          document.getElementById(it.id).checked = true;
          document.getElementById(it.id + "_condition").value = it.condition;
          setShowInput((prev) => ({ ...prev, [it.id + "_condition"]: true }));
          if (it.condition === "empty" || it.condition === "nonEmpty") {
            setShowInput((prev) => ({ ...prev, [it.id + "_value"]: false }));
          } else {
            if (it.condition === "customRange") {
              cr.push(it.id);
              crValue[it.id] = [
                new Date(it.value.start_value),
                new Date(it.value.end_value),
              ];
            }
            document.getElementById(it.id + "_value").value = it.value;
            setShowInput((prev) => ({ ...prev, [it.id + "_value"]: true }));
          }
        }
      });
    } else {
      custom.current.reset();
      setShowInput({});
    }
    setCrField(cr);
    setCrValue((prev) => ({ ...prev, ...crValue }));
    setCustomFilter(preservedFilter);
  }, [preservedFilter, operators, ddOption]);

  useEffect(() => {
    getWFOperatorsAPI()
      .then((res) => {
        setOperators(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setOperators([]);
      });
    
    getEnumsByModule(module)
      .then((res) => {
        if (res.status && res.data.length > 0) {
          const data = groupByProperty(res.data);
          setddOption(data);
        }
      })
      .catch((err) => {
        setddOption({});
      });
  }, []);

  const handleChange = async (e, datatype) => {
    const conditionElement = e.target.id + "_condition";
    const valueElement = e.target.id + "_value";
    if (e.target.type === "checkbox") {
      const data = [...customFilter];
      if (e.target.checked && !data.find((el) => el.field === e.target.id)) {
        data.push({
          id: e.target.id,
          field: e.target.id,
          datatype,
          condition: "",
          value: "",
          fieldLabel:
            document.getElementById(e.target.id + "label")?.textContent || "",
        });
        setShowInput((prev) => ({
          ...prev,
          [conditionElement]: true,
          [valueElement]: true,
        }));
      } else if (
        !e.target.checked &&
        data.find((el) => el.field === e.target.id)
      ) {
        const index = data.findIndex((l) => l.field === e.target.id);
        data.splice(index, 1);
        document.getElementById(conditionElement).value = "";
        document.getElementById(valueElement).value = "";
        setShowInput((prev) => ({
          ...prev,
          [conditionElement]: false,
          [valueElement]: false,
        }));
      }
      setCustomFilter(data);
    }
    const event = {
      target: {
        id: e.target.id,
        value: e.target.value,
        datatype
      },
    }
    const validationResponse = customFilterFieldValidator(event);
    if (!validationResponse.valid) {
      document.getElementById("err_" + e.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + e.target.id).style.display = "block";
    } else {
      document.getElementById("err_" + e.target.id).innerText = "";
      document.getElementById("err_" + e.target.id).style.display = "none";
    }
  };

  const handleConditionChange = async (e, field,datatype) => {
    const data = [...customFilter];
    const index = data.findIndex((l) => l.field === field);

    if (e.target.value === "empty" || e.target.value === "nonEmpty") {
      setShowInput((prev) => ({ ...prev, [field + "_value"]: false }));
      data[index]["value"] = "";
    } else {
      setShowInput((prev) => ({ ...prev, [field + "_value"]: true }));
    }
    data[index]["condition"] = e.target.value;
    setCustomFilter(data);

    const fields = [...crField];
    if (e.target.value === "customRange") {
      if (!fields.includes(field)) {
        fields.push(field);
      }
    } else {
      const index = fields.indexOf(field);
      if (index >= 0) {
        fields.splice(index, 1);
      }
    }
    setCrField(fields);
    // const event = {
    //   target: {
    //     id: e.target.id,
    //     value: e.target.value,
    //     datatype
    //   },
    // }
    // const validationResponse = customFilterFieldValidator(event);
    // if (!validationResponse.valid) {
    //   document.getElementById("err_" + e.target.id).innerText =
    //     validationResponse["error_message"];
    //   document.getElementById("err_" + e.target.id).style.display = "block";
    // } else {
    //   document.getElementById("err_" + e.target.id).innerText = "";
    //   document.getElementById("err_" + e.target.id).style.display = "none";
    // }
  };

  const handleValueChange = async (e, field,datatype) => {
    const data = [...customFilter];
    const index = data.findIndex((l) => l.field === field);

    data[index]["value"] = inputValueConverter(e.target.value,datatype);

    setCustomFilter(data);
    const event = {
      target: {
        id: e.target.id,
        value: inputValueConverter(e.target.value,datatype),
        datatype
      },
    }

    const validationResponse = customFilterFieldValidator(event);
    if (!validationResponse.valid) {
      document.getElementById("err_" + e.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + e.target.id).style.display = "block";
    } else {
      document.getElementById("err_" + e.target.id).innerText = "";
      document.getElementById("err_" + e.target.id).style.display = "none";
    }
  };

  const handleCustomRangeValueChange = async (v, field) => {
    const data = [...customFilter];
    const index = data.findIndex((l) => l.field === field);

    data[index]["value"] = {
      start_value: v[0]?.format(),
      end_value: v[1]?.format(),
    };

    setCustomFilter(data);
  };

  const handleSelectValueChange = async (e, field,datatype) => {
    const data = [...customFilter];
    const index = data.findIndex((l) => l.field === field);

    data[index]["value"] = [e.target.value];

    setCustomFilter(data);
    const event = {
      target: {
        id: e.target.id,
        value: e.target.value,
        datatype
      },
    }

    const validationResponse = customFilterFieldValidator(event);
    if (!validationResponse.valid) {
      document.getElementById("err_" + e.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + e.target.id).style.display = "block";
    } else {
      document.getElementById("err_" + e.target.id).innerText = "";
      document.getElementById("err_" + e.target.id).style.display = "none";
    }
  };

  const applyFilter = () => {
    let valid = 0;
    let filterData = {};

    customFilter.forEach((item) => {
      const e = {
        target: {
          id: item.id + "_value",
          value: item.value,
          datatype:item.datatype
        },
      };
      const validationResponse = customFilterFieldValidator(e);
      if (
        item.condition &&
        item.value &&
        validationResponse.valid &&
        item.condition !== "customRange"
      ) {
        valid += 1;
      }
      if (
        ((!item.value && item.condition === "empty") ||
          (!item.value && item.condition === "nonEmpty")) &&
        item.condition !== "customRange"
      ) {
        valid += 1;
      }
      if (!item.condition) {
        if (document.getElementById("err_" + item.id + "_condition")) {
          document.getElementById("err_" + item.id + "_condition").innerText =
            "Please select valid input";
          document.getElementById(
            "err_" + item.id + "_condition"
          ).style.display = "block";
        }
      }
      if (item.condition) {
        document.getElementById("err_" + item.id + "_condition").innerText = "";
        document.getElementById("err_" + item.id + "_condition").style.display =
          "none";
      }
      if (
        (item.condition === "customRange" && !item.value.start_value) ||
        (item.condition === "customRange" && !item.value.end_value)
      ) {
        document.getElementById("err_" + item.id + "_value").innerText =
          "Please select valid input";
        document.getElementById("err_" + item.id + "_value").style.display =
          "block";
      }
      if (
        (item.condition === "customRange" && item.value.start_value) &&
        (item.condition === "customRange" && item.value.end_value)
      ) {
        document.getElementById("err_" + item.id + "_value").innerText = "";
        document.getElementById("err_" + item.id + "_value").style.display =
          "none";
        valid += 1;
      }
      if (
        (!item.value && item.condition !== "empty") ||
        (!item.value && item.condition !== "nonEmpty")
      ) {
        if (document.getElementById("err_" + item.id + "_value")) {
          document.getElementById("err_" + item.id + "_value").innerText =
            "Please select valid input";
        }
        document.getElementById("err_" + item.id + "_value").style.display =
          "block";
      }
      if (
        validationResponse.valid &&
        item.value &&
        item.condition !== "customRange"
      ) {
        if (document.getElementById("err_" + item.id + "_value")) {
          document.getElementById("err_" + item.id + "_value").innerText = "";
          document.getElementById("err_" + item.id + "_value").style.display =
            "none";
        }
      }
    });

    if (valid === customFilter?.length) {
      filterData = {
        preservedFilter: customFilter,
        appliedFilters: generateFilterPayload(customFilter),
      };

      if (filterData?.appliedFilters && filterData?.preservedFilter) {
        functions.applyCustomFilter(filterData);
      }
      if (filterData?.preservedFilter) {
        if (module) {
          sessionStorage.setItem(
            module + "Filter",
            JSON.stringify(filterData.preservedFilter)
          );
        }
      }
    }
  };

  const clearAll = () => {
    custom.current.reset();
    if (customFilter?.length > 0) {
      customFilter.forEach((el) => {
        if (document.getElementById(el.id)) {
          document.getElementById(el.id).checked = false;
        }
      });
    }
    setShowInput({});
    setCustomFilter([]);
    functions.clearAll(true);
    setCrField([]);
    setCrValue({});
  };

  useKeyEvent(functions.closeFilterSideBar, 27);

  const handlers = {
    handleValueChange,
    handleCustomRangeValueChange,
    handleSelectValueChange
  };

  return (
    <React.Fragment>
      <div className="fltr-head">
        <h2>
          Filter by fields{" "}
          <a
            className={
              customFilter?.length ? "clr-link" : "clr-link disabled"
            }
            onClick={
              customFilter?.length
                ? () => {
                    clearAll();
                  }
                : null
            }
          >
            Clear all
          </a>
        </h2>
        <span
          id="t-close"
          className="crm-close cm-tooltip"
          data-original-title="Close"
          onClick={() => functions.closeFilterSideBar()}
        ></span>
        <Tooltip place={"left"} anchorId={"t-close"} content="Close" />
      </div>
      <div className="fltr-body">
        <div className="crm-rhs-right">
          <div className="fltr-content">
            <div className="crm-rhs-bsbody">
              <form className="rhs-form" ref={custom}>
                <div className="crm-form-row">
                  {loader ? (
                    <RightMenuLoader />
                  ) : (
                    <ul className="filter-list crm-radio-container">
                      {operators &&
                      Object.keys(operators)?.length > 0 &&
                      columnInfo?.length > 0 ? (
                        columnInfo.map(
                          (l, i) =>
                            l.custom_filter && (
                              <li key={i}>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="crm-checkmark"
                                    id={l.field}
                                    name={l.field}
                                    onChange={(e)=>handleChange(e,l.datatype)}
                                  />
                                  <label htmlFor={l.field}>
                                    <span id={l.field + "label"}>
                                      {echo(l.display_name)}
                                      {/* {APP_DATA.APIColumnMappings[module][
                                        l.field
                                      ]
                                        ? APP_DATA.APIColumnMappings[module][
                                            l.field
                                          ]
                                        : columnNameFormatter(l.field)} */}
                                    </span>
                                  </label>
                                  <span
                                    className="crm-inp-error"
                                    id={"err_" + l.field}
                                  ></span>
                                </div>
                                <div
                                  className="crm-form-row"
                                  style={{
                                    display: showInput[`${l.field}_condition`]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div className="crm-form-grp">
                                    <div className="crm-form-grp">
                                      <div className="crm-form-field">
                                        <select
                                          className="crm-form-ctrl floating-select"
                                          id={`${l.field}_condition`}
                                          onChange={(e) =>
                                            handleConditionChange(e, l.field,l.datatype)
                                          }
                                        >
                                          <option value="">
                                            Please select an option
                                          </option>
                                          {operators[l.datatype]?.map((o) => (
                                            <option value={o.operator}>
                                              {o.display_name}
                                            </option>
                                          ))}
                                        </select>

                                        <label
                                          alt="Starts with"
                                          htmlFor=""
                                          placeholder="Starts with"
                                          className="floating-label"
                                        >
                                          Starts with
                                        </label>
                                        <span
                                          className="crm-inp-error"
                                          id={`err_${l.field}_condition`}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="crm-form-row"
                                  style={{
                                    display: showInput[`${l.field}_value`]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div className="crm-form-grp">
                                    <div className="crm-form-grp">
                                      <div className="crm-form-field">
                                        <CustomFilterInputMapper
                                          datatype={l.datatype}
                                          field={l.field}
                                          handlers={handlers}
                                          crField={crField}
                                          crValue={crValue}
                                          ddOption={ddOption}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                        )
                      ) : (
                        <NoResults message="No filters found." />
                      )}
                    </ul>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="fltr-foot">
            <div className="crm-btn">
              <div className="crm-btn-left">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!customFilter?.length}
                  onClick={applyFilter}
                >
                  Apply Filter
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => functions.closeFilterSideBar()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
