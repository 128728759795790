import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import EmailEditor from "raven-react-email-editor";

const myStyle = {
  div: {
    height: "100vh",
    margin: "30px 15px 20px",
    borderRadius: "5px",
  },
  nav: {
    height: "8%",
    borderBottom: "1px solid #a39f9f",
  },
  button: {
    float: "right",
    margin: "10px 20px 10px 10px",
    padding: "10px 40px",
    color: "white",
    border: "1px solid rgba(88, 80, 236, 0.5)",
    fontSize: "0.875rem",
    backgroundColor: "#2472BC",
    borderRadius: "4px",
    cursor: "pointer",
  },
  editor: {
    height: "90%",
  },
};

const CustomEmailEditor = forwardRef(({ data, functions }, ref) => {
  const [savedState, setSavedState] = useState({
    state: data?.htmlJson?.data || "",
    html: data?.html || "",
  });
  const formData = useRef({});

  const [isLoaded, setIsLoaded] = useState(false);
  const editorRef = useRef(null);

  const onLoad = () => {
    setIsLoaded(true);
  };

  useImperativeHandle(
    ref,
    () => ({
      onTriggerSave() {
        if (isLoaded) {
          editorRef.current.fetchState();
          functions.handleSaveLoader();
          setIsLoaded(false);
          setTimeout(() => {
            const data = {
              htmlJson: { data: formData.current.state },
              html: formData.current.html,
            };
            functions?.updateEditorBody(data);
          }, 2000);
        }
      },
    }),
    [isLoaded]
  );

  useEffect(() => {
    setSavedState((prev) => ({
      ...prev,
      html: data?.html || "",
      state: data?.htmlJson?.data || "",
    }));
  }, [isLoaded, data]);

  const onFetched = useCallback((state, html) => {
    setSavedState((prevState) => ({
      ...prevState,
      state: state,
      html: html,
    }));
    formData.current["state"] = state;
    formData.current["html"] = html;
    setIsLoaded(true);
  }, []);

  return (
    <div style={myStyle.div}>
      <div style={myStyle.editor}>
        <EmailEditor
          state={savedState.state}
          onEditorLoad={onLoad}
          onFetched={onFetched}
          ref={editorRef}
        />
      </div>
    </div>
  );
});

export default CustomEmailEditor;
