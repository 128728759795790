import React, { useState, useEffect, useRef } from "react";
import { useLocation, useOutletContext } from "react-router";
import CustomFilter from "../components/filters/customFilter";
import { getGroupById, getGroupsLimitOffset } from "../apis/groupAPI";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import {
  echo,
  forEach,
  search,
  debounceOptFunc,
  generateFilterPayload,
  getUserAccessInfo,
  getSortBy,
  overrideDefaultEvent,
  getObjValue,
  getDate,
} from "../utils";
import { searchThroughAPIs } from "../apis";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import { APP_DATA } from "../app_data";
import { useOutside } from "../customHooks/useOutside";
import Range from "../components/datepicker/range";
import { Tooltip } from "react-tooltip";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import createResizer from "../utils/resizerHandler";

export default function GroupsView() {
  const location = useLocation();
  const {
    handleRSidebar,
    handleRSidebarManageColumns,
    openModal,
    handleAllMenuSideBar,
    openHideOverLay,
    closeAllMenuSideBar,
  } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);

  const deleteIdsList = useRef([]);
  const selectAllCheck = useRef(null);
  const selectFilter = useRef(null);
  const customFilter = useRef(null);
  const datePicker = useRef(null);
  const searchRef = useRef(null);
  const pagination = useRef(null);

  const [groups, setGroups] = useState([]);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All Groups");
  const [selectedCheck, setSelectedCheck] = useState([]);

  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    preservedFilter: [],
    appliedFilters: [],
  });
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [groupsPermission, setGroupsPermission] = useState({});
  const [columnInfo, setColumnInfo] = useState([]);
  const [pageLimit, setPageLimit] = useState(0);
  const [filter, setFilter] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [loading, setLoading] = useState(false);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    const groupsFilter = JSON.parse(sessionStorage.getItem("groupsFilter"));
    if (
      groupsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(groupsFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: groupsFilter,
      }));
      getGroupsForCustomFilter(appliedFilters);
    } else {
      //clearing filters after create, edit ,clone or delete
      //as sometimes no records will create confusion to users
      //   clearFixedFilter();
      //   clearDateFilter();
      //   clearAll();
      //   searchRef.current.value = "";
      //   clearSort();
      // getGroups();
      handlePaginatedFetchData({ limit: 0, offset: 0 });
    }
    deleteIdsList.current.map((id) => {
      document.getElementById(id).checked = false;
    });
    deleteIdsList.current = [];
    setSelectedCheck([]);
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const groups = getUserAccessInfo("Groups");

    if (groups?.permission) {
      setGroupsPermission(groups.permission);
    } else {
      setGroupsPermission({});
    }
    getModuleDataTypesAPI("groups")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName); 
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  useEffect(()=>{
    createResizer();
  },[loading,groups,location.search])

  const getGroups = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);

    const groups = await getGroupsLimitOffset(
      0,
      pageLimit,
      true,
      selectedFilter,
      selectedFilter === "My Groups" ? getUserAccessInfo()?.user_id : "",
      sortBy,
      sortField
    );
    if (groups?.status) {
      if (groups.data?.hasOwnProperty("groups")) {
        setGroups(groups.data.groups);
      } else {
        setGroups([]);
        setTotalRecords(0);
      }

      if (groups.data?.hasOwnProperty("total_count")) {
        setTotalRecords(groups.data.total_count);
      }
    }
    setLoading(false);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (!selectedCheck.includes(parseInt(e.target.id))) {
        setSelectedCheck((prev) => [...prev, parseInt(e.target.id)]);
      }
      if (deleteIdsList.current?.length === groups?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      setSelectedCheck((prev) => [
        ...prev.filter((s) => s !== parseInt(e.target.id)),
      ]);
      if (deleteIdsList.current?.length !== groups?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let e = {
          target: {
            checked: true,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
      } else {
        let e = {
          target: {
            checked: false,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const groups = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters
      );
      if (groups.status) {
        setGroups(groups.data);
      } else {
        setGroups([]);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getGroupsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit
        );
      } else {
        getGroupsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const groups = await getGroups(
        selectedFilter,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (groups.status) {
        setGroups(groups.data);
      } else {
        setGroups([]);
      }
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearFixedFilter();
    clearDateFilter();
    clearAll();
    unCheckRecords();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs("groups", qstring, 0, pageLimit);
      if (searched.status) {
        setTotalRecords(searched.data.total_count);
        setGroups(searched.data.groups);
      } else {
        setGroups([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      setFilter(false);
      getGroups();
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      getGroups(value);
      clearAll();
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      clearDateFilter();
      searchRef.current.value = "";
      unCheckRecords();
      setFilter(true);
    }
  };

  const getGroupsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const groups = await getDataForCustomFilters(
        modules,
        true,
        0,
        pageLimit,
        filter,
        sortBy,
        sortField
      );
      if (groups.status) {
        if (groups?.data?.groups?.length > 0) {
          setGroups(groups.data.groups);
        } else {
          setGroups([]);
        }
        if (groups.data?.hasOwnProperty("total_count")) {
          setTotalRecords(groups.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };

  const getGroupsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const groups = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (groups.status) {
        if (groups?.data?.groups?.length > 0) {
          setGroups(groups.data.groups);
        } else {
          setGroups([]);
        }
        if (groups.data?.hasOwnProperty("total_count")) {
          setTotalRecords(groups.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setGroups([]);
      console.log("err", err);
    }
  };

  const clearAll = (groups) => {
    if (customFilterDetails?.filter) {
      setFilter(false);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      if (groups) {
        getGroups();
      }
      unCheckRecords();
    }

    if (sessionStorage.getItem("groupsFilter")) {
      sessionStorage.removeItem("groupsFilter");
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    if (selectedFilter) {
      clearFixedFilter();
    }

    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    openFilterSidebar(false);
    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    getGroupsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageLimit,
      sortInfo.sortField,
      sortInfo.sortBy
    );
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const handleDatePicker = (value) => {
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getGroupsForDateFilter(
        value,
        true,
        0,
        pageLimit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
    clearAll();
    clearFixedFilter();
    searchRef.current.value = "";
    unCheckRecords();
  };

  const handleCallback = (start, end) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getGroupsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageLimit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Groups");
      document.getElementById("select-value").innerText = "All groups";
      setFilter(false);
    }
  };

  const clearDateFilter = (groups) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (groups) {
      getGroups();
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
      setSelectedCheck([]);
    }
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "groups_check") {
      return;
    }

    if (e.target.id === "groups") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getGroupsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageLimit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getGroupsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getGroupsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getGroups(selectedFilter, sortBy, fieldKey.disply_name);
      }
      unCheckRecords();
    }
  };

  const handleVECMenu = (recordId, actionType) => {
    if (actionType === "vec") {
      handleAllMenuSideBar("vecGroup", {
        id: recordId,
        type: "vec",
      });
    } else {
      handleAllMenuSideBar("vecGroup", {
        id: recordId,
        type: "edit",
      });
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Groups</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}

          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  groupsPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                onClick={groupsPermission?.view ? handleFilterSelect : null}
                ref={selectFilter}
              >
                <div className="crm-custom-select-value">
                  <span title="All Groups" id="select-value">
                    All groups
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.groups.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-name={item.name}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search groups"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!groupsPermission?.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                groupsPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                groupsPermission?.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                groupsPermission?.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                groupsPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                id="t-daterange"
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
              ></span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filters"
              />
            </div>
            {
              <div
                id="date-picker"
                className="daterangepicker ltr show-ranges opensright range"
                style={{
                  display: showDatePicker ? "block" : "none",
                  top: "98.1406px",
                  left: "auto",
                  right: "0px",
                }}
                ref={datePicker}
              >
                <Range
                  functions={{ handleDatePicker, handleCallback }}
                  datePickerFilter={datePickerFilter}
                  showCalendar={showDatePicker}
                />
              </div>
            }
          </div>
        </div>
      </div>
      {/* top menu */}
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              groupsPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt grp-lhs"
              onClick={
                groupsPermission?.create
                  ? () => handleRSidebar("createGroup")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Group</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                groupsPermission?.delete && btnDelete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                groupsPermission?.delete && btnDelete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "groups",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? groups?.find((g) =>
                                deleteIdsList.current.includes(g.group_id)
                              )
                            : null,
                      })
                  : () => {}
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>
        {Object.keys(displayName).length>0 && groupsPermission?.view && groups?.length>0 &&
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total groups: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
          {/* this is not required for now if needed just uncomment */}
          {/* {groupsPermission?.view && groups?.length > 0 ? (
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
          ) : null} */}
        </div>}
      </div>
      {/* table section */}
      <div
        className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
          !totalRecords ? "noscrl" : ""
        } mtbl`}
      >
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-fixed-field">
              {groups?.length > 0 && Object.keys(displayName).length>0 && !loading && groupsPermission?.view && (
                <div className="crm-td crm-td-grpname">
                  <div className="check-box">
                    <input
                      type="checkbox"
                      className="crm-checkmark"
                      ref={selectAllCheck}
                      id="groups_check"
                      onChange={(e) => handleSelectAll(e)}
                      disabled={groups.length ? false : true}
                    />
                    <label htmlFor="groups_check"></label>
                  </div>
                  <div className="name">
                    <span
                      className={
                        sortInfo.sortKey === "group_name"
                          ? "sort-highlight"
                          : ""
                      }
                      id="groups"
                      onClick={(e) => {
                        handleSort(e, "group_name", "groupName");
                      }}
                    >
                      {/* Group name */}
                      {displayName["group_name"]}
                    </span>
                  </div>
                  <i
                    className={
                      sortInfo.sortKey === "group_name"
                        ? sortInfo.sortBy === "desc"
                          ? "sort sort-down"
                          : "sort sort-up"
                        : ""
                    }
                    id="groupName"
                    onClick={(e) => handleSort(e, "group_name", "groupName")}
                  ></i>
                </div>
              )}
            </div>
            <div className="crm-scroll-field">
              {groups?.length > 0 && Object.keys(displayName).length>0 &&
                groupsPermission?.view && !loading &&
                Object.keys(groups[0]).map(
                  (heading, index) =>
                    !APP_DATA.hiddenColumns.groups.includes(heading) && (
                      <div
                        id={`col-${index}`}
                        className={`crm-td r  ${
                          sortInfo.sortKey === heading
                            ? sortInfo.sortBy === "desc"
                              ? "crm-sort sort-active"
                              : "crm-sort sort-active-up"
                            : ""
                        }`}
                      >
                        <span
                          onClick={(e) =>
                            handleSort(e, heading, `col-${index}`)
                          }
                        >
                          {/* {APP_DATA.APIColumnMappings.groups[heading]
                            ? APP_DATA.APIColumnMappings.groups[heading]
                            : heading?.split("_").join(" ")} */}
                          {echo(displayName[heading])}
                        </span>
                      </div>
                    )
                )}
              {/* <div className="crm-td crm-action">
                <a
                  id="m-managecolumn"
                  className="crm-action-filter"
                  onclick="openSidebar('crm-managecol')"
                  data-original-title="Manage Columns"
                ></a>
                <Tooltip
                  place={"left"}
                  anchorId={"m-managecolumn"}
                  content="Manage columns"
                />
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="crm-tb-bdy"
          style={{
            height: `${!groups.length ? "100%" : ""}`,
            maxHeight: `${!groups.length ? "100%" : ""}`,
          }}
        >
          {!loading ? (
            groupsPermission?.view && Object.keys(displayName).length>0 && groups?.length > 0 ? (
              groups.map((g, i) => (
                <div className="crm-tb-rw" key={i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-grpname">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark ids"
                          id={g?.group_id}
                          onChange={(e) => handleDeleteCheck(e)}
                          checked={
                            selectedCheck.includes(g?.group_id) ? true : false
                          }
                        />
                        <label
                          className="grp-name"
                          title=""
                          htmlFor={g?.group_id}
                        ></label>
                      </div>
                      <div
                        className="name"
                        onClick={
                          groupsPermission?.view
                            ? () => handleVECMenu(g.group_id, "vec")
                            : null
                        }
                      >
                        <span className="link">{echo(g?.group_name)}</span>
                      </div>
                      <div className="action-rw">
                        <i
                          id={"edit-title" + i}
                          className={
                            groupsPermission?.edit
                              ? "crm-edit"
                              : "crm-edit disabled"
                          }
                          onClick={
                            groupsPermission?.edit
                              ? () => {
                                  handleVECMenu(g.group_id, "edit");
                                }
                              : null
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div
                    className="crm-scroll-field"
                    onClick={
                      groupsPermission?.view
                        ? () => handleVECMenu(g.group_id, "vec")
                        : null
                    }
                  >
                    {groups?.length > 0 &&
                      Object.keys(groups[0])?.map(
                        (heading, i) =>
                          !APP_DATA.hiddenColumns.groups.includes(heading) && (
                            <div data-colpos={i} className={`crm-td `}>
                              <span>
                                {!APP_DATA.formattingColumns.groups.includes(
                                  heading
                                ) && echo(getObjValue(g, heading))}
                              </span>
                            </div>
                          )
                      )}
                    {/* <div data-colpos="2" className="crm-td crm-td-grpdesc">
                    <span>{echo(g?.group_description)}</span>
                  </div> */}
                  </div>
                </div>
              ))
            ) : (
              <NoResults permission={!groupsPermission?.view} filter={filter} />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-grpname"} module="group" />
          )}
        </div>
      </div>
      {/* <!--Pagination-->
      {groups.length && groupsPermission?.view ? (
        <Pagination
          functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
          totalRecords={totalRecords}
        />
      ) : null} */}
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"groups"}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
