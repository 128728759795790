import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { APP_DATA } from "../app_data";
import { getSavedInputConfigAPI } from "../apis/customization";
import { echo, utf8_to_b64,getUserAccessInfo } from "../utils";
import NoResults from "../components/common/noResults";
import createResizer from "../utils/resizerHandler";

export default function CustomizationsView() {
  const location = useLocation();
  const navigate = useNavigate();

  const selectModule = useRef(null);
  const targetModule = useRef(null);
  const staticModules = useRef(APP_DATA.modules);

  const [configs, setConfigs] = useState([]);
  const [modules, setModules] = useState(staticModules.current[0]);

  const [permission, setPermission] = useState({});

  useEffect(() => {
    setPermission(getUserAccessInfo("Customizations").permission || {});
  }, []);

  const handleModuleSwitch = (e) => {
    if (targetModule.current == e.target.dataset.value) {
      selectModule.current.classList.remove("open");
      return;
    }
    navigate({
      pathname: "/app/customizations",
      search: createSearchParams({
        target: "create-customization-" + e.target.dataset.value,
        tNode: utf8_to_b64(String(Date.now())),
      }).toString(),
    });

    targetModule.current = e.target.dataset.value;
    setModules(
      staticModules.current.find((m) => m.value === e.target.dataset.value)
    );
    selectModule.current.classList.remove("open");
    getConfiguredCustomInputs(targetModule.current);
  };

  useEffect(() => {
    createResizer();
  }, [configs, location.search]);

  useEffect(() => {
    navigate({
      pathname: "/app/customizations",
      search: createSearchParams({
        target: "create-customization-" + modules.value,
        tNode: utf8_to_b64(String(Date.now())),
      }).toString(),
    });
    targetModule.current = modules.value;
    getConfiguredCustomInputs(targetModule.current);
  }, []);

  const getConfiguredCustomInputs = async (modules) => {
    const config = await getSavedInputConfigAPI(modules);
    const abData = [];
    if (config.data && config.data.hasOwnProperty("config_fields")) {
      config.data.config_fields.map((conf) => {
        const dataObj = {
          data_type: String(conf.type).replace("-", " ").toUpperCase(),
          field_name: conf.label,
          modified_on: config.data["updated_at"],
          created_at: config.data["created_at"],
        };
        abData.push(dataObj);
      });
      setConfigs(abData);
    } else {
      setConfigs([]);
    }
  };

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Customization</h1>
        </div>
        <div className="header_rhs"></div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div className="crm-custom-select-wrap">
            <div className="custom-select" ref={selectModule}>
              <div
                className={permission.view ? "crm-custom-select-value":"crm-custom-select-value disabled"}
                onClick={permission.view ?() => selectModule.current.classList.add("open"):null}
              >
                <span title="Leads">{modules.name}</span>
                <div className="arrow"></div>
              </div>
              <div
                className="crm-custom-opblock"
                onMouseLeave={() =>
                  selectModule.current.classList.remove("open")
                }
              >
                {staticModules.current.map((m, i) => (
                  <span
                    className="crm-custom-option"
                    data-value={m.value}
                    key={"modules_" + i}
                    onClick={(e) => handleModuleSwitch(e)}
                  >
                    {m.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="hdr-btn-drpdwn mr15">
            <div className="lhs-prt crt-fld-btn">
              <Link
                to={
                  "/app/create-fields?referrer=create-customization-init&target=" +
                  targetModule.current
                }
                onClick={!permission.create ? (e)=>{e.preventDefault()}:null}
              >
                <i className="plus-icn"></i>
                <span>{configs.length?"View field configurations":"Customise module field"}</span>
              </Link>
            </div>
          </div>
        </div>
        {permission?.view && configs?.length>0 &&<div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total count: </div>
            <div className="total_number">
              <strong>{configs.length}</strong>
            </div>
          </div>
        </div>}
      </div>
      <div className="crm-table cst-mrgn">
        <div className="crm-tb-hed">
          {permission.view && configs.length>0 &&<div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-td crm-td-fields crm-sort sort-active-up">
              {/* <input type="checkbox" className="crm-checkmark" id="users" /> */}
              <label htmlFor="fields">
                <span>Fields</span>
              </label>
            </div>
            <div className="crm-td r crm-td-datatype" id={`col-2`}>
              <span>Data type</span>
            </div>
            <div className="crm-td r crm-td-modifiedon" id={`col-3`}>
              <span>Modified on</span>
            </div>
            <div className="crm-td r crm-td-modifiedon" id={`col-4`}>
              <span>Created at</span>
            </div>
            <div className="crm-td crm-action"></div>
          </div>}
        </div>

        <div className="crm-tb-bdy">
          {configs.length >0 && permission.view ? (
            configs.map((conf, i) => {
              return (
                <div className="crm-tb-rw" key={"conf_list" + i}>
                  <div className="crm-td crm-td-fields">
                    <label className="user-name" title="" htmlFor="name">
                      <span>
                        {echo(conf.field_name)}
                        <sup>*</sup>
                      </span>
                    </label>
                  </div>
                  <div className="crm-td crm-td-datatype" data-colpos={2}>
                    <span style={{ textTransform: "capitalize" }}>
                      {echo(conf.data_type)}
                    </span>
                  </div>
                  <div className="crm-td crm-td-modifiedon" data-colpos={3}>
                    <span>{echo(conf.modified_on)}</span>
                  </div>
                  <div className="crm-td crm-td-modifiedon" data-colpos={4}>
                    <span>{echo(conf.created_at)}</span>
                  </div>
                </div>
              );
            })
          ) : (
            <NoResults permission={!permission.view} />
          )}
        </div>
      </div>
    </>
  );
}
