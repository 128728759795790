import { getEnumsByModule } from "../apis/enumAPI";
import { getModuleDataTypesAPI, getWFOperatorsAPI } from "../apis/workflowAPIs";
import { envInjector } from "../env";
import CryptoJS from "crypto-js";
import { groupByProperty } from "../utils";
const CONSTANTS = {
  monthNames: {
    January: "1",
    February: "2",
    March: "3",
    April: "4",
    May: "5",
    June: "6",
    July: "7",
    August: "8",
    September: "9",
    October: "10",
    November: "11",
    December: "12",
  },

  staticFields: {
    leads: {
      leadSource: {
        name: "Lead source",
        api_name: "lead_source",
      },
      leadIndustry: {
        name: "Lead industry",
        api_name: "lead_industry",
      },
      leadStatus: {
        name: "Lead status",
        api_name: "lead_status",
      },
    },
    contacts: {
      contactSource: {
        name: "Contact source",
        api_name: "contact_lead_source",
      },
    },
    accounts: {
      accountType: {
        name: "Account type",
        api_name: "account_type",
      },
      businessStatus: {
        name: "Business status",
        api_name: "business_status",
      },
      ownershipType: {
        name: "Ownership type",
        api_name: "ownership_type",
      },
      businessCategory: {
        name: "Business category",
        api_name: "business_category",
      },
    },
    deals: {
      dealStage: {
        name: "Deal stage",
        api_name: "deal_stage",
      },
      dealCampaign: {
        name: "Deal campaign",
        api_name: "deal_campaign_source",
      },
      ownershipType: {
        name: "Deal type",
        api_name: "deal_type",
      },
    },
  },

  encryptAppData: function (text = envInjector.REACT_APP_WEBFORM_KEY) {
    const utf8Data = CryptoJS.enc.Latin1.parse(text);
    const utf8Key = CryptoJS.enc.Latin1.parse(envInjector.REACT_APP_PUBLIC_KEY);
    const encrypted = CryptoJS.AES.encrypt(utf8Data, utf8Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.NoPadding,
    });
    return encrypted.toString();
  },

  cookieNames: {
    extraData: "ex__",
  },
};

export class moduleEnums {
  constructor(moduleName) {
    this.enums = [];
    this.operators = [];
    this.moduleName = moduleName;
    this.properties = { custom_filter: [], bulk_update: [] };
  }

  async getEnums() {
    if (this.enums.length) {
      return this.enums;
    } else {
      const res = await getEnumsByModule(this.moduleName);
      if (res.status) {
        this.enums = groupByProperty(res.data);
        return this.enums;
      }
    }
  }
  async getOperators() {
    if (this.operators.length) {
      return this.operators;
    } else {
      const res = await getWFOperatorsAPI();
      if (res.status) {
        this.operators = res.data;
        return this.operators;
      }
    }
  }
  async getProperties() {
    if (this.properties.custom_filter.length) {
      return this.properties;
    } else {
      const res = await getModuleDataTypesAPI(this.moduleName);
      if (res.status) {
        const tempCustomFilter = [];
        const tempBulkUpdate = [];

        res.data.map((property) => {
          if (property.custom_filter) {
            tempCustomFilter.push(property);
          }
          if (property.bulk_update) {
            tempBulkUpdate.push(property);
          }
        });
        this.properties = {
          bulk_update: tempBulkUpdate,
          custom_filter: tempCustomFilter,
        };
        return this.properties;
      }
    }
  }
}

export default CONSTANTS;
