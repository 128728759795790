import React, { forwardRef, useImperativeHandle } from "react";

const SelectProperties = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({}));
  return (
    <React.Fragment>
      <select
        data-forgi={props.j}
        id={"field_name_" + props.i + props.j}
        data-forci={props.i}
        className="crm-form-ctrl floating-select"
        onChange={(e) => props.hcs(e)}
      >
        {props.mode === "edit" && props.remotePros?.field_name ? (
          <optgroup label="Remote settings">
            <option selected disabled>
              {
                props.moduleField.find(
                  (mf) => mf.field === props.remotePros?.field_name
                )?.display_name
              }
            </option>
          </optgroup>
        ) : null}

        {props.moduleField.length ? (
          <optgroup label="Available properties">
            {props.moduleField.map((dt, k) => (
              <React.Fragment>
                {k === 0 ? (
                  <option disabled selected>
                    Select
                  </option>
                ) : null}
                <option
                  value={dt.field + "-" + dt.datatype}
                  key={"opt_dt" + k}
                  selected={
                    props.mode === "edit"
                      ? String(
                          props.conditionProps.current[props.j][props.i]
                            .field_name
                        ).includes(dt.field)
                      : false
                  }
                >
                  {dt.display_name}
                </option>
              </React.Fragment>
            ))}
          </optgroup>
        ) : (
          <>
            <option disabled>Loading...</option>
          </>
        )}
      </select>
      <label
        alt="Properties"
        htmlFor=""
        placeholder="Properties"
        className="floating-label"
      >
        Properties
      </label>
      <span
        id={"err_field_name_" + props.i + props.j}
        className="crm-inp-error"
      ></span>
    </React.Fragment>
  );
});

export default SelectProperties;
