import React, { useState, useEffect, useRef } from "react";
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";
import { getMeetingsLimitNoffset } from "../apis/meetingAPI";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import {
  echo,
  search,
  forEach,
  getDate,
  getSortBy,
  getObjValue,
  organiseColumns,
  debounceOptFunc,
  getUserAccessInfo,
  getManagedColumns,
  meetingsFormatter,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import { useOutside } from "../customHooks/useOutside";
import Range from "../components/datepicker/range";
import CustomFilter from "../components/filters/customFilter";
import createResizer from "../utils/resizerHandler";
import { APP_DATA } from "../app_data";
import {
  searchAPI,
  searchThroughAPIs,
  getExportFileDateFilter,
  getExportFileFixedFilter,
  getExportFileSearchFilter,
  getExportFileCustomFilter,
  downloadCSVExportedFiles,
} from "../apis";
import { Tooltip } from "react-tooltip";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";

const MODULE_NAME = "meetings";

export default function MeetingsView() {
  const {
    openToast,
    openModal,
    closeModal,
    handleRSidebar,
    closeAllMenuSideBar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);

  const searchRef = useRef(null);
  const datePicker = useRef(null);
  const pagination = useRef(null);
  const deleteIdsList = useRef([]);
  const customFilter = useRef(null);
  const selectFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);

  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All Meetings");
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [meetingPermission, setMeetingPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const [filter, setFilter] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    const meetingsFilter = JSON.parse(sessionStorage.getItem("meetingsFilter"));
    if (
      meetingsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(meetingsFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: meetingsFilter,
      }));
      getMeetingsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      //clearing filters after create, edit ,clone or delete
      //as sometimes no records will create confusion to users
      // clearFixedFilter();
      // clearDateFilter();
      // clearAll();
      // searchRef.current.value = "";
      // clearSort();

      // getMeetings();
      handlePaginatedFetchData(pageData);
    }
    deleteIdsList.current.map((id) => {
      document.getElementById(id).checked = false;
    });
    deleteIdsList.current = [];
    setBtnDelete(false);
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const meetings = getUserAccessInfo("Meetings");

    if (meetings?.permission) {
      setMeetingPermission(meetings.permission);
    } else {
      setMeetingPermission({});
    }
    getModuleDataTypesAPI("meetings")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName); 
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName(displayName); 
      });
    if (location.state?.id) {
      handleVECMenu(location.state?.id, "vec");
      navigate(location.pathname, {});
    }
  }, []);

  useEffect(() => {
    createResizer();
  }, [meetings, location.search, loading]);

  const getMeetings = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);

    const meetings = await getMeetingsLimitNoffset(
      0,
      pageData.limit,
      true,
      selectedFilter,
      APP_DATA.fixedFiltersOwnerFields.meetings.includes(selectedFilter) ? getUserAccessInfo()?.user_id : "",
      sortBy,
      sortField
    );
    if (meetings.status) {
      setMeetings(
        organiseColumns(
          meetings.data.meetings,
          getManagedColumns("meetingsColumn")
        )
      );
      if (meetings.data.hasOwnProperty("total_count")) {
        setTotalRecords(meetings.data.total_count);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === meetings?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== meetings?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleDeleteMenu = (id) => {
    try {
      openModal("deleteModal", {
        data: [id],
        redirect: "meetings",
        closeAllMenuSideBar,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs(
        "meetings",
        qstring,
        0,
        pageData.limit
      );
      if (searched.status) {
        setTotalRecords(searched.data.total_count);
        setMeetings(
          organiseColumns(
            searched.data.meetings,
            getManagedColumns("meetingsColumn")
          )
        );
      } else {
        setMeetings([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getMeetings();
      setFilter(false);
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let e = {
          target: {
            checked: true,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = true;
      } else {
        let e = {
          target: {
            checked: false,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = false;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    //to uncheck select all and disable delete while going to next page
    unCheckRecords();
    setLoading(true);

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const meetings = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (meetings.status) {
        if (meetings?.data) {
          setMeetings(
            organiseColumns(meetings.data, getManagedColumns("meetingsColumn"))
          );
        } else {
          setMeetings([]);
        }
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const meetings = await searchAPI(
          "meetings",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (meetings.status) {
          setTotalRecords(meetings.data.total_count);
          setMeetings(
            organiseColumns(
              meetings.data.meetings,
              getManagedColumns("meetingsColumn")
            )
          );
        } else {
          setMeetings([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getMeetingsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getMeetingsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const meetings = await getMeetingsLimitNoffset(
        pageData.offset,
        pageData.limit,
        true,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.meetings.includes(selectedFilter) ? getUserAccessInfo()?.user_id : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (meetings.status) {
        setTotalRecords(meetings.data.total_count);
        setMeetings(
          organiseColumns(
            meetings.data.meetings,
            getManagedColumns("meetingsColumn")
          )
        );
      } else {
        setMeetings([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      getMeetings(value, sortInfo.sortBy, sortInfo.sortField);
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      clearAll();
      clearDateFilter();
      searchRef.current.value = "";
      unCheckRecords();
      setFilter(true);
    }
  };

  const handleDatePicker = (value) => {
    clearAll();
    clearFixedFilter();
    searchRef.current.value = "";
    unCheckRecords();
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getMeetingsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end, label) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getMeetingsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Meetings");
      document.getElementById("select-value").innerText = "All meetings";
      setFilter(false);
    }
  };

  const clearDateFilter = (meetings) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (meetings) {
      getMeetings();
    }
  };

  const getMeetingsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setFilter(true);
    setLoading(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const meetings = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (meetings.status) {
        if (meetings?.data?.meetings?.length > 0) {
          setMeetings(
            organiseColumns(
              meetings.data.meetings,
              getManagedColumns("meetingsColumn")
            )
          );
        } else {
          setMeetings([]);
        }
        if (meetings.data?.hasOwnProperty("total_count")) {
          setTotalRecords(meetings.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMeetings([]);
      console.log("err", err);
    }
  };

  const getMeetingsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setFilter(true);
    setLoading(false);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const meetings = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (meetings.status) {
        if (meetings?.data?.meetings?.length > 0) {
          setMeetings(
            organiseColumns(
              meetings.data.meetings,
              getManagedColumns("meetingsColumn")
            )
          );
        } else {
          setMeetings([]);
        }
        if (meetings.data?.hasOwnProperty("total_count")) {
          setTotalRecords(meetings.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMeetings([]);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    if (selectedFilter) {
      clearFixedFilter();
    }

    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    openFilterSidebar(false);
    getMeetingsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  const clearAll = (meetings) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      setFilter(false);
      if (meetings) {
        getMeetings("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }

    if (sessionStorage.getItem("meetingsFilter")) {
      sessionStorage.removeItem("meetingsFilter");
    }
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getMeetingsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getMeetingsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getMeetingsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getMeetings(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("meetingsColumn")) {
      sessionStorage.removeItem("meetingsColumn");
    }
    getDataForManageColumns();
  };

  const handleVECMenu = (recordId, type) => {
    if (type === "vec") {
      handleAllMenuSideBar("vecMeeting", {
        id: recordId,
        type: "vec",
      });
    } else {
      handleAllMenuSideBar("vecMeeting", {
        id: recordId,
        type: "edit",
      });
    }
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "title") {
      return;
    }

    if (e.target.id === "tit") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getMeetingsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getMeetingsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getMeetingsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getMeetings(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Meetings",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });
      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }
      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file..",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  const openMeetingLink = (e, link) => {
    overrideDefaultEvent(e);
    if (link) {
      window.open(link);
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Meetings</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}

          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  meetingPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                ref={selectFilter}
                onClick={meetingPermission?.view ? handleFilterSelect : null}
              >
                <div className="crm-custom-select-value">
                  <span title="All Meetings" id="select-value">
                    All meetings
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.meetings.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      data-name={item.name}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search meetings"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!meetingPermission?.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                meetingPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                meetingPermission?.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                meetingPermission?.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                meetingPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              ></span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            <div
              id="date-picker"
              className="daterangepicker ltr show-ranges opensright range"
              style={{
                display: showDatePicker ? "block" : "none",
                top: "98.1406px",
                left: "auto",
                right: "0px",
              }}
              ref={datePicker}
            >
              <Range
                functions={{ handleDatePicker, handleCallback }}
                datePickerFilter={datePickerFilter}
                showCalendar={showDatePicker}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              meetingPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                meetingPermission?.create
                  ? () => handleRSidebar("createMeeting")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Meeting</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                btnDelete && meetingPermission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && meetingPermission?.delete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "meetings",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? meetings?.find((m) =>
                                deleteIdsList.current.includes(m.meeting_id)
                              )
                            : null,
                      })
                  : () => {}
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>

            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                {/* <span
                  className={`${
                    totalRecords && meetingPermission.view ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && meetingPermission.view
                      ? () =>
                          navigate(
                            "/app/meetings/bulkupdate?mode=bu&target=meetings"
                          )
                      : null
                  }
                >
                  Mass Update
                </span> */}
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && meetingPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && meetingPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
              </div>
            </button>
          </div>
        </div>
        {meetingPermission?.view && Object.keys(displayName).length>0 && meetings?.length > 0 && (
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total meetings: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
        </div>)}
      </div>
      {/* table section */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {meetings?.length > 0 && Object.keys(displayName).length>0 && meetingPermission?.view && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                onClick={
                  meetingPermission?.view
                    ? () =>
                        handleRSidebarManageColumns("manageColumns", {
                          applyManageColumn,
                          clearManageColumn,
                          columns: columnInfo,
                          module: "meetings",
                          sessModule: "meetingsColumn",
                        })
                    : null
                }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {meetings?.length > 0 && Object.keys(displayName).length>0 && 
                !loading && meetingPermission?.view && (
                  <div className="crm-td crm-td-title">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        ref={selectAllCheck}
                        className="crm-checkmark"
                        id="title"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={meetings.length ? false : true}
                      />
                      <label htmlFor="title"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "meeting_title"
                            ? "sort-highlight"
                            : ""
                        }
                        id="tit"
                        onClick={(e) => {
                          handleSort(e, "meeting_title", "meetingTit");
                        }}
                      >
                        {echo(displayName["meeting_title"])}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "meeting_title"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="meetingTit"
                      onClick={(e) =>
                        handleSort(e, "meeting_title", "meetingTit")
                      }
                    ></i>
                  </div>
                )}
              </div>
              <div className="crm-scroll-field">
                {meetings?.length > 0 &&
                Object.keys(displayName).length>0 &&
                  meetingPermission?.view && !loading &&
                  Object.keys(meetings[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.meetings.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                          style={
                            heading === "meeting_link"
                              ? {
                                  width: "300px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }
                              : {}
                          }
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.meetings[heading]
                              ? APP_DATA.APIColumnMappings.meetings[heading]
                              : heading?.split("_").join(" ")} */}
                            {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              meetingPermission?.view && Object.keys(displayName).length>0 && meetings?.length > 0 ? (
                meetings.map((m, i) => (
                  <div className="crm-tb-rw" key={"meetingList_" + i}>
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-title">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={m.meeting_id}
                            onChange={(e) => handleDeleteCheck(e)}
                          />
                          <label
                            className="title"
                            title=""
                            htmlFor={m.meeting_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            meetingPermission?.view
                              ? () => handleVECMenu(m.meeting_id, "vec")
                              : null
                          }
                        >
                          <span className="link">{echo(m.meeting_title)}</span>
                        </div>

                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              meetingPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              meetingPermission?.edit
                                ? () => handleVECMenu(m.meeting_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        meetingPermission?.view
                          ? () => handleVECMenu(m.meeting_id, "vec")
                          : undefined
                      }
                    >
                      {meetings?.length > 0 &&
                        Object.keys(meetings[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.meetings.includes(
                              heading
                            ) && (
                              <div
                                data-colpos={i}
                                className={
                                  heading === "meeting_link"
                                    ? "crm-td crm-td-link"
                                    : "crm-td"
                                }
                              >
                                {heading === "meeting_link" ? (
                                  <span>
                                    <a
                                      onClick={(e) =>
                                        openMeetingLink(
                                          e,
                                          getObjValue(m, heading)
                                        )
                                      }
                                    >
                                      {echo(getObjValue(m, heading))}
                                    </a>
                                  </span>
                                ) : (
                                  <span>
                                    {!APP_DATA.formattingColumns.meetings.includes(
                                      heading
                                    )
                                      ? echo(getObjValue(m, heading))
                                      : meetingsFormatter(
                                          heading,
                                          getObjValue(m, heading)
                                        )}
                                  </span>
                                )}
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!meetingPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-title"}
                module="meeting"
              />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"meetings"}
            />
          )}
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
