import React from "react";

export default function InputDropdownSelect() {
  return (
    <React.Fragment>
      <div
        className="crm-form-field"
        style={{ display: "none" }}
        id={"inputType_6"}
      >
        <select className="crm-form-ctrl floating-select" id="">
          <option value="1">value 1</option>
          <option value="2">value 2</option>
        </select>
        {/* <label alt="Lead Status" htmlFor="" placeholder="Lead Status" className="floating-label">Lead Status</label> */}
        <i className="blur-arw"></i>
        <span className="crm-inp-error" style={{display:'none'}}>
          Lead Status cannot be blank
        </span>
        <div className="crm-action">
          <a className="crm-action-opt crm-action-settings" id="">
            <ul className="crm-dropdown-action ul">
              <li>
                <span className="crm-action-edit" data-link="edit">
                  Edit Properties
                </span>
              </li>
              <li>
                <span className="crm-action-delete" data-link="remove">
                  Remove
                </span>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
