import React from "react";

export default function DropdownSelect(props) {
  const inputProperties = {
    id: props.conf.id,
    name: props.conf.name,
    label: props.conf.label,
    required: props.conf.required,
    options: props.conf.options,
  };
  return (
    <React.Fragment>
      <div className="crm-form-field">
        <select
          className="crm-form-ctrl floating-select"
          id={inputProperties.id}
          onChange={(e) => props.formChangeHandler(e)}
          defaultValue={props.defaultValue}
        >
          <option>Select</option>
          {inputProperties.options.map((o) => (
            <option value={o.value}>{o.name}</option>
          ))}
        </select>
        <label
          htmlFor={inputProperties.id}
          placeholder={inputProperties.label}
          className="floating-label"
        >
          {inputProperties.label}
          <sup>{inputProperties.required ? "*" : ""}</sup>
        </label>
        <span className="crm-inp-error" id={`err_${inputProperties.id}`}></span>
      </div>
    </React.Fragment>
  );
}
