import React from "react";

export default function DateInput(props) {
  const inputProperties = {
    id: props.conf.id,
    name:props.conf.name,
    label: props.conf.label,
    required: props.conf.required,
  };

  return (
    <React.Fragment>
      <div className="crm-form-field">
        <input
          type="date"
          placeholder=" "
          max="9999-12-12"
          id={inputProperties.id}
          name={inputProperties.name}
          className="crm-form-ctrl floating-input"
          onChange={(e) => props.formChangeHandler(e)}
          defaultValue={props.defaultValue}
        />
        <label
          htmlFor={inputProperties.id}
          placeholder={inputProperties.label}
          className="floating-label"
        >
          {inputProperties.label}
          <sup>{inputProperties.required ? "*" : ""}</sup>
        </label>
        <span className="crm-inp-error" id={`err_${inputProperties.id}`}></span>
      </div>
    </React.Fragment>
  );
}
