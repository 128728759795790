import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const saveUplodedFilesAPI = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/contracts", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAllContractsAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/contracts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const downloadContractFiles = (fileName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(fileName, {
        decompress: false,
        responseType: "arraybuffer",
      })
      .then(async (res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteContractFiles = (fileName, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        API_URL +
          `/contracts/file?file_os_name=${fileName}&deleted_by=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getContractsLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = false,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/contracts/offset?offset=${offset}&limit=${limit}&count=${count}&contract_field=${
            sortField ? sortField : "Created date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deteleContractFilesAPI = async (fileNames = [], userId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + `/contracts/delete_files/?deleted_by=${userId}`,
        fileNames,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
