import React from "react";

export default function CanvasTableView(props) {
  return (
    <React.Fragment>
      <div className="canvas-table" style={{ display: "block" }}>
        <div className="crm-tb-rw crm-grprw">
          <p>Commercial Press</p>
        </div>
        <div className="crm-tb-rw crm-grpitm">
          <div className="deal-preview">
            <div className="deal-img"></div>
            <div className="deal-info">
              <div className="file-actns">
                <i className="crm-file-icon"></i>
                <span className="file-name">Contract.jpg</span>
                <a className="crm-action-opt crm-action-settings" id="">
                  <ul className="crm-dropdown-action" id="">
                    <li>
                      <span className="crm-action-download">Download</span>
                    </li>
                    <li>
                      <span className="crm-action-view">View</span>
                    </li>
                    <li className="divider">
                      <span className="crm-action-delete">Delete</span>
                    </li>
                  </ul>
                </a>
              </div>
              <p className="file-desc">You uploaded yesterday</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
