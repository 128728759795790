import React from "react";

export default function DealStaticFields(props) {
  return (
    <React.Fragment>
      <div className="crm-form-row">
        <h3>Deal Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Deal name" className="floating-label">
                Deal name
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Deal Owner" className="floating-label">
                Deal Owner
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Amount" className="floating-label">
                Amount
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="date"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Closing date" className="floating-label">
                Closing date
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Contact" className="floating-label">
                Contact
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Account" className="floating-label">
                Account
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Expected revenue" className="floating-label">
                Expected revenue
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label placeholder="Campaign source" className="floating-label">
                Campaign source
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="dealCampaign"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label placeholder="Deal stage" className="floating-label">
                Deal stage
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="dealStage"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label placeholder="Probability(%)" className="floating-label">
                Probability(%)
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label placeholder="Deal type" className="floating-label">
                Deal type
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="ownershipType"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <h3>Additional Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp">
            <div className="crm-form-field">
              <textarea
                placeholder="Additional"
                rows="6"
                readOnly={true}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
