import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  debounceOptFunc,
  echo,
  forEach,
  generateFilterPayload,
  getManagedColumns,
  getObjValue,
  getUserAccessInfo,
  organiseColumns,
  sentenceCaseFormatter,
  overrideDefaultEvent,
  getSortBy,
  campaignFormatter,
} from "../utils";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import { APP_DATA } from "../app_data";
import { getDataForCustomFilters } from "../apis/customFilterAPI";
import { getCampaignsLimitOffset } from "../apis/campaignAPIs";
import NewPagination from "../components/common/newPagination";
import CustomFilter from "../components/filters/customFilter";
import NoResults from "../components/common/noResults";
import DataTableLoader from "../components/common/dataTableLoader";
import createResizer from "../utils/resizerHandler";
import { useOutside } from "../customHooks/useOutside";
import { searchAPI, searchThroughAPIs } from "../apis";
import { useCookies } from "react-cookie";
import CONSTANTS from "../app_data/constants";

export default function CampaignView() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    openModal,
    openToast,
    closeModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);
  const [cookies, setCookies] = useCookies(CONSTANTS.cookieNames.extraData);
  const currency = useRef(cookies[CONSTANTS.cookieNames.extraData]);

  const searchRef = useRef(null);
  const pagination = useRef(null);
  const datePicker = useRef(null);
  const deleteIdsList = useRef([]);
  const dropdownMenu = useRef(null);
  const selectFilter = useRef(null);
  const customFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);

  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All Campaigns");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [permission, setPermission] = useState({
    view: true,
    edit: true,
    delete: true,
    create: true,
  });
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const [filter, setFilter] = useState(false);
  const [newLead, setNewLead] = useState(null);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    const access = getUserAccessInfo("Campaigns");
    if (access && access?.permission) {
      setPermission(access?.permission);
    } else {
      setPermission({});
    }
    getModuleDataTypesAPI("campaigns")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName);
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  useEffect(() => {
    const campaignsFilter = JSON.parse(
      sessionStorage.getItem("campaignsFilter")
    );
    if (
      campaignsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(campaignsFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: campaignsFilter,
      }));
      getCampaignsForCustomFilter(
        appliedFilters,
        true,
        0,
        10,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      handlePaginatedFetchData(pageData);
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    createResizer();
  }, [campaigns, location.search, loading]);

  const getCampaigns = async (selectFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1

    pagination?.current?.resetDefaultPage();
    setLoading(true);

    const campaigns = await getCampaignsLimitOffset(
      0,
      pageData.limit,
      selectFilter,
      APP_DATA.fixedFiltersOwnerFields.campaigns.includes(selectFilter)
        ? getUserAccessInfo()?.user_id
        : "",
      sortBy,
      sortField
    );
    if (campaigns.status) {
      if (campaigns.data?.hasOwnProperty("campaigns")) {
        setCampaigns(
          organiseColumns(
            campaigns.data.campaigns,
            getManagedColumns("campaignsColumn")
          )
        );
      } else {
        setCampaigns([]);
        setTotalRecords(0);
      }

      if (campaigns.data?.hasOwnProperty("total_count")) {
        setTotalRecords(campaigns.data.total_count);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  //searching function
  async function handleSearch(qstring = "") {
    //reset
    clearAll();
    unCheckRecords();
    clearFixedFilter();
    clearSort();
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const campaigns = await searchAPI(
        "campaigns",
        qstring,
        0,
        pageData.limit
      );
      if (campaigns.status) {
        setTotalRecords(campaigns.data.total_count);
        setCampaigns(
          organiseColumns(
            campaigns.data.campaigns,
            getManagedColumns("campaignsColumn")
          )
        );
      } else {
        setCampaigns([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      if (searchRef.current) {
        searchRef.current.value = "";
      }
      getCampaigns();
      setFilter(false);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "none",
        searchQuery: qstring,
      }));
    }
  }
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handlePaginatedFetchData = async (pageData) => {
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const campaigns = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (campaigns.status) {
        if (campaigns?.data) {
          setCampaigns(
            organiseColumns(
              campaigns.data,
              getManagedColumns("campaignsColumn")
            )
          );
        } else {
          setCampaigns([]);
        }
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const campaigns = await searchThroughAPIs(
          "campaigns",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (campaigns.status) {
          setTotalRecords(campaigns.data.total_count);
          setCampaigns(
            organiseColumns(
              campaigns.data.campaigns,
              getManagedColumns("campaignsColumn")
            )
          );
        } else {
          setCampaigns([]);
          setTotalRecords(0);
        }
      }
    } else {
      //default get data
      const campaigns = await getCampaignsLimitOffset(
        pageData.offset,
        pageData.limit,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.campaigns.includes(selectedFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (campaigns.status) {
        setTotalRecords(campaigns.data.total_count);
        setCampaigns(
          organiseColumns(
            campaigns.data.campaigns,
            getManagedColumns("campaignsColumn")
          )
        );
      } else {
        setCampaigns([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
  };

  const handleVECMenu = (recordId, actionType) => {
    if (actionType === "vec") {
      handleAllMenuSideBar("vecCampaign", {
        id: recordId,
        type: "vec",
      });
    } else {
      handleAllMenuSideBar("vecCampaign", {
        id: recordId,
        type: "edit",
      });
      return;
    }
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === campaigns?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== campaigns?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      setBtnDelete(false);
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleDeleteCampaign = () => {
    openModal("deleteModal", {
      data: deleteIdsList.current,
      redirect: "campaigns",
      msgInfo:
        deleteIdsList.current.length === 1
          ? campaigns?.find((c) => deleteIdsList.current.includes(c.id))
          : null,
    });
  };

  const getCampaignsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();
    const modules = location.pathname.split("/")[2] || "";
    try {
      const campaigns = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (campaigns.status) {
        if (campaigns?.data?.campaigns?.length > 0) {
          setCampaigns(
            organiseColumns(
              campaigns.data.campaigns,
              getManagedColumns("campaignsColumn")
            )
          );
        } else {
          setCampaigns([]);
        }
        if (campaigns.data?.hasOwnProperty("total_count")) {
          setTotalRecords(campaigns.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setCampaigns([]);
      setLoading(false);
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    openFilterSidebar(false);
    if (selectedFilter) {
      clearFixedFilter();
    }
    if (searchRef.current) {
      searchRef.current.value = "";
    }
    unCheckRecords();
    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    getCampaignsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  const clearAll = (campaigns) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      if (campaigns) {
        getCampaigns("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }
    if (sessionStorage.getItem("campaignsFilter")) {
      sessionStorage.removeItem("campaignsFilter");
    }
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getCampaignsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      getCampaigns(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("campaignsColumn")) {
      sessionStorage.removeItem("campaignsColumn");
    }
    getDataForManageColumns();
  };

  const handleFixedFilter = (e) => {
    const filterValue = e.target.getAttribute("data-value");
    selectFilter.current.classList.add("open");
    if (filterValue) {
      setSelectedFilter(filterValue);
      getCampaigns(filterValue, sortInfo.sortBy, sortInfo.sortField);
      selectFilter.current.classList.remove("open");
      if (searchRef.current) {
        searchRef.current.value = "";
      }
      clearAll();
      setFilter(true);
      unCheckRecords();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Campaigns");
      setFilter(false);
    }
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "campaign_check") {
      return;
    }

    if (e.target.id === "campaign") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getCampaignsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else {
        getCampaigns(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i className="crm-backbtn"></i>
          <div className="header_title">Campaign</div>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by:</span>{" "}
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <= 1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 && (
                  <span
                    onClick={(e) => {
                      openFilterSidebar(true);
                    }}
                  >
                    {" "}
                    and <a>more</a>
                  </span>
                )}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  permission?.view ? "custom-select" : "custom-select disabled"
                }
                ref={selectFilter}
                onClick={permission?.view ? handleFixedFilter : null}
              >
                <div className="crm-custom-select-value">
                  <span id="selected_filter">
                    {sentenceCaseFormatter(selectedFilter)}
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.campaigns.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                id="searchInput"
                placeholder="Search Campaign"
                ref={searchRef}
                onChange={(e) => handleDebouncedSearch(e.target.value)}
              />
              {customFilterDetails.filter === "search" ? (
                <i
                  class="btn-ico-close"
                  data-original-title="Clear Search"
                  onClick={() => handleSearch()}
                ></i>
              ) : (
                <span className="btn-ico-srch"></span>
              )}
            </div>
            <div
              className={
                permission.view ? "fields-filter" : "fields-filter disabled"
              }
              id=""
              onClick={
                permission.view
                  ? () => openFilterSidebar(!showCustomFilter)
                  : null
              }
            >
              <span className="crm-action-field-filter"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div className="hdr-btn-drpdwn mr15">
            <div
              className="lhs-prt"
              onClick={() => handleRSidebar("createCampaign")}
            >
              <i className="plus-icn"></i>
              <span>Create Campaign</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            {/* delete action */}
            <button
              className={
                btnDelete && permission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && permission?.delete
                  ? () => handleDeleteCampaign()
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>

        {permission?.view &&
          campaigns?.length > 0 &&
          Object.keys(displayName).length > 0 && (
            <div className="menu-rgt">
              <div className="total_count">
                <div className="total_title">Total campaigns: </div>
                <div className="total_number">
                  <strong>{totalRecords}</strong>
                </div>
              </div>
              <NewPagination
                ref={pagination}
                totalRecords={totalRecords}
                functions={{
                  handlePaginatedFetchData,
                }}
              />
            </div>
          )}
      </div>

      {/* <!-- table section --> */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {campaigns?.length > 0 &&
          permission?.view &&
          Object.keys(displayName).length > 0 && (
            <React.Fragment>
              <div className="crm-action">
                <a
                  id="m-managecolumn"
                  className="crm-action-filter"
                  onClick={
                    permission?.view
                      ? () =>
                          handleRSidebarManageColumns("manageColumns", {
                            applyManageColumn,
                            clearManageColumn,
                            columns: columnInfo,
                            module: "campaigns",
                            sessModule: "campaignsColumn",
                          })
                      : null
                  }
                ></a>
              </div>
              <Tooltip
                place={"left"}
                anchorId={"m-managecolumn"}
                content="Manage columns"
              />
            </React.Fragment>
          )}

        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {campaigns?.length > 0 &&
                  permission?.view > 0 &&
                  Object.keys(displayName).length > 0 &&
                  !loading && (
                    <div className="crm-td crm-td-title">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark"
                          ref={selectAllCheck}
                          id="campaign_check"
                          onClick={(e) => handleSelectAll(e)}
                          disabled={campaigns?.length ? false : true}
                        />
                        <label htmlFor="campaign_check"></label>
                      </div>
                      <div className="name">
                        <span
                          className={
                            sortInfo.sortKey === "name" ? "sort-highlight" : ""
                          }
                          onClick={(e) => {
                            handleSort(e, "name", "cmpName");
                          }}
                        >
                          {displayName["name"]}
                        </span>
                      </div>
                      <i
                        className={
                          sortInfo.sortKey === "name"
                            ? sortInfo.sortBy === "desc"
                              ? "sort sort-down"
                              : "sort sort-up"
                            : ""
                        }
                        id="cmpName"
                        onClick={(e) => {
                          handleSort(e, "name", "cmpName");
                        }}
                      ></i>
                    </div>
                  )}
              </div>
              <div className="crm-scroll-field">
                {campaigns?.length > 0 &&
                  permission?.view &&
                  Object.keys(displayName).length > 0 &&
                  !loading &&
                  Object.keys(campaigns[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.campaigns.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              permission?.view &&
              Object.keys(displayName).length > 0 &&
              campaigns?.length > 0 ? (
                campaigns.map((c, i) => (
                  <div
                    className={
                      c.id === newLead ? `crm-tb-rw highlight` : `crm-tb-rw`
                    }
                  >
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-title">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={c.id}
                            onChange={(e) => {
                              handleDeleteCheck(e);
                            }}
                          />
                          <label
                            className="email-id"
                            title=""
                            htmlFor={c.id}
                          ></label>
                        </div>
                        <div
                          className="name lead-name"
                          onClick={
                            permission?.view
                              ? () => handleVECMenu(c.id, "vec")
                              : null
                          }
                        >
                          <span className="link">{echo(c.name)}</span>
                        </div>
                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              permission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              permission?.edit
                                ? () => handleVECMenu(c.id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        permission?.view
                          ? () => handleVECMenu(c.id, "vec")
                          : null
                      }
                    >
                      {campaigns?.length > 0 &&
                        Object.keys(campaigns[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.campaigns.includes(
                              heading
                            ) && (
                              <div
                                data-colpos={i}
                                className={`crm-td `}
                                key={i}
                              >
                                <span>
                                  {!APP_DATA.formattingColumns.campaigns.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(c, heading))
                                    : campaignFormatter(
                                        heading,
                                        getObjValue(c, heading),
                                        currency.current.currency_symbol
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults permission={!permission?.view} filter={filter} />
              )
            ) : (
              <DataTableLoader mClass={"crm-td crm-td-title"} module="lead" />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"campaigns"}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
