import React, { useEffect, useState } from "react";
import { APP_DATA } from "../app_data";
import "react-funnel-pipeline/dist/index.css";
import { FunnelChart } from "react-funnel-pipeline";
import { useOutletContext } from "react-router-dom";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  getConvertedLeads,
  getLeadsBySource,
  getLeadsByStatus,
  getLeadOwners,
} from "../apis/analyticsAPIs";
import { DonutChartLoader } from "../components/analytics/donutChartLoader";
import ChartLegendLoader from "../components/analytics/chartLegendLoader";
import FunnelChartLoader from "../components/analytics/funnelChartLoader";
import BarChartLoader from "../components/analytics/barChartLoader";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import {  echo, getUserAccessInfo,barChartDataParser } from "../utils";
import NoResults from "../components/common/noResults";

export default function AnalyticsLeads() {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    BarElement,
    CategoryScale
  );
  const { closeLoader } = useOutletContext();
  const [donData, setDonData] = useState({});
  const [funnelData, setFunnelData] = useState([{}]);
  const [barData, setBarData] = useState(null);
  const [leadOwners, setLeadOwners] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [leadsPermission, setLeadsPermission] = useState({});
  // const [convertedLeadsFilter,setConvertedLeadsFilter] = useState("")
  // const [loader,setLoader] = useState({converted:true})

  useEffect(() => {
    loadGraphdata();
    setTimeout(() => {
      // closeLoader()
    }, 1000);

    const leads = getUserAccessInfo("Analytics");
    if (leads?.permission) {
      setLeadsPermission(leads.permission);
    } else {
      setLeadsPermission({});
    }
  }, []);

  const loadGraphdata = async () => {
    const leadsSourceData = await getLeadsBySource();
    const leadsStatusData = await getLeadsByStatus();
    const leadsConvertedData = await getConvertedLeads();
    const leadOwnersData = await getLeadOwners();
    const finalDonData = furnishDonData(leadsSourceData.data);
    const finalFunnelData = furnishFunnelData(leadsStatusData.data);
    const finalBarData = furnishBarData(leadsConvertedData.data);
    setDonData(finalDonData);
    setFunnelData(finalFunnelData);
    setBarData(finalBarData);
    setLeadOwners(leadOwnersData?.data?.lead_owner);
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
    // setLoader({converted:false})
  };

  const furnishDonData = (sourceData) => {
    const donData = {
      datasets: [
        {
          borderWidth: 0,
          hoverOffset: 30,
          label: "Number of Leads",
          data: sourceData.leads_source.map((d) => d.value),
          backgroundColor: APP_DATA.donutChartColors,
        },
      ],
      total: sourceData.total_count,
      labels: sourceData.leads_source.map((d) => d.name),
    };
    return donData;
  };

  const DonOptions = {
    cutout: "70%",
    responsive: true,
    layout: {
      padding: 13,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        backgroundColor: "rgba(155, 184, 216, .8)",
        titleColor: "#000",
        caretSize: 5,
        bodyColor: "#000",
        displayColors: false,
        padding: 10,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";

            if (label) {
              label = "Total Count: " + context.parsed;
            }
            document.getElementById("t_display").style.opacity = 0;
            return label;
          },
        },
      },
    },
  };

  const furnishFunnelData = (statusData) => {
    const funnelData = {
      dataset: statusData.leads_status,
      backgroundColor: APP_DATA.funnelChartColors,
    };

    return funnelData;
  };

  const BarOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        backgroundColor: "rgba(155, 184, 216, .8)",
        titleColor: "#000",
        caretSize: 5,
        bodyColor: "#000",
        displayColors: false,
        padding: 10,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  const furnishBarData = (sourceData,filter) => {
    const barChartData = barChartDataParser(sourceData?.converted_leads,"month");

    const data = [];
    const labels = [];

    barChartData?.map((it) => {
      data.push(it.value);
      labels.push(it.name?.substring(0, 3));
    });

    // barChartData?.map((it) => {
    //   data.push(it.value);
    //   labels.push(["monthly"].includes(filter) ? it.name?.substring(0, 3):it.name);
    // });

    const BarData = {
      datasets: [
        {
          label: "Leads",
          borderRadius: 4,
          barThickness: 24,
          backgroundColor: "#73BADF",
          data,
        },
      ],
      labels,
    };
    return BarData;
  };

  // const handleConvertedFilter=async(e,filter)=>{
  //   setConvertedLeadsFilter(filter)
  //   setLoader((prev)=>({...prev,converted:true}))
  //   const leadsConvertedData = await getConvertedLeads(filter);
  //   const finalBarData = furnishBarData(leadsConvertedData.data,filter);
  //   setBarData(finalBarData);
  //   setLoader((prev)=>({...prev,converted:false}))
  // }

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Lead analytics</h1>
        </div>
      </div>
      {leadsPermission?.view ? (
        <div className="crm-section">
          <div className="crm-sctnrw">
            <div className="crm-sctncol">
              <div className="hdr">
                <h2>Lead by source</h2>
                {loaded ? (
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="chart-legends"
                      style={{
                        alignItems:
                          donData?.labels?.length < 10 ? "center" : "",
                      }}
                    >
                      <ul>
                        {donData.labels.map((l, i) => (
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginBottom: 10,
                            }}
                            key={i}
                          >
                            <div
                              className="chart-bullets"
                              style={{
                                backgroundColor: `${
                                  donData.datasets[0].backgroundColor[
                                    i %
                                      donData?.datasets[0]?.backgroundColor
                                        ?.length
                                  ]
                                }`,
                              }}
                            ></div>
                            <span style={{}}>{echo(l)}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div id={"t_display"} className={"chart-center-title"}>
                        <h1>
                          <strong>Total Leads</strong>
                        </h1>
                        <h3>
                          <strong style={{ color: "#073075" }}>
                            {donData.total}
                          </strong>
                        </h3>
                      </div>
                      <Doughnut
                        data={donData}
                        options={DonOptions}
                        onMouseLeave={() =>
                          (document.getElementById(
                            "t_display"
                          ).style.opacity = 1)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <ChartLegendLoader />
                    </div>
                    <div>
                      <DonutChartLoader />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="crm-sctncol col2">
              <div className="hdr">
                <h2>Lead by status</h2>
                {loaded ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ fontSize: 12, alignSelf: "center" }}>
                      <ul>
                        {funnelData.dataset.map((d, i) => (
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginBottom: 10,
                            }}
                            key={i}
                          >
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                borderRadius: "50%",
                                display: "inline-block",
                                backgroundColor: `${
                                  funnelData.backgroundColor[
                                    i % funnelData?.backgroundColor?.length
                                  ]
                                }`,
                              }}
                            ></div>
                            {echo(d.name)}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      style={{ height: 250, width: "50%", margin: "0px 30px" }}
                    >
                      <FunnelChart
                        showNames={false}
                        showValues={true}
                        data={funnelData.dataset}
                        chartWidth={200}
                        getToolTip={(row) => row.name + ": " + row.value}
                        pallette={funnelData.backgroundColor}
                        getRowStyle={() => {
                          return {
                            padding:
                              funnelData?.dataset?.length === 1
                                ? "25px"
                                : "10px",
                            fontSize: "10px",
                          };
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <ChartLegendLoader />
                    </div>
                    <div>
                      <FunnelChartLoader />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="crm-sctnrw">
            <div className="crm-sctncol">
              <div className="hdr">
                <h2>Converted leads</h2>
                {loaded ? (
                  <>
                    <div style={{ height: 250 }}>
                      <Bar options={BarOptions} data={barData} />
                    </div>
                    {/* <i className="crm-action-settings">
                      <ul className="crm-dropdown-action" id="">
                        <li onClick={(e)=>{handleConvertedFilter(e,"yearly")}}>
                          <span className="">Year</span>
                        </li>
                        <li onClick={(e)=>{handleConvertedFilter(e,"monthly")}}>
                          <span className="">Monthly</span>
                        </li>
                        <li onClick={(e)=>{handleConvertedFilter(e,"weekly")}}>
                          <span className="">Weekly</span>
                        </li>
                      </ul>
                    </i> */}
                  </>
                ) : (
                  <BarChartLoader />
                )}
              </div>
            </div>
            <div className="crm-sctncol col2">
              <div className="hdr">
                <h2>Top lead owners</h2>
                <div style={{ height: 250, overflowY: "scroll" }}>
                  <div className="crm-tbl-str">
                    <ul>
                      {leadOwners?.map((v, i) => (
                        <li key={i}>
                          <div className="mnth col">{echo(v.name)}</div>
                          <div className="count col">{echo(v.value)}</div>
                          <div className="action col">
                            <button className="btn">
                              {(v?.percent || 0).toFixed(2)}%
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <i className="crm-action-settings">
                  <ul className="crm-dropdown-action" id="">
                    <li>
                      <span className="">Year</span>
                    </li>
                    <li>
                      <span className="">Monthly</span>
                    </li>
                    <li>
                      <span className="">Weekly</span>
                    </li>
                  </ul>
                </i> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoResults permission={!leadsPermission?.view} />
      )}
    </React.Fragment>
  );
}
