import { forEach } from ".";

let special = false;

const handleRisizeController = (columns, colEl) => {
  forEach(columns, (_, col) => {
    const synthId = String(col.id).split("-")[1];
    const resizer = document.createElement("div");
    resizer.classList.add("resizer");
    resizer.id = "rd_" + synthId;
    resizer.style.height = `${colEl.offsetHeight}px`;
    if (col.childElementCount == 1) {
      col.appendChild(resizer);
    }
    resizer.addEventListener("mousedown", mouseDownHandler);
  });
};

const mouseDownHandler = (e) => {
  const pelW = document.getElementById(e.target.id).parentNode.clientWidth;
  const resizeData = {
    w: pelW,
    x: e.clientX,
    col: String(e.target.id).split("_")[1],
  };
  const mouseMoveHandler = (e) => {
    let pEl;
    let childCols;
    const dx = e.clientX - resizeData.x;
    if (special) {
      pEl = document.getElementById("colsp-" + resizeData.col);
      childCols = document.querySelectorAll(
        `div[data-colpossp='${resizeData.col}']`
      );
    } else {
      pEl = document.getElementById("col-" + resizeData.col);
      childCols = document.querySelectorAll(
        `div[data-colpos='${resizeData.col}']`
      );
    }
    const newWidth = resizeData.w + dx;
    if (newWidth > 80) {
      pEl.style.width = `${newWidth}px`;
      forEach(childCols, (_, cc) => {
        cc.style.width = `${newWidth}px`;
      });
    }
  };
  const mouseUpHandler = () => {
    document.removeEventListener("mouseup", mouseUpHandler);
    document.removeEventListener("mousemove", mouseMoveHandler);
  };
  document.addEventListener("mouseup", mouseUpHandler);
  document.addEventListener("mousemove", mouseMoveHandler);
};

//seting up resizer
const createResizer = (specialRHSTable = false) => {
  special = specialRHSTable;
  const colDiv = document.getElementById("resizeMe");
  const headerCols = document.querySelectorAll(".crm-td.r");
  //main handler
  handleRisizeController(headerCols, colDiv);
};

export default createResizer;
