import React from "react";

export default function Checkbox(props) {
  const inputProperties = {
    id: props.conf.id,
    name:props.conf.name,
    label: props.conf.label,
    required: props.conf.required,
  };

  return (
    <React.Fragment>
      <div className="crm-form-field">
      <div className="chkbox only-disabled">
          <input 
          type="checkbox" 
          className="crm-checkmark" 
          id={inputProperties.id}
          name={inputProperties.name}
          onChange={(e) => props.formChangeHandler(e)}
          defaultChecked={props.defaultValue==="true"}
          />
          <label htmlFor={inputProperties.id}>
            <span id={"inputType_8_Label"}>{inputProperties.label}</span>
          </label>
        </div>
        <span className="crm-inp-error" id={`err_${inputProperties.id}`}></span>
      </div>
    </React.Fragment>
  );
}
