import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_DATA } from "../../app_data";
import { inputFieldValidator } from "../../utils";

export default function ActionPreference(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const generalSettings = useRef(
    Object.assign({}, APP_DATA.workflowRules.generalSettings)
  );

  const [actionType, setActionType] = useState(
    generalSettings.current.actionType[0]
  );
  const [mode, setMode] = useState("create");
  const [eventType, setEventType] = useState(null);
  const [remoteWFR, setRemoteWFR] = useState(null);
  const [modules, setModules] = useState(APP_DATA.modules[0]);

  useEffect(() => {
    const mode = String(urlSearch.get("mode"));
    const module = String(urlSearch.get("target")).split("-")[2];
    setModules(APP_DATA.modules.find((m) => m.value == module));
    setMode(mode);
    try {
      if (mode === "edit") {
        const remoteWFR = JSON.parse(
          window.sessionStorage.getItem("wf:remote")
        );
        setRemoteWFR(remoteWFR);
      }
    } catch (e) {
      console.log(e);
    }
  }, [location.search]);

  useEffect(() => {
    setupEditMode();
  }, [remoteWFR]);

  const setupEditMode = () => {
    if (remoteWFR) {
      props.functions.settingAgrregator("event", { value: remoteWFR["event"] });
    }
  };

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);
    if (validationResponse.valid) {
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const handleSetEventType = (e) => {
    if (
      APP_DATA.workflowRules.generalSettings.eventType.includes(e.target.value)
    ) {
      formChangeHandler(e);
      setEventType(e.target.value);
      props.functions.settingAgrregator("event", { value: e.target.value });
    }
  };

  return (
    <React.Fragment>
      <div className="wstep">
        <div className="count">
          <span>1</span>
        </div>
        <div className="step-info">
          <div className="frst-hlf">
            <h3>
              Set action type and trigger preference
              <i
                className="info-icon"
                data-original-title="Please set action type"
              ></i>
            </h3>
          </div>
          <div className="scnd-hlf">
            <div className="crm-form-row">
              <div className="crm-form-grp">
                <div className="crm-form-grp">
                  <div className="crm-form-field" style={{ width: "60%" }}>
                    <select
                      className="crm-form-ctrl floating-select"
                      id="event"
                      onChange={(e) => handleSetEventType(e)}
                    >
                      {mode === "edit" ? (
                        <optgroup label="Remote settings">
                          <option
                            key={"rwf-event_0"}
                            value={remoteWFR.event}
                            disabled
                            selected
                          >
                            when {remoteWFR.module + " is " + remoteWFR.event}
                          </option>
                        </optgroup>
                      ) : null}
                      {modules ? (
                        <>
                          <optgroup label="Availbale events">
                            <option value="created">
                              When {modules.singular} is created
                            </option>
                            <option value="updated">
                              When {modules.singular} is updated
                            </option>
                            <option value="created_or_updated">
                              When {modules.singular} is created or updated
                            </option>
                            <option value="deleted">
                              When {modules.singular} is deleted
                            </option>
                          </optgroup>
                        </>
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select>
                    <label
                      alt="Module"
                      htmlFor=""
                      placeholder="Module event"
                      className="floating-label"
                    >
                      Module event
                    </label>
                    <span className="crm-inp-error" id="err_event"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
