import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { getInstalledExtension, getExtensionByName } from "../../apis/extensionAPI";
import DetailExtensionView from "../../views/detailExtensionView";
import InstalledView from "./installedView";
import InstalledCred from "../extensionCred/installedCred";

const InstalledExtension = forwardRef((props, ref) => {

  const [installedExtension, setInstalledExtension] = useState(null);
  const [unInstalledView, setunInstalledView] = useState("");
  const [id, setExtensionId] = useState(null);
  const [view, setView] = useState("");
  const [active, setActive] = useState(null);

  useEffect(() => {
    fetchInstalledExtension();
  }, []);

  const fetchInstalledExtension = () => {
    getInstalledExtension()
      .then((res) => {
        if (res.status) {
          setInstalledExtension(res.data);
        }
      })
      .catch((err) => {
        setInstalledExtension(null);
        console.log(err);
      });
  };

  const detailExtensionId = (id) => {
    setView("details");
    setExtensionId(id);
  };

  const renderInstalledView = (view) => {
    switch (view) {
      case "details":
        return <DetailExtensionView setView={setView} id={id} />;
      case "enableDisable":
        return <InstalledCred id={id} active={active} />;
      default:
        return (
          <InstalledView
            installedExtension={installedExtension}
            detailExtensionId={detailExtensionId}
            setunInstalledView={setunInstalledView}
            fetchInstalledExtension={fetchInstalledExtension}
            id={id}
          />
        );
    }
  };

  useImperativeHandle(ref, () => ({
    handleSearch: async (qString) => {
      console.log(qString);
     if(qString.length) {
         const searched = await getExtensionByName(qString, true );
         if (searched.status) {
           setInstalledExtension(searched.data);
         } else {
           setInstalledExtension([]);
         }
     } else {
       fetchInstalledExtension();
     }
   }

 }))

  return (
    <>
      <div
        className="crm-tab-content"
        id="instExtTab"
        style={{ display: "block" }}
      >
        {renderInstalledView(view)}
      </div>
    </>
  );
});

export default InstalledExtension;
