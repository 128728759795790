import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

const CustomDateRangePicker = ({ children, onCallback }) => {
  return (
    <DateRangePicker
      onCallback={onCallback}
    >
      {children}
    </DateRangePicker>
  );
};

export default React.memo(CustomDateRangePicker);