import React from "react";

const DefaultNoDataImg = () => {
  return (
    <div className="crm-noDataCont">
      <div className="">
        <span className="crm-no-data-available"></span>
      </div>
      <p className="crm-noDataText">No data available</p>
    </div>
  );
};


const getCreateRHSName = (module) => {
  switch (module) {
    case "Tasks":
      return "createTask";
    case "Leads":
      return "createLead";
    case "Meetings":
      return "createMeeting";
    case "Deals":
      return "createDeal";
    default:
      return "";
  }
}

const ModuleNoDataImg = ({ module, functions,permission }) => {
  switch (module) {
    case "Meetings":
      return (
        <div className="crm-noDataCont">
          <div className="">
            <span className={`crm-no-${module?.toLowerCase()}`}></span>
            <h2>No {module} created yet!</h2>
            <button
              type="button"
              className={permission ? "btn btn-primary crt-btn":"btn btn-primary crt-btn disabled"}
              onClick={permission ? () => functions.handleRSidebar(getCreateRHSName(module)):null}
            >
              <i className="plus-icn"></i>Create {module}
            </button>
          </div>
        </div>
      );
    default:
      return (
        <div className="">
          <span className={`crm-no-${module?.toLowerCase()}`}></span>
          <h2>No {module} created yet!</h2>
          <button
            type="button"
            className={permission ? "btn btn-primary crt-btn":"btn btn-primary crt-btn disabled"}
            onClick={permission ? () => functions.handleRSidebar(getCreateRHSName(module)):null}
          >
            <i className="plus-icn"></i>Create {module}
          </button>
        </div>
      );
  }
};

const NoDashboardResults = ({ module, functions,permission }) => {
  return (
    <React.Fragment>
      {module ? <ModuleNoDataImg module={module} functions={functions} permission={permission} /> : <DefaultNoDataImg />}
    </React.Fragment>
  );
};

export default NoDashboardResults;
