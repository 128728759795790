import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getCompany = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/company", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateCompanyById = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/company/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const fetchCountries = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/company/countries", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};
