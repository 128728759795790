import React from "react";

export default function InputTextArea() {
  return (
    <React.Fragment>
      <div
        className="crm-form-field"
        style={{ display: "none" }}
        id={"inputType_2"}
      >
        <textarea
        className="multiline"
          placeholder="multi line"
          rows="1"
          spellcheck="false"
        ></textarea>
        <div className="crm-action">
          <a className="crm-action-opt crm-action-settings" id="">
            <ul className="crm-dropdown-action" id="">
              <li>
                <span className="crm-action-edit" data-link="edit">
                  Edit Properties
                </span>
              </li>
              <li>
                <span className="crm-action-delete" data-link="remove">
                  Remove
                </span>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
