import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { inputFieldValidator } from "../utils";
import { sendResetPasswordMailAPI } from "../apis/authAPIs";
import { APP_DATA } from "../app_data";

export default function ForgotPasswordView() {
  const formData = useRef(structuredClone({ email: "" }));

  const [loaded, setLoaded] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [invalidMail, setInvalidMail] = useState(false);

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);
    if (validationResponse.valid) {
      formData.current[event.target.id] = event.target.value;
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const handleSendResetMail = async () => {
    let finalValidation = 1;
    setInvalidMail(false);
    for (const f in formData.current) {
      if (formData.current[f] === undefined || formData.current[f] === "") {
        document.getElementById("err_" + f).innerText =
          "Email id can not be blank";
        document.getElementById("err_" + f).style.display = "block";
        finalValidation = 0;
      } else {
        document.getElementById("err_" + f).innerText = "";
        document.getElementById("err_" + f).style.display = "none";
      }
    }
    if (finalValidation) {
      setLoaded(true);
      const emailSent = await sendResetPasswordMailAPI(
        formData.current["email"]
      );
      if (emailSent.status) {
        if (emailSent.data["status"]) {
          setEmailSent(true);
        } else {
          setLoaded(false);
          setInvalidMail(true);
          setTimeout(() => {
            document.getElementById("email").value = "";
            document.getElementById("err_email_id").innerText =
              formData.current["email"];

            formData.current["email"] = "";
          }, 100);
        }
      } else {
        setLoaded(false);
        setInvalidMail(false);
      }
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="signup-hdr login">
        <div className="crm-container">
          <div className="hdr-lft">
            <a className="crm-logo"></a>
            <ul className="crm-hdr-menu">
              <li>
                <a>Features</a>
              </li>
              <li>
                <a>Pricing</a>
              </li>
              <li>
                <a>Download</a>
              </li>
              <li>
                <a>FAQs</a>
              </li>
              <li>
                <a>Support</a>
              </li>
            </ul>
          </div>
          <div className="hdr-rgt">
            <Link to={"/"} className="btn btn-secondary">
              Sign in
            </Link>
          </div>
        </div>
      </div>
      <section className="signup-section login">
        <div className="signup-main">
          <div className="crm-outer-form">
            {!emailSent ? (
              <form noValidate="" className="">
                <div className="crm-outer-head">
                  <h1>Forgot password</h1>
                </div>
                <fieldset className="forgotpwd">
                  <div className="crm-form-row">
                    {!invalidMail ? (
                      <div className="successmsg">
                        <p>
                          If your email address exists in our system, you will
                          receive an email with instructions to reset the
                          password.
                        </p>
                      </div>
                    ) : (
                      <div
                        className="successmsg"
                        style={{ borderColor: "#f00" }}
                      >
                        <p>
                          this <strong id="err_email_id"></strong> id does not
                          exist in our system, Please enter a correct email id.
                        </p>
                      </div>
                    )}
                    <p className="pwd-desc">
                      A link to set a new password for your account will be sent
                      to the email address.
                    </p>
                    <p className="pwd-info">
                      You may need to check your spam folder or add
                      support@idrivecrm.com to make sure that emails get
                      delivered to your inbox.
                    </p>
                    <div className="crm-form-grp crm-frm-100">
                      <div className="crm-form-field">
                        <input
                          className="crm-form-ctrl floating-input"
                          id="email"
                          placeholder=" "
                          onChange={(e) => formChangeHandler(e)}
                        />
                        <label
                          htmlFor="email"
                          className="floating-label"
                          placeholder="Email"
                        >
                          Email
                        </label>
                        <span className="crm-inp-error" id="err_email"></span>
                      </div>
                    </div>
                    <div className="crm-form-grp">
                      {!loaded ? (
                        <button
                          type="button"
                          className="btn btn-primary create-acnt"
                          onClick={() => handleSendResetMail()}
                        >
                          Send Link
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary create-acnt"
                        >
                          Please wait...
                        </button>
                      )}
                    </div>
                  </div>
                </fieldset>
              </form>
            ) : (
              <form noValidate="" className="">
                <div className="crm-outer-head">
                  <h1>Reset password link has been sent!</h1>
                </div>
                <fieldset className="forgotpwd">
                  <div className="crm-form-row">
                    <div className="successmsg">
                      <p>
                        An email has sent containing the password reset link,
                        you will find the next instructions there.
                      </p>
                    </div>
                  </div>
                </fieldset>
              </form>
            )}
          </div>
        </div>
      </section>

      <div className="signup-footer">
        <p>©IDrive Inc.</p>
      </div>
    </React.Fragment>
  );
}
