/**
 * All Static variables or Constants should be declared here
 */

export const APP_DATA = {
  sessionExpiryCheck: 1000 * 60, // in miliseconds
  profileImageSize: 3 * 1024 * 1024, // file size in MB
  emailFileSize: 2 * 1024 * 1024, // email attach file size in MB
  // imageCompressionLevel: 0.2 * 1024 * 1024, // size in MB
  maxExportRows: 5000,

  errorMessage: {
    requiredField: "This field cannot be blank", // Convert this to function and display '{Field name}'
  },
  messages: {
    notification: "Operation successfully executed!",
    error_message: "Operation failed to execute!",
  },
  invalidNumberChars: ["-", "+", "e", "E"],
  fileSettings: {
    DataSetting: [
      {
        size: 0,
        fileId: "",
        isValid: 0,
        fileItSelf: "",
        name: "somefile.ext",
        type: "application/ext",
      },
    ],
    uploadSetting: {
      keys: {
        status: 0,
        progress: 0,
      },
    },
    MIMESupportImportData: {
      csv: "text/csv",
      xlsx: "application/vnd.ms-excel",
      xls: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    isValidExportFile: function (fileType) {
      return Object.values(this.MIMESupportImportData).includes(fileType);
    },
    MIMESupport: {
      csv: "text/csv",
      png: "image/png",
      jpg: "image/jpg",
      jpeg: "image/jpeg",
      text: "text/plain",
      pdf: "application/pdf",
      doc: "application/msword",
      xlsx: "application/vnd.ms-excel",
      odt: "application/vnd.oasis.opendocument.text",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    MIMEImageSupport: {
      png: "image/png",
      jpg: "image/jpg",
      gif: "image/gif",
      bmp: "image/bmp",
      jpeg: "image/jpeg",
      // ttif: "image/tiff",
      // webp: "image/webp",
      // icon: "image/x-icon",
      // svg: "image/svg+xml",
    },
  },
  csvDataImportErrors: [
    { error_message: "No error response from server." },
    { error_message: "Check your connection and try again" },
    { error_message: "Contact application support manager" },
  ],
  donutChartColors: [
    "#9c38c6",
    "#B72879",
    "#E4455E",
    "#ED7950",
    "#F7AF35",
    "#87AC71",
    "#71A8AC",
    "#5A8BCB",
    "#2866B7",
    "#4928B7",
    "#6F28B7",
    "#AD28B7",
  ],
  funnelChartColors: [
    "#7052A9",
    "#2866B7",
    "#F7AF35",
    "#ED7950",
    "#E84242",
    "#87AC71",
    "#437313",
  ],
  profileColors: ["a", "b", "c", "d", "e", "f", "g", "h"],

  signUpInputs: {
    allFields: {
      name: "",
      email: "",
      password: "",
      company_name: "",
      phone_number: "",
      agree_tnc: true,
    },
  },

  campaignDealRevenu: {
    closed: {
      revenue: 0,
      count: 0,
    },
    open: {
      revenue: 0,
      count: 0,
    },
  },

  leadInputs: {
    allFields: {
      leadOwner: "",
      leadOwnerName: "",
      leadAccountId: "",
      firstName: "",
      lastName: "",
      // account: "",
      // contact: "",
      leadRole: "",
      email: "",
      leadPhone: "",
      leadMobile: "",
      status: "",
      leadSource: "",
      shippingCountry: "",
      shippingState: "",
      shippingCity: "",
      shippingPincode: null,
      shippingStreet: "",
      description: "",
      industry: "",
      leadTitle: "",
      leadImage: "",
      leadImageCompressed: "",
      // lead_custom_fields_data:[]
    },
    requiredFields: ["leadOwner", "firstName", "lastName"],
  },

  sendEmail: {
    addressFields: {
      emailTo: "",
      emailCc: "",
      emailBcc: "",
      emailSubject: "",
      emailBody: "",
      scheduledAt: "",
    },
    requiredFields: ["emailTo", "emailBody", "emailSubject"],
  },

  taskInputs: {
    allFields: {
      taskOwner: "",
      taskOwnerName: "",
      taskStatus: "",
      description: "",
      taskSubject: "",
      closingDate: "",
      followupDate: "",
      // taskReminder: "",
      taskPriority: "",
      taskLeads: [],
      taskDeals: [],
    },
    requiredFields: ["taskOwner", "taskSubject"],
  },
  accountInputs: {
    allFields: {
      accountName: "",
      accountOwner: "",
      accountOwnerName: "",
      accountType: "",
      // accountRole: "",
      accountEmail: "",
      accountPhone: "",
      accountState: "",
      accountCity: "",
      description: "",
      accountMobile: "",
      accountStreet: "",
      accountCountry: "",
      accountPincode: null,
      accountWebsite: "",
      accountLinkedin: "",
      accountOwnership: "",
      accountBusinessStatus: "",
      accountBusinessCategory: "",
      accountImage: "",
      accountImageCompressed: "",
    },
    requiredFields: ["accountName", "accountOwner"],
  },
  contactInputs: {
    allFields: {
      firstName: "",
      lastName: "",
      contactRole: "",
      contactEmail: "",
      contactPhone: "",
      contactOwner: "",
      contactOwnerName: "",
      description: "",
      contactMobile: "",
      contactWebsite: "",
      billingCountry: "",
      billingState: "",
      billingCity: "",
      billingPincode: null,
      billingStreet: "",
      shippingCountry: "",
      shippingState: "",
      shippingCity: "",
      shippingPincode: null,
      shippingStreet: "",
      contactLinkedin: "",
      contactLeadSource: "",
      contactLinkedAccount: "",
      contactImage: "",
      contactImageCompressed: "",
    },
    requiredFields: ["firstName", "lastName", "contactOwner"],
  },
  dealInputs: {
    allFields: {
      dealName: "",
      dealType: "",
      dealOwner: "",
      dealOwnerName: "",
      dealStage: "",
      dealAmount: 0,
      description: "",
      dealNextStep: "",
      dealLeadStatus: "",
      dealAccountName: "",
      dealContactName: "",
      dealClosingDate: "",
      dealProbability: 0,
      dealCampaignSource: "",
      dealExpectedRevenue: 0,
      dealImage: "",
      dealImageCompressed: "",
    },
    requiredFields: ["dealName", "dealOwner"],
  },
  callInputs: {
    allFields: {
      callAccountName: "",
      callContactName: "",
      callType: "",
      // outgoingCallStatus: "",
      callStartDate: "",
      callStartTime: "",
      callEndTime: "",
      callOwner: null,
      callOwnerName: "",
      callSubject: "",
      // callReminder: "",
      callPurpose: "",
      callAgenda: "",
      callDuration: null,
      callRelatedTo: "",
    },
    requiredFields: [
      "callSubject",
      "callContactName",
      "callStartDate",
      "callStartTime",
      "callEndTime",
      "callOwner",
    ],
  },
  meetingInputs: {
    allFields: {
      meetingTitle: "",
      meetingStartTime: "",
      meetingEndTime: "",
      meetingContacts: [],
      meetingUsers: [],
      meetingLocation: "",
      meetingType: "",
      meetingLink: "",
      meetingDescription: "",
      meetingNotes: "",
      meetingHostId: "",
      meetingDealId: "",
      meetingLeadId: "",
      meetingAccountId: "",
      meetingContactId: "",
      meetingStatus: "",
    },
    requiredFields: [
      "meetingTitle",
      "meetingStartTime",
      "meetingEndTime",
      "meetingType",
      // "meetingLink",
    ],
  },
  campaignInput: {
    allFields: {
      name: "",
      type: "",
      status: "",
      endDate: "",
      leadIds: [],
      dealIds: [],
      contactIds: [],
      startDate: "",
      actualCost: "",
      numbersSent: "",
      description: "",
      budgetedCost: "",
      campaignOwner: "",
      expectedRevenue: "",
      expectedResponse: "",
    },
    requiredFields: ["name", "type", "campaignOwner"],
  },
  templateInput: {
    module: "",
    templateName: "",
    subject: "",
    html: "",
    htmlJson: {},
    favorites: true,
    active: true,
    requiredFields: ["templateName", "subject"],
  },
  userInputs: {
    allFields: {
      userRole: "",
      userCity: "",
      userEmail: "",
      userAlias: "",
      userImage: "",
      userState: "",
      userMobile: "",
      userStreet: "",
      userGroupId: "",
      userCountry: "",
      userTimeZone: "",
      is_active: false,
      userLastName: "",
      userFirstName: "",
      userLastLogin: "",
      userZipCode: null,
      userWorkPhone: "",
      reportingToId: "",
      userTimeFormat: "",
      userProfileId: "",
      userDateFormat: "",
      userProfileName: "",
      userDateOfBirth: null,
      userImageCompressed: "",
    },
    requiredFields: [
      "userEmail",
      "userFirstName",
      "userLastName",
      "userProfileId",
    ],
  },

  groupsInputs: {
    allFields: {
      groupName: "",
      groupDescription: "",
      groupUsers: [],
    },
    requiredFields: ["groupName"],
  },
  contractInput: {
    allFields: {
      uploadedFiles: [],
      contractDealId: "",
      contracts_name: "",
    },
    requiredFields: ["contractDealId"],
  },
  profileInputs: {
    allFields: {
      profileName: "",
      cloneProfile: "",
      profileDescription: "",
      profileUserAccess: "",
    },
    requiredFields: ["profileName", "cloneProfile"],
  },
  companyInputs: {
    allFields: {
      companyName: "",
      companyEmail: " ",
      companyMobile: " ",
      companyLink: " ",
      companyLocation: " ",
      companyLanguage: " ",
      companyCountryLocale: " ",
      companyDateFormat: " ",
      companyTimeFormat: " ",
      companyTimeZone: " ",
      companyCurrency: " ",
      companyWebsite: " ",
      companyAccessUrl: " ",
      companyLogo: " ",
      is_active: false,
      companySenderEmail: "",
      // companyEmailHost:"",
      companyEmailUserName: "",
      companyEmailPassword: "",
      companyEmailPort: "",
    },
    requiredFields: [
      // "companyName",
      "companyEmail",
      "companyLink",
      "companyMobile",
    ],
  },

  webFormInputs: {
    createWebForm: {
      formName: "",
      module: "",
    },
  },

  emailTemplateInput: {
    createEmailTemplate: { module: "", templateName: "", subject: "" },
  },

  extensionInputs: {
    extensionAuthorization: {
      accountId: "",
      clientId: "",
      clientSecret: "",
    },
    requiredFields: ["apiKey", "apiSecret"],
  },

  tokenInputs: {
    token: "",
    requiredFields: ["token"],
  },

  ChannelInputs: {
    channelAuthorization: {
      channel: "",
      token: "",
    },
    requiredFields: ["channel", "token"],
  },

  APIFieldMappings: {
    contract: {
      uploadedFiles: "contracts_files",
      contractDealId: "contracts_deal",
      contracts_name: "contracts_name",
    },
    contact: {
      firstName: "contact_firstname",
      lastName: "contact_lastname",
      contactRole: "contact_role",
      contactEmail: "contact_email",
      contactPhone: "contact_phone",
      contactOwner: "contact_owner",
      contactOwnerName: "contact_owner_name",
      description: "contact_description",
      contactMobile: "contact_mobile",
      contactWebsite: "contact_website",
      billingCountry: "contact_billing_country",
      billingState: "contact_billing_state",
      billingCity: "contact_billing_city",
      billingPincode: "contact_billing_pincode",
      billingStreet: "contact_billing_street",
      shippingCountry: "contact_shipping_country",
      shippingState: "contact_shipping_state",
      shippingCity: "contact_shipping_city",
      shippingPincode: "contact_shipping_pincode",
      shippingStreet: "contact_shipping_street",
      contactLinkedin: "contact_linkedin",
      contactLeadSource: "contact_lead_source",
      contactLinkedAccount: "contact_account_id",
      contactImage: "contact_image",
      contactImageCompressed: "contact_image_compressed",
    },
    account: {
      accountName: "account_name",
      accountCity: "account_city",
      accountType: "account_type",
      accountRole: "account_role",
      accountOwner: "account_owner",
      accountImage: "account_image",
      accountState: "account_state",
      accountEmail: "account_email",
      accountPhone: "account_phone",
      accountMobile: "account_mobile",
      accountStreet: "account_street",
      accountCountry: "account_country",
      accountWebsite: "account_website",
      accountPincode: "account_pincode",
      accountOwnership: "ownership_type",
      description: "account_description",
      accountLinkedin: "account_linkedin",
      accountOwnerName: "account_owner_name",
      accountBusinessStatus: "business_status",
      accountBusinessCategory: "business_category",
      accountImageCompressed: "account_image_compressed",
    },
    lead: {
      email: "lead_email",
      leadImage: "lead_image",
      leadOwner: "lead_owner",
      firstName: "lead_firstname",
      lastName: "lead_lastname",
      leadRole: "lead_role",
      leadPhone: "lead_phone",
      leadMobile: "lead_mobile",
      status: "lead_status",
      leadAccountId: "lead_account_id",
      leadOwnerName: "lead_owner_name",
      leadSource: "lead_source",
      shippingCountry: "lead_country",
      shippingState: "lead_state",
      shippingCity: "lead_city",
      shippingPincode: "lead_pin",
      shippingStreet: "lead_street",
      leadTitle: "lead_title",
      industry: "lead_industry",
      description: "lead_description",
      leadImageCompressed: "lead_image_compressed",
    },
    deal: {
      dealName: "deal_name",
      dealType: "deal_type",
      dealOwner: "deal_owner",
      dealOwnerName: "deal_owner_name",
      dealStage: "deal_stage",
      dealAmount: "deal_amount",
      description: "deal_description",
      dealNextStep: "deal_nextstep",
      dealLeadStatus: "deal_status",
      dealAccountName: "deal_account",
      dealContactName: "deal_contact",
      dealClosingDate: "deal_closed_at",
      dealProbability: "deal_probability",
      dealCampaignSource: "deal_campaign_source",
      dealExpectedRevenue: "deal_expected_revenue",
      // businessCategory:"",
      dealImage: "deal_image",
      dealImageCompressed: "deal_image_compressed",
      lossReason: "deal_reason_for_loss",
      reasonDetail: "deal_reason_detail_for_loss",
    },
    task: {
      // taskReminder: "task_reminder",
      taskOwner: "task_owner",
      taskOwnerName: "task_owner_name",
      taskStatus: "task_status",
      description: "task_description",
      taskSubject: "task_subject",
      closingDate: "task_closing_date",
      taskPriority: "task_priority",
      taskLeads: "task_leads",
      taskDeals: "task_deals",
      followupDate: "task_follow_up",
    },
    call: {
      callContactName: "call_contact",
      callAccountName: "call_account",
      callType: "call_type",
      outgoingCallStatus: "outgoing_call_status",
      callStartTime: "call_start_time",
      callEndTime: "call_end_time",
      callStartDate: "call_start_date",
      callOwner: "call_owner",
      callOwnerName: "call_owner_name",
      callSubject: "call_subject",
      callReminder: "call_reminder",
      callPurpose: "call_purpose",
      callAgenda: "call_agenda",
      callDuration: "call_duration",
      callRelatedTo: "call_related_to",
    },
    meeting: {
      meetingTitle: "meeting_title",
      meetingStartTime: "meeting_from",
      meetingEndTime: "meeting_to",
      meetingContacts: "meeting_contacts",
      meetingUsers: "meeting_users",
      meetingLocation: "meeting_location",
      meetingType: "meeting_type",
      meetingLink: "meeting_link",
      meetingDescription: "meeting_description",
      meetingNotes: "meeting_notes",
      meetingHostId: "meeting_host_id",
      meetingDealId: "meeting_deal_id",
      meetingLeadId: "meeting_lead_id",
      meetingAccountId: "meeting_account_id",
      meetingContactId: "meeting_contact_id",
      meetingStatus: "meeting_status",
      meetingDuration: "meeting_duration",
    },
    campaign: {
      name: "name",
      type: "type",
      status: "status",
      leadIds: "lead_ids",
      dealIds: "deal_ids",
      endDate: "end_date",
      startDate: "start_date",
      contactIds: "contact_ids",
      description: "description",
      actualCost: "actual_cost",
      numbersSent: "numbers_sent",
      budgetedCost: "budgeted_cost",
      campaignOwner: "campaign_owner",
      expectedRevenue: "expected_revenue",
      expectedResponse: "expected_response",
    },
    template: {
      module: "module",
      templateName: "name",
      subject: "subject",
      html: "body",
      htmlJson: "body_json",
      favorites: "favorites",
      active: "active",
    },

    user: {
      userRole: "user_role",
      userCity: "user_city",
      is_active: "is_active",
      userEmail: "user_email",
      userState: "user_state",
      userAlias: "user_alias",
      userImage: "user_image",
      userMobile: "user_mobile",
      userStreet: "user_street",
      userCountry: "user_country",
      userZipCode: "user_zip_code",
      userGroupId: "user_group_id",
      userLastName: "user_lastname",
      userTimeZone: "user_time_zone",
      userFirstName: "user_firstname",
      userCreatedBy: "user_created_by",
      userProfileId: "user_profile_id",
      userLastLogin: "user_last_login",
      userWorkPhone: "user_work_phone",
      userDateFormat: "user_date_format",
      userTimeFormat: "user_time_format",
      reportingToId: "user_reporting_id",
      userProfileName: "user_profile_name",
      userDateOfBirth: "user_date_of_birth",
      userImageCompressed: "user_image_compressed",
    },

    group: {
      groupName: "group_name",
      groupDescription: "group_description",
      groupUsers: "group_users",
    },
    profile: {
      profileName: "profile_name",
      cloneProfile: "profile_clone_id",
      profileDescription: "profile_description",
      profileUserAccess: "profile_user_access",
    },

    company: {
      companyName: "company_name",
      companyEmail: "company_email",
      companyMobile: "company_mobile",
      companyLink: "company_link",
      companyLocation: "company_location",
      companyLanguage: "company_language",
      companyCountryLocale: "company_country_locale",
      companyDateFormat: "company_date_format",
      companyTimeFormat: "company_time_format",
      companyTimeZone: "company_time_zone",
      companyCurrency: "company_currency",
      companyWebsite: "company_website",
      companyAccessUrl: "company_access_url",
      companyLogo: "company_logo",
      companySenderEmail: "company_sender_email",
      // companyEmailHost:"company_email_host",
      companyEmailUserName: "company_email_username",
      companyEmailPassword: "company_email_password",
      companyEmailPort: "company_email_port",
    },

    extension: {
      accountId: "account_id",
      clientId: "client_id",
      clientSecret: "client_secret",
    },

    tokenAuth: {
      token: "token",
    },

    channelToken: {
      channel: "channel",
      token: "token",
    },

    signup: {
      // TODO : SignUp API Request Body
      name: "signup_name",
      email: "signup_email",
      password: "signup_password",
      company_name: "signup_domain",
      phone_number: "signup_phone",
    },
  },

  modules: [
    { name: "Leads", value: "leads", singular: "lead" },
    {
      name: "Deals",
      value: "deals",
      singular: "deal",
    },
    {
      name: "Accounts",
      value: "accounts",
      singular: "account",
    },
    {
      name: "Contacts",
      value: "contacts",
      singular: "contact",
    },
  ],

  inputTypeMappings: {
    1: "single-line",
    2: "multi-line",
    3: "number-input",
    4: "phone-input",
    5: "",
    6: "dropdown-select",
    7: "date-input",
    8: "check-box",
  },

  inpuFieldSettings: [
    {
      dnd: true,
      enabled: true,
      required: false,

      inputType: "number",
      numberOfChar: 100,

      type: "number-input",
      label: "Example field",
      id: "exampleField1",
      name: "example_field",
    },
    {
      dnd: true,
      enabled: true,
      required: false,

      inputType: "number",
      numberOfChar: 100,

      type: "phone-input",
      label: "Example field",
      id: "exampleField2",
      name: "example_field",
    },
    {
      dnd: true,
      enabled: true,
      required: false,

      inputType: "text",
      numberOfChar: 100,

      type: "date-input",
      label: "Example field",
      id: "exampleField3",
      name: "example_field",
    },
    {
      dnd: true,
      enabled: true,
      required: false,

      inputType: "text",
      numberOfChar: 100,

      type: "single-line",
      label: "single line field",
      id: "exampleField4",
      name: "example_field",
    },
    {
      dnd: false,
      enabled: false,
      required: false,

      inputType: "text",
      numberOfChar: 100,

      type: "multi-line",
      label: "Example field",
      id: "exampleField5",
      name: "example_field",
    },
    {
      dnd: true,
      enabled: true,
      required: false,
      inputType: "checkbox",
      // numberOfChar: 100,
      type: "check-box",
      label: "Example field",
      id: "exampleField8",
      name: "example_field",
    },
    {
      dnd: true,
      enabled: true,
      required: false,

      type: "dropdown-select",
      label: "dropdown field",
      id: "exampleField6",
      name: "example_field",
      options: [
        {
          name: "Value-1",
        },
        {
          name: "Value-2",
        },
      ],
    },
  ],

  customInputTitleMapper: {
    "number-input": "Number",
    "phone-input": "Phone",
    "date-input": "Date",
    "single-line": "Single Line",
    "multi-line": "Multi Line",
    "check-box": "Checkbox",
  },

  customInputTypesAndProperties: {
    leads: [
      {
        type: "single-line",
        id: "leadCreator",
        name: "lead_creator",
        label: "Lead Creator",
        required: false,
      },
      {
        type: "multi-line",
        id: "leadAuthor",
        name: "lead_author",
        label: "Lead Author",
        required: false,
      },
      {
        type: "dropdown-select",
        id: "country",
        name: "lead_country",
        label: "country",
        required: false,
        options: [
          {
            name: "India",
            value: "india",
          },
          {
            name: "USA",
            value: "usa",
          },
        ],
      },
    ],
  },

  customInputTypesAndProperties: {
    leads: [
      {
        type: "single-line",
        id: "leadCreator",
        name: "lead_creator",
        label: "Lead Creator",
        required: false,
      },
      {
        type: "multi-line",
        id: "leadAuthor",
        name: "lead_author",
        label: "Lead Author",
        required: false,
      },
      {
        type: "dropdown-select",
        id: "country",
        name: "lead_country",
        label: "country",
        required: false,
        options: [
          {
            name: "India",
            value: "india",
          },
          {
            name: "USA",
            value: "usa",
          },
        ],
      },
    ],
  },

  webformInputFieldSettings: [
    {
      dnd: true,
      hidden: false,
      required: true,

      inputType: "number",
      numberOfChar: 100,

      type: "number-input",
      label: "Example field",
      id: "exampleField1",
      name: "example_field",
    },
    {
      dnd: true,
      hidden: false,
      required: true,

      inputType: "number",
      numberOfChar: 100,

      type: "phone-input",
      label: "Example field",
      id: "exampleField2",
      name: "example_field",
    },
    {
      dnd: true,
      hidden: false,
      required: true,

      inputType: "text",
      numberOfChar: 100,

      type: "date-input",
      label: "Example field",
      id: "exampleField3",
      name: "example_field",
    },
    {
      dnd: true,
      hidden: false,
      required: true,

      inputType: "text",
      numberOfChar: 100,

      type: "single-line",
      label: "single line field",
      id: "exampleField4",
      name: "example_field",
    },
    {
      dnd: false,
      hidden: false,
      required: true,

      inputType: "text",
      numberOfChar: 100,

      type: "multi-line",
      label: "Example field",
      id: "exampleField5",
      name: "example_field",
    },

    {
      dnd: true,
      hidden: false,
      required: true,

      type: "dropdown-select",
      label: "dropdown field",
      id: "exampleField6",
      name: "example_field",
      options: [
        {
          name: "Value-1",
          value: "value-1",
        },
        {
          name: "Value-2",
          value: "value-2",
        },
      ],
    },
  ],

  dropdownOptions: {
    industry: [
      { name: "IT Technology", value: "IT technology" },
      { name: "Finance", value: "finance" },
      { name: "Fintech", value: "fintech" },
      { name: "Food and Beverage", value: "food and beverage" },
      { name: "Others", value: "others" },
    ],
  },

  workflowRules: {
    scheduling: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    actionMappings: [
      {
        value: "webhook_trigger",
        display_name: "Webhook",
      },
      {
        value: "reassign",
        display_name: "Reassign",
      },
      {
        value: "send_mail",
        display_name: "Send email",
      },
    ],
    generalSettings: {
      name: "",
      description: "",
      active: true,
      module: "",
      eventType: ["created", "updated", "created_or_updated", "deleted"],
      matchType: ["any", "all"],
      actionType: ["immediate", "delayed"],
    },
    conditions: {
      field_name: "",
      operator: "",
      value: [],
    },
    actions: [
      {
        type: "reassign",
        properties: {
          value: 0,
          field_name: "",
          scheduled_at: null,
        },
      },
      {
        type: "send_mail",
        properties: {
          email_to: [],
          email_cc: [],
          email_bcc: [],
          email_body: "",
          scheduled_at: null,
          email_subject: "",
        },
      },
      {
        type: "webhook_trigger",
        properties: {
          url: "",
          auth: {},
          content: {},
          scheduled_at: null,
          request_type: "GET",
          custom_headers: {
            content_type: "application/json",
          },
        },
      },
    ],

    wfAPIMapping: {
      real: {
        name: "",
        description: "",
        active: true,
        module: "",
        event: "created",
        match_type: "any",
        conditions: [],
        actions: [],
      },
      virtual: {
        actionPref: "immediate",
        conditioned: false,
        action_type: "reassign",
      },
    },
  },

  emailTemplating: {
    newMailTemplateInput: {
      template_name: "",
      template_body: "",
      template_module: "",
      template_subject: "",
      template_active: true,
      template_description: "",
    },
    requiredFields: [
      "template_name",
      "template_body",
      "template_module",
      "template_subject",
    ],
  },

  calendarTimingInfo: [
    { colour: "#55B1EE", text: "0 to 2 hours of meeting" },
    { colour: "#FFAF6A", text: "2 to 4 hours of meeting" },
    { colour: "#FF9B97", text: "4 hours and above" },
  ],

  fixedFilters: {
    leads: [
      { name: "All leads", value: "All Leads" },
      { name: "My leads", value: "My Leads" },
      { name: "My converted leads", value: "My Converted Leads" },
      { name: "Open", value: "Open" },
      { name: "Converted", value: "Converted" },
    ],
    accounts: [
      { name: "All accounts", value: "All Accounts" },
      { name: "My accounts", value: "My Accounts" },
      { name: "New last week", value: "New Last Week" },
      { name: "New this week", value: "New This Week" },
      { name: "Recently created", value: "Recently Created Accounts" },
      {
        name: "Recently modified",
        value: "Recently Modified Accounts",
      },
    ],
    contacts: [
      { name: "All contacts", value: "All Contacts" },
      { name: "My contacts", value: "My Contacts" },
      { name: "New last week", value: "New Last Week" },
      { name: "New this week", value: "New This Week" },
      { name: "Recently created", value: "Recently Created Contacts" },
      {
        name: "Recently modified",
        value: "Recently Modified Contacts",
      },
    ],
    deals: [
      { name: "All deals", value: "All Deals" },
      { name: "My deals", value: "My Deals" },
      { name: "My open deals", value: "My Open Deals" },
      { name: "Open deals", value: "Open Deals" },
    ],
    tasks: [
      { name: "All tasks", value: "All Tasks" },
      // { name: "Open tasks", value: "Open Tasks" },
      { name: "Closed tasks", value: "Closed Tasks" },
      { name: "Overdue tasks", value: "Overdue Tasks" },
      { name: "My today's tasks", value: "My Todays Tasks" },
      { name: "My tasks", value: "My Tasks" },
      { name: "My tomorrow's tasks", value: "My Tomorrows Tasks" },
      // { name: "My open tasks", value: "My Open Tasks" },
      { name: "My closed tasks", value: "My Closed Tasks" },
      { name: "My overdue tasks", value: "My Overdue Tasks" },
    ],
    calls: [
      { name: "All calls", value: "All Calls" },
      { name: "My calls", value: "My Calls" },
      // { name: "Attended calls", value: "Attended Calls" },
      // { name: "Cancelled calls", value: "Cancelled Calls" },
      // { name: "Completed calls", value: "Completed Calls" },
      { name: "Incoming calls", value: "Incoming Calls" },
      { name: "Missed calls", value: "Missed Calls" },
      { name: "Outgoing calls", value: "Outgoing Calls" },
      // { name: "Overdue calls", value: "Overdue Calls" },
      // { name: "Scheduled calls", value: "Scheduled Calls" },
    ],
    meetings: [
      { name: "All meetings", value: "All Meetings" },
      { name: "Cancelled meetings", value: "Cancelled Meetings" },
      { name: "Completed meetings", value: "Completed Meetings" },
      { name: "My meetings", value: "My Meetings" },
      { name: "Today's meetings", value: "Todays Meetings" },
      { name: "My today's meetings", value: "My Todays Meetings" },
      { name: "Upcoming meetings", value: "Upcoming Meetings" },
      { name: "This week's meetings", value: "This Weeks Meetings" },
    ],
    templates: [
      { name: "All templates", value: "All Templates" },
      { name: "My templates", value: "My Templates" },
      { name: "Favorites", value: "Favorites" },
    ],
    campaigns: [
      { name: "All campaigns", value: "All Campaigns" },
      { name: "My campaigns", value: "My Campaigns" },
      { name: "All active campaigns", value: "All Active Campaigns" },
      { name: "My active campaigns", value: "My Active Campaigns" },
    ],
    users: [
      { name: "Active users", value: "Active Users" },
      { name: "All users", value: "All Users" },
      { name: "My users", value: "My Users" },
      { name: "New users", value: "New Users" },
    ],
    groups: [
      { name: "All groups", value: "All Groups" },
      { name: "My groups", value: "My Groups" },
      { name: "New groups", value: "New Groups" },
      // { name: "Recently contacted groups", value: "Recently Contacted Groups" },
    ],
  },
  dateFilter: [
    { name: "All time", value: "All_Days" },
    { name: "Today", value: "Today" },
    { name: "Yesterday", value: "Yesterday" },
    { name: "Last 7 days", value: "Last_7_days" },
    { name: "Last 30 days", value: "Last_30_days" },
    { name: "This month", value: "This_month" },
    { name: "Last month", value: "Last_month" },
    { name: "Custom range", value: "Custom_Range" },
  ],
  recycleBinModules: [
    { name: "Leads", value: "leads" },
    { name: "Accounts", value: "accounts" },
    { name: "Contacts", value: "contacts" },
    { name: "Deals", value: "deals" },
    { name: "Tasks", value: "tasks" },
    { name: "Calls", value: "calls" },
    { name: "Meetings", value: "meetings" },
    // { name: "Users", value: "users" },
    // { name: "Groups", value: "groups" },
  ],

  APIColumnMappings: {
    leads: {
      lead_fullname: "Lead name",
      lead_role: "Role",
      created_at: "Date",
      lead_city: "City",
      lead_company: "Company",
      lead_country: "Country",
      lead_description: "Description",
      lead_email: "Email",
      lead_firstname: "First name",
      lead_id: "Lead Id",
      lead_industry: "Industry",
      lead_lastname: "Last name",
      lead_mobile: "Mobile",
      lead_owner: "Owner",
      lead_owner_name: "Lead owner",
      lead_phone: "Phone",
      lead_pin: "Pin code",
      lead_source: "Source",
      lead_state: "State",
      lead_status: "Lead status",
      lead_street: "Street",
      lead_title: "Title",
      lead_account_name: "Account",
      updated_at: "Updated",
    },
    contacts: {
      contact_id: "Contact id",
      contact_owner: "Owner",
      contact_owner_name: "Contact owner",
      contact_lead_source: "Source",
      contact_firstname: "First name",
      contact_lastname: "Last name",
      contact_fullname: "Contact full name",
      contact_role: "Role",
      contact_account_name: "Account name",
      contact_email: "Email",
      contact_phone: "Phone",
      contact_mobile: "Mobile",
      contact_website: "Website",
      contact_linkedin: "Linkedin",
      contact_billing_country: "Billing country",
      contact_billing_state: "Billing state",
      contact_billing_city: "Billing city",
      contact_billing_pincode: "Billing pincode",
      contact_billing_street: "Billing street",
      contact_shipping_country: "Shipping country",
      contact_shipping_state: "Shipping state",
      contact_shipping_city: "Shipping city",
      contact_shipping_pincode: "Shipping pincode",
      contact_shipping_street: "Shipping street",
      contact_description: "Description",
      updated_at: "Updated",
      created_at: "Date",
    },
    accounts: {
      account_id: "Account id",
      account_name: "Account name",
      account_owner: "Owner",
      account_owner_name: "Account owner",
      account_type: "Account type",
      ownership_type: "Ownership type",
      business_category: "Business category",
      business_status: "Business status",
      account_role: "Role",
      account_email: "Email",
      account_phone: "Phone",
      account_mobile: "Mobile",
      account_website: "Website",
      account_linkedin: "LinkedIn",
      account_country: "Country",
      account_state: "State",
      account_city: "City",
      account_pincode: "Pincode",
      account_street: "Street",
      account_description: "Description",
      updated_at: "Updated",
      created_at: "Date",
    },
    deals: {
      deal_name: "Deal name",
      deal_owner: "Owner",
      deal_owner_name: "Deal owner",
      deal_amount: "Amount",
      deal_closed_at: "Closing date",
      deal_stage: "Deal stage",
      deal_nextstep: "Deal next step",
      deal_expected_revenue: "Expected revenue",
      deal_type: "Type",
      deal_probability: "Probability",
      deal_status: "Status",
      deal_campaign_source: "Campaign source",
      deal_account_name: "Account name",
      deal_contact_name: "Contact name",
      deal_description: "Description",
      deal_lead_id: "Deal lead id",
      deal_id: "Deal id",
      created_at: "Date",
      updated_at: "Updated",
      deal_reason_for_loss: "Deal lost reason",
      deal_reason_detail_for_loss: "Lost reason details",
    },
    tasks: {
      task_id: "Task Id",
      task_owner: "Owner",
      task_owner_name: "Task owner",
      task_subject: "Subject",
      task_closing_date: "Closing date",
      task_status: "Status",
      task_priority: "Priority",
      task_reminder: "Reminder",
      task_follow_up: "Follow up",
      task_description: "Description",
      task_leads: "Task leads",
      task_deals: "Task deals",
      task_accounts: "Task accounts",
      task_contacts: "Task contacts",
      created_at: "Date",
      updated_at: "Updated",
    },
    calls: {
      call_id: "Call id",
      call_contact_name: "Contact name",
      call_account_name: "Account name",
      call_type: "Call type",
      outgoing_call_status: "Outgoing call status",
      call_start_date: "Call start date",
      call_start_time: "Call start time",
      call_end_time: "Call end time",
      call_owner: "Owner",
      call_owner_name: "Call Owner",
      call_subject: "Subject",
      call_reminder: "Reminder",
      call_purpose: "Purpose",
      call_agenda: "Agenda",
      call_duration: "Duration",
      call_related_to: "Related to",
      updated_at: "Updated",
      created_at: "Date",
    },
    meetings: {
      meeting_id: "Meeting id",
      meeting_title: "Title",
      meeting_from: "From",
      meeting_to: "To",
      meeting_host: "Host",
      meeting_location: "Location",
      meeting_link: "Link",
      meeting_description: "Description",
      meeting_notes: "Notes",
      meeting_deals: "Deals",
      meeting_leads: "Leads",
      meeting_users: "Users",
      meeting_contacts: "Contacts",
      created_at: "Date",
      updated_at: "Updated",
      meeting_status: "Status",
      meeting_account_name: "Account name",
      meeting_host_name: "Host name",
      meeting_host_email: "Host email",
      meeting_deal_name: "Deal name",
      meeting_lead_name: "Lead name",
      meeting_contact_name: "Contact name",
      meeting_type: "Meeting Type",
      meeting_duration: "Meeting Duration",
    },
    users: {
      user_id: "User id",
      user_firstname: "First name",
      user_lastname: "Last name",
      user_email: "Email",
      user_fullname: "User full name",
      user_title: "Title",
      user_mobile: "Mobile",
      user_last_login: "Last login",
      user_work_phone: "Phone",
      user_reporting_to: "Reporting to",
      user_groups: "Group",
      user_role: "Job Role",
      user_is_active: "Status",
      user_is_email_verified: "Verified",
      updated_at: "Updated at",
      user_profile_id: "Profile id",
      created_at: "Created at",
      user_profile_name: "Access profile",
      user_time_format: "User time format",
      user_date_format: "User date format",
    },
    campaigns: {
      name: "Campaign name",
      type: "Type",
      status: "Status",
      lead_ids: "Lead id",
      deal_ids: "Deal id",
      end_date: "End date",
      start_date: "Start date",
      contact_ids: "Contact id",
      description: "Description",
      actual_cost: "Actual cost",
      numbers_sent: "Numbers sent",
      budgeted_cost: "Budgeted cost",
      campaign_owner: "Campaign owner",
      expected_revenue: "Expected revenue",
      expected_response: "Expected response",
    },
    groups: {
      group_id: "Group id",
      group_name: "Group name",
      group_description: "Description",
      updated_at: "Updated",
      created_at: "Created at",
    },
    profiles: {
      profile_name: "Profile Name",
      profile_clone_id: "Clone Profile",
      profile_description: "Description",
    },
  },

  formattingColumns: {
    leads: ["created_at"],
    accounts: ["created_at"],
    contacts: ["created_at"],
    deals: ["deal_amount", "deal_expected_revenue"],
    tasks: [],
    calls: ["call_start_date", "call_duration", "created_at"],
    templates: ["active"],
    meetings: ["meeting_from", "meeting_to", "created_at", "meeting_duration"],
    users: ["user_is_active"],
    groups: [],
    profiles: [],
    campaigns: ["budgeted_cost", "expected_revenue", "actual_cost"],
  },
  recycleBinColumns: {
    leads: ["lead_id", "lead_fullname"],
    accounts: ["account_id", "account_name"],
    contacts: ["contact_id", "contact_fullname"],
    deals: ["deal_id", "deal_name"],
    tasks: ["task_id", "task_subject"],
    calls: ["call_id", "call_subject"],
    meetings: ["meeting_id", "meeting_title"],
    users: ["user_id", "user_fullname"],
    groups: ["group_id", "group_name"],
  },
  sortKeyForRecycleBin: {
    leads: "Lead name",
    accounts: "Account name",
    contacts: "Contact name",
    deals: "Deal name",
    tasks: "Task subject",
    calls: "Call subject",
    meetings: "Meeting title",
  },
  hiddenColumns: {
    leads: [
      "lead_id",
      "lead_fullname",
      "lead_firstname",
      "lead_lastname",
      "lead_owner",
      "updated_at",
      "lead_custom_fields_data",
      "deleted_at",
      "uuid",
      "lead_account_id",
      "lead_image",
      "lead_image_compressed",
      "lead_description",
    ],
    accounts: [
      "account_id",
      "account_name",
      "account_owner",
      "updated_at",
      "account_custom_fields_data",
      "deleted_at",
      "uuid",
      "account_image",
      "account_image_compressed",
      "account_description",
    ],
    contacts: [
      "contact_id",
      "contact_owner",
      "contact_account_id",
      "updated_at",
      "contact_custom_fields_data",
      "deleted_at",
      "uuid",
      "contact_image",
      "contact_fullname",
      "contact_image_compressed",
      "contact_description",
    ],
    deals: [
      "deal_id",
      "deal_name",
      "deal_owner",
      "deal_contact",
      "deal_account",
      "deal_lead_id",
      "updated_at",
      "deal_custom_fields_data",
      "deleted_at",
      "uuid",
      "created_by",
      "deal_image",
      "deal_image_compressed",
      "deal_nextstep",
      "deal_description",
    ],
    tasks: [
      "task_id",
      "task_subject",
      "task_reminder",
      "task_owner",
      "task_deals",
      "task_leads",
      "updated_at",
      "deleted_at",
      "uuid",
      "task_description",
    ],
    calls: [
      "call_id",
      "call_subject",
      "call_owner",
      "updated_at",
      "deleted_at",
      "uuid",
      "call_contact",
      "call_account",
      "call_description"
    ],
    meetings: [
      "meeting_id",
      "meeting_title",
      "meeting_owner",
      "meeting_users",
      "meeting_contacts",
      "meeting_account_id",
      "updated_at",
      "deleted_at",
      "uuid",
      "meeting_host_id",
      "meeting_deal_id",
      "meeting_lead_id",
      "meeting_account_id",
      "meeting_contact_id",
      "meeting_description"
      // "meeting_duration"
    ],
    templates: [
      "name",
      "body",
      "created_by",
      "id",
      "subject",
      "updated_at",
      "updated_by",
      "updated_by_name",
      "body_json",
      "favorites",
    ],
    users: [
      "uuid",
      "user_id",
      "updated_at",
      "created_by",
      "deleted_at",
      "updated_at",
      "user_firstname",
      "user_lastname",
      "user_fullname",
      "user_profile_id",
      "user_image",
      "user_group_id",
      "user_reporting_id",
      "user_image_compressed",
      "user_is_email_verified",
      "user_email_activation_link",
      "user_currency",
      "user_language",
      "user_date_of_birth",
      "user_website",
      "user_fax",
      "user_street",
      "user_country_locale",
      "user_time_zone",
      "user_date_format",
      "user_time_format",
    ],
    groups: [
      "uuid",
      "group_id",
      "group_name",
      "group_users",
      "updated_at",
      "created_at",
      "deleted_at",
    ],
    profiles: [
      "profile_id",
      "profile_user_access",
      "updated_at",
      "created_at",
      "deleted_at",
      "deleted_by",
      "uuid",
    ],
    campaigns: [
      "id",
      "name",
      "lead_ids",
      "deal_ids",
      "contact_ids",
      "updated_at",
      "created_by",
      "updated_by",
      "description",
      "deals_count",
      "leads_count",
      "campaign_owner",
      "contacts_count",
    ],
  },
  fixedFiltersOwnerFields: {
    leads: ["My Leads", "My Converted Leads"],
    deals: ["My Deals", "My Open Deals"],
    tasks: [
      "My Closed Tasks",
      "My Open Tasks",
      "My Overdue Tasks",
      "My Tasks",
      "My Todays Tasks",
      "My Tomorrows Tasks",
    ],
    contacts: [],
    calls: ["My Calls"],
    meetings: ["My Meetings", "My Todays Meetings"],
    templates: ["My Templates"],
    users: ["My Users"],
    campaigns: ["My Campaigns", "My Active Campaigns"],
  },
  salutations: ["None", "Mr.", "Mrs.", "Ms.", "Dr.", "Prof."],

  dealsStage: [
    { stage: "Select", nextStep: "", probability: null },
    { stage: "Qualification", nextStep: "Need analysis", probability: "20" },
    {
      stage: "Need analysis",
      nextStep: "Value preposition",
      probability: "40",
    },
    {
      stage: "Value preposition",
      nextStep: "Identify decision makers",
      probability: "50",
    },
    {
      stage: " Identify decision makers",
      nextStep: "Proposal/Price quote",
      probability: "60",
    },
    {
      stage: "Proposal/Price quote",
      nextStep: "Closed-won",
      probability: "80",
    },
    { stage: "Closed-won", nextStep: "Closed-won", probability: "100" },
  ],

  dealExpectedRevenue: [
    {
      dealAmount: "dealAmount",
    },
  ],
  primaryColumns: {
    leads: ["lead_id", "lead_firstname", "lead_lastname"],
    contacts: ["contact_id", "contact_fullname"],
    accounts: ["account_id", "account_name"],
    deals: ["deal_id", "deal_name"],
    tasks: ["task_id", "task_subject"],
    calls: ["call_id", "call_subject"],
    meetings: ["meeting_id", "meeting_title"],
    templates: ["id", "name", "favorites"],
    users: ["user_id", "user_firstname", "user_lastname"],
    campaigns: ["id", "name"],
  },
  operations: [
    "create",
    "edit",
    "clone",
    "delete",
    "rename",
    "user-deactivation",
  ],
  restrictedProfiles: ["Administrator", "Standard"],
  roles: {
    superAdmin: "SuperAdministrator",
  },
  hideViewMenu: ["edit", "clone", "leadsAdder", "dealsAdder", "contactsAdder"],
  templateFieldMapping: {
    templateName: "Name",
    subject: "Subject",
  },
};

function roughSizeOfObject(object) {
  var objectList = [];
  var stack = [object];
  var bytes = 0;
  while (stack.length) {
    var value = stack.pop();
    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);
      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

console.log("App Data", formatBytes(roughSizeOfObject(APP_DATA)));
