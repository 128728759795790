import React, { useEffect, useState } from "react";
import {
  getApiUsageAPI,
  getRecordsCountAPI,
  getIntegrationCountAPI,
  getStorageCountAPI,
  getUsersUsageAPI,
} from "../apis/usageAPI";
import { showValue, getUserAccessInfo, getLimit,getChartData } from "../utils";
import NoResults from "../components/common/noResults";

const UsageView = () => {
  const [usage, setUsage] = useState({
    api: null,
    records: null,
    integration: null,
    storage: null,
    users: null,
  });

  const [loading, setLoading] = useState({
    api: true,
    records: true,
    integration: true,
    storage: true,
    users: true,
  });

  const [usagePermission, setUsagePermission] = useState({});

  useEffect(() => {
    setUsagePermission(getUserAccessInfo("Usage")?.permission || {});
    getApiUsage();
    getRecords();
    getIntegration();
    getStorage();
    getUsers();
  }, []);

  const getApiUsage = async () => {
    try {
      const api = await getApiUsageAPI();
      if (api.status && api.data) {
        if (Object.keys(api.data)?.length > 0) {
          setUsage((prev) => ({ ...prev, api: api.data }));
        }
      }
      setLoading((prev) => ({ ...prev, api: false }));
    } catch (err) {
      setLoading((prev) => ({ ...prev, api: false }));
      setUsage((prev) => ({ ...prev, api: null }));
    }
  };

  const getRecords = async () => {
    try {
      const records = await getRecordsCountAPI();
      if (records.status && records.data) {
        if (Object.keys(records.data)?.length > 0) {
          setUsage((prev) => ({ ...prev, records: records.data }));
        }
      }
      setLoading((prev) => ({ ...prev, records: false }));
    } catch (err) {
      setUsage((prev) => ({ ...prev, records: null }));
      setLoading((prev) => ({ ...prev, records: false }));
      console.log(err);
    }
  };

  const getIntegration = async () => {
    try {
      const integration = await getIntegrationCountAPI();
      if (integration.status && integration.data) {
        if (Object.keys(integration.data)?.length > 0) {
          setUsage((prev) => ({
            ...prev,
            integration: integration.data,
          }));
        }
      }
      setLoading((prev) => ({ ...prev, integration: false }));
    } catch (err) {
      setUsage((prev) => ({ ...prev, integration: null }));
      setLoading((prev) => ({ ...prev, integration: false }));
    }
  };

  const getStorage = async () => {
    try {
      const storage = await getStorageCountAPI();
      if (storage.status && storage.data) {
        if (Object.keys(storage.data)?.length > 0) {
          setUsage((prev) => ({ ...prev, storage: storage.data }));
        }
      }
      setLoading((prev) => ({ ...prev, storage: false }));
    } catch (err) {
      setLoading((prev) => ({ ...prev, storage: false }));
      setUsage((prev) => ({ ...prev, storage: null }));
    }
  };

  const getUsers = async () => {
    try {
      const users = await getUsersUsageAPI();
      if (users.status && users.data) {
        if (Object.keys(users.data)?.length > 0) {
          setUsage((prev) => ({ ...prev, users: users.data }));
        }
      }
      setLoading((prev) => ({ ...prev, users: false }));
    } catch (err) {
      setLoading((prev) => ({ ...prev, users: false }));
      setUsage((prev) => ({ ...prev, users: null }));
    }
  };

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <div className="header_title">Usage</div>
        </div>
      </div>
      {usagePermission?.view ? (
        <div className="usg-info">
          <div className="usg-col blue">
            {loading?.storage ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 8,
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <div
                  className="loaderTheme-sm"
                  style={{
                    borderTop: "3px solid #0071bc",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h2>Storage</h2>
                <div className="str-grph">
                  <div className="chart-graph">
                <div className="outer-circle" style={{transform: `rotate(-${getChartData(usage?.storage?.meter_value, usage?.storage?.limit)}deg)`}}></div>
                <div className="inner-circle"></div>
                  <div className="usg-infr">
                    <span className="usg-lmt">Total Used</span>
                    <span className="used-value">
                      {showValue(usage?.storage?.count)}
                    </span>
                    <span className="usg-lmt">{`out of ${showValue(
                      getLimit(usage?.storage?.limit)
                    )} used`}</span>
                  </div>
                </div>
                </div>
              </>
            )}
          </div>
          <div className="usg-col violet">
            {loading?.records ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 8,
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <div
                  className="loaderTheme-sm"
                  style={{
                    borderTop: "3px solid #0071bc",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h2>Records</h2>
                <div className="btm-hlf">
                  <p>
                    <span className="count" style={{ margin: "2px 3px 0px" }}>
                      {showValue(usage?.records?.count)}
                    </span>
                    <span className="limit">
                      {"  / " + showValue(usage?.records?.limit)}
                    </span>
                  </p>
                  <i className="records"></i>
                </div>
              </>
            )}
          </div>
          <div className="usg-col pink">
            {loading?.api ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 8,
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <div
                  className="loaderTheme-sm"
                  style={{
                    borderTop: "3px solid #0071bc",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h2>API</h2>
                <div className="btm-hlf">
                  <p>
                    <span className="count" style={{ margin: "2px 3px 0px" }}>
                      {showValue(usage?.api?.count)}
                    </span>
                    <span className="limit">
                      {" / " + showValue(usage?.api?.limit) + " used"}
                    </span>
                  </p>
                  <i className="api"></i>
                </div>
              </>
            )}
          </div>
          <div className="usg-col brown">
            {loading?.integration ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 8,
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <div
                  className="loaderTheme-sm"
                  style={{
                    borderTop: "3px solid #0071bc",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h2>Integration</h2>
                <div className="btm-hlf">
                  <p>
                    <span className="count" style={{ margin: "2px 3px 0px" }}>
                      {showValue(usage?.integration?.count)}
                    </span>
                    <span className="limit">
                      {" / " + showValue(usage?.integration?.limit) + " used"}
                    </span>
                  </p>
                  <i className="integration"></i>
                </div>
              </>
            )}
          </div>
          <div className="usg-col blue-shade">
            {loading?.users ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 8,
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <div
                  className="loaderTheme-sm"
                  style={{
                    borderTop: "3px solid #0071bc",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <h2>Users</h2>
                <div className="btm-hlf">
                  <p>
                    <span className="count">{showValue(usage?.users?.count)} </span>
                    <span className="limit">  / {showValue(usage?.users?.limit)}</span>
                  </p>
                  <i className="users"></i>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <NoResults permission={!usagePermission?.view} />
      )}
    </>
  );
};

export default UsageView;
