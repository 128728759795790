import React from "react";

export default function InputPhone() {
  return (
    <React.Fragment>
      <div
        className="crm-form-field"
        style={{ display: "none" }}
        id={"inputType_4"}
      >
        <input
          type="text"
          className="crm-form-ctrl floating-input"
          placeholder="phone input"
        />
        <div className="crm-action">
          <a className="crm-action-opt crm-action-settings">
            <ul className="crm-dropdown-action ul">
              <li>
                <span className="crm-action-edit" data-link="edit">
                  Edit Properties
                </span>
              </li>
              <li>
                <span className="crm-action-delete" data-link="remove">
                  Remove
                </span>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
