import React from "react";
import DatePicker from "react-multi-date-picker";
import { APP_DATA } from "../../app_data";

const CustomFilterInputMapper = ({
  field,
  datatype,
  ddOption,
  crField,
  crValue,
  handlers,
}) => {
  switch (datatype) {
    case "string":
      return (
        <>
          <input
            type={"text"}
            data-type={datatype}
            className="crm-form-ctrl floating-input"
            id={`${field}_value`}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => handlers.handleValueChange(e, field, datatype)}
          />
          <label
            alt="Type here"
            htmlFor="typeHere"
            placeholder="Type here"
            className="floating-label"
          >
            Type here
          </label>
          <span className="crm-inp-error" id={`err_${field}_value`}></span>
        </>
      );
    case "numeric":
      if (field === "call_duration") {
        return (
          <>
            <input
              style={{ width: "100px" }}
              type="time"
              className="crm-form-ctrl floating-input"
              id={`${field}_value`}
              autoComplete="off"
              placeholder=" "
              onChange={(e) => handlers.handleValueChange(e, field, datatype)}
            />
            <span className="crm-inp-error" id={`err_${field}_value`}></span>
          </>
        );
      }
      return (
        <>
          <input
            type="number"
            className="crm-form-ctrl floating-input"
            id={`${field}_value`}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => handlers.handleValueChange(e, field, datatype)}
            min={0}
            onKeyDown={function (e) {
              if (APP_DATA.invalidNumberChars.includes(e.key)) {
                e.preventDefault();
                return false;
              }
            }}
          />
          <label
            alt="Type here"
            htmlFor="typeHere"
            placeholder="Type here"
            className="floating-label"
          >
            Type here
          </label>
          <span className="crm-inp-error" id={`err_${field}_value`}></span>
        </>
      );
    case "picklist":
      return (
        <>
          <select
            className="crm-form-ctrl floating-select"
            id={`${field}_value`}
            onChange={(e) =>
              handlers.handleSelectValueChange(e, field, datatype)
            }
          >
            <option value="">Please select an option</option>
            {ddOption.hasOwnProperty(field) &&
              ddOption[field]?.map((o) => <option value={o}>{o}</option>)}
          </select>

          <label
            alt="Value"
            htmlFor=""
            placeholder="Value"
            className="floating-label"
          ></label>
          <span className="crm-inp-error" id={`err_${field}_value`}></span>
        </>
      );
    case "date":
      if (crField.includes(field)) {
        return (
          <>
            <DatePicker
              range
              id={`${field}_value`}
              value={crValue[field]}
              style={{
                height: "42px",
                width: "265px",
              }}
              placeholder="yyyy/mm/dd ~ yyyy/mm/dd"
              onChange={(v) => handlers.handleCustomRangeValueChange(v, field)}
            />
            <span className="crm-inp-error" id={`err_${field}_value`}></span>
          </>
        );
      }
      return (
        <>
          <input
            type="date"
            max={datatype === "date" && "9999-12-12"}
            data-type={datatype}
            className="crm-form-ctrl floating-input"
            id={`${field}_value`}
            autoComplete="off"
            placeholder=" "
            onChange={(e) => handlers.handleValueChange(e, field, datatype)}
          />
          <span className="crm-inp-error" id={`err_${field}_value`}></span>
        </>
      );
  }
};

export default CustomFilterInputMapper;
