import axios from "axios";
import { APISuccessResponse, axiosPrivate, getAuthToken } from ".";
import { API_URL } from ".";

export const createNewWFRule = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/rules/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAllWFRulesAPI = async (sortBy, sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/rules/?rule_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getWFRuleByIdAPI = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/rules/id/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateWFRuleAPI = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/rules/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getWFOperatorsAPI = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/rules/fetch_operators", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getModuleDataTypesAPI = async (moduleName) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(API_URL + `/rules/fetch_data_types?module=${moduleName}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getEmailTemplatesByIdAPI = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/email_templates/id/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteWFRuleByAPI = (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/rules/?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
