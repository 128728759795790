import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import NoResults from "../components/common/noResults";
import { getUserAccessInfo, echo, overrideDefaultEvent } from "../utils";
import { getCompany, updateCompanyById } from "../apis/companyAPI";
import { Tooltip } from "react-tooltip";
import { APP_DATA } from "../app_data";
import { deleteImageAPI } from "../apis/utilsAPI";
import FullPageLoader from "../components/common/fullPageLoader";

export default function CompanyDetailsView() {
  const location = useLocation();
  const { openToast, openModal, closeModal, handleRSidebar } =
    useOutletContext();

  const formData = useRef({});

  const [src, setImageSource] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    const company = getUserAccessInfo("Company");
    if (company?.permission) {
      setPermissions(company?.permission);
    } else {
      setPermissions({});
    }
    getCompanyInfo();
  }, [location.search]);

  const getCompanyInfo = async () => {
    const company = await getCompany();
    if (company.status) {
      setCompanyDetails(company.data);
      setImageSource(company.data.company_logo);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    } else {
      // openToast("Network problem detected,Please check", "error");
    }
  };

  const handleCopyClick = (e) => {
    const text = e.target.dataset.token;
    if (text) {
      navigator.clipboard.writeText(text);
      if (e.target.id === "companyTokenCopy") {
        document.getElementById(e.target.id + "-s").style.display = "block";
        setTimeout(() => {
          document.getElementById(e.target.id + "-s").style.display = "none";
        }, 3000);
      } else {
        document.getElementById(e.target.id + "-s").innerText = "Copied";
        setTimeout(() => {
          document.getElementById(e.target.id + "-s").innerText = "Copy";
        }, 3000);
      }
    } else {
      openToast("No text found to copy");
    }
  };

  const setImageSrc = async (src) => {
    setImageSource(src.companySrc);
    const updateCompany = await updateCompanyById({
      id: companyDetails.id,
      [APP_DATA.APIFieldMappings.company.companyLogo]: src.companyLogo,
    });
    if (updateCompany.status) {
      getCompanyInfo();
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    } else {
      // openToast("Network problem detected, Please check", "error");
    }
  };

  const handleSelectLogo = () => {
    openModal("uploadImageModal", {
      module: "companyDetailsView",
      moduleName: "company",
      setImageSrc: setImageSrc,
    });
  };

  const deleteImage = async () => {
    updateCompanyById({
      id: companyDetails.id,
      [APP_DATA.APIFieldMappings.company.companyLogo]: "",
    })
      .then((res) => {
        if (res.status) {
          closeModal();
          formData.current["companyLogo"] = "";
          setImageSource("");
          getCompanyInfo(companyDetails?.company_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (e) => {
    overrideDefaultEvent(e);
    openModal("imageDeleteModal", {
      module: "company",
      deleteImage: deleteImage,
    });
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Company details</h1>
        </div>
      </div>
      {permissions?.view ? (
        <>
          {loaded ? (
            <React.Fragment>
              <div className="info-sctn">
                <div className="info-wrap m30">
                  <h3>
                    {" "}
                    {echo(companyDetails?.company_name)}{" "}
                    <i
                      id={"edit-title-company"}
                      className={
                        permissions?.edit
                          ? "crm-edit-icn"
                          : "crm-edit-icn disabled"
                      }
                      data-original-title="Edit Info"
                      onClick={
                        permissions?.edit
                          ? () =>
                              handleRSidebar("editCompanyInfo", {
                                companyDetails: companyDetails,
                              })
                          : null
                      }
                    ></i>
                    <Tooltip
                      anchorId={"edit-title-company"}
                      place={"right"}
                      content="Edit"
                    />
                  </h3>
                  <div className="info-fields cpmy-info">
                    <div
                      className="cmpny-logo"
                      onClick={() => handleSelectLogo()}
                    >
                      <div>
                        <img
                          src={
                            src ||
                            "https://www.idrive.com/crm/webconsole/assets/images/company_icon.svg"
                          }
                        />
                        <i
                          className="crm-camera-icon"
                          data-original-title="Upload Image"
                          data-placement="right"
                          id="t-uploadImage"
                          onClick={() => handleSelectLogo()}
                        ></i>
                      </div>

                      <div className="update-links">
                        {companyDetails.company_logo && (
                          <a onClick={() => handleSelectLogo()}>Change</a>
                        )}
                        {companyDetails.company_logo && (
                          <a onClick={(e) => handleDelete(e)}>Delete</a>
                        )}
                      </div>

                      {!companyDetails.company_logo && (
                        <span>
                          <a
                            // onClick={
                            //   permissions?.create
                            //     ? () =>
                            //         handleRSidebar("uploadLogo", {
                            //           companyDetails: companyDetails,
                            //         })
                            //     : null
                            // }
                            onClick={() => handleSelectLogo()}
                          >
                            Upload your logo
                          </a>
                        </span>
                      )}
                    </div>
                    <div className="fields-row">
                      <div className="row">
                        <span className="title">Email</span>
                        <span className="value">
                          {echo(companyDetails?.company_email)}
                        </span>
                      </div>
                      <div className="row">
                        <span className="title">Mobile</span>
                        <span className="value">
                          {echo(companyDetails?.company_mobile)}
                        </span>
                      </div>
                      <div className="row">
                        <span className="title">Link</span>
                        <span className="value">
                          <span className="link">
                            {echo(companyDetails?.company_link)}
                          </span>
                        </span>
                      </div>
                      <div className="row">
                        <span className="title">Location</span>
                        <span className="value">
                          {echo(companyDetails?.company_location)}
                        </span>
                      </div>
                      <div className="row">
                        <span className="title">Website</span>
                        <span className="value">
                          {echo(companyDetails?.company_website)}
                        </span>
                      </div>
                      <div className="row">
                        <span className="title token-title">Token ID</span>
                        <span className="value">
                          <div className="token-input">
                            <div className="token-id">
                              <span className="id">
                                {echo(companyDetails?.company_token_id)}
                              </span>
                              <span
                                id="companyTokenCopy-s"
                                className="copied-link"
                              >
                                Token ID copied
                              </span>
                            </div>
                            <span
                              id="companyTokenCopy"
                              data-token={echo(
                                companyDetails?.company_token_id
                              )}
                              className="copy-btn"
                              onClick={(e) => handleCopyClick(e)}
                            >
                              Copy
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info-wrap m30">
                  <h3>Access URL</h3>
                  <div className="info-fields access-url">
                    <div className="info-fields">
                      <div className="row">
                        <span className="title">URL</span>
                        <span className="value">
                          {echo(companyDetails?.company_access_url)}
                          <i
                            className="copy"
                            id={"companyAccessUrl"}
                            data-token={echo(
                              companyDetails?.company_access_url
                            )}
                            onClick={(e) => handleCopyClick(e)}
                          ></i>
                          <Tooltip
                            content="Copy"
                            place={"right"}
                            id={"companyAccessUrl-s"}
                            anchorId={"companyAccessUrl"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info-wrap m30">
                  <h3>
                    Locale information{" "}
                    <i
                      id={"edit-title-locale"}
                      className={
                        permissions?.edit
                          ? "crm-edit-icn"
                          : "crm-edit-icn disabled"
                      }
                      data-original-title="Edit Info"
                      onClick={
                        permissions?.edit
                          ? () =>
                              handleRSidebar("editCompanyLocale", {
                                companyDetails: companyDetails,
                              })
                          : null
                      }
                    ></i>
                    <Tooltip
                      place={"right"}
                      anchorId={"edit-title-locale"}
                      content="Edit"
                    />
                  </h3>
                  <div className="info-fields">
                    <div className="row">
                      <span className="title">Currency</span>
                      <span className="value">
                        {echo(companyDetails?.company_currency)}
                      </span>
                    </div>
                    {/* <div className="row">
                      <span className="title">Time zone</span>
                      <span className="value">
                        {echo(companyDetails?.company_time_zone)}
                      </span>
                    </div>
                    <div className="row">
                      <span className="title">Date format</span>
                      <span className="value">
                        {echo(companyDetails?.company_date_format)}
                      </span>
                    </div>
                    <div className="row">
                      <span className="title">Time format</span>
                      <span className="value">
                        {echo(companyDetails?.company_time_format)}
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="info-wrap m30">
                  <h3>
                    Mail Configuration{" "}
                    <i
                      className={
                        permissions?.edit
                          ? "crm-edit-icn"
                          : "crm-edit-icn disabled"
                      }
                      data-original-title="Edit Info"
                      onClick={
                        permissions?.edit
                          ? () =>
                              handleRSidebar("editMailInfo", {
                                companyDetails: companyDetails,
                              })
                          : null
                      }
                    ></i>
                  </h3>
                  <div className="info-fields">
                    <div className="row">
                      <span className="title">Sender email</span>
                      <span className="value">
                        {echo(companyDetails.company_sender_email)}
                      </span>
                    </div>
                    <div className="row">
                      <span className="title">IMAP server</span>
                      <span className="value">
                        {echo(companyDetails.company_email_host)}
                      </span>
                    </div>
                    <div className="row">
                      <span className="title">Username</span>
                      <span className="value">
                        {echo(companyDetails.company_email_username)}
                      </span>
                    </div>
                    <div className="row">
                      <span className="title">Password</span>
                      <span className="value">
                        {companyDetails.company_email_password?.length
                          ? "*".repeat(
                              companyDetails.company_email_password?.length
                            )
                          : echo(companyDetails.company_email_password)}
                      </span>
                    </div>
                    {/* <div className="row">
                            <span className="title">Port</span>
                            <span className="value">{echo(companyDetails.company_email_port)}</span>
                        </div> */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <FullPageLoader />
          )}
        </>
      ) : (
        <NoResults permission={!permissions?.view} />
      )}
    </React.Fragment>
  );
}
