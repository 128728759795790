import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const fetchNotifications = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/notifications/fetch_notifications?user_id=" + userId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};

export const getReminders = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/notifications/fetch_reminders?user_id=" + userId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject([]);
      });
  });
};

export const getSubscriptionKey = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/notifications/subscription", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log("nres", res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject([]);
      });
  });
};

export const subscribeUser = async (userId, subToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + "/notifications/subscribe",
        {
          user_id: userId,
          subscription_token: subToken,
          device_type: "Web",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject([]);
      });
  });
};

export const unsubscribeUser = async (userId, subToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + "/notifications/unsubscribe",
        {
          user_id: userId,
          subscription_token: subToken,
          device_type: "Web",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject([]);
      });
  });
};

export const clearAllNotification = (userId="") => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL +"/notifications/delete_all_notifications?user_id=" +userId,{
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};


export const updateNotification = (notificationId = "", payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL +
          "/notifications/update_notifications?notification_id=" +
          notificationId,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};

export const markAllAsRead = (userId = "") => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL +
          "/notifications/mark_all_notifications_as_read?user_id=" +
          userId,
          null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};