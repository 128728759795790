import React, { useState, useEffect } from "react";
import { echo, getUserAccessInfo, logoForExtension } from "../../utils";
import { unInstallExtensionById } from "../../apis/extensionAPI";

export default function InstalledView({
  installedExtension,
  detailExtensionId,
  fetchInstalledExtension,
  id,
}) {

  const [permission,setPermission] = useState({});

  useEffect(() => {
    setPermission(getUserAccessInfo("Extensions")?.permission || {});
  }, []);

  // useEffect(() => {
  //   if (id) {
  //     unInstallExtensionById(id);
  //   }
  // }, []);

  const unInstallExtension = (id) => {
    unInstallExtensionById(id)
      .then((res) => {
        if (res.status) {
          fetchInstalledExtension();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="ext-wrap">
        <div className="ext-hdr">
          <div className="desc">
            <h2>Marketing</h2>
            <p className="sub-title">
              Make your marketing more inventive and interactive
            </p>
          </div>
          <div className="action">
            {/* <button type="button" className="btn btn-secondary">
              View All
            </button> */}
          </div>
        </div>
      </div>
      <div className="enbl-apps">
        {installedExtension?.length > 0
          ? installedExtension.map((e) => (
              <div className="app-dtlsbox">
                <div className="lft">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "initial",
                    }}
                  >
                    <img
                      style={{ width: "120px", height: "75px" }}
                      src={logoForExtension(e?.name, e?.id)}
                    />
                  </div>
                  <div className="info">
                    {/* <p className="title">{echo(e.name)}</p> */}
                    {/* <p className="desc">{echo(e.description)}</p> */}
                  </div>
                </div>
                <div className="rgt">
                  <button
                    type="button"
                    className={permission.view ? "btn btn-primary dtls-btn":"btn btn-primary dtls-btn disabled"}
                    onClick={permission.view ? () => {
                      detailExtensionId(e.id);
                    }:null}
                  >
                    Details
                  </button>
                  <button
                    type="button"
                    className={permission.delete ? "btn btn-secondary uninstl-btn":"btn btn-secondary uninstl-btn disabled"}
                    onClick={permission.delete ? () => unInstallExtension(e?.id):null}
                  >
                    Uninstall
                  </button>
                </div>
              </div>
            ))
          : null}
      </div>
    </React.Fragment>
  );
}
