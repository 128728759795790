import React, { useEffect, useState } from "react";
import { forEach } from "../../utils";

export default function DataTableLoader(props) {
  const [cols, setCols] = useState("1000000000");
  const checkboxHiddenModules = ["webform", "contract","profiles"];

  useEffect(() => {
    if (props.cols) {
      setCols(props.cols);
    }
    if (checkboxHiddenModules.includes(props?.module)) {
      const checkBoxes = document.querySelectorAll(".tbl-loader.xx");
      forEach(checkBoxes, function (index, el) {
        el.classList.remove("first");
      });
    } else {
      const checkBoxes = document.querySelectorAll(".tbl-loader.xx");
      forEach(checkBoxes, function (index, el) {
        el.classList.add("first");
      });
    }
  }, []);

  return (
    <React.Fragment>
      {Array.from("1000000000").map((_, i) => (
        <div className="crm-tb-rw">
          {!checkboxHiddenModules.includes(props?.module) ? (
            <div className="crm-fixed-field">
              <div className={props.mClass}>
                <div className="tbl-loader xx">
                  {!checkboxHiddenModules.includes(props?.module) ? (
                    <div className="bar check"></div>
                  ) : null}
                  <div className="bar"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className={props.mClass}>
              <div className="tbl-loader xx">
                {!checkboxHiddenModules.includes(props?.module) ? (
                  <div className="bar check"></div>
                ) : null}
                <div className="bar"></div>
              </div>
            </div>
          )}
          {!checkboxHiddenModules.includes(props?.module) ? (
            <div className="crm-scroll-field">
              {Array.from(cols).map(() => (
                <div className="crm-td">
                  <div className="tbl-loader">
                    <div className="bar"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            Array.from(cols).map(() => (
              <div className="crm-td">
                <div className="tbl-loader">
                  <div className="bar"></div>
                </div>
              </div>
            ))
          )}
        </div>
      ))}
    </React.Fragment>
  );
}
