import React, { useEffect, useState } from "react";
import "react-funnel-pipeline/dist/index.css";
import { FunnelChart } from "react-funnel-pipeline";
import { useOutletContext } from "react-router-dom";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  getCallsByMonth,
  getCallsByOwner,
  getCallsByPurpose,
  getCallsByWeek,
} from "../apis/analyticsAPIs";
import { APP_DATA } from "../app_data";
import { DonutChartLoader } from "../components/analytics/donutChartLoader";
import ChartLegendLoader from "../components/analytics/chartLegendLoader";
import BarChartLoader from "../components/analytics/barChartLoader";
import {
  barChartDataParser,
  echo,
  getUserAccessInfo,
  secondsToHms,
} from "../utils";
import NoResults from "../components/common/noResults";

export default function AnalyticsCalls() {
  const { closeLoader } = useOutletContext();
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    BarElement,
    CategoryScale
  );

  const [donData, setDonData] = useState({});
  const [callsMonthBarData, setCallsMonthBarData] = useState(null);
  const [callsWeekBarData, setCallsWeekBarData] = useState(null);
  const [callOwners, setCallowners] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [callsPermission, setCallsPermission] = useState({});

  useEffect(() => {
    loadGraphdata();
    const calls = getUserAccessInfo("Analytics");
    if (calls?.permission) {
      setCallsPermission(calls.permission);
    } else {
      setCallsPermission({});
    }
  }, []);

  const loadGraphdata = async () => {
    const callsPurposeData = await getCallsByPurpose();
    const callsByMonth = await getCallsByMonth();
    const callsByWeek = await getCallsByWeek();
    const callsOwners = await getCallsByOwner();
    const finalDonData = furnishDonData(callsPurposeData.data);
    const finalCallsMonthBarData = furnishBarData(
      callsByMonth?.data?.calls_by_user,
      "month"
    );
    const finalCallsWeekBarData = furnishBarData(
      callsByWeek?.data?.calls_by_user,
      "week"
    );
    setDonData(finalDonData);
    setCallsMonthBarData(finalCallsMonthBarData);
    setCallsWeekBarData(finalCallsWeekBarData);
    setCallowners(callsOwners?.data?.call_owners);
    setLoaded(true);
  };

  const furnishDonData = (sourceData) => {
    const donData = {
      datasets: [
        {
          borderWidth: 0,
          hoverOffset: 30,
          label: "Number of Calls",
          data: sourceData.call_purpose.map((d) => d.value),
          backgroundColor: APP_DATA.donutChartColors,
        },
      ],
      total: sourceData?.total_count,
      labels: sourceData.call_purpose.map((d) => d.name),
    };
    return donData;
  };

  const DonOptions = {
    cutout: "70%",
    responsive: true,
    layout: {
      padding: 13,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        backgroundColor: "rgba(155, 184, 216, .8)",
        titleColor: "#000",
        caretSize: 5,
        bodyColor: "#000",
        displayColors: false,
        padding: 10,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";

            if (label) {
              label = "Total Count: " + context.parsed;
            }
            document.getElementById("t_display").style.opacity = 0;
            return label;
          },
        },
      },
    },
  };

  const BarOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        backgroundColor: "rgba(155, 184, 216, .8)",
        titleColor: "#000",
        caretSize: 5,
        bodyColor: "#000",
        displayColors: false,
        padding: 10,
      },
    },
  };

  const furnishBarData = (sourceData, duration) => {
    const barChartData = barChartDataParser(sourceData, duration);

    const data = [];
    const labels = [];

    barChartData?.map((it) => {
      data.push(it.value);
      labels.push(it.name?.substring(0, 3));
    });

    const BarData = {
      datasets: [
        {
          label: "Calls",
          borderRadius: 4,
          barThickness: 24,
          backgroundColor: "#73BADF",
          data,
        },
      ],
      labels,
    };
    return BarData;
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Call status</h1>
        </div>
      </div>
      {callsPermission?.view ? (
        <div className="crm-section">
          <div className="crm-sctnrw">
            <div className="crm-sctncol">
              <div className="hdr">
                <h2>Calls by purpose</h2>
                {loaded ? (
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="chart-legends"
                      style={{
                        alignItems:
                          donData?.labels?.length < 10 ? "center" : "",
                      }}
                    >
                      <ul>
                        {donData.labels.map((l, i) => (
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginBottom: 10,
                            }}
                            key={i}
                          >
                            <div
                              className="chart-bullets"
                              style={{
                                backgroundColor: `${
                                  donData.datasets[0].backgroundColor[
                                    i %
                                      donData?.datasets[0]?.backgroundColor
                                        ?.length
                                  ]
                                }`,
                              }}
                            ></div>
                            <span>{echo(l)}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div id={"t_display"} className={"chart-center-title"}>
                        <h1>
                          <strong>Total Calls</strong>
                        </h1>
                        <h3>
                          <strong style={{ color: "#073075" }}>
                            {donData?.total}
                          </strong>
                        </h3>
                      </div>
                      <Doughnut
                        data={donData}
                        options={DonOptions}
                        onMouseLeave={() =>
                          (document.getElementById(
                            "t_display"
                          ).style.opacity = 1)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <ChartLegendLoader />
                    </div>
                    <div>
                      <DonutChartLoader />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="crm-sctncol col2">
              <div className="hdr">
                <h2>Calls this week</h2>
                {loaded ? (
                  <>
                    <div style={{ height: 250 }}>
                      <Bar options={BarOptions} data={callsWeekBarData} />
                    </div>
                  </>
                ) : (
                  <BarChartLoader width={420} />
                )}
              </div>
            </div>
          </div>
          <div className="crm-sctnrw">
            <div className="crm-sctncol col">
              <div className="hdr">
                <h2>Calls this month</h2>
                {loaded ? (
                  <>
                    <div style={{ height: 250 }}>
                      <Bar options={BarOptions} data={callsMonthBarData} />
                    </div>
                    {/* <i className="crm-action-settings">
                      <ul className="crm-dropdown-action" id="">
                        <li>
                          <span className="">Year</span>
                        </li>
                        <li>
                          <span className="">Monthly</span>
                        </li>
                        <li>
                          <span className="">Weekly</span>
                        </li>
                      </ul>
                    </i> */}
                  </>
                ) : (
                  <BarChartLoader />
                )}
              </div>
            </div>
            <div className="crm-sctncol col2">
              <div className="hdr">
                <h2>Top call owners</h2>
                <div style={{ height: 250, overflowY: "scroll" }}>
                  <div className="crm-tbl-str">
                    <ul>
                      {callOwners?.map((v, i) => (
                        <li key={i}>
                          <div className="mnth col">{echo(v.name)}</div>
                          <div className="count col">
                            {echo(secondsToHms(v.value))}
                          </div>
                          <div className="action col">
                            <button className="btn">
                              {(v?.percent || 0).toFixed(2)}%
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <i className="crm-action-settings">
                  <ul className="crm-dropdown-action" id="">
                    <li>
                      <span className="">Year</span>
                    </li>
                    <li>
                      <span className="">Monthly</span>
                    </li>
                    <li>
                      <span className="">Weekly</span>
                    </li>
                  </ul>
                </i> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoResults permission={!callsPermission?.view} />
      )}
    </React.Fragment>
  );
}
