/**
 * Some common APIs, functions and Constants
 */
import axios from "axios";
import { envInjector } from "../env";
import { utf8_to_b64 } from "../utils";
import {
  getCachedData,
  getAccessTokenAPI,
  deleteCachedRefresh,
  getAccessTokenAPINew,
} from "./authAPIs";

import jwt_docode from "jwt-decode";
import WorkerLoader from "../workerLoader";

//Global API Base URL-------------------------------------------------
export const API_URL = envInjector.REACT_APP_NODE_BASE_URL;
//--------------------------------------------------------------------

// Public APIs instance
export const axiosPublic = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json", client: "web" },
});

//Private API instance
export const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json", client: "web" },
  // withCredentials: true,
});

const requestIntercept = axiosPrivate.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = getAuthToken();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// const responseIntercept = axiosPrivate.interceptors.response.use(
//   (response) => {
//     const prevRequest = response?.config;
//     console.log(response.data.errors[0].error_code);
//     return response;
//   },
//   (error) => {
//     const prevRequest = error?.config;
//     if (
//       refreshTokenErrorCodes.includes(error?.response?.status) &&
//       !prevRequest?.sent
//     ) {
//       prevRequest.sent = true;
//       return axiosPrivate(prevRequest);
//     }
//     return Promise.reject(error);
//   }
// );

export const ejectInterceptors = () => {
  // axiosPrivate.interceptors.request.eject(requestIntercept);
  // axiosPrivate.interceptors.response.eject(responseIntercept);
};

//worker conection
let refreshed = false;
const authWorker = new WorkerLoader();

//Error codes for api handling----------------------------------------
export const planExpiryErrorCodes = ["412"];
export const permissionErrorCodes = [];
export const refreshTokenErrorCodes = ["453"];
export const forceLogoutErrorCodes = ["411", "403", "401"];
//--------------------------------------------------------------------

// adding auth and client header--------------------------------------
export const getAuthToken = () => {
  const lt = window.localStorage.getItem("a_d");
  if (!lt) return "";

  const au = JSON.parse(lt);
  return "Bearer " + au["access_token"];
};
axios.defaults.headers.common["client"] = "web";
// --------------------------------------------------------------------

export const downloadSampleAPI = (slug) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/${slug}/download_sample/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        responseType: "blob",
      })
      .then(async (res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const APIErrorResponse = (res) => {};

//api success response processor
export const APISuccessResponse = async (res) => {
  const successRes = {
    data: [],
    error: null,
    status: false,
  };
  const statusCode = res.status;
  return new Promise((resolve, reject) => {
    try {
      if (res.data.status && statusCode === 200) {
        if (res.data.data != null || !res.data["errors"].length) {
          refreshed = false;
          successRes.status = true;
          successRes.data = res.data.data;
          resolve(successRes);
        } else {
          const error_code = res.data.errors[0].error_code;
          const error_message = res.data.errors[0].error_message;

          if (planExpiryErrorCodes.includes(error_code)) {
            //TODO need to handle plan expiry
            fireErrorNotification(error_message);
          } else if (permissionErrorCodes.includes(error_code)) {
            //TODO need to handle permission
            fireErrorNotification(error_message);
          } else if (refreshTokenErrorCodes.includes(error_code)) {
            //TODO need to handle token refresh
            if (!refreshed) {
              getCachedData()
                .then(async (refreshToken) => {
                  const newToken = await getAccessTokenAPI(refreshToken);
                  if (newToken.status) {
                    const decodedToken = jwt_docode(newToken.data.access_token);
                    window.localStorage.setItem(
                      "a_d",
                      JSON.stringify({
                        ...newToken.data,
                      })
                    );
                    authWorker.port.postMessage({
                      action: "tokenRefresh",
                      data: {
                        aExp: decodedToken.exp,
                      },
                    });
                  }
                })
                .catch(() => {
                  handleLogout();
                });

              // activating Refresh token request
              refreshed = true;
            }
          } else if (forceLogoutErrorCodes.includes(error_code)) {
            //TODO force logout
            handleLogout();
          }
          resolve({ ...successRes, error: res.data.errors[0] });
        }
      } else {
        resolve({
          ...successRes,
          error: null,
        });
      }
      // #depricated
      // ejectInterceptors();
    } catch (error) {
      resolve({
        ...successRes,
        error: error,
      });
    }
  });
};

const handleLogout = () => {
  window.localStorage.removeItem("a_d");
  const url = new URL(envInjector.REACT_APP_DOMAIN_URL + "/auth/login");
  url.searchParams.set("referrer", "auth-login-sessionExpired");
  url.searchParams.set("tNode", utf8_to_b64(String(Date.now())));
  deleteCachedRefresh()
    .then(() => {
      window.location.replace(url.toString());
    })
    .catch(() => {
      window.location.replace(url.toString());
    });
};

export const fireErrorNotification = (message) => {
  document.getElementById("err_note_message_class").classList.add("active");
  document.getElementById("err_note_message").innerText = message;
  setTimeout(() => {
    document
      .getElementById("err_note_message_class")
      .classList.remove("active");
  }, 5000);
};

export const searchAPI = (
  module = "",
  searchKey = "",
  offset = 0,
  limit = 0
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/${module}/name/${searchKey}?count=true&offset=${offset}&limit=${limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const searchThroughAPIs = (
  endpointName,
  queryString,
  offset = 0,
  limit = 0
) => {
  return new Promise(async (resolve, reject) => {
    let res;
    let out = { status: false, data: [] };
    try {
      switch (endpointName) {
        case "accounts":
          res = await searchAPI("accounts", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "contacts":
          res = await searchAPI("contacts", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "deals":
          res = await searchAPI("deals", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "leads":
          res = await searchAPI("leads", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "tasks":
          res = await searchAPI("tasks", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "calls":
          res = await searchAPI("calls", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "meetings":
          res = await searchAPI("meetings", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "users":
          res = await searchAPI("users", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "groups":
          res = await searchAPI("groups", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "rules":
          res = await searchAPI("rules", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "webforms":
          res = await searchAPI("web_forms", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "contracts":
          res = await searchAPI("contracts", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "emailTemplate":
          res = await searchAPI("email_templates", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        case "campaigns":
          res = await searchAPI("campaigns", queryString, offset, limit);
          if (res.status) {
            out.status = true;
            out.data = res.data;
          }
          break;
        default:
          break;
      }
      resolve(out);
    } catch (e) {
      resolve(out);
    }
  });
};

export const exportDataInCSV = async (
  module = "",
  offset = 0,
  limit = 5000,
  body = []
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/export?module=${module}&offset=${offset}&limit=${limit}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const downloadCSVExportedFiles = (fileName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/utils/download_file/?file_name=${fileName}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        responseType: "blob",
      })
      .then(async (res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const saveImportByFilenameAPI = (fileName, module) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + `/${module}/upload_data/?file_name=${fileName}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

//returns-> export csv file name
export const getExportFileCustomFilter = async (
  module,
  offset = 0,
  limit = 10,
  body,
  orderBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/export_with_custom_filter?module=${module}&offset=${offset}&limit=${limit}&field=${
            sortField ? sortField : "Created date"
          }${orderBy ? `&order_by=${orderBy}` : ""}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getExportFileFixedFilter = async (
  module,
  offset = 0,
  limit = 10,
  fixedFilter, //its ambigus
  ownerId,
  orderBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/export_with_fixed_filter?module=${module}&offset=${offset}&limit=${limit}&fixed_filter=${
            fixedFilter
              ? fixedFilter
              : `All ${
                  String(module).charAt(0).toUpperCase() +
                  String(module).slice(1)
                }`
          }${ownerId ? `&owner_id=${ownerId}` : ""}&field=${
            sortField ? sortField : "Created date"
          }${orderBy ? `&order_by=${orderBy}` : ""}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getExportFileDateFilter = async (
  module,
  offset = 0,
  limit = 10,
  dateFilter,
  orderBy,
  sortField,
  from,
  to
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/export_with_date_filter?module=${module}&offset=${offset}&limit=${limit}&date__filter=${
            dateFilter ? dateFilter : "All_Days"
          }${from ? `&from_date=${from}` : ""}${
            to ? `&to_date=${to}` : ""
          }&field=${sortField ? sortField : "Created date"}${
            orderBy ? `&order_by=${orderBy}` : ""
          }`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getExportFileSearchFilter = async (
  module,
  searchKey,
  offset = 0,
  limit = 10,
  orderBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/export_with_search_filter?module=${module}&offset=${offset}&limit=${limit}&search_keyword=${searchKey}&field=${
            sortField ? sortField : "Created date"
          }${orderBy ? `&order_by=${orderBy}` : ""}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getTimezonesAPI = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/utils/fetch_time_zones`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getDateFormatesAPI = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/utils/fetch_date_formats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getTimeFormatesAPI = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/utils/fetch_time_formats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getMassUpdateDataAPI = (
  moduleName,
  reqPayload,
  limit = 10,
  offset = 0
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          `/utils/custom_filter?module=${moduleName}&field=Created date&order_by=desc&count=true&offset=${offset}&limit=${limit}`,
        reqPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const massUpdateAPI = (module, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + `/${module}/` + "bulk_update/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
