import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import { inputFieldValidator, utf8_to_b64 } from "../utils";
import { APP_DATA } from "../app_data";
import {
  loginAPI,
  getCachedData,
  AUTH_CACHE_NAME,
  getAccessTokenAPI,
} from "../apis/authAPIs";
import { AES } from "crypto-js";
import { envInjector } from "../env";
import useKeyEvent from "../customHooks/useKeyEvent";
import { requestPNPermission } from "../sw_utils/index";
import LocalSuspense from "../layouts/suspense";

export default function LoginView() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const passwordField = useRef(null);
  const formData = useRef({ username: "", password: "" });

  const [loggingIn, setLogginIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [authorizing, setAuthorizing] = useState(true);
  const noAuthCheck = [
    "auth-logout",
    "auto-sessionExpired",
    "auth-login-sessionExpired",
  ];

  useEffect(() => {
    if (!noAuthCheck.includes(urlSearch.get("referrer"))) {
      getCachedData()
        .then(async (authData) => {
          if (authData) {
            const decodedToken = jwt_decode(authData);
            if (parseInt(decodedToken.exp + "000") <= Date.now()) {
              setAuthorizing(false);
              window.localStorage.removeItem("a_d");
              return navigate({
                replace: true,
                pathname: "/auth/login",
                search: createSearchParams({
                  referrer: "auth-login-sessionExpired",
                  tNode: utf8_to_b64(String(Date.now())),
                }).toString(),
              });
            } else {
              const newToken = await getAccessTokenAPI(authData);
              if (newToken.status) {
                window.localStorage.setItem(
                  "a_d",
                  JSON.stringify({
                    ...newToken.data,
                  })
                );
                setTimeout(() => {
                  return navigate({
                    replace: true,
                    pathname: "/app",
                    search: createSearchParams({
                      referrer: "auth-login-sessionActive",
                      tNode: utf8_to_b64(String(Date.now())),
                    }).toString(),
                  });
                }, 1000);
              } else {
                setTimeout(() => {
                  setAuthorizing(false);
                  document.getElementById("username").focus();
                }, 2000);
              }
            }
          }
        })
        .catch(() => {
          setTimeout(() => {
            setAuthorizing(false);
            document.getElementById("username").focus();
          }, 2000);
        });
    } else {
      setAuthorizing(false);
      document.getElementById("username").focus();
    }
  }, []);

  useEffect(() => {
    const referrer = urlSearch.get("referrer");
    switch (referrer) {
      case "auto-sessionExpired":
      case "auth-login-sessionExpired":
        loginViewHandler("open", "Your session has expired!");
        break;
      case "auth-logout":
        loginViewHandler("open", "You have successfully logged out!");
        break;
      default:
        loginViewHandler("hide");
        break;
    }
    setTimeout(() => {
      loginViewHandler("hide");
    }, 5000);
  }, [location.search]);

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);

    if (validationResponse.valid) {
      formData.current[event.target.id] = event.target.value;
      document.getElementById("err_" + event.target.id).innerText = "";
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        validationResponse["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const handleSecureLogin = async () => {
    if (loggingIn) return;
    setLogginIn(true);
    let finalValidation = 0;
    const enPassword = AES.encrypt(
      formData.current.password,
      envInjector.REACT_APP_APP_SEC
    );
    //@f is the current field
    for (const f in formData.current) {
      let error_message = APP_DATA.errorMessage.requiredField;
      //@ (!f in formData.current)-> condition should never occure, so maintain accordingly
      if (formData.current[f] === undefined || formData.current[f] === "") {
        finalValidation = finalValidation - 1;
        document.getElementById("err_" + f).innerText = error_message;
        document.getElementById("err_" + f).style.display = "block";
        setLogginIn(false);
      } else {
        finalValidation = finalValidation + 1;
        document.getElementById("err_" + f).innerText = "";
        document.getElementById("err_" + f).style.display = "none";
      }
    }
    if (finalValidation === Object.keys(formData.current).length) {
      document.getElementById("err_invalid_login").style.display = "none";
      loginAPI({ ...formData.current }) //loginAPI({ ...formData.current, password: enPassword.toString() })
        .then(async (loginData) => {
          if (loginData.status) {
            if ("caches" in window && rememberMe) {
              const cacheData = new Response(
                JSON.stringify(loginData.data["refresh_token"])
              );
              const cache = await caches.open(AUTH_CACHE_NAME);
              cache.put(envInjector.REACT_APP_DOMAIN_URL, cacheData);

              window.localStorage.setItem(
                "a_d",
                JSON.stringify({ access_token: loginData.data["access_token"] })
              );

              if (
                localStorage.getItem("swr") &&
                !localStorage.getItem("sub_token")
              ) {
                requestPNPermission();
              }

              return navigate({
                replace: true,
                pathname: "/app",
                search: createSearchParams({
                  referrer: "auth-success",
                  tNode: utf8_to_b64(String(Date.now())),
                }).toString(),
              });
            } else {
              setLogginIn(false);
              loginViewHandler(
                "open",
                "Security check failed. Please try again."
              );
            }
          } else {
            setLogginIn(false);
            loginViewHandler("open", "Invalid username or password");
          }
        })
        .catch((e) => {
          loginViewHandler("open", "Something went wrong! Try after sometime");
          setLogginIn(false);
        });
    }
  };

  const loginViewHandler = (type = "open", msg = "") => {
    const el = document.getElementById("err_invalid_login");
    if (type === "hide") {
      if (el) {
        el.style.display = "none";
      }
    } else {
      if (el) {
        document.getElementById("login_err_msg").innerText = msg;
        el.style.display = "block";
      }
    }
  };

  const handleShowPassword = (state) => {
    if (state === "show") {
      passwordField.current.type = "text";
      document.getElementById("btnShowHidePwd").classList.add("crm-show-pwd");
      document
        .getElementById("btnShowHidePwd")
        .classList.remove("crm-hide-pwd");
    } else {
      passwordField.current.type = "password";
      document
        .getElementById("btnShowHidePwd")
        .classList.remove("crm-show-pwd");
      document.getElementById("btnShowHidePwd").classList.add("crm-hide-pwd");
    }
  };

  useKeyEvent(handleSecureLogin, 13);

  return (
    <React.Fragment>
      {!authorizing ? null : (
        <React.Fragment>
          <LocalSuspense/>
        </React.Fragment>
      )}
      <div className="signup-hdr login">
        <div className="crm-container">
          <div className="hdr-lft">
            <a className="crm-logo"></a>
            <ul className="crm-hdr-menu">
              <li>
                <a>Features</a>
              </li>
              <li>
                <a>Pricing</a>
              </li>
              <li>
                <a>Download</a>
              </li>
              <li>
                <a>FAQs</a>
              </li>
              <li>
                <a>Support</a>
              </li>
            </ul>
          </div>
          <div className="hdr-rgt">
            <a
              target="_blank"
              href="https://www.idrivecrm.com"
              className="btn btn-secondary"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>

      <section
        className="signup-section login"
        style={{ maxHeight: window.innerHeight }}
      >
        <div
          className="successms crm-cmn-msg"
          id="err_invalid_login"
          style={{ display: "none" }}
        >
          <span
            className="close-icn"
            id="successClsBtn"
            onClick={() => loginViewHandler("hide")}
          ></span>
          <p id="login_err_msg"></p>
        </div>
        <div className="signup-main">
          <div className="crm-outer-form">
            <form noValidate="" className="">
              <div className="crm-outer-head">
                <h1>
                  Sign in to IDrive<sup>®</sup> CRM
                </h1>
              </div>
              <fieldset>
                <div className="crm-form-row">
                  <div className="crm-form-grp crm-frm-100">
                    <div className="crm-form-field">
                      <input
                        className="crm-form-ctrl floating-input"
                        id="username"
                        placeholder=" "
                        type={"email"}
                        onChange={(e) => formChangeHandler(e)}
                      />
                      <label
                        htmlFor="email"
                        className="floating-label"
                        placeholder="Email"
                      >
                        Email
                      </label>
                      <span className="crm-inp-error" id="err_username"></span>
                    </div>
                  </div>
                  <div className="crm-form-grp crm-frm-100 crm-form-password">
                    <div className="crm-form-field">
                      <input
                        type="password"
                        id="password"
                        className="crm-form-ctrl floating-input"
                        placeholder=" "
                        onChange={(e) => formChangeHandler(e)}
                        ref={passwordField}
                      />
                      <label
                        htmlFor="password"
                        className="floating-label"
                        placeholder="Password"
                      >
                        Password
                      </label>
                      <span className="crm-inp-error" id="err_password"></span>
                      <div
                        onMouseDown={() => handleShowPassword("show")}
                        onMouseUp={() => handleShowPassword("hide")}
                        className="crm-hide-pwd pwd"
                        id="btnShowHidePwd"
                      ></div>
                    </div>
                  </div>
                  <div className="crm-form-grp">
                    {/* <div className="stay-signed">
                      <input
                        type="checkbox"
                        className="crm-checkmark"
                        id="rem"
                        defaultChecked={rememberMe}
                        onChange={(e) => handleRememberMe(e)}
                      />
                      <label htmlFor="rem">Remember Me</label>
                    </div> */}
                    <Link
                      to={"/forgot-password"}
                      className="forgot-pwd"
                      id="forgotPwdLink"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div className="crm-form-grp">
                    <button
                      type="button"
                      className={`btn btn-primary create-acnt ${
                        !loggingIn ? null : "btnLoader"
                      }`}
                      onClick={() => handleSecureLogin()}
                    >
                      Sign in{" "}
                    </button>
                  </div>
                </div>
              </fieldset>
              <div className="crm-form-row crm-form-row-btm">
                <div className="crm-form-grp">
                  Don't have an account?
                  <a
                    className="crm-crte-newacc"
                    target="_blank"
                    href="https://www.idrivecrm.com"
                  >
                    {" "}
                    Sign up
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div className="signup-footer">
        <p>©IDrive Inc.</p>
      </div>
    </React.Fragment>
  );
}
