import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { APP_DATA } from "../../app_data";
import ActionWebhook from "./actionWebhook";
import ActionSendMail from "./actionSendMail";
import { inputFieldValidator } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsersLimitNoffset, getUsersByName } from "../../apis/usersAPI";

const ActionsToPerform = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);

  const [users, setUsers] = useState([]);
  const [mode, setMode] = useState(urlSearch.get("mode"));
  const [remoteWFR, setRemoteWFR] = useState(null);
  const [usersFound, setUserFound] = useState(true);
  const [searchSelector, setSearchSelector] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [reassignIndices, setReassignIndices] = useState([]);
  const [actions, setActions] = useState([
    structuredClone(APP_DATA.workflowRules.actions[0]),
  ]);

  const sendMailCompChildren = useRef(new Array());
  const webhookCompChildren = useRef(new Array());

  useEffect(() => {
    if (mode === "edit") {
      const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
      setRemoteWFR(rWFR);
    }
  }, [location.search]);

  useEffect(() => {
    if (remoteWFR) {
      if (remoteWFR.hasOwnProperty("actions")) {
        if (remoteWFR.actions.length && reassignIndices.length) {
          reassignIndices.map((index) => {
            const realId = remoteWFR.actions[index].properties["value"];
            const userInfo = props.ruleExtraData["users"].find(
              (u) => u.user_id === realId
            );
            const tempEvent = {
              target: {
                value: realId,
                name: "assignee_owner",
                forIndex: parseInt(index),
                innerText: userInfo.user_name,
                id: `assigneeOwner-${parseInt(index)}_${realId}`,
              },
            };
            selectDropdown(tempEvent, "users");
          });
        }
      }
    }
  }, [reassignIndices]);

  useEffect(() => {
    setupEditMode();
  }, [remoteWFR]);

  const handleAddActionHolder = (e) => {
    //adding the default action on add
    const tA = actions;
    tA.push(structuredClone(APP_DATA.workflowRules.actions[0]));
    setActions((prev) => [...tA]);
  };

  const setupEditMode = () => {
    // const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
    if (remoteWFR) {
      if (remoteWFR.hasOwnProperty("actions")) {
        if (remoteWFR.actions.length) {
          const settings = new Array();
          const allReassignedIndices = new Array();
          remoteWFR.actions.map((action, i) => {
            const rowSettings = APP_DATA.workflowRules.actions.find(
              (a) => a.type === action.type
            );
            const reassignSettings = structuredClone(rowSettings);
            if (action.properties.scheduled_at) {
              reassignSettings.properties.scheduled_at =
                action.properties.scheduled_at;
            }
            if (action.type === "reassign") {
              allReassignedIndices.push(i);
            }
            settings.push(reassignSettings);
          });
          //need to set reassign first
          setReassignIndices(allReassignedIndices);
          setActions(settings);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleActionData() {
      let finalValidation = 1;
      // let finalActions = [];
      actions.map((act, i) => {
        if (act.type === "reassign") {
          let tempAction = { ...actions[i] };
          const rData = selectedUserIds.find((u) => u.index === i);
          if (!rData) {
            const elm = document.getElementById(
              `err_assigneeOwner_${parseInt(i)}`
            );
            finalValidation &= 0;
            elm.style.display = "block";
            elm.innerText = APP_DATA.errorMessage.requiredField;
          } else {
            //configure action temporarily
            const ap = {
              field_name: `${
                APP_DATA.modules.find(
                  (m) =>
                    m.value === String(urlSearch.get("target").split("-")[2])
                ).singular
              }_owner`,
              value: rData.value,
            };
            tempAction.properties = {
              ...tempAction.properties,
              field_name: ap.field_name,
              value: ap.value,
            };

            if (
              tempAction.properties.scheduled_at &&
              tempAction.properties.scheduled_at.minutes < 5
            ) {
              if (
                tempAction.properties.scheduled_at.hours ||
                tempAction.properties.scheduled_at.days
              ) {
                document.getElementById("err_delay_" + i).style.display =
                  "none";
              } else {
                finalValidation &= 0;
                document.getElementById("err_delay_" + i).style.display =
                  "block";
              }
            }

            if (window.sessionStorage.getItem("wf:actions")) {
              let prev = JSON.parse(
                window.sessionStorage.getItem("wf:actions")
              );
              prev.push(tempAction);
              window.sessionStorage.setItem("wf:actions", JSON.stringify(prev));
            } else {
              window.sessionStorage.setItem(
                "wf:actions",
                JSON.stringify([tempAction])
              );
            }
          }
        } else if (act.type === "webhook_trigger") {
          if (!webhookCompChildren.current[i].validate(i)) {
            finalValidation &= 0;
          }
        } else if (act.type === "send_mail") {
          if (!sendMailCompChildren.current[i].validate(i)) {
            finalValidation &= 0;
          }
        } else {
          // return pass
        }
      });

      if (finalValidation) {
        return true;
      } else {
        return false;
      }
    },
    resetFormData() {},
  }));

  //config data exchanger
  const handleActionTypeSelector = (e) => {
    const id = parseInt(String(e.target.id).split("_")[1]);
    if (e.target.value === "reassign") {
      const rowSettings = APP_DATA.workflowRules.actions.find(
        (a) => a.type === e.target.value
      );
      const tempAction = [...actions];
      const reassignSettings = structuredClone(rowSettings);
      if (tempAction[id].properties.scheduled_at) {
        reassignSettings.properties.scheduled_at =
          tempAction[id].properties.scheduled_at;
      }
      tempAction.splice(id, 1, reassignSettings);
      setActions((prev) => [...tempAction]);
    } else if (e.target.value === "webhook_trigger") {
      const rowSettings = APP_DATA.workflowRules.actions.find(
        (a) => a.type === e.target.value
      );
      const tempAction = [...actions];
      const webhookSettings = structuredClone(rowSettings);
      if (tempAction[id].properties.scheduled_at) {
        webhookSettings.properties.scheduled_at =
          tempAction[id].properties.scheduled_at;
      }
      tempAction.splice(id, 1, webhookSettings);
      setActions((prev) => [...tempAction]);
    } else if (e.target.value === "send_mail") {
      const rowSettings = APP_DATA.workflowRules.actions.find(
        (a) => a.type === e.target.value
      );
      const tempAction = [...actions];
      const emailSettings = structuredClone(rowSettings);
      if (tempAction[id].properties.scheduled_at) {
        emailSettings.properties.scheduled_at =
          tempAction[id].properties.scheduled_at;
      }
      tempAction.splice(id, 1, emailSettings);
      setActions((prev) => [...tempAction]);
    }

    const tempUser = [...selectedUserIds];
    const realIndex = tempUser
      .map((u) => u.index)
      .indexOf(parseInt(e.target.dataset.index));
    if (realIndex >= 0) {
      tempUser.splice(realIndex, 1);
    }
    setSelectedUserIds(() => [...tempUser]);
  };

  const formChangeHandler = async (event) => {
    const validationResponse = await inputFieldValidator(event);
    const elm = document.getElementById("err_" + event.target.id);
    if (validationResponse.valid) {
      setSelectedUserIds((prev) => [
        ...prev,
        { index: event.target.forIndex, value: event.target.value },
      ]);
      elm.innerText = "";
      elm.style.display = "none";
    } else {
      elm.innerText = validationResponse["error_message"];
      elm.style.display = "block";
    }
  };

  const getUsers = async () => {
    const users = await getUsersLimitNoffset(0, 20, false, "Active Users");
    if (users.status) {
      setTimeout(() => {
        if (!users.data.length) {
          setUserFound(false);
        } else {
          setUsers(users.data);
          setUserFound(true);
        }
      }, 500);
    }
  };

  const getUserByName = async (name) => {
    const users = await getUsersByName(name);
    if (users.status) {
      setTimeout(() => {
        if (!users.data.length) {
          setUserFound(false);
        } else {
          setUsers(users.data);
          setUserFound(true);
        }
      }, 500);
    }
  };

  const handleDropdownSearch = (e, type = "default") => {
    /**
     * making sure api gets called only once
     */
    const inputValue = String(e.target.value).trim();
    if (inputValue === "") {
      type = "default";
      setSearchSelector((prev) => ({
        ...prev,
        [e.target.id]: null,
      }));
    }
    if (searchSelector[e.target.id]) {
      return;
    }
    switch (e.target.id) {
      case `${e.target.id}`:
        document.getElementById(
          "userSearchView_" + String(e.target.id).split("_")[1]
        ).style.display = "block";
        if (!users.length && type === "default" && inputValue === "") {
          getUsers(20);
        } else if (type === "search" && inputValue.length >= 1) {
          setUsers([]);
          getUserByName(inputValue);
        } else if (type === "default" && inputValue.length >= 1) {
          setUsers([]);
          getUserByName(inputValue);
        } else {
          return;
        }
        break;
      default:
        break;
    }
  };

  const selectDropdown = (e, type) => {
    /**
     * pass the select event through form change handler
     * and accordingly manipulate elements
     */
    let tempEvent = {};
    switch (type) {
      case "users":
        // 'assigneeOwner-1_1'
        const forId = parseInt(
          String(String(e.target.id).split("_")[0].split("-")[1])
        );

        tempEvent = {
          target: {
            forIndex: forId,
            name: "assignee_owner",
            innerText: e.target.innerText,
            id: "assigneeOwner" + "_" + forId,
            value: parseInt(e.target.id.split("_")[1]),
          },
        };
        formChangeHandler(tempEvent);
        document.getElementById(tempEvent.target.id).value =
          tempEvent.target.innerText;
        setSearchSelector((prev) => ({
          ...prev,
          [tempEvent.target.id]: tempEvent.target.innerText,
        }));
        document.getElementById("userSearchView_" + forId).style.display =
          "none";
        break;
      default:
        break;
    }
  };

  const resetassigneeOwnerSelected = (e) => {
    const evId = e.target.id.split("-")[1];
    const tIndex = parseInt(String(evId).split("_")[1]);

    setSearchSelector((prev) => ({
      ...prev,
      [evId]: "",
    }));
    setUsers([]);

    const tempUser = selectedUserIds;
    const realIndex = tempUser.map((u) => u.index).indexOf(tIndex);
    if (realIndex >= 0) {
      tempUser.splice(realIndex, 1);
    }
    setSelectedUserIds(() => [...tempUser]);
    document.getElementById(evId).value = "";
    document.getElementById(evId).focus();
  };

  const handleRemoveAction = (e) => {
    if (actions.length > 1) {
      const tA = actions;
      tA.splice(e.target.dataset.forindex, 1);
      setActions((prev) => [...tA]);
    } else {
      return;
    }
  };

  const handleSetActionType = (e) => {
    const id = String(e.target.value).split("_")[1];
    const value = String(e.target.value).split("_")[0];
    const tempAction = [...actions];
    if (value === "delayed") {
      tempAction[id]["properties"].scheduled_at = structuredClone(
        APP_DATA.workflowRules.scheduling
      );
    } else {
      tempAction[id]["properties"].scheduled_at = null;
    }
    setActions(tempAction);
    return;
  };

  const handleScheduling = (e) => {
    const tempAction = [...actions];
    const evType = String(e.target.id).split("_")[0];
    const evId = parseInt(String(e.target.id).split("_")[1]);
    const el = "err_delay_" + evId;
    tempAction[evId]["properties"].scheduled_at[evType] =
      parseInt(e.target.value) || 0;
    if (
      evType === "minutes" &&
      !tempAction[evId].properties.scheduled_at.days &&
      !tempAction[evId].properties.scheduled_at.hours
    ) {
      if (parseInt(e.target.value) >= 5) {
        document.getElementById(el).style.display = "none";
      } else {
        document.getElementById(el).style.display = "block";
      }
    } else {
      if (!parseInt(e.target.value)) {
        if (
          tempAction[evId].properties.scheduled_at.minutes >= 5 ||
          tempAction[evId].properties.scheduled_at.hours ||
          tempAction[evId].properties.scheduled_at.days
        ) {
          document.getElementById(el).style.display = "none";
        } else {
          document.getElementById(el).style.display = "block";
        }
      } else {
        document.getElementById(el).style.display = "none";
      }
    }
    setActions(tempAction);
  };

  return (
    <React.Fragment>
      <div className="wstep">
        <div className="count">
          <span>3</span>
        </div>
        <div className="step-info">
          <div className="frst-hlf">
            <h3 className="actn-hdr">
              Set actions to be performed and trigger preference to your
              workflow
              <i
                className="info-icon"
                data-original-title="Set any actions you want the system to perform automatically when the above selected trigger"
              ></i>
            </h3>
          </div>

          {actions.map((act, i) => (
            <React.Fragment key={"action-list" + i}>
              <div className="crm-radio-container">
                <label className="crm-radio-check rgt-mgn">
                  <span>Immediate</span>
                  <input
                    type="radio"
                    name={"actionType_" + i}
                    value={"immediate_" + i}
                    className="crm-form-ctrl"
                    onClick={(e) => handleSetActionType(e)}
                    checked={!actions[i]["properties"].scheduled_at}
                  />
                  <span className="crm-radio-checkmark"></span>
                </label>
                <label className="crm-radio-check rgt-mgn">
                  <span>Delayed action</span>
                  <input
                    type="radio"
                    name={"actionType_" + i}
                    value={"delayed_" + i}
                    className="crm-form-ctrl"
                    onClick={(e) => handleSetActionType(e)}
                    checked={actions[i]["properties"].scheduled_at}
                  />
                  <span className="crm-radio-checkmark"></span>
                </label>
              </div>
              {actions[i]["properties"].scheduled_at ? (
                <div className="scnd-hlf">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "50%",
                    }}
                  >
                    <div className="crm-form-row">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field">
                            <select
                              id={"days_" + i}
                              onChange={(e) => handleScheduling(e)}
                              className="crm-form-ctrl floating-select"
                            >
                              {mode === "edit" ? (
                                <option selected disabled>
                                  {actions[i]["properties"].scheduled_at.days}
                                </option>
                              ) : null}
                              {Array.from(Array(6).keys()).map((v, i) => (
                                <option value={v} key={"options_d" + i}>
                                  {v}
                                </option>
                              ))}
                            </select>
                            <label
                              alt="days"
                              htmlFor={"days_" + i}
                              placeholder="Day(s)"
                              className="floating-label"
                            >
                              Day(s)
                            </label>
                            <span
                              className="crm-inp-error"
                              id={"err_days_" + i}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-form-row">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field">
                            <select
                              id={"hours_" + i}
                              onChange={(e) => handleScheduling(e)}
                              className="crm-form-ctrl floating-select"
                            >
                              {mode === "edit" ? (
                                <option selected disabled>
                                  {actions[i]["properties"].scheduled_at.hours}
                                </option>
                              ) : null}
                              {Array.from(Array(24).keys()).map((v, i) => (
                                <option key={"options_h" + i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                            <label
                              alt="hours"
                              htmlFor={"hours_" + i}
                              placeholder="Hour(s)"
                              className="floating-label"
                            >
                              Hour(s)
                            </label>
                            <span
                              className="crm-inp-error"
                              id={"err_hours_" + i}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-form-row">
                      <div className="crm-form-grp">
                        <div className="crm-form-grp">
                          <div className="crm-form-field">
                            <select
                              id={"minutes_" + i}
                              onChange={(e) => handleScheduling(e)}
                              className="crm-form-ctrl floating-select"
                            >
                              {mode === "edit" ? (
                                <option selected disabled>
                                  {
                                    actions[i]["properties"].scheduled_at
                                      .minutes
                                  }
                                </option>
                              ) : null}
                              {Array.from(Array(60).keys()).map((v, i) => (
                                <option key={"options_m" + i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                            <label
                              alt="minutes"
                              htmlFor={"minutes_" + i}
                              placeholder="Miniute(s)"
                              className="floating-label"
                            >
                              Miniute(s)
                            </label>
                            <span
                              className="crm-inp-error"
                              id={"err_minutes_" + i}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: -5,
                      fontSize: 12,
                      color: "red",
                      display: "none",
                    }}
                    id={`err_delay_${parseInt(i)}`}
                  >
                    Delay should be atleat 5 minutes
                  </div>
                </div>
              ) : null}
              <div className="scnd-hlf">
                <div className="crm-form-row">
                  <div className="crm-form-grp">
                    <div className="crm-form-field col-6">
                      <select
                        data-index={parseInt(i)}
                        id={"actionType_" + parseInt(i)}
                        className="crm-form-ctrl floating-select"
                        onChange={(e) => handleActionTypeSelector(e)}
                        // defaultValue={act.type}
                      >
                        <optgroup label="Available actions">
                          <option
                            value="reassign"
                            selected={act.type === "reassign"}
                          >
                            Reassign
                          </option>
                          <option
                            value="webhook_trigger"
                            selected={act.type === "webhook_trigger"}
                          >
                            Webhook
                          </option>
                          <option
                            value="send_mail"
                            selected={act.type === "send_mail"}
                          >
                            Send email
                          </option>
                        </optgroup>
                      </select>
                      <label
                        alt="Action type"
                        htmlFor=""
                        placeholder="Action type"
                        className="floating-label"
                      >
                        Action type
                      </label>
                      <span
                        className="crm-inp-error"
                        id="err_actionType"
                      ></span>
                    </div>
                    {i === actions.length - 1 ? (
                      <div className="wflow-actions">
                        <i
                          className="crm-action-plus"
                          onClick={() => handleAddActionHolder()}
                        ></i>
                        {i != 0 ? (
                          <i
                            data-forindex={i}
                            className="crm-dlt-border"
                            onClick={(e) => handleRemoveAction(e)}
                          ></i>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                {actions[i].type === "reassign" ? (
                  <div className="crm-form-row">
                    <div className="crm-form-grp">
                      <div className="crm-form-field req col-6">
                        <input
                          type="text"
                          className="crm-form-ctrl floating-input"
                          id={"assigneeOwner_" + parseInt(i)}
                          autoComplete="off"
                          onMouseDown={(e) => {
                            handleDropdownSearch(e);
                          }}
                          onChange={(e) => handleDropdownSearch(e, "search")}
                          placeholder=" "
                          readOnly={
                            searchSelector[`assigneeOwner_${parseInt(i)}`]
                          }
                        />
                        <label
                          alt="Assign to"
                          htmlFor={"assigneeOwner_" + parseInt(i)}
                          placeholder="Assign to"
                          className="floating-label"
                        >
                          Assign to
                        </label>
                        {!searchSelector[`assigneeOwner_${parseInt(i)}`] ? (
                          <i className="crm-search-icon"></i>
                        ) : (
                          <i
                            className="crm-action-close"
                            id={"icon-assigneeOwner_" + parseInt(i)}
                            onClick={(e) => resetassigneeOwnerSelected(e)}
                          ></i>
                        )}

                        <div
                          className="crm-auto-sgst"
                          id={"userSearchView_" + parseInt(i)}
                          onMouseLeave={() =>
                            (document.getElementById(
                              "userSearchView_" + parseInt(i)
                            ).style.display = "none")
                          }
                        >
                          <ul>
                            {users.length && usersFound ? (
                              users.map((u, j) => (
                                <li
                                  key={`usersList-${parseInt(i)}_` + j}
                                  style={{}}
                                >
                                  <span
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      padding: "0.56rem 0.62rem",
                                    }}
                                    id={`assigneeOwner-${parseInt(i)}_${
                                      u.user_id
                                    }`}
                                    onClick={(e) => selectDropdown(e, "users")}
                                  >
                                    {u?.user_firstname + " " + u?.user_lastname}
                                  </span>
                                </li>
                              ))
                            ) : usersFound ? (
                              <i className="drpdwn-loader show"></i>
                            ) : (
                              <div
                                style={{
                                  height: 60,
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 8,
                                }}
                              >
                                <p>No users found</p>
                              </div>
                            )}
                          </ul>
                        </div>

                        <span
                          className="crm-inp-error"
                          id={`err_assigneeOwner_${parseInt(i)}`}
                        ></span>
                      </div>
                    </div>
                  </div>
                ) : actions[i].type === "send_mail" ? (
                  <ActionSendMail
                    {...props}
                    forIndex={i}
                    key={"forAction_" + i}
                    scheduling={actions[i].properties.scheduled_at}
                    ref={(el) => sendMailCompChildren.current.splice(i, 1, el)}
                  />
                ) : actions[i].type === "webhook_trigger" ? (
                  <ActionWebhook
                    {...props}
                    forIndex={i}
                    key={"forAction_" + i}
                    scheduling={actions[i].properties.scheduled_at}
                    ref={(el) => webhookCompChildren.current.splice(i, 1, el)}
                  />
                ) : null}
                {actions.length != 1 && i != actions.length - 1 ? (
                  <hr className="hr-actions" />
                ) : null}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
});

export default ActionsToPerform;
