import axios from "axios";
import { APISuccessResponse } from ".";
import { API_URL } from ".";
import { getAuthToken } from ".";

export const getApiUsageAPI = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/usage/get_api_usage`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};

export const getRecordsCountAPI = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + `/usage/get_records_count`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        })
        .then(async (res) => {
          console.log(res);
          resolve(await APISuccessResponse(res));
        })
        .catch(async (e) => {
          console.log(e);
          reject({ status: false, data: [] });
        });
    });
  };

  export const getIntegrationCountAPI = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + `/usage/get_extensions_count`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        })
        .then(async (res) => {
          console.log(res);
          resolve(await APISuccessResponse(res));
        })
        .catch(async (e) => {
          console.log(e);
          reject({ status: false, data: [] });
        });
    });
  };

  export const getStorageCountAPI = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + `/usage/get_storage_usage`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        })
        .then(async (res) => {
          console.log(res);
          resolve(await APISuccessResponse(res));
        })
        .catch(async (e) => {
          console.log(e);
          reject({ status: false, data: [] });
        });
    });
  };

  export const getUsersUsageAPI = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + `/usage/get_users_usage`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        })
        .then(async (res) => {
          console.log(res);
          resolve(await APISuccessResponse(res));
        })
        .catch(async (e) => {
          console.log(e);
          reject({ status: false, data: [] });
        });
    });
  };