import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const SubjectInput = forwardRef((props, ref) => {
  const inRef = useRef(null);
  const [dfValue, setDfValue] = useState(null);

  useImperativeHandle(ref, () => ({
    updateEmailSubject(updatedSubject) {
      inRef.current.value = updatedSubject;
      const tempEvent = {
        target: {
          id: "email_subject",
          value: updatedSubject,
          selectionStart: String(updatedSubject).length,
        },
      };
      props.functions.fc(tempEvent);
    },
    getInnerText() {
      return inRef.current.value;
    },
  }));

  useEffect(() => {
    if (props?.defaultValue) {
      inRef.current.value = props?.defaultValue;
      const tempEvent = {
        target: {
          id: "email_subject",
          value: props?.defaultValue,
          selectionStart: String(props?.defaultValue).length,
        },
      };
      props.functions.fc(tempEvent);
    }
    setDfValue(props?.defaultValue);
  }, [props?.defaultValue, dfValue]);

  return (
    <React.Fragment>
      <input
        ref={inRef}
        type="text"
        placeholder="Subject"
        id={props.id}
        autoComplete="off"
        onChange={(e) => props.functions.fc(e)}
        onClick={(e) => props.functions.cp(e)}
        onKeyDown={(e) => props.functions.hc(e)}
        className="crm-form-ctrl floating-input sbjct"
      />
    </React.Fragment>
  );
});

export default SubjectInput;
