import React, { useEffect,useRef,useState } from "react";
import DashboardDefaultView from "../components/dashboard/dashboardDefaultView";
import DashboardManagerView from "../components/dashboard/dashboardManagerView";
import { getUserAccessInfo } from "../utils";

const DashboardView = () => {
  const [view, setView] = useState("default");
  const [userDetails, setUserDetails] = useState({});

  const select = useRef(null);

  useEffect(() => {
    const userInfo = getUserAccessInfo();
    if (userInfo?.user_name) {
      setUserDetails({ userName: userInfo.user_name });
    }
  }, []);

  const renderView = (view) => {
    switch (view) {
      case "manager":
        return <DashboardManagerView />;
      default:
        return <DashboardDefaultView />;
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">
            <i className="org-icon"></i>
            Welcome {userDetails?.userName}
          </h1>
        </div>
      </div>
      {renderView(view)}
    </React.Fragment>
  );
};

export default DashboardView;
