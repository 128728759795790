import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getEnumsByModule = async (module) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/enums/module?module=" + module, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const updateEnumsByModuleAPI = async (payload, module, fieldName) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL + `/enums/?module=${module}&field_name=${fieldName}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const updateEnumBulkByAPI = async (payload, module) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + `/enums/bulk?module=${module}`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};
