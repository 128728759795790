import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  
  useImperativeHandle,
} from "react";
import LeadsTable from "./module_tables/leadsTable";
import DealsTable from "./module_tables/dealsTable";
import ContactsTable from "./module_tables/contactsTable";
import AccountsTable from "./module_tables/accountsTable";
import TasksTable from "./module_tables/tasksTable";
import CallsTable from "./module_tables/callsTable";
import MeetingsTable from "./module_tables/meetingsTable";
import NewPagination from "../common/newPagination";

export const ModuleDataTables = forwardRef((props, ref) => {
  const pagination = useRef(null);
  const deleteIdsList = useRef([]);
  const [noResultMessage, setNoResultMessage] = useState(
    "Bulk update search view"
  );
  const [loading, setLoading] = useState(false);
  const [btnDelete, setBtnDelete] = useState(false);
  const [tableData, setTableData] = useState({ data: [], count: 0 });

  useImperativeHandle(ref, () => ({
    setTableData: (data) => {
      setTableData(data);
    },
    setLoading: (s) => {
      setLoading(s);
    },
    handlePaginatedFetchData: (pageData) => {
      handlePaginatedFetchData(pageData);
    },
    setNoResultMessage: (message) => {
      setNoResultMessage(message);
    },
  }));

  const renderDataTable = (module) => {
    switch (module) {
      case "leads":
        return (
          <LeadsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "deals":
        return (
          <DealsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "contacts":
        return (
          <ContactsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "accounts":
        return (
          <AccountsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "tasks":
        return (
          <TasksTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "calls":
        return (
          <CallsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
      case "meetings":
        return (
          <MeetingsTable
            {...props}
            loading={loading}
            updateData={tableData.data}
            setBtnDelete={setBtnDelete}
            deleteIdsList={deleteIdsList}
            noResultMessage={noResultMessage}
          />
        );
    }
  };

  const handleMassUpdate = () => {
    if (!btnDelete) {
      props.functions.openToast("Please select one record", "error");
      return;
    }
    props.functions.openModal("massUpdate", {
      redirect: props.module,
      msgInfo: "Mass update",
      properties: props.data.properties,
      triggerPoint: "bulkupdate",
      data: deleteIdsList.current,
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    setLoading(true);
    setBtnDelete(false);
    // setPageLimit(pageData.limit);
    await props.functions.handlePagination(pageData);
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  return (
    <React.Fragment>
      {tableData.count ? (
        <div className="crm-top-menu mass-update">
          <div className="menu-lft">
            <div className="hdr-btn-drpdwn mr15">
              <div
                className={`lhs-prt ${btnDelete ? "" : "disabled"}`}
                onClick={btnDelete ? () => handleMassUpdate() : undefined}
              >
                <span>Mass Update</span>
              </div>
            </div>
          </div>
          <div className="menu-rgt">
            <div className="total_count">
              <div className="total_title">Total Records: </div>
              <div className="total_number">
                <strong>{tableData.count}</strong>
              </div>
            </div>
            <NewPagination
              ref={pagination}
              totalRecords={tableData.count}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
            />
          </div>
        </div>
      ) : null}

      {renderDataTable(props.module)}
    </React.Fragment>
  );
});
