import axios from "axios";
import { APISuccessResponse, axiosPublic, ejectInterceptors } from ".";
import { API_URL } from ".";
import qs from "qs";
import { envInjector } from "../env";

export const AUTH_CACHE_NAME = "refreshAuthCache";

export const loginAPI = async (reqPayload) => {
  let data = qs.stringify({
    username: reqPayload.username,
    password: reqPayload.password,
  });
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/auth/login", data, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const sendResetPasswordMailAPI = async (emailId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL +
          "/auth/send_pwd_reset_link?email=" +
          encodeURIComponent(emailId),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updatePasswordAPI = async (resetData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/auth/reset-password", resetData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const validatePasswordResetTokenAPI = async (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/auth/check_token_validity?token=" + token, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccessTokenAPINew = (refreshToken) => {
  return new Promise((resolve, reject) => {
    axiosPublic
      .post(API_URL + "/auth/refresh",{ token: refreshToken },)
      .then(async (res) => {
        console.log(res);
        ejectInterceptors()
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccessTokenAPI = (refreshToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + "/auth/refresh",
        { token: refreshToken },
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const setCachedData = async (cacheableData) => {
  return new Promise(async (resolve, reject) => {
    if ("caches" in window) {
      const cacheData = new Response(JSON.stringify(cacheableData));
      const cache = await caches.open(AUTH_CACHE_NAME);
      cache.put(envInjector.REACT_APP_DOMAIN_URL, cacheData);
      resolve({ message: "caches successfully saved", status: true });
    } else {
      reject({ message: "No caches found", status: false });
    }
  });
};

export const getCachedData = async () => {
  return new Promise((resolve, reject) => {
    if ("caches" in window) {
      window.caches
        .open(AUTH_CACHE_NAME)
        .then((cs) => {
          cs.match(envInjector.REACT_APP_DOMAIN_URL)
            .then((res) => {
              res
                .json()
                .then((c) => {
                  resolve(c);
                })
                .catch(() => {
                  reject("no cache found");
                });
            })
            .catch(() => {
              reject("no cache found");
            });
        })
        .catch(() => {
          reject("no cache found");
        });
    } else {
      console.log("cache is not available in the window");
      reject("no cache found");
    }
  });
};

export const deleteCachedRefresh = async () => {
  return new Promise((resolve, reject) => {
    caches.open(AUTH_CACHE_NAME).then((cache) => {
      cache
        .delete(envInjector.REACT_APP_DOMAIN_URL)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  });
};
