import React from "react"
import ContentLoader from "react-content-loader"

export function DonutChartLoader(props){
    return (
        <React.Fragment>
            <ContentLoader 
                speed={2}
                width={229}
                height={229}
                viewBox="0 0 229 229"
                backgroundColor="#f3f3f3"
                foregroundColor="#ccc"
                {...props}
            >
                <path d="M 36 115 c 0.1 14.2 4 27.5 10.6 39 l -29.5 17 c -9.5 -16.5 -15 -35.6 -15.1 -56 h 34 z M 46.7 75 L 17.2 58 c 9.9 -17 24.1 -31.2 41.2 -41 l 17 29.4 C 63.5 53.3 53.6 63.1 46.7 75 z M 74.9 182.3 C 86.4 189 99.8 192.9 114 193 v 34 c -20.5 -0.1 -39.6 -5.6 -56.1 -15.2 l 17 -29.5 z M 170.6 16.9 l -17 29.4 C 142 39.8 128.7 36 114.5 36 h -0.5 V 2 h 0.5 c 20.4 0 39.6 5.4 56.1 14.9 z M 182.6 153.5 l 29.5 17 c -9.9 17.1 -24 31.4 -41.1 41.3 l -17 -29.5 c 11.9 -6.9 21.8 -16.8 28.6 -28.8 z M 227 114.5 v 0.5 h -34 v -0.5 c 0 -14.2 -3.8 -27.5 -10.4 -39 l 29.5 -17 c 9.5 16.5 14.9 35.6 14.9 56 z M 74.9 182.3 l -17 29.4 C 41.1 201.9 27 187.9 17.2 171 l 29.5 -17 c 6.8 11.7 16.5 21.5 28.2 28.3 z" /> 
                <path d="M 114 2 v 34 c -14.1 0.1 -27.3 3.9 -38.6 10.4 L 58.4 17 C 74.7 7.5 93.7 2.1 114 2 z M 46.7 75 C 39.9 86.6 36 100.1 36 114.5 v 0.5 H 2 v -0.5 C 2 93.9 7.5 74.6 17.2 58 l 29.5 17 z M 154 182.4 l 17 29.5 c -16.6 9.7 -35.9 15.2 -56.5 15.2 h -0.5 v -34 h 0.5 c 14.4 -0.1 27.9 -4 39.5 -10.7 z M 212.1 58.5 l -29.5 17 c -6.9 -12.1 -17 -22.2 -29.1 -29.1 l 17 -29.4 c 17.3 9.9 31.7 24.3 41.6 41.5 z M 227 115 c -0.1 20.2 -5.5 39.2 -14.9 55.5 l -29.5 -17 c 6.5 -11.4 10.3 -24.5 10.4 -38.5 h 34 z" />
            </ContentLoader>
        </React.Fragment>
    )
}