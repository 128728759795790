import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getDealsLimitNoffset } from "../apis/dealAPIs";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import {
  echo,
  forEach,
  getDate,
  getSortBy,
  getObjValue,
  dealsFormatter,
  debounceOptFunc,
  organiseColumns,
  getManagedColumns,
  getUserAccessInfo,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import Range from "../components/datepicker/range";
import CustomFilter from "../components/filters/customFilter";
import { useOutside } from "../customHooks/useOutside";
import {
  searchAPI,
  searchThroughAPIs,
  downloadCSVExportedFiles,
  getExportFileCustomFilter,
  getExportFileDateFilter,
  getExportFileFixedFilter,
  getExportFileSearchFilter,
} from "../apis";
import createResizer from "../utils/resizerHandler";
import { APP_DATA } from "../app_data";
import { Tooltip } from "react-tooltip";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";
import { useCookies } from "react-cookie";
import CONSTANTS from "../app_data/constants";

const MODULE_NAME = "deals";

export default function DealsView() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);
  const {
    openModal,
    openToast,
    closeModal,
    getExtraData,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const [cookies, setCookies] = useCookies(CONSTANTS.cookieNames.extraData);
  
  const dropdownMenu = useRef();
  const searchRef = useRef(null);
  const datePicker = useRef(null);
  const pagination = useRef(null);
  const deleteIdsList = useRef([]);
  const selectFilter = useRef(null);
  const customFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);
  const currency = useRef(cookies[CONSTANTS.cookieNames.extraData]);

  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All Deals");
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [dealsPermission, setDealsPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const [filter, setFilter] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName,setDisplayName] = useState({})

  useEffect(() => {
    const dealsFilter = JSON.parse(sessionStorage.getItem("dealsFilter"));
    if (
      dealsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(dealsFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: dealsFilter,
      }));
      getDealsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      if (location.state?.filter?.value) {
        getDeals(location.state.filter.value);
        setSelectedFilter(location.state.filter.value);
        setFilter(true);
        document.getElementById("select-value").innerText =
          APP_DATA.fixedFilters.deals.find(
            (c) => c.value === location.state.filter.value
          )?.name;
        //we're navigating to clear state so that prev filter is not maintained
        navigate(location.pathname, {});
      } else {
        //clearing filters after create, edit ,clone or delete
        //as sometimes no records will create confusion to users
        // clearFixedFilter();
        // clearDateFilter();
        // clearAll();
        // searchRef.current.value = "";
        // clearSort();

        // getDeals();
        handlePaginatedFetchData(pageData);
      }
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    setBtnDelete(false);
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const deals = getUserAccessInfo("Deals");

    if (deals?.permission) {
      setDealsPermission(deals.permission);
    } else {
      setDealsPermission({});
    }
    getModuleDataTypesAPI("deals")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName);
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
    if (location.state?.id) {
      handleEdit(location.state?.id, "vec");
      navigate(location.pathname, {});
    }
  }, []);

  useEffect(() => {
    createResizer();
  }, [deals, location.search,loading]);

  const getDeals = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);

    const deals = await getDealsLimitNoffset(
      0,
      pageData.limit,
      true,
      selectedFilter,
      APP_DATA.fixedFiltersOwnerFields.deals.includes(selectedFilter)
        ? getUserAccessInfo()?.user_id
        : "",
      sortBy,
      sortField
    );
    if (deals.status) {
      setDeals(
        organiseColumns(
          deals.data.deals,
          getManagedColumns("dealsColumn"),
          "deals"
        )
      );
      setTotalRecords(deals.data?.total_count);
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(e.target.id)) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === deals?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== deals?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs(
        "deals",
        qstring,
        0,
        pageData.limit
      );
      if (searched.status) {
        setTotalRecords(searched.data.total_count);
        setDeals(
          organiseColumns(
            searched.data.deals,
            getManagedColumns("dealsColumn"),
            "deals"
          )
        );
      } else {
        setDeals([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getDeals();
      setFilter(false);
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let e = {
          target: {
            checked: true,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = true;
      } else {
        let e = {
          target: {
            checked: false,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = false;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    //to uncheck select all and disable delete while going to next page
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const deals = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (deals.status) {
        if (deals?.data) {
          setDeals(
            organiseColumns(
              deals.data,
              getManagedColumns("dealsColumn"),
              "deals"
            )
          );
        } else {
          setDeals([]);
        }
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const deals = await searchAPI(
          "deals",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (deals.status) {
          setTotalRecords(deals.data.total_count);
          setDeals(
            organiseColumns(deals.data.deals, getManagedColumns("dealsColumn"))
          );
        } else {
          setDeals([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getDealsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getDealsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const deals = await getDealsLimitNoffset(
        pageData.offset,
        pageData.limit,
        true,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.deals.includes(selectedFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (deals.status) {
        setTotalRecords(deals.data.total_count);
        setDeals(
          organiseColumns(deals.data.deals, getManagedColumns("dealsColumn"))
        );
      } else {
        setDeals([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  const getDealsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setFilter(true);
    setLoading(true);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const deals = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (deals.status) {
        if (deals?.data?.deals?.length > 0) {
          setDeals(
            organiseColumns(
              deals.data.deals,
              getManagedColumns("dealsColumn"),
              "deals"
            )
          );
        } else {
          setDeals([]);
        }
        if (deals.data?.hasOwnProperty("total_count")) {
          setTotalRecords(deals.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setDeals([]);
      setLoading(false);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const getDealsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setFilter(true);
    setLoading(true);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const deals = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (deals.status) {
        if (deals?.data?.deals?.length > 0) {
          setDeals(
            organiseColumns(
              deals.data.deals,
              getManagedColumns("dealsColumn"),
              "deals"
            )
          );
        } else {
          setDeals([]);
        }
        if (deals.data?.hasOwnProperty("total_count")) {
          setTotalRecords(deals.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setDeals([]);
      setLoading(false);
      console.log("err", err);
    }
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      getDeals(value, sortInfo.sortBy, sortInfo.sortField);
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      clearAll();
      clearDateFilter();
      searchRef.current.value = "";
      unCheckRecords();
      setFilter(true);
    }
  };

  const handleDatePicker = (value) => {
    clearFixedFilter();
    clearAll();
    searchRef.current.value = "";
    unCheckRecords();
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getDealsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
    setFilter(true);
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end, label) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getDealsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearDateFilter = (deals) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (deals) {
      getDeals();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Deals");
      document.getElementById("select-value").innerText = "All deals";
      setFilter(false);
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    if (selectedFilter) {
      clearFixedFilter();
    }

    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));

    openFilterSidebar(false);
    getDealsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    setFilter(true);
  };

  const clearAll = (deals) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      setFilter(false);
      if (deals) {
        getDeals("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }

    if (sessionStorage.getItem("dealsFilter")) {
      sessionStorage.removeItem("dealsFilter");
    }
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getDealsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getDealsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getDealsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getDeals(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("dealsColumn")) {
      sessionStorage.removeItem("dealsColumn");
    }
    getDataForManageColumns();
  };

  const handleEdit = (recordId, openEdit) => {
    handleAllMenuSideBar("vecDeal", {
      id: recordId,
      openEdit,
    });
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "dealname") {
      return;
    }

    if (e.target.id === "deal") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getDealsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getDealsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getDealsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getDeals(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Deals",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });
      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }
      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file..",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Deals</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  dealsPermission.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                onClick={dealsPermission.view ? handleFilterSelect : null}
                ref={selectFilter}
              >
                <div className="crm-custom-select-value">
                  <span id="select-value">All deals</span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.deals.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-name={item.name}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search deals"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!dealsPermission.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                dealsPermission.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                dealsPermission.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                dealsPermission.view ? "date-filter" : "date-filter-disabled"
              }
              id="daterange"
              onClick={
                dealsPermission.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                id="t-daterange"
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
              >
                November 13, 2022 - December 12, 2022
              </span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            <div
              id="date-picker"
              className="daterangepicker ltr show-ranges opensright range"
              style={{
                display: showDatePicker ? "block" : "none",
                top: "98.1406px",
                left: "auto",
                right: "0px",
              }}
              ref={datePicker}
            >
              <Range
                showCalendar={showDatePicker}
                datePickerFilter={datePickerFilter}
                functions={{ handleDatePicker, handleCallback }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              dealsPermission.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                dealsPermission.create
                  ? () => handleRSidebar("createDeal")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Deal</span>
            </div>
            <div
              className="rhs-prt"
              onMouseEnter={() =>
                (dropdownMenu.current.style.display = "block")
              }
              onMouseLeave={() => (dropdownMenu.current.style.display = "none")}
            >
              <i className="drpdwn-icn"></i>
              <div className="drpdwn-values" ref={dropdownMenu}>
                <span
                  className="import-lead"
                  style={{}}
                  onClick={
                    dealsPermission?.create
                      ? () => handleRSidebar("importDeal")
                      : null
                  }
                >
                  Import Deals
                </span>
              </div>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                btnDelete && dealsPermission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && dealsPermission?.delete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "deals",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? deals?.find((d) =>
                                deleteIdsList.current.includes(d.deal_id)
                              )?.deal
                            : null,
                      })
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>

            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                <span
                  className={`${
                    totalRecords && dealsPermission.edit ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && dealsPermission.edit
                      ? () =>
                          navigate("/app/deals/bulkupdate?mode=bu&target=deals")
                      : null
                  }
                >
                  Mass Update
                </span>
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && dealsPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && dealsPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
              </div>
            </button>
          </div>
        </div>
        {dealsPermission?.view && deals?.length > 0 && Object.keys(displayName).length>0 && (
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total deals: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
        </div>)}
      </div>

      {/* table section  */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {deals?.length > 0 && Object.keys(displayName).length>0 &&  dealsPermission?.view && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                onClick={
                  dealsPermission?.view
                    ? () =>
                        handleRSidebarManageColumns("manageColumns", {
                          applyManageColumn,
                          clearManageColumn,
                          columns: columnInfo,
                          module: "deals",
                          sessModule: "dealsColumn",
                        })
                    : null
                }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {deals?.length > 0 && Object.keys(displayName).length>0 && dealsPermission?.view && !loading && (
                  <div className="crm-td crm-td-dealname">
                    <div className="check-box">
                      <input
                        id="dealname"
                        type="checkbox"
                        ref={selectAllCheck}
                        className="crm-checkmark"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={deals.length ? false : true}
                      />
                      <label htmlFor="dealname"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "deal_name"
                            ? "sort-highlight"
                            : ""
                        }
                        id="deal"
                        onClick={(e) => {
                          handleSort(e, "deal_name", "dealNm");
                        }}
                      >
                        {/* Deal name */}
                        {echo(displayName["deal_name"])}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "deal_name"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="dealNm"
                      onClick={(e) => handleSort(e, "deal_name", "dealNm")}
                    ></i>
                  </div>
                )}
              </div>
              <div className="crm-scroll-field">
                {deals?.length > 0 &&
                  dealsPermission?.view && Object.keys(displayName).length>0 &&
                  Object.keys(deals[0])?.length > 0 && !loading &&
                  Object.keys(deals[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.deals.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.deals[heading]
                              ? APP_DATA.APIColumnMappings.deals[heading]
                              : heading?.split("_").join(" ")} */}
                          {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>

          <div className="crm-tb-bdy">
            {!loading ? (
              dealsPermission?.view && Object.keys(displayName).length>0 && deals?.length > 0 ? (
                deals.map((d, i) => (
                  <div className="crm-tb-rw" key={"dealList_" + i}>
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-dealname">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={d.deal_id}
                            onChange={(e) => handleDeleteCheck(e)}
                          />
                          <label
                            className="deal-name"
                            title=""
                            htmlFor={d.deal_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            dealsPermission?.view
                              ? () => handleEdit(d.deal_id)
                              : null
                          }
                        >
                          <span className="link">{echo(d.deal_name)}</span>
                        </div>

                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              dealsPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              dealsPermission?.edit
                                ? () => handleEdit(d.deal_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        dealsPermission?.view
                          ? () => handleEdit(d.deal_id)
                          : null
                      }
                    >
                      {deals?.length > 0 &&
                        Object.keys(deals[0])?.length > 0 &&
                        Object.keys(deals[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.deals.includes(heading) && (
                              <div data-colpos={i} className={`crm-td `}>
                                <span>
                                  {!APP_DATA.formattingColumns.deals.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(d, heading))
                                    : dealsFormatter(
                                        heading,
                                        getObjValue(d, heading),
                                        currency.current.currency_symbol
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!dealsPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-dealname"}
                module="deal"
              />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              module={"deals"}
              columnInfo={columnInfo}
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
            />
          )}
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
