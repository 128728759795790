import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { getUsersLimitNoffset } from "../../apis/usersAPI";
import TextEditor from "./textEditor";
import { APP_DATA } from "../../app_data";
import { useLocation } from "react-router-dom";
import { getModuleDataTypesAPI } from "../../apis/workflowAPIs";
import { getEmailTemplateByModule } from "../../apis/emailTemplateAPIs";
import SubjectInput from "./comps/SubjectInput";
import UserEmailSelector from "./comps/userEmailSelector";

const ActionSendMail = forwardRef((props, ref) => {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const emailBodyEditor = useRef(null);
  const emailSubjectRef = useRef(null);
  const selected = useRef(
    structuredClone({ [props.forIndex]: { users: [], cc: [], bcc: [] } })
  );
  const formData = useRef(
    structuredClone(
      APP_DATA.workflowRules.actions.find((wf) => wf.type == "send_mail")
    )
  );

  const [users, setUsers] = useState([]);
  const [moduleField, setModuleFields] = useState([]);
  const [mode, setMode] = useState(urlSearch.get("mode"));
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [remoteEmailSettings, setRemoteEmailSettings] = useState(null);
  const [cursorPosition, setCursorposition] = useState({ subject: 0, body: 0 });

  useEffect(() => {
    const module = String(urlSearch.get("target")).split("-")[2];
    getEmailTempates(module);
    getModuleProperties(module);
    if (mode === "edit") {
      setupEditMode();
    }
    const selectEmailTemplate = document.getElementById("selectEmailTemplate");
    if (selectEmailTemplate) {
      handleLoadEmailTemplate(null);
      selectEmailTemplate.selectedIndex = 0;
    }
  }, [urlSearch.get("target").split("-")[2]]);

  useEffect(() => {
    getUsers();
  }, []);

  const checkExistanceOfRemoteWebhook = (rWFR) => {
    try {
      if (rWFR.actions[props.forIndex].type === "send_mail") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const setupEditMode = () => {
    try {
      const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
      if (rWFR) {
        if (rWFR.hasOwnProperty("actions")) {
          if (rWFR.actions.length && checkExistanceOfRemoteWebhook(rWFR)) {
            setRemoteEmailSettings(rWFR.actions[props.forIndex]);
          } else {
            setMode("create");
          }
        } else {
          setMode("create");
        }
      } else {
        setMode("create");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getEmailTempates = async (module) => {
    const templates = await getEmailTemplateByModule(module);
    if (templates.status) {
      setEmailTemplates(templates.data);
    }
  };

  useImperativeHandle(ref, () => ({
    validate(index) {
      console.log(formData.current, "aasdasd");
      let finalValidation = 1;
      for (const f in formData.current.properties) {
        let elm = document.getElementById(
          "err_" + String(f).toLocaleLowerCase() + "-" + index
        );
        if (f === "email_to") {
          if (users && selected.current[index]["users"].length) {
            const emailList = new Array();
            selected.current[index]["users"].map((u, i) => {
              const usersInfo = users.find((l) => l.user_id === parseInt(u));
              emailList.push(usersInfo.user_email);
            });
            formData.current.properties[f] = emailList;
            elm.style.display = "none";
          } else {
            finalValidation &= 0;
            elm.style.display = "block";
            elm.innerText = APP_DATA.errorMessage.requiredField;
          }
        } else if (f === "email_cc") {
          if (users && selected.current[index]["cc"].length) {
            const emailList = new Array();
            selected.current[index]["cc"].map((u, i) => {
              const usersInfo = users.find((l) => l.user_id === parseInt(u));
              emailList.push(usersInfo.user_email);
            });
            formData.current.properties[f] = emailList;
          }
        } else if (f === "email_bcc") {
          if (users && selected.current[index]["bcc"].length) {
            const emailList = new Array();
            selected.current[index]["bcc"].map((u, i) => {
              const usersInfo = users.find((l) => l.user_id === parseInt(u));
              emailList.push(usersInfo.user_email);
            });
            formData.current.properties[f] = emailList;
          }
        } else if (f === "email_subject") {
          if (
            formData.current.properties[f] === undefined ||
            formData.current.properties[f] === ""
          ) {
            finalValidation &= 0;
            elm.style.display = "block";
            elm.innerText = "Email subject can not be blank";
          } else {
            elm.style.display = "none";
          }
        } else if (f === "email_body") {
          if (
            formData.current.properties[f] === undefined ||
            formData.current.properties[f] === ""
          ) {
            finalValidation &= 0;
            elm.style.display = "block";
            elm.innerText = "Email body can not be blank";
          } else {
            elm.style.display = "none";
          }
        }
      }

      if (props.scheduling) {
        if (props.scheduling.minutes < 5) {
          if (props.scheduling.days || props.scheduling.hours) {
            document.getElementById(
              "err_delay_" + props.forIndex
            ).style.display = "none";
          } else {
            finalValidation &= 0;
            document.getElementById(
              "err_delay_" + props.forIndex
            ).style.display = "block";
          }
        }
      }

      if (finalValidation) {
        formData.current.properties.scheduled_at = props.scheduling;
        if (window.sessionStorage.getItem("wf:actions")) {
          let prev = JSON.parse(window.sessionStorage.getItem("wf:actions"));
          prev.push(formData.current);
          window.sessionStorage.setItem("wf:actions", JSON.stringify(prev));
        } else {
          window.sessionStorage.setItem(
            "wf:actions",
            JSON.stringify([formData.current])
          );
        }
        return true;
      } else {
        return false;
      }
    },

    resetFormData() {
      formData.current = structuredClone(
        APP_DATA.workflowRules.actions.find((wf) => wf.type == "send_mail")
      );
    },
  }));

  const formChangeHandler = async (event) => {
    const elm = document.getElementById(
      "err_" + event.target.id + "-" + props.forIndex
    );
    if (event.target.id === "email_subject") {
      calculatePosition(event);
      elm.style.display = "none";
      formData.current.properties[event.target.id] = event.target.value;
    } else if (event.target.id === "email_body") {
      calculatePosition(event);
      elm.style.display = "none";
      formData.current.properties[event.target.id] = event.target.value;
    } else {
      return;
    }
  };

  const getModuleProperties = async (moduleName) => {
    const pr = await getModuleDataTypesAPI(moduleName);
    if (pr.status) {
      const tempCustomFilter = [];
      pr.data.map((property) => {
        if (property.custom_filter) {
          tempCustomFilter.push(property);
        }
      });
      setModuleFields(tempCustomFilter);
    } else {
      //TODO alert and go back
    }
  };

  const getUsers = async (limit = 20, search = "") => {
    let users = {};
    if (limit > -1) {
      users = await getUsersLimitNoffset(0, 0, false, "Active Users");
    }
    if (users.status) {
      setTimeout(() => {
        if (users.data.length) {
          setUsers(users.data);
        }
      }, 500);
    }
  };

  const userSelectionManipulator = (selectionData, action = "add") => {
    if (action === "add") {
      selected.current[selectionData.index][selectionData.type].push(
        selectionData.id
      );
    } else {
      const index = selected.current[selectionData.index][
        selectionData.type
      ].indexOf(selectionData.id);
      if (index > -1) {
        selected.current[selectionData.index][selectionData.type].splice(
          index,
          1
        );
      }
    }
  };

  const insert = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
  };

  const addPlaceholder = (e) => {
    let v;
    const where = String(e.target.id).split("-")[0];
    const fp = `{${String(e.target.id).split("-")[1]}} `;
    if (where === "sub") {
      v = emailSubjectRef.current.getInnerText();
      v = insert(v, cursorPosition.subject, fp);
      emailSubjectRef.current.updateEmailSubject(v);
    } else {
      v = emailBodyEditor.current.getInnerText();
      v = insert(v, cursorPosition.body, fp);
      emailBodyEditor.current.updateEmailBody(v);
    }
  };

  const calculatePosition = (e) => {
    if (e.target.id === "email_subject") {
      setCursorposition((p) => ({ ...p, subject: e.target.selectionStart }));
    } else {
      if (e.target.selectionStart) {
        setCursorposition((p) => ({ ...p, body: e.target.selectionStart }));
      }
    }
  };

  const handleCursorMovement = (e) => {
    if (["38", "40", "37", "39"].includes(e.keyCode)) {
      calculatePosition(e);
    }
  };

  const handleViewCCBCC = (e) => {
    const el = document.getElementById(
      String(e.target.id).split("_")[0] + "_selector" + props.forIndex
    );
    el.style.display = "block";
  };

  const handleLoadEmailTemplate = (e) => {
    let templates = { subject: "", body: "" };
    document.getElementById("template_loader").style.display = "block";
    if (e && e.target.value != "none") {
      templates = emailTemplates.find(
        (et) => et.id === parseInt(e.target.value)
      );
      setTimeout(() => {
        emailBodyEditor.current.updateEmailBody(templates["body"]);
        emailSubjectRef.current.updateEmailSubject(templates["subject"]);
        document.getElementById("template_loader").style.display = "none";
      }, 500);
    } else {
      emailBodyEditor.current.updateEmailBody("");
      emailSubjectRef.current.updateEmailSubject("");
      document.getElementById("template_loader").style.display = "none";
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="wflow-rptsctn email-wflow">
            <p className="email-title">Details of recipients:</p>

            <div className="crm-form-field  mgn cc-optns req">
              <UserEmailSelector
                key={"emailto-" + props.forIndex}
                forIndex={props.forIndex}
                functions={{
                  userSelectionManipulator,
                }}
                settings={{ mode: mode, recipient: "users", label: "To" }}
                loadedUsers={users}
                defaultEmailIds={
                  mode === "edit" && remoteEmailSettings
                    ? remoteEmailSettings.properties["email_to"]
                    : ""
                }
              />

              <div className="optn">
                <span
                  className="cc"
                  id={"cc_opener" + props.forIndex}
                  onClick={(e) => handleViewCCBCC(e)}
                >
                  Cc
                </span>
                <span
                  className="bcc"
                  id={"bcc_opener" + props.forIndex}
                  onClick={(e) => handleViewCCBCC(e)}
                >
                  Bcc
                </span>
              </div>
            </div>

            <div className="cc-mail-wrp">
              <div
                id={"cc_selector" + props.forIndex}
                style={{
                  display:
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_cc"].length
                        ? "block"
                        : "none"
                      : "none",
                }}
                className="crm-form-field mgn cc"
              >
                <UserEmailSelector
                  key={"emailcc-" + props.forIndex}
                  forIndex={props.forIndex}
                  functions={{
                    userSelectionManipulator,
                  }}
                  settings={{ mode: mode, recipient: "cc", label: "CC" }}
                  loadedUsers={users}
                  defaultEmailIds={
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_cc"]
                      : ""
                  }
                />
              </div>

              <div
                id={"bcc_selector" + props.forIndex}
                style={{
                  display:
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_bcc"].length
                        ? "block"
                        : "none"
                      : "none",
                }}
                className="crm-form-field mgn bcc"
              >
                <UserEmailSelector
                  key={"emailbcc-" + props.forIndex}
                  forIndex={props.forIndex}
                  functions={{
                    userSelectionManipulator,
                  }}
                  defaultEmailIds={
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_bcc"]
                      : ""
                  }
                  loadedUsers={users}
                  settings={{ mode: mode, recipient: "bcc", label: "BCC" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="wflow-rptsctn email-wflow">
            <p className="email-title">Compose email and templates</p>
            <div
              className="crt-tmplt"
              onClick={() => props.functions.openRHMenu("createTemplate")}
            >
              <a>
                <span className="plus-icon"></span>
                <span className="txt">Create email template</span>
              </a>
            </div>
            <div className="crm-form-field">
              <select
                id="selectEmailTemplate"
                className="crm-form-ctrl floating-select"
                onChange={(e) => handleLoadEmailTemplate(e)}
              >
                {emailTemplates.length ? (
                  <option value={"none"} selected>
                    None
                  </option>
                ) : null}
                {emailTemplates.length ? (
                  emailTemplates.map((et, i) => (
                    <option value={et.id} key={"op_et" + i}>
                      {et.name}
                    </option>
                  ))
                ) : (
                  <option value={"none"} disabled>
                    No templates available
                  </option>
                )}
              </select>
              <label
                alt="Choose Template"
                htmlFor=""
                placeholder="Select Template"
                className="floating-label"
              >
                Choose Template
              </label>
              <span className="crm-inp-error">
                Choose Template cannot be blank
              </span>
              <span
                className="input-loader"
                id="template_loader"
                style={{ display: "none" }}
              ></span>
            </div>
            <div className="plchldr">
              <div className="crm-form-field req mgn">
                <SubjectInput
                  ref={emailSubjectRef}
                  id="email_subject"
                  functions={{
                    fc: formChangeHandler,
                    cp: calculatePosition,
                    hc: handleCursorMovement,
                  }}
                  defaultValue={
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_subject"]
                      : ""
                  }
                />
                <label
                  alt="Subject"
                  htmlFor="email_subject"
                  placeholder="Subject"
                  className="floating-label"
                >
                  Subject
                </label>
                <span
                  className="crm-inp-error"
                  id={"err_email_subject-" + props.forIndex}
                ></span>
                <div className="slct-hdr">
                  <span className="plus"></span>
                  <span className="txt">Properties</span>
                  <ul className="options" id="">
                    {moduleField.length ? (
                      moduleField.map((mf, i) => (
                        <li key={"mp-" + i}>
                          <span
                            id={"sub-" + mf.field}
                            onClick={(e) => addPlaceholder(e)}
                          >
                            {mf.display_name}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li>
                        <span>No propetires available</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="crm-form-field mgn req">
                <TextEditor
                  id={"email_body"}
                  ref={emailBodyEditor}
                  functions={{ fc: formChangeHandler, cp: calculatePosition }}
                  defaultValue={
                    mode === "edit" && remoteEmailSettings
                      ? remoteEmailSettings.properties["email_body"]
                      : ""
                  }
                />
                <span
                  className="crm-inp-error"
                  id={"err_email_body-" + props.forIndex}
                ></span>
                <div className="slct-hdr">
                  <span className="plus"></span>
                  <span className="txt">Properties</span>
                  <ul className="options" id="">
                    {moduleField.length ? (
                      moduleField.map((mf, i) => (
                        <li key={"mpb" + i}>
                          <span
                            id={"body-" + mf.field}
                            onClick={(e) => addPlaceholder(e)}
                          >
                            {mf.display_name}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li>
                        <span>No propetires available</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default ActionSendMail;
