import React, { useEffect, useRef, useState } from "react";
import { APP_DATA } from "../../../app_data";
import { echo, forEach, getObjValue, callsFormatter } from "../../../utils";
import NoResults from "../../common/noResults";
import DataTableLoader from "../../common/dataTableLoader";
import createResizer from "../../../utils/resizerHandler";
import { getModuleDataTypesAPI } from "../../../apis/workflowAPIs";
import { Tooltip } from "react-tooltip";

export default function CallsTable(props) {
  const selectAllCheck = useRef(null);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [columnInfo, setColumnInfo] = useState([]);
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    if (props.updateData.length) {
      getModuleDataTypesAPI(props.module)
        .then((res) => {
          setColumnInfo(res.data);
          const displayName = res.data?.reduce((acc, curr) => {
            acc[curr.field] = curr.display_name;
            return acc;
          }, {});
          unCheckRecords();
          setDisplayName(displayName);
          setTimeout(() => {
            createResizer();
          }, 200);
        })
        .catch((err) => {
          setColumnInfo([]);
          setDisplayName({});
        });
    }
  }, [props.updateData]);

  const unCheckRecords = () => {
    if (props.deleteIdsList.current?.length > 0) {
      props.deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      props.deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };
  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!props.deleteIdsList.current.includes(parseInt(e.target.id))) {
        props.deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (props.deleteIdsList.current?.length === props.updateData?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = props.deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        props.deleteIdsList.current.splice(i, 1);
      }
      if (props.deleteIdsList.current?.length !== props.updateData?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (props.deleteIdsList.current.length > 0) {
      props.setBtnDelete(true);
    } else {
      props.setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };
  return (
    <div className="tbl-wrpr mass-update">
      {!props.loading &&
        props.updateData.length > 0 &&
        Object.keys(displayName)?.length > 0 && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                // onClick={
                //   callsPermission?.view
                //     ? () =>
                //         handleRSidebarManageColumns("manageColumns", {
                //           applyManageColumn,
                //           clearManageColumn,
                //           columns: columnInfo,
                //           module: "calls",
                //           sessModule: "callsColumn",
                //         })
                //     : null
                // }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
      <div className="crm-table cst-mrgn mtbl mass-update">
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id="resizeMe">
            <div className="crm-fixed-field">
              {props.updateData?.length > 0 &&
                Object.keys(displayName).length > 0 &&
                !props.loading && (
                  <div className="crm-td crm-td-sbjct">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        ref={selectAllCheck}
                        className="crm-checkmark"
                        id="subject"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={props.updateData.length ? false : true}
                      />
                      <label htmlFor="subject"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "call_subject"
                            ? "sort-highlight"
                            : ""
                        }
                        id="sub"
                        // onClick={(e) => {
                        //   handleSort(e, "call_subject", "callSub");
                        // }}
                      >
                        {/* Subject */}
                        {displayName["call_subject"]}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "call_subject"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="callSub"
                      // onClick={(e) => handleSort(e, "call_subject", "callSub")}
                    ></i>
                  </div>
                )}
            </div>
            <div className="crm-scroll-field">
              {!props.loading &&
                props.updateData?.length > 0 &&
                Object.keys(displayName)?.length > 0 &&
                Object.keys(props.updateData[0]).map(
                  (heading, index) =>
                    !APP_DATA.hiddenColumns.calls.includes(heading) && (
                      <div
                        id={`col-${index - 1}`}
                        className={`crm-td r  ${
                          sortInfo.sortKey === heading
                            ? sortInfo.sortBy === "desc"
                              ? "crm-sort sort-active"
                              : "crm-sort sort-active-up"
                            : ""
                        }`}
                      >
                        <span
                        //   onClick={(e) =>
                        //     handleSort(e, heading, `col-${index - 1}`)
                        //   }
                        >
                          {echo(displayName[heading])}
                        </span>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="crm-tb-bdy">
          {!props.loading ? (
            props.updateData?.length > 0 &&
            Object.keys(displayName).length > 0 ? (
              props.updateData.map((c, i) => (
                <div className="crm-tb-rw" key={i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-sbjct">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark ids"
                          id={c.call_id}
                          onChange={(e) => {
                            handleDeleteCheck(e);
                          }}
                        />
                        <label
                          className="acnt-name"
                          title=""
                          htmlFor={c.call_id}
                        ></label>
                      </div>
                      <div className="name">
                        <span className="link">{echo(c.call_subject)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {props.updateData.length > 0 &&
                      Object.keys(displayName).length > 0 &&
                      Object.keys(props.updateData[0])?.map(
                        (heading, i) =>
                          !APP_DATA.hiddenColumns.calls.includes(heading) && (
                            <div
                              key={i}
                              data-colpos={i - 1}
                              className={`crm-td `}
                            >
                              <span>
                                {!APP_DATA.formattingColumns.calls.includes(
                                  heading
                                )
                                  ? echo(getObjValue(c, heading))
                                  : callsFormatter(
                                      heading,
                                      getObjValue(c, heading)
                                    )}
                              </span>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <NoResults message={props.noResultMessage} />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-sbjct"} module="call" />
          )}
        </div>
      </div>
    </div>
  );
}
