import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import "./App.css";
import "react-tooltip/dist/react-tooltip.css";

import DashboardView from "./views/dashboardView";
import LeadsView from "./views/leadsView";
import ContactsView from "./views/contactsView";
import DealsView from "./views/dealsView";
import TaskView from "./views/taskView";
import CallsView from "./views/callsView";
import MeetingsView from "./views/meetingsView";
import AccountsView from "./views/accountsView";
import EmailTemplatesView from "./views/emailTemplatesView";
import CampaignView from "./views/campaignView";
import UsersView from "./views/usersView";
import PersonalSettingsView from "./views/personalSettingsView";
import ContractsView from "./views/contractsView";
import AnalyticsLeads from "./views/analyticsLeadsView";
import AnalyticsDeals from "./views/analyticsDealsView";
import AnalyticsMeetings from "./views/analyticsMeetingsView";
import AnalyticsCalls from "./views/analyticsCallsView";
import GroupsView from "./views/groupsView";
import ExtensionView from "./views/extensionView";
import InstalledCred from "./components/extensionCred/installedCred";
import InstalledToken from "./components/extensionToken/installedToken";
import CustomizationsView from "./views/customizationsView";
import CreateCustomizationsView from "./components/customizations/createCustomization";
import WorkflowsView from "./views/workflowsView";
import ProfileView from "./views/profileView";
import PermissionView from "./views/permissionView";
import WorkflowRules from "./components/workflows/workflowRules";
import LoginView from "./views/loginView";
import ForgotPasswordView from "./views/forgotPasswordView";
import CompanySettingsView from "./views/companyDetailsView";
import RecycleBinView from "./views/recycleBinView";
import UsageView from "./views/usageView";
import ResetPasswordView from "./views/resetPasswordView";
import WebformsView from "./views/webformsView";
import CreateWebForms from "./components/website_tracking/createWebForms";
import LocalSuspense from "./layouts/suspense";
import NotFound from "./components/notfound/notfound";
import TokenExtensionView from "./views/tokenExtensionView";
import UsersDeactivatedView from "./views/usersDeactivatedView";
import BulkUpdate from "./views/bulkUpdate";
import EmailTemplatesCreate from "./components/emailTemplates/emailTemplatesCreate";

// Laxy loaded comps
const DisplayWebforms = React.lazy(() => import("./views/displayWebforms"));
const LandingPage = React.lazy(() => import("./layouts/landingPage"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* 404 Not found page*/}
          <Route path="*" element={<NotFound />} />
          {/* unprotected Routes */}
          <Route
            exact
            path="/"
            element={<Navigate to={"/auth/login"} replace />}
          />
          <Route path="/auth/login" element={<LoginView />} />
          {/* <Route path="/signup" element={<SignUpView />} /> */}
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/reset-password" element={<ResetPasswordView />} />
          <Route
            path="/crm/webforms"
            element={
              <Suspense fallback={<LocalSuspense />}>
                <DisplayWebforms />
              </Suspense>
            }
          />

          {/*-----------end of unprotected Routes-----------------------------------------------*/}

          {/* Protected app routes */}
          <Route
            exact
            path="/app"
            element={
              <Suspense fallback={<LocalSuspense />}>
                <LandingPage />
              </Suspense>
            }
          >
            <Route
              path="/app"
              element={<Navigate to="/app/dashboard" replace />}
            />
            <Route path="/app/dashboard" element={<DashboardView />} />

            {/* Leads Page */}
            <Route path="/app/leads" element={<LeadsView />} />
            <Route path="/app/leads/bulkupdate" element={<BulkUpdate />} />

            {/* Accounts Pages */}
            <Route path="/app/contacts" element={<ContactsView />} />
            <Route path="/app/contacts/bulkupdate" element={<BulkUpdate />} />

            <Route path="/app/accounts" element={<AccountsView />} />
            <Route path="/app/accounts/bulkupdate" element={<BulkUpdate />} />

            {/* Deals Pages */}
            <Route path="/app/deals" element={<DealsView />} />
            <Route path="/app/deals/bulkupdate" element={<BulkUpdate />} />

            <Route path="/app/contracts" element={<ContractsView />} />

            {/* Sales Pages */}
            <Route path="/app/tasks" element={<TaskView />} />
            <Route path="/app/tasks/bulkupdate" element={<BulkUpdate />} />

            <Route path="/app/calls" element={<CallsView />} />
            <Route path="/app/calls/bulkupdate" element={<BulkUpdate />} />

            <Route path="/app/meetings" element={<MeetingsView />} />
            <Route path="/app/meetings/bulkupdate" element={<BulkUpdate />} />

            <Route path="/app/campaigns" element={<CampaignView />} />

            <Route path="/app/templates" element={<EmailTemplatesView />} />
            <Route path="/app/templates/easyCreate" element={<EmailTemplatesCreate/>}/>

            {/* Analyics Pages */}
            <Route path="/app/analytics/leads" element={<AnalyticsLeads />} />

            <Route path="/app/analytics/deals" element={<AnalyticsDeals />} />

            <Route path="/app/analytics/calls" element={<AnalyticsCalls />} />

            <Route
              path="/app/analytics/meetings"
              element={<AnalyticsMeetings />}
            />

            {/* Settings Pages */}
            <Route path="/app/users" element={<UsersView />} />
            <Route
              path="/app/deactivated-users"
              element={<UsersDeactivatedView />}
            />
            <Route path="/app/groups" element={<GroupsView />} />
            <Route path="/app/profiles" element={<ProfileView />} />
            <Route path="/app/personal" element={<PersonalSettingsView />} />
            <Route path="/app/company" element={<CompanySettingsView />} />
            <Route
              path="/app/profiles/permissions"
              element={<PermissionView />}
            />
            <Route path="/app/extensions" element={<ExtensionView />} />

            {/* Customization page */}
            <Route
              path="/app/customizations"
              element={<CustomizationsView />}
            />
            <Route
              path="/app/create-fields"
              element={<CreateCustomizationsView />}
            />

            {/* Workflows page */}
            <Route path="/app/workflows" element={<WorkflowsView />} />
            <Route path="/app/workflows/create" element={<WorkflowRules />} />

            {/* RecycleBin page */}
            <Route path="/app/recyclebin" element={<RecycleBinView />} />

            {/* usage */}
            <Route path="/app/usage" element={<UsageView />} />

            {/* website tracking */}
            <Route path="/app/web-forms" element={<WebformsView />} />
            <Route path="/app/create-web-forms" element={<CreateWebForms />} />
          </Route>
          {/*----------end of Protected app routes--------------------------------------------*/}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
