import axios from "axios";
import { APISuccessResponse, API_URL, getAuthToken } from ".";

export const getAllExtensions = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/extensions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};

export const getInstalledExtension = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/extensions/installed", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};

export const getExtensionById = (extensionId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/extensions/id/${extensionId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};

export const installExtensionById = (installId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL + `/extensions/install?extension_id=${installId}`,
        {
          authorization: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};

export const updateExtensionById = (updateId, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + `/extensions/?extension_id=${updateId}`, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        reject({ status: false, data: [] });
      });
  });
};

export const unInstallExtensionById = (unInstallId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_URL + `/extensions/uninstall?extension_id=${unInstallId}`,
        {
          authorization: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};

export const extensionUploadFile = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `/extensions/upload_file`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getExtensionByName = async (extensionName, installed = false) => {
  return new Promise((resolve, reject) => {
    axios 
      .get(API_URL + `/extensions/name/${extensionName}?installed=${installed}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: []});
      })
  })
};
