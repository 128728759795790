import axios from "axios";
import { API_URL } from ".";
import { APISuccessResponse, getAuthToken } from ".";

export const uploadImageAPI = async (moduleName, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `/utils/upload_image/?module=${moduleName}`, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};

export const deleteImageAPI = async (module, moduleId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + `/utils/delete_image?module=${module}&module_id=${moduleId}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteFileAPI = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/utils/delete_images/?deleted_by=" + id, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const uploadAttachmentAPI = async (file) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `/utils/upload_attachment/`, file, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        reject({ status: false, data: [] });
      });
  });
};
