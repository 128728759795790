import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";
import CONSTANTS from "../app_data/constants";

export const getAllContacts = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/contacts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getContactsByName = async (contactName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/contacts/name/${contactName}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getContactsLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = true,
  contactFilter,
  contactOwner,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/contacts/offset?offset=${offset}&limit=${limit}&count=${count}&contact_filter=${
            contactFilter ? contactFilter : "All Contacts"
          }${
            contactOwner ? `&contact_owner=${contactOwner}` : ""
          }&contact_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewContact = async (reqPayload = {}, webform) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/contacts", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: webform
            ? "Bearer " + CONSTANTS.encryptAppData()
            : getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteContact = async (reqPayload = [], userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/contacts?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getContactById = (contactId = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/contacts/" + contactId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateContactById = (id = 0, reqPayload = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/contacts/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getContactByAccountId = (accountId = 0) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/contacts/accounts/" + accountId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccountByContactId = (contactId = 0) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/contacts/account/" + contactId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
