import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { getAccessTokenAPI, getCachedData, setCachedData } from "./authAPIs";
import { API_URL } from ".";

export const createProfiles = (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/profiles/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getProfiles = (sortBy, sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/profiles/?profile_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateProfileById = (profileId, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + `/profiles/${profileId}`, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        getCachedData()
        .then((c) => {
          setTimeout(() => {
            getAccessTokenAPI(c)
              .then(async (t) => {
                setCachedData(c)
                  .then(async () => {
                    window.localStorage.setItem(
                      "a_d",
                      JSON.stringify({ access_token: t.data["access_token"] })
                    );
                    resolve(await APISuccessResponse(res));
                  })
                  .catch(() => {
                    resolve({ status: false, data: [] });
                  });
              })
              .catch(() => {
                resolve({ status: false, data: [] });
              });
          }, 2000);
        })
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteProfile = async (profileId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        API_URL +
          `/profiles/${profileId}?deleted_by=${userId}&profile_id=${profileId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        getCachedData()
        .then((c) => {
          setTimeout(() => {
            getAccessTokenAPI(c)
              .then(async (t) => {
                setCachedData(c)
                  .then(async () => {
                    window.localStorage.setItem(
                      "a_d",
                      JSON.stringify({ access_token: t.data["access_token"] })
                    );
                    resolve(await APISuccessResponse(res));
                  })
                  .catch(() => {
                    resolve({ status: false, data: [] });
                  });
              })
              .catch(() => {
                resolve({ status: false, data: [] });
              });
          }, 2000);
        })      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getProfileById = (profileId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/profiles/${profileId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getUsersByProfileId = (profileId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/profiles/users/${profileId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
