import {
  getSubscriptionKey,
  subscribeUser,
  unsubscribeUser,
} from "../apis/pushNotificationAPI";
import { getUserAccessInfo } from "../utils";

let isSubscribed = false;
let swRegistration = null;

const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const requestPNPermission = () => {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });
    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      if (localStorage.getItem("sub_token")) {
        try {
          const subToken = JSON.parse(localStorage.getItem("sub_token"));
          unsubscribeUser(getUserAccessInfo()?.user_id, subToken)
            .then((res) => {
              if(res.status){
              localStorage.removeItem("sub_token");
              }
            })
            .catch((err) => {});
        } catch (err) {
          console.log("Error while parsing");
        }
      }
    } else {
      //subscribe API
      if (!localStorage.getItem("sub_token")) {
        getSubscriptionKey()
          .then((res) => {
            if (res.status) {
              localStorage.setItem(
                "applicationServerPublicKey",
                res.data.public_key
              );
              getSubTokenAndSubscribe();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  });
};

const getSubTokenAndSubscribe = () => {
  const applicationServerPublicKey = localStorage.getItem(
    "applicationServerPublicKey"
  );
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  swRegistration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey,
    })
    .then(function (subscription) {
      console.log("User is subscribed.");
      localStorage.setItem("sub_token", JSON.stringify(subscription));
      isSubscribed = true;
      //sending generate sub token and subscribing for PN
      subscribeUser(getUserAccessInfo()?.user_id, subscription);
    })
    .catch(function (err) {
      console.log("Failed to subscribe the user: ", err);
    });
};

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    console.log("Service Worker and Push is supported");
    const swPath = `${process.env.PUBLIC_URL}/serviceWorker.js`;
    navigator.serviceWorker
      .register(swPath)
      .then(function (swReg) {
        console.log("Service Worker is registered", swReg);
        swRegistration = swReg;
        localStorage.setItem("swr",true)
        if(localStorage.getItem("a_d")){
        requestPNPermission();
        }
      })
      .catch(function (error) {
        console.error("Service Worker Error", error);
        if(localStorage.getItem("swr")){
          localStorage.removeItem("swr")
        }
      });
  } else {
    console.warn("Push manager and service worker not supported");
    if(localStorage.getItem("swr")){
      localStorage.removeItem("swr")
    }
  }
};
