import React from "react";
import ReactDOM from "react-dom/client";
import config from "react-global-configuration";

import App from "./App";
import { envInjector } from "./env";
import reportWebVitals from "./reportWebVitals";
import { registerServiceWorker } from "./sw_utils";
import { AuthProvider } from "./contexts/authProvider";
import { CookiesProvider } from "react-cookie";

// overriding console
const disableConsoleLogs = () => {
  if (envInjector.REACT_APP_ENV === "production") {
    console.log = () => {};
  }
};

/*
 * Global Application Configurations
 * @Warning- do not modify
 * ----------------------------------------------------
 */
config.set({
  api_url: process.env.REACT_APP_NODE_BASE_URL,
});

/*
 * Finally rendering application on DOM at root
 * Refer- index.html file in the public folder
 * ----------------------------------------------------
 */
const application = ReactDOM.createRoot(document.getElementById("root"));
application.render(
  <React.StrictMode>
    <AuthProvider>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <App />
      </CookiesProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
disableConsoleLogs();
registerServiceWorker();
