import React from "react";
import Calendar from "react-calendar";
import "./calendar.css";

const CalendarView = ({ markDates,onCalendarNavChange }) => {

    const setClass = (date) => {
    if (markDates?.length > 0) {
      const dateobj = markDates.find((x) => {
        return (
          date.getDay() === new Date(x.date).getDay() &&
          date.getMonth() === new Date(x.date).getMonth() &&
          date.getDate() === new Date(x.date).getDate()
        );
      });
      return dateobj ? dateobj.colorName : "";
    }
  };

  return (
    <Calendar
      tileClassName={({ date }) => setClass(date)}
      onActiveStartDateChange={onCalendarNavChange}
    />
  );
};

export default CalendarView;
