import React, { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  createSearchParams,
} from "react-router-dom";
import {
  getAllUsers,
  deactivateUser,
  reactivateUserAPI,
} from "../apis/usersAPI";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import NoResults from "../components/common/noResults";
import { useOutside } from "../customHooks/useOutside";
import Range from "../components/datepicker/range";
import CustomFilter from "../components/filters/customFilter";
import {
  echo,
  forEach,
  search,
  getDate,
  getSortBy,
  utf8_to_b64,
  getObjValue,
  userFormatter,
  debounceOptFunc,
  getUserAccessInfo,
  overrideDefaultEvent,
  generateFilterPayload,
  sentenceCaseFormatter,
  organiseColumns,
  getManagedColumns,
} from "../utils";
import { searchThroughAPIs } from "../apis";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import { APP_DATA } from "../app_data";
import createResizer from "../utils/resizerHandler";
import { Tooltip } from "react-tooltip";
import DataTableLoader from "../components/common/dataTableLoader";

export default function UsersView() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);

  const {
    openToast,
    openModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();

  const customFilter = useRef(null);
  const [users, setUsers] = useState([]);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const searchRef = useRef(null);
  const datePicker = useRef(null);
  const deleteIdsList = useRef([]);
  const selectFilter = useRef(null);
  const selectAllCheck = useRef(null);

  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Active Users");
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    preservedFilter: [],
    appliedFilters: [],
  });
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLimit, setPageLimit] = useState(0);
  const [columnInfo, setColumnInfo] = useState([]);
  const [userPermission, setUserPermission] = useState({});
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    const usersFilter = JSON.parse(sessionStorage.getItem("usersFilter"));
    if (
      usersFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(usersFilter);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: usersFilter,
      }));
      getUsersForCustomFilter(
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (
      urlSearch.get("referrer") === "create" &&
      urlSearch.get("filter") === "all"
    ) {
      setFilter(true);
      getUsers("All Users");
      setSelectedFilter("All Users");
    } else {
      if (customFilterDetails?.filter === "Custom") {
        getUsersForCustomFilter(
          customFilterDetails.appliedFilters,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getUsersForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortInfo.sortBy,
            sortInfo.sortField
          );
        } else {
          getUsersForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortInfo.sortBy,
            sortInfo.sortField,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getUsers(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
      }
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const users = getUserAccessInfo("Users");

    if (users?.permission) {
      setUserPermission(users?.permission);
    } else {
      setUserPermission({});
    }
    getModuleDataTypesAPI("users")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName);
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      clearAll();
      getUsers(value, sortInfo.sortBy, sortInfo.sortField);
      unCheckRecords();
      clearDateFilter();
      setSelectedFilter(value);
      searchRef.current.value = "";
      selectFilter.current.classList.remove("open");
    }
  };

  useEffect(() => {
    createResizer();
  }, [users, location.search, loading]);

  const handleDatePicker = (value) => {
    clearAll();
    unCheckRecords();
    clearFixedFilter();
    setDatePickerFilter(value);
    searchRef.current.value = "";
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getUsersForDateFilter(
        value,
        true,
        0,
        0,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
    setFilter(true);
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getUsersForDateFilter(
      datePickerFilter,
      true,
      0,
      0,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearDateFilter = (users) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (users) {
      getUsers();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setFilter(false);
      setSelectedFilter("Active Users");
    }
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const getUsersForCustomFilter = async (userFilter, sortBy, sortField) => {
    setLoading(true);
    setFilter(true);
    const modules = location.pathname.split("/")[2] || "";
    try {
      const users = await getDataForCustomFilters(
        modules,
        true,
        0,
        0,
        userFilter,
        sortBy,
        sortField
      );
      if (users.status) {
        if (users?.data?.users?.length > 0) {
          setUsers(
            organiseColumns(
              users?.data?.users,
              getManagedColumns("usersColumn")
            )
          );
        } else {
          setUsers([]);
        }
        if (users.data?.hasOwnProperty("total_count")) {
          setTotalRecords(users.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };

  const getUsersForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    const modules = location.pathname.split("/")[2] || "";
    setFilter(true);
    setLoading(true);
    try {
      const users = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (users.status) {
        if (users?.data?.users?.length > 0) {
          setUsers(
            organiseColumns(
              users?.data?.users,
              getManagedColumns("usersColumn")
            )
          );
        } else {
          setUsers([]);
        }
        if (users.data?.hasOwnProperty("total_count")) {
          setTotalRecords(users.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setUsers([]);
      console.log("err", err);
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    if (selectedFilter) {
      clearFixedFilter();
    }
    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();
    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    openFilterSidebar(false);
    getUsersForCustomFilter(
      appliedFilters,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  const clearAll = (users) => {
    if (customFilterDetails?.filter) {
      setFilter(false);
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      if (users) {
        getUsers();
      }
      unCheckRecords();
    }
    if (sessionStorage.getItem("usersFilter")) {
      sessionStorage.removeItem("usersFilter");
    }
  };

  const getUsers = async (userFilter, sortBy = "", sortField = "") => {
    setLoading(true);
    try {
      const users = await getAllUsers(
        userFilter,
        APP_DATA.fixedFiltersOwnerFields.users.includes(userFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortBy,
        sortField
      );
      if (users?.status) {
        setUsers(
          organiseColumns(users?.data, getManagedColumns("usersColumn"))
        );
        setTotalRecords(users.data.length);
      } else {
        setUsers([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const clearSort = () => {
    setSortInfo({
      sortBy: "",
      sortField: "",
      sortKey: "",
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === users?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== users?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleSearch = async (qstring) => {
    clearFixedFilter();
    clearDateFilter();
    clearAll();
    unCheckRecords();
    clearSort();

    if (qstring.length) {
      setFilter(true);
      const searched = await search(users, "user_name", qstring);
      if (searched.status && searched.data.length) {
        setTotalRecords(searched.data.length);
        setUsers(
          organiseColumns(searched.data, getManagedColumns("usersColumn"))
        );
      } else {
        const searched = await searchThroughAPIs("users", qstring);
        if (searched.status) {
          setTotalRecords(searched.data.total_count);
          setUsers(
            organiseColumns(
              searched?.data?.users,
              getManagedColumns("usersColumn")
            )
          );
        } else {
          setUsers([]);
        }
      }
    } else {
      setFilter(false);
      getUsers();
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  const handleDeactivateUser = () => {
    openModal("confirmation", {
      data: deleteIdsList.current,
      redirect: "users",
      feature: "user-deactivation",
      lastLine: () => {
        if (deleteIdsList.current.length > 1) {
          return "deactivate selected users";
        } else {
          const u = users.find((u) => u.user_id === deleteIdsList.current[0]);
          return `deactivate ${u?.user_firstname + " " + u?.user_lastname}`;
        }
      },
    });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "users_check") {
      return;
    }

    if (e.target.id === "userSort") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getUsersForCustomFilter(
          appliedFilters,

          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getUsersForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getUsersForDateFilter(
            datePickerFilter,
            true,
            0,
            pageLimit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getUsers(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  const handleVECMenu = (recordId, actionType) => {
    if (actionType === "vec") {
      handleAllMenuSideBar("vecUser", {
        id: recordId,
        type: "vec",
      });
    } else {
      handleAllMenuSideBar("vecUser", {
        id: recordId,
        type: "edit",
      });
      return;
    }
  };

  const handleUserActivation = async (e) => {
    e.stopPropagation();
    const uId = parseInt(String(e.target.id).split("-")[1]);
    handleReactivate([uId]);
  };

  const handleReactivate = async (payload) => {
    const activate = await reactivateUserAPI(payload);
    if (activate.status) {
      openToast("User activation link has sent.", "success");
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          referrer: "user-reactivation",
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
    } else {
      openToast("Failed to send activation link.", "error");
    }
  };

  const getDataForManageColumns = async () => {
    getUsers(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("usersColumn")) {
      sessionStorage.removeItem("usersColumn");
    }
    getDataForManageColumns();
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Users</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <= 1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 && (
                  <span
                    onClick={() => {
                      openFilterSidebar(true);
                    }}
                  >
                    {" "}
                    and <a>more</a>
                  </span>
                )}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}

          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  userPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                onClick={userPermission?.view ? handleFilterSelect : null}
                ref={selectFilter}
              >
                <div className="crm-custom-select-value">
                  <span title="Active users" id="select-value">
                    {sentenceCaseFormatter(selectedFilter)}
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.users.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-name={item.name}
                      data-value={item.value}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search users"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!userPermission?.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                userPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                userPermission?.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                userPermission?.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                userPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              ></span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            <div
              id="date-picker"
              className="daterangepicker ltr show-ranges opensright range"
              style={{
                display: showDatePicker ? "block" : "none",
                top: "98.1406px",
                left: "auto",
                right: "0px",
              }}
              ref={datePicker}
            >
              <Range
                functions={{ handleDatePicker, handleCallback }}
                datePickerFilter={datePickerFilter}
                showCalendar={showDatePicker}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              userPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                userPermission?.create
                  ? () => handleRSidebar("createUser")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Add Users</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr users-btn">
            <div
              className={
                userPermission?.delete && btnDelete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                userPermission?.delete && btnDelete
                  ? () =>
                      openModal("userDeleteModal", {
                        data: deleteIdsList.current,
                        redirect: "users",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? users.find((u) =>
                                deleteIdsList.current.includes(u.user_id)
                              )
                            : null,
                      })
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </div>
            <div
              className={
                userPermission?.edit && btnDelete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                userPermission?.edit && btnDelete ? handleDeactivateUser : null
              }
            >
              <i className="crm-action-dctvtusr"></i>
              <span>Deactivate User</span>
            </div>
          </div>
        </div>
        <div className="menu-rgt">
          <button
            type="button"
            className={
              userPermission.view
                ? "crm-btn-grp dctvt-users"
                : "crm-btn-grp dctvt-users disabled"
            }
            onClick={
              userPermission.view
                ? () => navigate({ pathname: "/app/deactivated-users" })
                : null
            }
          >
            <i className="crm-action-viewdctvtusr"></i>
            <span>View Deactivated Users</span>
          </button>
          {userPermission?.view &&
            Object.keys(displayName).length > 0 &&
            users.length > 0 && (
              <div className="total_count">
                <div className="total_title">Total users: </div>
                <div className="total_number">
                  <strong>{totalRecords}</strong>
                </div>
              </div>
            )}
        </div>
      </div>
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {users?.length > 0 &&
          userPermission?.view &&
          !loading &&
          Object.keys(displayName).length > 0 && (
            <>
              <div className="crm-action">
                <a
                  id="m-managecolumn"
                  className="crm-action-filter"
                  onClick={
                    userPermission?.view
                      ? () =>
                          handleRSidebarManageColumns("manageColumns", {
                            applyManageColumn,
                            clearManageColumn,
                            columns: columnInfo,
                            module: "users",
                            sessModule: "usersColumn",
                          })
                      : null
                  }
                ></a>
              </div>
              <Tooltip
                place={"left"}
                anchorId={"m-managecolumn"}
                content="Manage columns"
              />
            </>
          )}
        {/* table section */}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !users?.length ? "noscrl" : ""
          }`}
        >
          {users?.length > 0 &&
            Object.keys(displayName).length > 0 &&
            userPermission?.view &&
            !loading && (
              <div className="crm-tb-hed">
                <div className="crm-tb-rw" id={"resizeMe"}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-users">
                      <div className="check-box">
                        <input
                          type="checkbox"
                          className="crm-checkmark"
                          ref={selectAllCheck}
                          id="users_check"
                          onClick={(e) => handleSelectAll(e)}
                          disabled={users?.length ? false : true}
                        />
                        <label htmlFor="users_check"></label>
                      </div>
                      <div className="name">
                        <span
                          className={
                            sortInfo.sortKey === "user_firstname"
                              ? "sort-highlight"
                              : ""
                          }
                          id="userSort"
                          onClick={(e) => {
                            handleSort(e, "user_firstname", "userFullName");
                          }}
                        >
                          Users
                        </span>
                      </div>

                      <i
                        className={
                          sortInfo.sortKey === "user_firstname"
                            ? sortInfo.sortBy === "desc"
                              ? "sort sort-down"
                              : "sort sort-up"
                            : ""
                        }
                        id="userFullName"
                        onClick={(e) =>
                          handleSort(e, "user_firstname", "userFullName")
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {users?.length > 0 &&
                      Object.keys(displayName).length > 0 &&
                      !loading &&
                      Object.keys(users[0]).map(
                        (heading, index) =>
                          !APP_DATA.hiddenColumns.users.includes(heading) && (
                            <div
                              id={`col-${index}`}
                              className={`crm-td r ${
                                heading === "user_is_active"
                                  ? "crm-td-usrstatus"
                                  : ""
                              } ${
                                sortInfo.sortKey === heading
                                  ? sortInfo.sortBy === "desc"
                                    ? "crm-sort sort-active"
                                    : "crm-sort sort-active-up"
                                  : ""
                              }`}
                            >
                              <span
                                onClick={(e) =>
                                  handleSort(e, heading, `col-${index}`)
                                }
                              >
                                {/* {APP_DATA.APIColumnMappings.users[heading]
                              ? APP_DATA.APIColumnMappings.users[heading]
                              : heading?.split("_").join(" ")} */}
                                {echo(displayName[heading])}
                              </span>
                            </div>
                          )
                      )}
                    {/* {users?.length > 0 &&
                      Object.keys(users[0]).includes("user_is_active")&&<>
                  <div className={"crm-td crm-td-usrstatus"}>
                    <span>User status</span>
                  </div>
                </>} */}
                  </div>
                </div>
              </div>
            )}
          <div className="crm-tb-bdy">
            {!loading ? (
              userPermission?.view &&
              Object.keys(displayName).length > 0 &&
              users.length > 0 ? (
                users.map((u, i) => (
                  <div className="crm-tb-rw">
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-users">
                        <div className="check-box">
                          {u.user_profile_name !==
                            APP_DATA.roles.superAdmin && (
                            <input
                              type="checkbox"
                              className="crm-checkmark ids"
                              id={u.user_id}
                              onChange={(e) => handleDeleteCheck(e)}
                            />
                          )}
                          <label
                            className="user-name"
                            title=""
                            htmlFor={u.user_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            userPermission?.view
                              ? () => handleVECMenu(u.user_id, "vec")
                              : null
                          }
                        >
                          <span className="link">
                            {echo(u.user_firstname) +
                              " " +
                              echo(u.user_lastname)}
                          </span>
                        </div>
                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              userPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              userPermission?.edit
                                ? () => handleVECMenu(u.user_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        userPermission?.view
                          ? () => handleVECMenu(u.user_id, "vec")
                          : null
                      }
                    >
                      {users?.length > 0 &&
                        Object.keys(users[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.users.includes(heading) &&
                            (!APP_DATA.formattingColumns.users.includes(
                              heading
                            ) ? (
                              <>
                                <div data-colpos={i} className={`crm-td `}>
                                  <span>{echo(getObjValue(u, heading))}</span>
                                </div>
                              </>
                            ) : (
                              <div
                                data-colpos={i}
                                className="crm-td crm-td-usrstatus"
                              >
                                {userFormatter(
                                  "user_is_active",
                                  getObjValue(u, "user_is_active")
                                )}

                                {u["user_is_active"] === null ? (
                                  <>
                                    <span className="actn-lnks">
                                      <a
                                        className="resend-lnk"
                                        id={"resend-" + u.user_id}
                                        onClick={(e) => handleUserActivation(e)}
                                      >
                                        Resend
                                      </a>
                                    </span>
                                  </>
                                ) : null}

                                {u["user_is_active"] === false ? (
                                  <>
                                    <span className="actn-lnks">
                                      <a
                                        className="resend-lnk"
                                        id={"reactivate-" + u.user_id}
                                        onClick={(e) => handleUserActivation(e)}
                                      >
                                        Reactivate
                                      </a>
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            ))
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults permission={!userPermission?.view} filter={filter} />
              )
            ) : (
              <DataTableLoader mClass={"crm-td crm-td-users"} module="user" />
            )}
          </div>
        </div>
      </div>

      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              module={"users"}
              columnInfo={columnInfo}
              preservedFilter={customFilterDetails.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
