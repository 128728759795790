import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { APP_DATA } from "../app_data";
import {
  debounceOptFunc,
  echo,
  inputFieldValidator,
  inputSpaceValidator,
  utf8_to_b64,
  getUserAccessInfo,
  getSortBy,
} from "../utils";
import {
  getSavedWebformsConfigAPI,
  updateWebformAPI,
} from "../apis/webformAPIs";
import NoResults from "../components/common/noResults";
import DataTableLoader from "../components/common/dataTableLoader";
import { envInjector } from "../env";
import { searchThroughAPIs } from "../apis";
import CONSTANTS from "../app_data/constants";

export default function WebformsView() {
  const { openModal, openToast, getUserInfo } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  const createForm = useRef(null);
  const formData = useRef(
    structuredClone(APP_DATA.webFormInputs.createWebForm)
  );
  const embedModal = useRef(null);

  const [loading, setLoading] = useState(true);
  const [savedForms, setSavedForms] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [permission, setPermission] = useState({});
  const [filter, setFilter] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  useEffect(() => {
    setPermission(getUserAccessInfo("Web Forms").permission || {});
  }, []);

  useEffect(() => {
    getAllSaveedForms();
  }, [location.search]);

  const getAllSaveedForms = async (selectFilter, sortBy, sortField) => {
    const forms = await getSavedWebformsConfigAPI(
      selectFilter,
      sortBy,
      sortField
    );
    if (forms.status) {
      setSavedForms(forms.data);
      setTotalRecords(forms.data.length);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      savedForms([]);
    }
  };

  const handleSearch = async (qstring) => {
    clearSort();
    if (qstring.length) {
      setLoading(true);
      setFilter(true);
      const searched = await searchThroughAPIs("webforms", qstring);
      if (searched.status) {
        setSavedForms(searched.data.web_forms);
        setTotalRecords(searched.data.total_count);
      } else {
        setSavedForms([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      setFilter(false);
      getAllSaveedForms();
    }
  };

  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const formChangeHandler = async (event) => {
    const input = await inputFieldValidator(event);
    if (input.valid) {
      formData.current[event.target.id] = event.target.value;
      document.getElementById("err_" + event.target.id).style.display = "none";
    } else {
      document.getElementById("err_" + event.target.id).innerText =
        input["error_message"];
      document.getElementById("err_" + event.target.id).style.display = "block";
    }
  };

  const initCreateForm = () => {
    let finalValidation = 1;
    for (const f in formData.current) {
      if (
        formData.current[f] === undefined ||
        formData.current[f] === "" ||
        inputSpaceValidator(formData.current[f])
      ) {
        finalValidation &= 0;
        document.getElementById("err_" + f).style.display = "block";
      }
    }
    if (finalValidation) {
      navigate(
        "/app/create-web-forms?" +
          createSearchParams({
            referrer: "create-web-form-init",
            tNode: utf8_to_b64(String(Date.now())),
          }).toString(),
        {
          state: {
            mode: "create",
            module: formData.current["module"],
            form_name: formData.current["formName"],
          },
        }
      );
    }
  };

  const handleEditWeborm = (id) => {
    const f = savedForms.find((f) => f.id === id);
    navigate(
      "/app/create-web-forms?" +
        createSearchParams({
          referrer: "create-web-form-init",
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      {
        state: {
          mode: "edit",
          module: f.module,
          form_name: f.name,
          created_webform: f.id,
        },
      }
    );
  };

  const handleDeleteMenu = (id) => {
    try {
      openModal("deleteModal", {
        data: [id],
        redirect: "web-forms",
        msgInfo: savedForms.find((f) => f.id === id),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const activeDeactiveWebform = async (e) => {
    let pl = {};
    const id = String(e.target.id).split("_")[1];
    if (e.target.checked) {
      pl = {
        active: true,
        updated_by: getUserInfo().user_id,
      };
    } else {
      pl = {
        active: false,
        updated_by: getUserInfo().user_id,
      };
    }
    const rupdated = await updateWebformAPI(id, pl);
    if (rupdated.status) {
      openToast(APP_DATA.messages.notification, "success");
    } else {
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const handleEmbedCode = (id, module) => {
    const currentTNode = utf8_to_b64(String(Date.now()));
    const appDomain = envInjector.REACT_APP_DOMAIN_URL;
    const pLink = utf8_to_b64(
      JSON.stringify({
        fid: id,
        module: module,
      })
    );
    const accessLink = `${appDomain}/crm/webforms?cwfi=${pLink}&tNode=${currentTNode}&ci=${CONSTANTS.encryptAppData()}`;
    const iframeLink = `<iframe  width="300" height="600" id="webform-${currentTNode}" src="${accessLink}"></iframe>`;
    document.getElementById("embed-area").innerText = iframeLink;
    document.getElementById("shared-link").value = `${accessLink}`;
    handleEmbedModal();
    return;
  };

  const handleEmbedModal = (option = "open") => {
    if (option === "close") {
      embedModal.current.style.display = "none";
    } else {
      embedModal.current.style.display = "block";
    }
  };

  const handlePreviewForm = (id, module) => {
    window.open(
      `/crm/webforms?cwfi=${utf8_to_b64(
        JSON.stringify({
          fid: id,
          module: module,
        })
      )}&tNode=${utf8_to_b64(
        String(Date.now())
      )}&ci=${CONSTANTS.encryptAppData()}`,
      "_blank"
    );
    return;
  };

  const copyCode = (dataId, btnId) => {
    var cp = document.getElementById(dataId);
    cp.select();
    cp.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(cp.value);
    const el = document.getElementById(btnId);
    el.innerText = "Copied";
    setTimeout(() => {
      el.innerText = "Copy";
    }, 10000);
  };

  const clearSort = () => {
    setSortInfo({
      sortBy: "",
      sortField: "",
      sortKey: "",
    });
  };

  const handleSort = (e, field, id) => {
    const element = document.getElementById(id);
    const sortBy = getSortBy(element);
    if (sortBy && field) {
      setSortInfo({ sortBy, sortField: field, sortKey: id });
    }
    getAllSaveedForms("", sortBy, field);
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i className="crm-backbtn"></i>
          <h1 className="header_title">Webforms</h1>
        </div>
        <div className="header_rhs">
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div className="custom-select">
                <div className="crm-custom-select-value">
                  <span>All Forms</span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  <span
                    className="crm-custom-option selected"
                    data-value="All Forms"
                  >
                    All Forms
                  </span>
                  <span className="crm-custom-option" data-value="Leads">
                    Leads
                  </span>
                  <span className="crm-custom-option" data-value="Contacts">
                    Contacts
                  </span>
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search Webforms"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                disabled={!permission.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="wform-info">
        <p className="desc">
          Ensure your lead’s vital data is captured with intuitive forms that
          are easy to share.
        </p>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              permission.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                permission.create
                  ? () => (createForm.current.style.display = "block")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Form</span>
            </div>
          </div>
        </div>
        {savedForms?.length > 0 && permission?.view && (
          <div className="menu-rgt">
            <div className="total_count">
              <div className="total_title">Total Webforms: </div>
              <div className="total_number">
                <strong>&nbsp;{totalRecords}</strong>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="crm-table cst-mrgn">
        <div className="crm-tb-hed">
          {savedForms?.length > 0 && permission.view && (
            <div className="crm-tb-rw">
              <div
                id="formName"
                className={`crm-td crm-td-fname ${
                  sortInfo.sortKey === "formName"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}
              >
                <span onClick={(e) => handleSort(e, "Name", "formName")}>
                  Forms
                </span>
              </div>
              <div
                id="col-2"
                className={`crm-td crm-td-type ${
                  sortInfo.sortKey === "col-2"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}
              >
                <span onClick={(e) => handleSort(e, "Module", "col-2")}>
                  Type
                </span>
              </div>
              <div
                id="col-3"
                className={`crm-td crm-td-crtdby ${
                  sortInfo.sortKey === "col-3"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}
              >
                <span onClick={(e) => handleSort(e, " ", "col-3")}>
                  Created by
                </span>
              </div>
              <div
                id="col-4"
                className={`crm-td crm-td-submsns ${
                  sortInfo.sortKey === "col-4"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}
              >
                <span onClick={(e) => handleSort(e, " ", "col-4")}>
                  Submissions
                </span>
              </div>
              <div
                id="col-5"
                className={`crm-td crm-td-status ${
                  sortInfo.sortKey === "col-5"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}
              >
                <span onClick={(e) => handleSort(e, "Active", "col-5")}>
                  Status
                </span>
              </div>
              <div className="crm-td crm-action"></div>
            </div>
          )}
        </div>
        <div className="crm-tb-bdy">
          {!loading ? (
            savedForms.length > 0 && permission.view ? (
              savedForms.map((savedForm, i) => (
                <React.Fragment key={"s-forms-" + i}>
                  <div
                    className="crm-tb-rw"
                    // onClick={() => handleEditWeborm(savedForm.id)}
                  >
                    <div className="crm-td crm-td-fname">
                      <span>{echo(savedForm.name)}</span>
                    </div>
                    <div className="crm-td crm-td-type">
                      <span>{echo(savedForm.module)}</span>
                    </div>
                    <div className="crm-td crm-td-crtdby">
                      <span>{echo(savedForm.created_by_name)}</span>
                    </div>
                    <div className="crm-td crm-td-submsns">
                      <span>{echo(savedForm.j)}</span>
                    </div>
                    <div className="crm-td crm-td-status">
                      <label className="switch">
                        <input
                          type="checkbox"
                          defaultChecked={savedForm.active}
                          id={"switch_" + savedForm.id}
                          onChange={(e) => activeDeactiveWebform(e)}
                          disabled={!permission.edit}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="crm-td crm-action">
                      <a className="crm-action-opt crm-action-settings" id="">
                        <ul className="crm-dropdown-action" id="">
                          <li>
                            <span
                              className="crm-action-view"
                              onClick={() => handleEditWeborm(savedForm.id)}
                            >
                              View
                            </span>
                          </li>
                          <li className="">
                            <span
                              className={
                                permission.delete
                                  ? "crm-action-delete"
                                  : "crm-action-delete disabled"
                              }
                              onClick={
                                permission.delete
                                  ? () => handleDeleteMenu(savedForm.id)
                                  : null
                              }
                            >
                              Delete
                            </span>
                          </li>
                          <li className="">
                            <span
                              className="crm-action-code"
                              onClick={() =>
                                handleEmbedCode(savedForm.id, savedForm.module)
                              }
                            >
                              Get Code
                            </span>
                          </li>
                          <li className="">
                            <span
                              className="crm-action-preview"
                              onClick={() =>
                                handlePreviewForm(
                                  savedForm.id,
                                  savedForm.module
                                )
                              }
                            >
                              Preview Form
                            </span>
                          </li>
                        </ul>
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <NoResults filter={filter} permission={!permission.view} />
            )
          ) : (
            <DataTableLoader
              cols="100000"
              module="webform"
              mClassName="crm-td crm-td-fname crm-sort sort-active-up"
            />
          )}
        </div>
      </div>

      {/* Create form init modal */}
      <div
        className="overlay crtform-mod"
        id="createFormModal"
        ref={createForm}
        style={{ display: "none" }}
      >
        <div className="crm-pop-vt">
          <div className="crm-pop-hztl">
            <div className="crm-popup crm-popup-smm rhs-popvt">
              <form>
                <div className="crm-pop-header">
                  <h2>Create Form</h2>
                  <a
                    className="close crm-close"
                    href="#"
                    onClick={() => (createForm.current.style.display = "none")}
                  ></a>
                </div>
                <div className="crm-popup-content">
                  <div className="crm-form-row fname">
                    <div className="crm-form-grp">
                      <div className="crm-form-grp crm-frm-100">
                        <div className="crm-form-field req">
                          <input
                            type="text"
                            className="crm-form-ctrl floating-input"
                            id="formName"
                            autoComplete="off"
                            placeholder=" "
                            onChange={(e) => formChangeHandler(e)}
                          />
                          <label
                            alt="Form Name"
                            htmlFor="formName"
                            placeholder="Form Name"
                            className="floating-label"
                          >
                            Form Name
                          </label>
                          <span
                            className="crm-inp-error"
                            style={{ textAlign: "left" }}
                            id={"err_formName"}
                          >
                            Form Name cannot be blank
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="crm-form-row fmod">
                    <div className="crm-form-grp">
                      <div className="crm-form-grp">
                        <div className="crm-form-field req">
                          <select
                            className="crm-form-ctrl floating-select"
                            id="module"
                            onChange={(e) => formChangeHandler(e)}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            <option value="leads">Leads</option>
                            <option value="contacts">Contacts</option>
                          </select>
                          <label
                            alt="Module"
                            htmlFor="module"
                            placeholder="Module"
                            className="floating-label"
                          >
                            Module
                          </label>
                          <span
                            className="crm-inp-error"
                            style={{ textAlign: "left" }}
                            id="err_module"
                          >
                            Module cannot be blank
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="crm-pop-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => initCreateForm()}
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => (createForm.current.style.display = "none")}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Copy embed code modal */}
      <React.Fragment>
        <div
          className="overlay embed-code"
          ref={embedModal}
          style={{ display: "none" }}
        >
          <div className="crm-pop-vt">
            <div className="crm-pop-hztl">
              <div className="crm-popup crm-popup-md rhs-popvt">
                <form>
                  <div className="crm-pop-header">
                    <h2>Embed Options</h2>
                    <a
                      className="close crm-close"
                      href="#"
                      onClick={() => handleEmbedModal("close")}
                    ></a>
                  </div>
                  <div className="crm-popup-content">
                    <p className="code-info">
                      Please use the below code snippets to capture the Leads
                      from your websites.
                    </p>
                    <div className="crm-form-row">
                      <div className="src-code">
                        <div className="hdr">
                          <p className="title">
                            Embed in your website using iframe
                          </p>
                          <div className="cpy-wrpr">
                            <i className="cpy-icn"></i>
                            <span
                              className="txt"
                              id="btnCopyIframe"
                              onClick={() =>
                                copyCode("embed-area", "btnCopyIframe")
                              }
                            >
                              Copy
                            </span>
                          </div>
                        </div>
                        <div className="body">
                          <textarea readOnly={true} id="embed-area"></textarea>
                        </div>
                      </div>
                    </div>
                    <p className="link-hdr">Share Link:</p>
                    <div className="shrlnk-input">
                      <div className="shrlnk-wrpr">
                        <input
                          type="text"
                          className="link"
                          readOnly={true}
                          id="shared-link"
                        />
                        <span className="copied-link">Link copied</span>
                      </div>
                      <span
                        className="copy-btn"
                        id="btnCopyLink"
                        onClick={() => copyCode("shared-link", "btnCopyLink")}
                      >
                        Copy
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
}
