import React, { useEffect, useRef, useState } from "react";
import NoResults from "../../common/noResults";
import DataTableLoader from "../../common/dataTableLoader";
import { dealsFormatter, echo, forEach, getObjValue } from "../../../utils";
import { APP_DATA } from "../../../app_data";
import createResizer from "../../../utils/resizerHandler";
import { getModuleDataTypesAPI } from "../../../apis/workflowAPIs";
import { Tooltip } from "react-tooltip";

export default function DealsTable(props) {
  const selectAllCheck = useRef(null);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const [columnInfo, setColumnInfo] = useState([]);
  const [displayName, setDisplayName] = useState({});

  useEffect(() => {
    if (props.updateData.length) {
      getModuleDataTypesAPI(props.module)
        .then((res) => {
          setColumnInfo(res.data);
          const displayName = res.data?.reduce((acc, curr) => {
            acc[curr.field] = curr.display_name;
            return acc;
          }, {});
          unCheckRecords();
          setDisplayName(displayName);
          setTimeout(() => {
            createResizer();
          }, 200);
        })
        .catch((err) => {
          setColumnInfo([]);
          setDisplayName({});
        });
    }
  }, [props.updateData]);

  const unCheckRecords = () => {
    if (props.deleteIdsList.current?.length > 0) {
      props.deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      props.deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!props.deleteIdsList.current.includes(parseInt(e.target.id))) {
        props.deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (props.deleteIdsList.current?.length === props.updateData?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = props.deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        props.deleteIdsList.current.splice(i, 1);
      }
      if (props.deleteIdsList.current?.length !== props.updateData?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (props.deleteIdsList.current.length > 0) {
      props.setBtnDelete(true);
    } else {
      props.setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };
  return (
    <div className="tbl-wrpr mass-update">
      {!props.loading &&
        props.updateData?.length > 0 &&
        Object.keys(displayName).length > 0 && (
          <React.Fragment>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                // onClick={
                //   leadsPermission?.view
                //     ? () =>
                //         handleRSidebarManageColumns("manageColumns", {
                //           applyManageColumn,
                //           clearManageColumn,
                //           columns: columnInfo,
                //           module: "leads",
                //           sessModule: "leadsColumn",
                //         })
                //     : null
                // }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </React.Fragment>
        )}
      <div className="crm-table cst-mrgn mtbl mass-update">
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-fixed-field">
              {props.updateData?.length > 0 &&
                Object.keys(displayName).length > 0 &&
                !props.loading && (
                  <div className="crm-td crm-td-dealname">
                    <div className="check-box">
                      <input
                        id="dealname"
                        type="checkbox"
                        ref={selectAllCheck}
                        className="crm-checkmark"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={props.updateData.length ? false : true}
                      />
                      <label htmlFor="dealname"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "deal_name"
                            ? "sort-highlight"
                            : ""
                        }
                        id="deal"
                        // onClick={(e) => {
                        //   handleSort(e, "deal_name", "dealNm");
                        // }}
                      >
                        {/* Deal name */}
                        {echo(displayName["deal_name"])}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "deal_name"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="dealNm"
                      // onClick={(e) => handleSort(e, "deal_name", "dealNm")}
                    ></i>
                  </div>
                )}
            </div>
            <div className="crm-scroll-field">
              {!props.loading &&
                props.updateData?.length > 0 &&
                Object.keys(displayName).length > 0 &&
                Object.keys(props.updateData[0]).map(
                  (heading, index) =>
                    !APP_DATA.hiddenColumns.deals.includes(heading) && (
                      <div
                        id={`col-${index + 2}`}
                        className={`crm-td r  ${
                          sortInfo.sortKey === heading
                            ? sortInfo.sortBy === "desc"
                              ? "crm-sort sort-active"
                              : "crm-sort sort-active-up"
                            : ""
                        }`}
                      >
                        <span
                        //   onClick={(e) =>
                        //     handleSort(e, heading, `col-${index + 2}`)
                        //   }
                        >
                          {echo(displayName[heading])}
                        </span>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>

        <div className="crm-tb-bdy">
          {!props.loading ? (
            props.updateData?.length > 0 ? (
              props.updateData.map((d, i) => (
                <div className="crm-tb-rw" key={"dealList_" + i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-dealname">
                      <input
                        type="checkbox"
                        className="crm-checkmark ids"
                        id={d.deal_id}
                        onChange={(e) => handleDeleteCheck(e)}
                      />
                      <label className="deal-name" title="" htmlFor={d.deal_id}>
                        <span>{echo(d.deal_name)}</span>
                      </label>
                      <div className="action-rw"></div>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {props.updateData?.length > 0 &&
                      Object.keys(props.updateData[0])?.map(
                        (heading, i) =>
                          !APP_DATA.hiddenColumns.deals.includes(heading) && (
                            <div data-colpos={i + 2} className={`crm-td `}>
                              <span>
                                {!APP_DATA.formattingColumns.deals.includes(
                                  heading
                                )
                                  ? echo(getObjValue(d, heading))
                                  : dealsFormatter(
                                      heading,
                                      getObjValue(d, heading),
                                      props.functions.getExtraData()
                                        .currency_symbol
                                    )}
                              </span>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <NoResults message={props.noResultMessage} />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-dealname"} module="deal" />
          )}
        </div>
      </div>
    </div>
  );
}
