import React, { useEffect } from "react";
import  CustomDateRangePicker from "../common/dateRangePicker";
import { APP_DATA } from "../../app_data";

export const Range=({showCalendar,datePickerFilter,functions})=>{

  useEffect(() => {
    const datePicker = document.getElementById("date-picker");
    if(datePickerFilter==="Custom_Range" && showCalendar){
        document.getElementById("cdp")?.click();  
    }
    if (datePicker) {
      if (
        datePickerFilter === "Custom_Range" 
      ) {
        datePicker.classList.add("show-range");
      } else if (datePicker.classList.contains("show-range")) {
        datePicker.classList.remove("show-range");
      }
    }
  });
  

  const handleSelect=(value)=>{
    if(value==="Custom_Range"){
        document.getElementById("cdp").click();  
    }
    functions.handleDatePicker(value);
  }

  return (
    <React.Fragment>
      <div className="ranges">
        <ul>
          {APP_DATA.dateFilter.map((l,i) => (
            <li
              key={i}
              id={l.value}
              data-range-key={l.value}
              className={datePickerFilter === l.value ? "active" : ""}
              onClick={() => {
                handleSelect(l.value);
              }}
            >
              {l.name}
            </li>
          ))}
        </ul>
      </div>
      <CustomDateRangePicker onCallback={functions.handleCallback}>
        <button
          id="cdp"
          style={{
            display: "none",
            background: "none",
            border: "1px solid #0071bc",
            borderRadius: "5px",
            margin: "100px 10px 100px",
          }}
        >
          Show Custom Date Picker
        </button>
      </CustomDateRangePicker>
    </React.Fragment>
  );
};

export default Range;