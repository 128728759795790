import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getContactsLimitNoffset } from "../apis/contactAPIs";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import CustomFilter from "../components/filters/customFilter";
import Range from "../components/datepicker/range";
import {
  echo,
  getDate,
  forEach,
  getSortBy,
  getObjValue,
  organiseColumns,
  debounceOptFunc,
  getUserAccessInfo,
  contactsFormatter,
  getManagedColumns,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import { useOutside } from "../customHooks/useOutside";
import {
  searchAPI,
  searchThroughAPIs,
  downloadCSVExportedFiles,
  getExportFileCustomFilter,
  getExportFileDateFilter,
  getExportFileFixedFilter,
  getExportFileSearchFilter,
} from "../apis";
import { APP_DATA } from "../app_data";
import createResizer from "../utils/resizerHandler";
import { Tooltip } from "react-tooltip";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";

const MODULE_NAME = "contacts";

export default function ContactsView() {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const {
    openToast,
    openModal,
    closeModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const navigate = useNavigate();

  const deleteIdsList = useRef([]);
  const dropdownMenu = useRef(null);
  const selectAllCheck = useRef(null);
  const selectFilter = useRef(null);
  const datePicker = useRef(null);
  const customFilter = useRef(null);
  const searchRef = useRef(null);
  const exportDownloadViewer = useRef(null);
  const pagination = useRef(null);

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [contactsPermission, setContactsPermission] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("All Contacts");
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const [filter, setFilter] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName,setDisplayName] = useState({})

  useEffect(() => {
    const contactsFilter = JSON.parse(sessionStorage.getItem("contactsFilter"));
    if (
      contactsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(contactsFilter);
      console.log(appliedFilters, "aa");
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: contactsFilter,
      }));
      getContactsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      //clearing filters after create, edit ,clone or delete
      //as sometimes no records will create confusion to users
      // clearFixedFilter();
      // clearDateFilter();
      // clearAll();
      // searchRef.current.value = "";
      // clearSort();

      // getContacts();
      handlePaginatedFetchData(pageData);
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const contacts = getUserAccessInfo("Contacts");
    if (contacts?.permission) {
      setContactsPermission(contacts.permission);
    } else {
      setContactsPermission({});
    }
    getModuleDataTypesAPI("contacts")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName) 
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  useEffect(() => {
    createResizer();
  }, [contacts, location.search,loading]);

  const getContacts = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);

    const contacts = await getContactsLimitNoffset(
      0,
      pageData.limit,
      true,
      selectedFilter,
      selectedFilter === "My Contacts" ? getUserAccessInfo()?.user_id : "",
      sortBy,
      sortField
    );
    if (contacts.status) {
      setContacts(
        organiseColumns(
          contacts.data.contacts,
          getManagedColumns("contactsColumn")
        )
      );
      if (contacts.data.hasOwnProperty("total_count")) {
        setTotalRecords(contacts.data.total_count);
      }
    } else {
      setContacts([]);
      setTotalRecords(0);
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === contacts?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== contacts?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      //need to search with page limit and offset
      setFilter(true);
      const searched = await searchThroughAPIs(
        "contacts",
        qstring,
        0,
        pageData.limit
      );
      if (searched.status) {
        setTotalRecords(searched.data.total_count);
        setContacts(
          organiseColumns(
            searched.data.contacts,
            getManagedColumns("contactsColumn")
          )
        );
      } else {
        setContacts([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getContacts();
      setFilter(false);
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleDropdownMenu = (menuType) => {
    dropdownMenu.current.style.display = "none";
    switch (menuType) {
      case "import_contacts":
        handleRSidebar("importContact");
        break;
      default:
        break;
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    //to uncheck select all and disable delete while going to next page
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      try {
        const contacts = await getDataForCustomFilters(
          modules,
          false,
          pageData.offset,
          pageData.limit,
          appliedFilters,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        if (contacts.status) {
          setContacts(
            organiseColumns(contacts.data, getManagedColumns("contactsColumn"))
          );
        } else {
          setContacts([]);
        }
      } catch (err) {
        setContacts([]);
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const contacts = await searchAPI(
          "contacts",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (contacts.status) {
          setTotalRecords(contacts.data.total_count);
          setContacts(
            organiseColumns(
              contacts.data.contacts,
              getManagedColumns("contactsColumn")
            )
          );
        } else {
          setContacts([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getContactsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getContactsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const contacts = await getContactsLimitNoffset(
        pageData.offset,
        pageData.limit,
        true,
        selectedFilter,
        selectedFilter === "My Contacts" ? getUserAccessInfo()?.user_id : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (contacts.status) {
        setTotalRecords(contacts.data.total_count);
        setContacts(
          organiseColumns(
            contacts.data.contacts,
            getManagedColumns("contactsColumn")
          )
        );
      } else {
        setContacts([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  const getContactsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setLoading(true);
    setFilter(true);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const contacts = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (contacts.status) {
        if (contacts?.data?.contacts?.length > 0) {
          setContacts(
            organiseColumns(
              contacts.data.contacts,
              getManagedColumns("contactsColumn")
            )
          );
        } else {
          setContacts([]);
        }
        if (contacts.data?.hasOwnProperty("total_count")) {
          setTotalRecords(contacts.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setContacts([]);
      setLoading(false);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const getContactsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setFilter(true);
    setLoading(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const contacts = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (contacts.status) {
        if (contacts?.data?.contacts?.length > 0) {
          setContacts(
            organiseColumns(
              contacts.data.contacts,
              getManagedColumns("contactsColumn")
            )
          );
        } else {
          setContacts([]);
        }
        if (contacts.data?.hasOwnProperty("total_count")) {
          setTotalRecords(contacts.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setContacts([]);
      setLoading(false);
      console.log("err", err);
    }
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      getContacts(value, sortInfo.sortBy, sortInfo.sortField);
      clearAll();
      clearDateFilter();
      unCheckRecords();
      searchRef.current.value = "";
      setFilter(true);
    }
  };

  const handleDatePicker = (value) => {
    searchRef.current.value = "";
    clearAll();
    clearFixedFilter();
    unCheckRecords();
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getContactsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
    setFilter(true);
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end, label) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getContactsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Contacts");
      document.getElementById("select-value").innerText = "All contacts";
      setFilter(false);
    }
  };

  const clearDateFilter = (contacts) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (contacts) {
      getContacts();
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    openFilterSidebar(false);
    if (selectedFilter) {
      clearFixedFilter();
    }

    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    getContactsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const clearAll = (contacts) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      setFilter(false);
      if (contacts) {
        getContacts("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }
    if (sessionStorage.getItem("contactsFilter")) {
      sessionStorage.removeItem("contactsFilter");
    }
  };

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getContactsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getContactsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getContactsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getContacts(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("contactsColumn")) {
      sessionStorage.removeItem("contactsColumn");
    }
    getDataForManageColumns();
  };

  const handleVECMenu = (recordId, actionType) => {
    if (actionType === "vec") {
      handleAllMenuSideBar("vecContact", {
        id: recordId,
        type: "vec",
      });
    } else {
      handleAllMenuSideBar("vecContact", {
        id: recordId,
        type: "edit",
      });
    }
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "contacts") {
      return;
    }

    if (e.target.id === "cntct") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getContactsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getContactsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getContactsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getContacts(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Contacts",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });
      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }
      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file..",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      setBtnDelete(false);
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Contacts</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  contactsPermission.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                onClick={contactsPermission.view ? handleFilterSelect : null}
                ref={selectFilter}
              >
                <div className="crm-custom-select-value">
                  <span id="select-value">All contacts</span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.contacts.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      data-name={item.name}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search contacts"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!contactsPermission.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                contactsPermission.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                contactsPermission.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                contactsPermission.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                contactsPermission.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"t-daterange"}
                content="Date Filters"
              />
            </div>
            <div
              id="date-picker"
              className="daterangepicker ltr show-ranges opensright range"
              style={{
                display: showDatePicker ? "block" : "none",
                top: "98.1406px",
                left: "auto",
                right: "0px",
              }}
              ref={datePicker}
            >
              <Range
                functions={{ handleDatePicker, handleCallback }}
                datePickerFilter={datePickerFilter}
                showCalendar={showDatePicker}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              contactsPermission.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                contactsPermission.create
                  ? () => handleRSidebar("createContact")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Contact</span>
            </div>
            <div
              className="rhs-prt"
              onMouseLeave={() => (dropdownMenu.current.style.display = "none")}
              onMouseEnter={() =>
                (dropdownMenu.current.style.display = "block")
              }
            >
              <i className="drpdwn-icn"></i>
              <div className="drpdwn-values" ref={dropdownMenu}>
                <span
                  className="import-lead"
                  style={{}}
                  onClick={
                    contactsPermission?.create
                      ? () => handleDropdownMenu("import_contacts")
                      : null
                  }
                >
                  Import Contacts
                </span>
              </div>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                btnDelete && contactsPermission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && contactsPermission?.delete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "contacts",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? contacts?.find((c) =>
                                deleteIdsList.current.includes(c.contact_id)
                              )
                            : null,
                      })
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>

            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                <span
                  className={`${
                    totalRecords && contactsPermission.edit ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && contactsPermission.edit
                      ? () =>
                          navigate(
                            "/app/contacts/bulkupdate?mode=bu&target=contacts"
                          )
                      : null
                  }
                >
                  Mass Update
                </span>
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && contactsPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && contactsPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
              </div>
            </button>
          </div>
        </div>
        {contactsPermission?.view && contacts?.length > 0 && Object.keys(displayName).length>0 && (
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total contacts: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
        </div>)}
      </div>
      {/* table section */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {contacts?.length > 0 && contactsPermission?.view && Object.keys(displayName).length>0 && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                onClick={
                  contactsPermission?.view
                    ? () =>
                        handleRSidebarManageColumns("manageColumns", {
                          applyManageColumn,
                          clearManageColumn,
                          columns: columnInfo,
                          module: "contacts",
                          sessModule: "contactsColumn",
                        })
                    : null
                }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {contacts?.length > 0 && contactsPermission?.view && 
                Object.keys(displayName).length>0 && !loading && (
                  <div className="crm-td crm-td-contacts">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        ref={selectAllCheck}
                        id="contacts"
                        className="crm-checkmark"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={contacts?.length ? false : true}
                      />

                      <label htmlFor="contacts"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "contact_fullname"
                            ? "sort-highlight"
                            : ""
                        }
                        id="cntct"
                        onClick={(e) => {
                          handleSort(e, "contact_fullname", "contactFname");
                        }}
                      >
                      {echo(displayName["contact_fullname"])}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "contact_fullname"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="contactFname"
                      onClick={(e) =>
                        handleSort(e, "contact_fullname", "contactFname")
                      }
                    ></i>
                  </div>
                )}
              </div>
              <div className="crm-scroll-field">
                {contacts?.length > 0 &&
                  contactsPermission?.view
                  && Object.keys(displayName).length>0 && !loading &&
                  Object.keys(contacts[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.contacts.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.contacts[heading]
                              ? APP_DATA.APIColumnMappings.contacts[heading]
                              : heading?.split("_").join(" ")} */}
                            {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              contactsPermission?.view && Object.keys(displayName).length>0 && contacts?.length > 0 ? (
                contacts.map((c, i) => (
                  <div className="crm-tb-rw" key={"contact-data-list" + i}>
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-contacts">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={c.contact_id}
                            onChange={(e) => handleDeleteCheck(e)}
                          />
                          <label
                            className="email-id"
                            title=""
                            htmlFor={c.contact_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            contactsPermission?.view
                              ? () => handleVECMenu(c.contact_id, "vec")
                              : null
                          }
                        >
                          <span className="link">
                            {echo(c.contact_fullname)}
                          </span>
                        </div>

                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              contactsPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              contactsPermission?.edit
                                ? () => handleVECMenu(c.contact_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        contactsPermission?.view
                          ? () => handleVECMenu(c.contact_id, "vec")
                          : null
                      }
                    >
                      {contacts?.length > 0 &&
                        Object.keys(contacts[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.contacts.includes(
                              heading
                            ) && (
                              <div data-colpos={i} className={`crm-td `}>
                                <span>
                                  {!APP_DATA.formattingColumns.contacts.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(c, heading))
                                    : contactsFormatter(
                                        heading,
                                        getObjValue(c, heading)
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!contactsPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-contacts"}
                module="contact"
              />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"contacts"}
            />
          )}
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
