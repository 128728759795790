import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getModuleDataTypesAPI } from "../../apis/workflowAPIs";
import { APP_DATA } from "../../app_data";
import { forEach } from "../../utils";
import SelectProperties from "./comps/selectProperties";
import SelectOperators from "./comps/SelectOperators";
import ConditionValueAdder from "./comps/conditionValueAdder";

const SetConditions = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const valuesAdder = useRef([]);
  const operatorRefs = useRef([]);
  const matchType = useRef(["any"]);
  const currentTypedValue = useRef(null);
  const nonValueOps = useRef(["empty", "nonEmpty"]);
  const conditionProps = useRef([
    [structuredClone(APP_DATA.workflowRules.conditions)],
  ]);

  const [wfOps, setWfOps] = useState([[[]]]);
  const [remoteWFR, setRemoteWFR] = useState(null);
  const [moduleField, setModuleFields] = useState([]);
  const [conditional, setConditional] = useState(false);
  const [mode, setMode] = useState(urlSearch.get("mode"));
  const [modules, setModules] = useState(APP_DATA.modules[0]);

  const [allConditions, setAllConditions] = useState([["c-1"]]);
  const [conditionGroups, setConditionGroups] = useState(["cg-1"]);

  useEffect(() => {
    const mode = String(urlSearch.get("mode"));
    const module = String(urlSearch.get("target")).split("-")[2];
    if (module) {
      getModuleProperties(module)
        .then((mfs) => {
          if (mode === "edit" && mfs.length) {
            setupEditMode(mfs);
          }
          setModuleFields(mfs);
          setModules(APP_DATA.modules.find((m) => m.value == module));
        })
        .catch((e) => {
          //navigate to main menu
        });
    }
  }, []);

  useEffect(() => {
    const module = String(urlSearch.get("target")).split("-")[2];
    if (module) {
      if (conditional) {
        getModuleProperties(module)
          .then((mfs) => {
            setModuleFields(mfs);
            setModules(APP_DATA.modules.find((m) => m.value == module));
          })
          .catch((e) => {
            //navigate to main menu
          });
      }
      setModules(APP_DATA.modules.find((m) => m.value == module));
    }
  }, [conditional, location.search]);

  const handleAddConditionHolder = (e) => {
    const tC = [...allConditions];
    const sufix = tC[e.target.dataset.forgi].length + 1;
    const newP = "c-" + sufix;
    tC[e.target.dataset.forgi].push(newP);

    conditionProps.current[e.target.dataset.forgi].push(
      structuredClone(APP_DATA.workflowRules.conditions)
    );
    const twfOps = [...wfOps];
    twfOps[e.target.dataset.forgi].push([]);

    setWfOps(twfOps);
    setAllConditions(() => [...tC]);
  };

  const handleAddConditionGroup = () => {
    const tCgs = [...conditionGroups];
    const sufix = tCgs.length + 1;
    const newP = "cg-" + sufix;
    tCgs.push(newP);
    setConditionGroups(() => [...tCgs]);

    const tC = allConditions;
    tC.push(["c-1"]);

    const twfOps = [...wfOps];
    twfOps.push([]);

    setWfOps(twfOps);
    setAllConditions(() => [...tC]);
    matchType.current.push("any");
    conditionProps.current.push([
      structuredClone(APP_DATA.workflowRules.conditions),
    ]);
  };

  const setupEditMode = (moduleFields = []) => {
    const rWFR = JSON.parse(window.sessionStorage.getItem("wf:remote"));
    if (rWFR.hasOwnProperty("conditions") && rWFR.conditions) {
      if (rWFR.conditions.length) {
        let cond;
        let condProps;
        let condGroups;
        let operators;

        //get the condition groups and set in the state
        if (rWFR.conditions.length === 2) {
          cond = [[], []];
          condProps = [[], []];
          operators = [[], []];
          condGroups = ["cg-1", "cg-2"];
        } else if (rWFR.conditions.length === 1) {
          cond = [[]];
          condProps = [[]];
          operators = [[]];
          condGroups = ["cg-1"];
        }

        rWFR.conditions.map((c, j) => {
          for (let i = 0; i < c.conditions.length; i++) {
            const tC = "c-" + (i + 1);
            cond[j].push(tC);
            //configure conditional Props, for all operations
            condProps[j].push(c.conditions[i]);
            const ft = moduleFields.find(
              (f) => f.field === c.conditions[i].field_name
            );
            operators[j].push(props.operators[ft.datatype]);
          }
        });
        setRemoteWFR(rWFR);
        setWfOps(operators);
        setAllConditions(cond);
        setConditionGroups(condGroups);
        conditionProps.current = condProps;

        //finally set
        setConditional(true);
      } else {
        setConditional(false);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    validateConditions() {
      let finalValidation = 1;
      if (conditional) {
        conditionGroups.map((_, j) => {
          allConditions[j].map((_, i) => {
            for (const f in conditionProps.current[j][i]) {
              if (f === "value") {
                if (
                  nonValueOps.current.includes(
                    conditionProps.current[j][i]["operator"]
                  )
                ) {
                  conditionProps.current[j][i][f] = [];
                  continue;
                }

                if (!conditionProps.current[j][i][f].length) {
                  document.getElementById("err_value-" + i + j).innerText =
                    APP_DATA.errorMessage.requiredField;
                  finalValidation = 0;
                  document.getElementById("err_value-" + i + j).style.display =
                    "block";
                } else {
                  document.getElementById("err_value-" + i + j).innerText = "";
                  document.getElementById("err_value-" + i + j).style.display =
                    "none";
                }
              } else {
                if (
                  conditionProps.current[j][i][f] == "" ||
                  conditionProps.current[j][i][f] === undefined
                ) {
                  document.getElementById("err_" + f + "_" + i + j).innerText =
                    APP_DATA.errorMessage.requiredField;
                  finalValidation = 0;
                  document.getElementById(
                    "err_" + f + "_" + i + j
                  ).style.display = "block";
                }
              }
            }
          });
        });

        if (finalValidation) {
          if (conditional) {
            const fCProps = [];
            for (let i = 0; i < conditionProps.current.length; i++) {
              const tempConf = {
                match_type: matchType.current[i],
                conditions: conditionProps.current[i],
              };
              fCProps.push(tempConf);
            }
            window.sessionStorage.setItem(
              "wf:conditions",
              JSON.stringify(fCProps)
            );
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (window.sessionStorage.getItem("wf:conditions")) {
          window.sessionStorage.removeItem("wf:conditions");
        }
        return true;
      }
    },
    resetFormData() {
      conditionProps.current = structuredClone(
        APP_DATA.workflowRules.conditions
      );
    },
  }));

  const getModuleProperties = async (moduleName) => {
    const tempPr = [];
    const pr = await getModuleDataTypesAPI(moduleName);
    if (pr.status) {
      if (pr.data.length) {
        pr.data.map((p) => {
          if (p.custom_filter) {
            tempPr.push(p);
          }
        });
        // setModuleFields(tempPr);
        return tempPr;
      }
    } else {
      //TODO not found go back to menu
      return tempPr;
    }
  };

  const handleConditionSettings = async (event) => {
    let cp = {};
    if (conditionProps.current.length) {
      cp =
        conditionProps.current[event.target.dataset.forgi][
          event.target.dataset.forci
        ];

      if (String(event.target.id).includes("field_name")) {
        const opsHandler =
          operatorRefs.current[event.target.dataset.forgi][
            event.target.dataset.forci
          ];
        if (opsHandler) {
          //update operators on Property change
          opsHandler.updateOps(String(event.target.value).split("-")[1]);
        }
        cp.field_name = String(event.target.value).split("-")[0];
      } else if (String(event.target.id).includes("operator")) {
        cp.operator = event.target.value;
        valuesAdder.current[event.target.dataset.forgi][
          event.target.dataset.forci
        ].updateOperator(event.target.value);
      }
    }
    conditionProps.current[event.target.dataset.forgi][
      event.target.dataset.forci
    ] = cp;
    document.getElementById("err_" + event.target.id).innerText = "";
    document.getElementById("err_" + event.target.id).style.display = "none";
  };

  const handleAddValues = (e) => {
    currentTypedValue.current = e.target.value;
  };

  const handleConditionType = (e) => {
    if (e.target.value === "true") {
      if (conditional) return;

      //TODO needs review
      if (remoteWFR && mode === "edit") {
        if (remoteWFR.hasOwnProperty("conditions")) {
          if (remoteWFR.conditions.length) {
            setConditional(true);
            // setRunEditFunctions(Math.floor(Math.random() * 100));
          }
        }
      } else {
        setConditional(true);
      }
    } else {
      setConditional(false);
    }
  };

  const furnishNode = async (nodeData) => {
    return new Promise((resolve, reject) => {
      try {
        const spanNode = document.createElement("span");
        const iNode = document.createElement("i");
        spanNode.className = "appendItem";
        spanNode.id = nodeData.for + "span_" + nodeData.id;
        iNode.className = "itemClose";
        iNode.id = nodeData.for + "i_" + nodeData.id;
        iNode.dataset.forgi = nodeData.forIndex;
        iNode.dataset.forci = nodeData.forConditionIndex;
        iNode.addEventListener("click", (e) => {
          console.log(conditionProps.current);
          const id = String(e.target.id).split("_")[1];
          const el = document.getElementById(nodeData.for + "span_" + id);
          const index = conditionProps.current[e.target.dataset.forgi][
            e.target.dataset.forci
          ].value.indexOf(el.innerText);
          if (index > -1) {
            const cp =
              conditionProps.current[e.target.dataset.forgi][
                e.target.dataset.forci
              ];
            cp.value.splice(index, 1);
            conditionProps.current[e.target.dataset.forgi][
              e.target.dataset.forci
            ] = cp;
            el.remove();
          }
        });
        spanNode.innerText = nodeData.name;
        spanNode.append(iNode);
        resolve({ status: true, node: spanNode });
      } catch (error) {
        reject({ status: false, error: error });
      }
    });
  };

  const getAndInsertAddedValues = async (e) => {
    let cp;
    let keynum;
    if (e.keyCode) {
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    }
    if (keynum != 13) {
      return;
    } else {
      if (
        conditionProps.current[e.target.dataset.forgi][e.target.dataset.forci][
          "value"
        ].includes(e.target.value)
      ) {
        return;
      } else {
        const conId =
          String(e.target.id).split("-")[1] +
          "--" +
          Math.floor(Math.random() * 100);

        const dd = {
          id: conId,
          for: "cond-" + conId,
          name: e.target.value,
          forIndex: e.target.dataset.forgi,
          forConditionIndex: e.target.dataset.forci,
        };

        cp =
          conditionProps.current[e.target.dataset.forgi][
            e.target.dataset.forci
          ];
        cp.value.push(dd.name);
        conditionProps.current[e.target.dataset.forgi][
          [e.target.dataset.forci]
        ] = cp;

        const n = await furnishNode(dd);
        const pn = document.getElementById(
          "addedValues_" + e.target.dataset.forci + e.target.dataset.forgi
        );
        pn.appendChild(n.node);

        const elm = document.getElementById(
          "err_value-" + e.target.dataset.forci + e.target.dataset.forgi
        );
        elm.innerText = "";
        elm.style.display = "none";
      }
      document.getElementById(
        "value-" + +e.target.dataset.forci + e.target.dataset.forgi
      ).value = "";
    }
  };

  const conditionValueSplicer = async (nodeData, valueText) => {
    console.log(conditionProps.current);
    return new Promise((resolve, reject) => {
      const dataIndex =
        conditionProps.current[nodeData.forgi][nodeData.forci].value.indexOf(
          valueText
        );
      if (dataIndex > -1) {
        const cp = conditionProps.current[nodeData.forgi][nodeData.forci];
        cp.value.splice(dataIndex, 1);
        conditionProps.current[nodeData.forgi][nodeData.forci] = cp;
        resolve({ index: dataIndex, valueText });
      } else {
        reject({ index: dataIndex, valueText });
      }
    });
  };

  const handleRemoveCondition = (e) => {
    if (e.target.dataset.forgi) {
      const tC = [...allConditions];
      tC[e.target.dataset.forgi].splice(e.target.dataset.forci, 1);
      if (!tC[e.target.dataset.forgi].length) {
        const tCgs = conditionGroups;
        tCgs.pop();
        tC.pop();
        conditionProps.current.pop();

        setConditionGroups(() => [...tCgs]);
        setAllConditions(() => [...tC]);
      } else {
        setAllConditions(() => [...tC]);
        conditionProps.current[e.target.dataset.forgi].splice(
          e.target.dataset.forci,
          1
        );
      }
    } else {
      if (
        allConditions[e.target.dataset.forgi][e.target.dataset.forci].length > 1
      ) {
        const tC = [...allConditions];
        tC[e.target.dataset.forgi].splice(e.target.dataset.forci, 1);
        setAllConditions(() => [...tC]);
        conditionProps.current[e.target.dataset.forgi].splice(
          e.target.dataset.forci,
          1
        );
      } else {
        return;
      }
    }
  };

  const handleMatchType = (e) => {
    if (!e.target.checked) {
      const allEl = document.querySelectorAll(
        ".orAnd-" + e.target.dataset.forgi
      );
      forEach(allEl, (_, el) => {
        el.checked = true;
      });
      matchType.current[e.target.dataset.forgi] = "all";
    } else {
      const allEl = document.querySelectorAll(
        ".orAnd-" + e.target.dataset.forgi
      );
      forEach(allEl, (_, el) => {
        el.checked = false;
      });
      matchType.current[e.target.dataset.forgi] = "any";
    }
  };

  const handleGlobalMatchType = (e) => {
    let mt = "";
    if (e.target.checked) {
      mt = "all";
    } else {
      mt = "any";
    }
    props.functions.settingAgrregator("match_type", { value: mt });
  };

  return (
    <React.Fragment>
      <div className="wstep">
        <div className="count">
          <span>2</span>
        </div>
        <div className="step-info">
          <div className="frst-hlf">
            <h3>
              Set conditions{" "}
              <i
                className="info-icon"
                data-original-title="Please select conditions to filter records to execute the workflow"
              ></i>
            </h3>
          </div>
          <div className="scnd-hlf">
            <div className="crm-radio-container rule-options">
              <div className="rule-wrpr">
                <label className="crm-radio-check rgt-mgn">
                  <span>All {modules ? modules.name?.toLowerCase() : ""}</span>
                  <input
                    type="radio"
                    className="crm-form-ctrl"
                    name="conditionType"
                    value={"false"}
                    onChange={(e) => handleConditionType(e)}
                    checked={!conditional}
                  />
                  <span className="crm-radio-checkmark"></span>
                </label>
                <label className="crm-radio-check rgt-mgn">
                  <span>Based on conditions</span>
                  <input
                    type="radio"
                    className="crm-form-ctrl"
                    name="conditionType"
                    value={"true"}
                    onChange={(e) => handleConditionType(e)}
                    checked={conditional}
                  />
                  <span className="crm-radio-checkmark"></span>
                </label>
              </div>
            </div>

            {!conditional ? (
              <div className="crm-form-row mb10">
                <p className="desc-prt col-6">
                  This workflow will be applied to all{" "}
                  {modules ? modules.value : ""}
                </p>
              </div>
            ) : conditionGroups.length ? (
              conditionGroups.map((_, j) => (
                <React.Fragment key={"con-row" + j}>
                  <div className="group-map">
                    <div className="toggle-wrap">
                      <p className="toggle-label">
                        Match <b>ALL</b> conditions
                      </p>
                      <label className="switch">
                        <input
                          data-forgi={j}
                          type="checkbox"
                          defaultChecked={
                            mode === "edit" && remoteWFR
                              ? remoteWFR.conditions[j]?.match_type === "any"
                                ? 1
                                : 0
                              : 1
                          }
                          onChange={(e) => handleMatchType(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                      <p className="toggle-label">
                        Match <b>ANY</b> condition
                      </p>
                    </div>

                    <div className="crm-form-row">
                      <div className="grp-rw">
                        {allConditions.length
                          ? allConditions[j].map((_, i) => (
                              <>
                                <React.Fragment key={"condition-list" + i}>
                                  <div className="crm-form-grp mt0">
                                    <div className="crm-form-field req col-4">
                                      <SelectProperties
                                        i={i}
                                        j={j}
                                        mode={mode}
                                        moduleField={moduleField}
                                        hcs={handleConditionSettings}
                                        conditionProps={conditionProps}
                                        remotePros={
                                          remoteWFR?.conditions[j]?.conditions[
                                            i
                                          ]
                                        }
                                      />
                                    </div>

                                    <div className="crm-form-field req col-4">
                                      <SelectOperators
                                        i={i}
                                        j={j}
                                        mode={mode}
                                        wfOps={wfOps[j][i]}
                                        hcs={handleConditionSettings}
                                        conditionProps={conditionProps}
                                        ref={(el) => {
                                          operatorRefs.current[j] =
                                            operatorRefs.current[j] || [];
                                          operatorRefs.current[j][i] = el;
                                        }}
                                        remoteOps={
                                          remoteWFR?.conditions[j]?.conditions[
                                            i
                                          ]
                                        }
                                        localOperators={props.operators}
                                      />
                                    </div>

                                    <div
                                      className="crm-form-grp col-10 values-col req"
                                      style={{ maxWidth: "100%" }}
                                    >
                                      <div className="crm-form-grp req crm-frm-100">
                                        <ConditionValueAdder
                                          i={i}
                                          j={j}
                                          mode={mode}
                                          functions={{
                                            handleAddValues,
                                            conditionValueSplicer,
                                            getAndInsertAddedValues,
                                          }}
                                          conditionProps={
                                            conditionProps.current[j][i]
                                          }
                                          ref={(el) => {
                                            valuesAdder.current[j] =
                                              valuesAdder.current[j] || [];
                                            valuesAdder.current[j][i] = el;
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="wflow-actions">
                                      {i === allConditions[j].length - 1 ? (
                                        <i
                                          data-forgi={j}
                                          onClick={(e) =>
                                            handleAddConditionHolder(e)
                                          }
                                          className="crm-action-plus"
                                        ></i>
                                      ) : null}
                                      <i
                                        style={{
                                          display:
                                            j > 0
                                              ? i ===
                                                allConditions[j].length - 1
                                                ? "block"
                                                : "none"
                                              : i === 0
                                              ? "none"
                                              : i ===
                                                allConditions[j].length - 1
                                              ? "block"
                                              : "none",
                                        }}
                                        data-forgi={j}
                                        data-forci={i}
                                        className="crm-dlt-border"
                                        onClick={(e) =>
                                          handleRemoveCondition(e)
                                        }
                                      ></i>
                                    </div>
                                  </div>

                                  {/* Scoped operator for each condition */}
                                  {i != allConditions[j].length - 1 ? (
                                    <div className="crm-form-grp">
                                      <h2 className="toggle">
                                        <label className="switch">
                                          <input
                                            disabled
                                            type="checkbox"
                                            className={`orAnd-${j}`}
                                            data-mt={matchType.current[j]}
                                            checked={
                                              matchType.current[j] === "all"
                                            }
                                          />
                                          <span className="slider round">
                                            <span className="text">AND</span>
                                          </span>
                                        </label>
                                      </h2>
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              </>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                  {/* Global Condition operator for groups */}
                  {j != conditionGroups.length - 1 ? (
                    <div className="wblock-operator">
                      <h2 className="toggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="match_type"
                            onChange={(e) => handleGlobalMatchType(e)}
                            defaultChecked={
                              mode === "edit" && remoteWFR
                                ? remoteWFR?.match_type === "any"
                                  ? 0
                                  : 1
                                : 0
                            }
                          />
                          <span className="slider round">
                            <span className="text">AND</span>
                          </span>
                        </label>
                      </h2>
                    </div>
                  ) : null}
                </React.Fragment>
              ))
            ) : null}

            {conditional && conditionGroups.length < 2 ? (
              <div className="add-block-container">
                <button
                  type="button"
                  className="btn btn-addgroup"
                  onClick={() => handleAddConditionGroup()}
                >
                  <i className="btn-ico-plus-blue"></i>
                  <span>Add another condition</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default SetConditions;
