import React from "react";

export default function LeadStaticFields(props) {
  return (
    <React.Fragment>
      <div className="crm-form-row">
        <h3>Lead Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="leadOwner"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Deal Owner"
                htmlFor="leadOwner"
                placeholder="Lead Owner"
                className="floating-label"
              >
                Lead Owner
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Account"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Account"
                htmlFor="Account"
                placeholder="Account"
                className="floating-label"
              >
                Account
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="leadOwner"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Deal Owner"
                htmlFor="leadOwner"
                placeholder="First name"
                className="floating-label"
              >
                First name
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field req disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Last name"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Last name"
                htmlFor="Last name"
                placeholder="Last name"
                className="floating-label"
              >
                Last name
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Role"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Deal Owner"
                htmlFor="Role"
                placeholder="Role"
                className="floating-label"
              >
                Role
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Email"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Email"
                htmlFor="Email"
                placeholder="Email"
                className="floating-label"
              >
                Email
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="leadOwner"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Deal Owner"
                htmlFor="leadOwner"
                placeholder="Phone"
                className="floating-label"
              >
                Phone
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Mobile"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Mobile"
                htmlFor="Mobile"
                placeholder="Mobile"
                className="floating-label"
              >
                Mobile
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label
                alt="Lead Status"
                htmlFor=""
                placeholder="Lead Status"
                className="floating-label"
              >
                Lead Status
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="leadStatus"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label
                alt="Lead Source"
                htmlFor=""
                placeholder="Lead Source"
                className="floating-label"
              >
                Lead Source
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="leadSource"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field">
              <select className="crm-form-ctrl floating-select">
                <option>Select</option>
              </select>
              <i className="blur-arw"></i>
              <label
                alt="Lead Industry"
                htmlFor=""
                placeholder="Lead Industry"
                className="floating-label"
              >
                Lead Industry
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a className="crm-action-opt crm-action-settings">
                    <ul className="crm-dropdown-action">
                      <li>
                        <span
                          id="link-phR_2_9"
                          data-field="leadIndustry"
                          className="crm-action-edit"
                          onClick={(e) =>
                            props.functions.ConfigureStaticDropdown(e)
                          }
                        >
                          Edit Properties
                        </span>
                      </li>
                    </ul>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="crm-form-row">
        <h3>Address Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="leadOwner"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Deal Owner"
                htmlFor="leadOwner"
                placeholder="Country"
                className="floating-label"
              >
                Country
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="State"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="State"
                htmlFor="State"
                placeholder="State"
                className="floating-label"
              >
                State
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="City"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="City"
                htmlFor="City"
                placeholder="City"
                className="floating-label"
              >
                City
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Street"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Street"
                htmlFor="Street"
                placeholder="Street"
                className="floating-label"
              >
                Street
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="crm-form-row">
        <div className="crm-form-grp">
          <div className="crm-form-grp crm-frm-50">
            <div className="crm-form-field disable-fld">
              <input
                type="text"
                className="crm-form-ctrl floating-input"
                id="Pin Code"
                autocomplete="off"
                placeholder=" "
                readOnly={true}
              />
              <label
                alt="Zip Code"
                htmlFor ="Pin Code"
                placeholder="Zip Code"
                className="floating-label"
              >
                Zip Code
              </label>
              {!props.preview ? (
                <div className="crm-action">
                  <a
                    id=""
                    className="crm-action-opt crm-action-settings"
                    title="System defined fields can not be modified"
                  ></a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="crm-form-row">
        <h3>Additional Information</h3>
        <div className="crm-form-grp">
          <div className="crm-form-grp">
            <div className="crm-form-field disable-fld">
              <textarea
                placeholder="Additional"
                rows="6"
                readOnly={true}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
