import axios from "axios";
import { API_URL } from ".";
import { APISuccessResponse, getAuthToken } from ".";
import { getAccessTokenAPI, getCachedData, setCachedData } from "./authAPIs";
import { getUserAccessInfo } from "../utils";
import WorkerLoader from "../workerLoader";
import jwt_decode from "jwt-decode"

export const getAllUsers = async (userFilter, userOwner, sortBy, sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/users/?user_filter=${userFilter ? userFilter : "Active Users"}${
            userOwner ? `&created_by=${userOwner}` : ""
          }&user_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getUsersByName = async (userName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/users/name/${userName}?active=true`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getFilteredUsers = async (searchText) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/users/name/" + searchText, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log("res in filtered users", res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const getUserById = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/users/" + userId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateUserById = async (id = 0, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/users/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        const { user_id } = getUserAccessInfo();
        if (id === user_id) {
          const authWorker = new WorkerLoader();
          getCachedData()
            .then((c) => {
              setTimeout(() => {
                getAccessTokenAPI(c)
                  .then(async (t) => {
                    setCachedData(c)
                      .then(async () => {
                        const decodedToken = jwt_decode(t.data["access_token"]);
                        window.localStorage.setItem(
                          "a_d",
                          JSON.stringify({
                            access_token: t.data["access_token"],
                          })
                        );
                        authWorker.port.postMessage({
                          action: "tokenRefresh",
                          data: {
                            aExp: decodedToken.exp,
                          },
                        });
                        resolve(await APISuccessResponse(res));
                      })
                      .catch(() => {
                        resolve({ status: false, data: [] });
                      });
                  })
                  .catch(() => {
                    resolve({ status: false, data: [] });
                  });
              }, 2000);
            })
            .catch(() => {
              resolve({ status: false, data: [] });
            });
        } else {
          resolve(await APISuccessResponse(res));
        }
      })
      .catch(async (e) => {
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewUser = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/users/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteUser = async (reqPayload, userId, tranferTo) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        API_URL +
          `/users/?deleted_by=${userId}${
            tranferTo ? `&transfer_to=${tranferTo}` : ""
          }`,
        {
          data: reqPayload,
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getUsersLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = false,
  userFilter
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/users/offset?offset=${offset}&limit=${limit}&count=${count}&user_filter=${
            userFilter ? userFilter : "Active Users"
          }&user_owner=""`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateUsersByProfileId = async (profileId, reqPayload) => {
  return axios
    .put(API_URL + `/users/profile/${profileId}`, reqPayload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });
};

export const deactivateUser = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/users/deactivate/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const reactivateUserAPI = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/users/reactivate/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getUsersWithoutGroup = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/users/users_without_group`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
