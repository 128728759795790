import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";
import CONSTANTS from "../app_data/constants";

export const saveWebformConfigAPI = (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/web_forms/", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getSavedWebformsConfigAPI = (selectFilter, sortBy, sortField) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/web_forms/?web_form_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getSavedWebformsConfigByIdAPI = (id, webform = false) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/web_forms/id/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: webform
            ? "Bearer " + CONSTANTS.encryptAppData()
            : getAuthToken(),
        },
      })
      .then(async (res) => {
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteWebformByIdAPI = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/web_forms/?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateWebformAPI = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/web_forms/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
