import React, { useState, useEffect, useRef } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import NoResults from "../components/common/noResults";
import { getUserById, updateUserById } from "../apis/usersAPI";
import { getUserAccessInfo, echo } from "../utils";
import { useOutside } from "../customHooks/useOutside";
import { Tooltip } from "react-tooltip";
import { deleteImageAPI } from "../apis/utilsAPI";
import FullPageLoader from "../components/common/fullPageLoader";

export default function PersonalSettingsView() {
  const formData = useRef({});
  const location = useLocation();
  const openMenu = useRef(null);
  const { openModal, openToast, handleRSidebar, closeModal } =
    useOutletContext();

  const [loaded, setLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [currentView, setCurrentView] = useState("view");
  const [src, setImageSource] = useState("");

  useEffect(() => {
    const { user_id } = getUserAccessInfo();
    if (user_id) {
      getUserInfo(user_id);
    } else {
      return;
    }
  }, [location.search]);

  const getUserInfo = async (id) => {
    const user = await getUserById(id);
    if (user.status) {
      setUserDetails(user.data);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    } else {
      // openToast("Network problem detected", "error");
    }
  };

  const handleOpenMenu = (state) => {
    if (state === "open") {
      openMenu.current.classList.add("active");
    } else {
      openMenu.current.classList.remove("active");
    }
  };

  useOutside(openMenu, () => {
    openMenu.current.classList.remove("active");
  });

  const setImageSrc = (src) => {
    setLoaded(false);
    setImageSource(src.userSrc);
    const { user_id } = getUserAccessInfo();
    const payload = {
      user_image: src.userImage,
      user_image_compressed: src.userImageCompressed,
    };
    updateUserById(user_id, payload)
      .then((res) => {
        if (res.status) {
          getUserInfo(user_id);
          closeModal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviewImage = () => {
    openModal("previewImageModal", {
      src: src,
      id: userDetails.user_image_compressed,
      module: "users",
    });
  };

  const handleSelectImage = () => {
    openModal("uploadImageModal", {
      module: "userProfile",
      moduleName: "users",
      id: userDetails?.user_id,
      setImageSrc: setImageSrc,
    });
  };

  const deleteImage = async () => {
    const { user_id } = getUserAccessInfo();
    const payload = {
      user_image: "",
      user_image_compressed: "",
    };
    updateUserById(user_id, payload)
      .then((res) => {
        if (res.status) {
          closeModal();
          setImageSource("");
          getUserInfo(user_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    openModal("imageDeleteModal", {
      module: "users",
      deleteImage: deleteImage,
    });
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">My account</h1>
        </div>
      </div>
      <>
        {loaded ? (
          <React.Fragment>
            <div className="profile-sctn">
              <div
                className="user-img-wrp"
                onClick={() => handleOpenMenu("open")}
              >
                <img
                  src={
                    userDetails?.user_image_compressed ||
                    "https://www.idrive.com/crm/webconsole/assets/images/profile_icon.svg"
                  }
                />
                <i
                  id="t-uploadImage"
                  className="crm-camera-icon"
                  data-original-title="Upload Image"
                  data-placement="right"
                  onClick={() => handleOpenMenu("open")}
                ></i>
                <Tooltip
                  place={"right"}
                  anchorId={"t-uploadImage"}
                  content="Upload Image"
                />
                <ul
                  className={"crm-dropdown-action"}
                  ref={openMenu}
                  id=""
                  onMouseLeave={() => handleOpenMenu("close")}
                >
                  <li>
                    {userDetails.user_image_compressed && (
                      <span
                        className={"crm-action-view"}
                        onClick={handlePreviewImage}
                      >
                        Preview Image
                      </span>
                    )}
                  </li>
                  <li>
                    {userDetails.user_image_compressed && (
                      <span
                        className={"crm-action-upload"}
                        onClick={() => handleSelectImage()}
                      >
                        Change Image
                      </span>
                    )}
                  </li>
                  <li>
                    {!userDetails.user_image_compressed && (
                      <span
                        className={"crm-action-upload"}
                        onClick={() => handleSelectImage()}
                      >
                        Upload Image
                      </span>
                    )}
                  </li>
                  <li className="divider">
                    {userDetails.user_image_compressed && (
                      <span
                        className={"crm-action-delete"}
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </span>
                    )}
                  </li>
                </ul>
              </div>
              <div className="profile-desc">
                <h2>{echo(userDetails?.user_fullname)}</h2>
                <p className="role" name="user_title">
                  <span>{echo(userDetails?.user_role)}</span>
                </p>
                <p className="mail" name="user_email">
                  <i id="t-uploadImage" className="mail-icon"></i>

                  <span className="mail-id">
                    {echo(userDetails?.user_email)}
                  </span>
                </p>
              </div>
            </div>
            <div className="info-sctn">
              <div className="info-wrap">
                <h3>
                  User information{" "}
                  <i
                    id={"edit-title-user"}
                    className={"crm-edit-icn"}
                    data-original-title="Edit Info"
                    onClick={() =>
                      handleRSidebar("editUserInfo", {
                        userDetails: userDetails,
                      })
                    }
                  ></i>
                  <Tooltip
                    place={"right"}
                    anchorId={"edit-title-user"}
                    content="Edit"
                  />
                </h3>
                <div className="info-fields">
                  <div className="row">
                    <span className="title">First name</span>
                    <span className="value">
                      {echo(userDetails?.user_firstname)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Last name</span>
                    <span className="value">
                      {echo(userDetails?.user_lastname)}
                    </span>
                  </div>
                  {/* <div className="row">
                    <span className="title">Full name</span>
                    <span className="value">
                      {echo(userDetails?.user_fullname)}
                    </span>
                  </div> */}
                  <div className="row">
                    <span className="title">Role</span>
                    <span className="value">
                      {echo(userDetails?.user_role)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Profile</span>
                    <span className="value">
                      {echo(userDetails?.user_profile_name)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Alias</span>
                    <span className="value">
                      {echo(userDetails?.user_alias)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Date of birth</span>
                    <span className="value">
                      {echo(userDetails?.user_date_of_birth)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="info-wrap m30">
                <h3>
                  Contact information{" "}
                  <i
                    className={"crm-edit-icn"}
                    data-original-title="Edit Info"
                    onClick={() =>
                      handleRSidebar("editUserContactInfo", {
                        userDetails: userDetails,
                      })
                    }
                    id={"edit-title-contact"}
                  ></i>
                  <Tooltip
                    place={"right"}
                    anchorId={"edit-title-contact"}
                    content="Edit"
                  />
                </h3>
                <div className="info-fields">
                  <div className="row">
                    <span className="title">Email</span>
                    <span className="value">
                      {echo(userDetails?.user_email)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Mobile</span>
                    <span className="value">
                      {echo(userDetails?.user_mobile)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Phone</span>
                    <span className="value">
                      {echo(userDetails?.user_work_phone)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="info-wrap m30">
                <h3>
                  Address information{" "}
                  <i
                    id={"edit-title-address"}
                    className={"crm-edit-icn"}
                    data-original-title="Edit Info"
                    onClick={() =>
                      handleRSidebar("editUserAddressInfo", {
                        userDetails: userDetails,
                      })
                    }
                  ></i>
                  <Tooltip
                    anchorId={"edit-title-address"}
                    place={"right"}
                    content="Edit"
                  />
                </h3>
                <div className="info-fields">
                  <div className="row">
                    <span className="title">Street</span>
                    <span className="value">
                      {echo(userDetails?.user_street)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">City</span>
                    <span className="value">
                      {echo(userDetails?.user_city)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">State</span>
                    <span className="value">
                      {echo(userDetails?.user_state)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Zip code</span>
                    <span className="value">
                      {echo(userDetails?.user_zip_code)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Country</span>
                    <span className="value">
                      {echo(userDetails?.user_country)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="info-wrap m30">
                <h3>
                  Locale information{" "}
                  <i
                    id={"edit-title-locale"}
                    className={"crm-edit-icn"}
                    data-original-title="Edit Info"
                    onClick={() =>
                      handleRSidebar("editUserLocale", {
                        userDetails: userDetails,
                      })
                    }
                  ></i>
                  <Tooltip
                    place={"right"}
                    anchorId={"edit-title-locale"}
                    content="Edit"
                  />
                </h3>
                <div className="info-fields">
                  <div className="row">
                    <span className="title">Time zone</span>
                    <span className="value">
                      {echo(userDetails?.user_time_zone)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Date format</span>
                    <span className="value">
                      {echo(userDetails?.user_date_format)}
                    </span>
                  </div>
                  <div className="row">
                    <span className="title">Time format</span>
                    <span className="value">
                      {echo(userDetails?.user_time_format)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <FullPageLoader />
        )}
      </>
    </React.Fragment>
  );
}
