import React, { useEffect, useState } from "react";
import useKeyEvent from "../../customHooks/useKeyEvent";
import { APP_DATA } from "../../app_data";

export default function PageNavigator(props) {
  const [pageNumber, setPageNumber] = useState(props.currentPage);

  useEffect(() => {
    setPageNumber(props.currentPage);
  }, [props.currentPage]);

  //handle increment and decrement according to the max page and allowed numebrs
  const handleIncDec = (e) => {
    if (e.target.id === "inc") {
      if (props.maxPageSize <= pageNumber) {
        return;
      } else {
        setPageNumber(pageNumber + 1);
      }
    } else {
      if (pageNumber <= 1) {
        return;
      } else {
        setPageNumber(pageNumber - 1);
      }
    }
  };

  const handlePageNumberInput = (e) => {
    //setting empty string but no call to action
    if (e.target.value === "" || e.target.value === undefined) {
      setPageNumber(e.target.value);
      return;
    }
    //set page number only its valid number and lesser than the max page size
    if (
      parseInt(e.target.value) < 1 ||
      props.maxPageSize < parseInt(e.target.value)
    ) {
      return;
    } else {
      setPageNumber(parseInt(e.target.value));
    }
  };

  const handleNavigatePage = () => {
    if (pageNumber === "" || pageNumber === undefined) return;
    props.functions.handlePageNavigation(pageNumber);
  };

  //handeling page nav on press enter
  const handleKeyDownPageNav = (e) => {
    if (APP_DATA.invalidNumberChars.includes(e.key)) {
      e.preventDefault();
      return false;
    }

    if (e.keyCode === 13) {
      handleNavigatePage();
    }
  };

  return (
    <React.Fragment>
      <div className="page-no">
        <div className="inptwrp">
          <input
            value={pageNumber}
            type="number"
            id="pagInput"
            onKeyDown={(e) => handleKeyDownPageNav(e)}
            onChange={(e) => handlePageNumberInput(e)}
            className="crm-form-ctrl floating-input"
          />
          <i
            className="up-arw arw"
            id="dec"
            onClick={(e) => handleIncDec(e)}
          ></i>
          <i
            className="down-arw arw"
            id="inc"
            onClick={(e) => handleIncDec(e)}
          ></i>
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handleNavigatePage()}
        >
          GO
        </button>
      </div>
    </React.Fragment>
  );
}
