import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getAllWFRulesAPI, updateWFRuleAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import { debounceOptFunc, echo, getUserAccessInfo, getSortBy } from "../utils";
import createResizer from "../utils/resizerHandler";
import { APP_DATA } from "../app_data";
import DataTableLoader from "../components/common/dataTableLoader";
import { Tooltip } from "react-tooltip";
import { searchThroughAPIs } from "../apis";

export default function WorkflowsView() {
  const { openModal, openToast, getUserInfo } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [permission, setPermission] = useState({});
  const [filter, setFilter] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  useEffect(() => {
    getAllRules();
  }, [location.search]);

  useEffect(() => {
    createResizer();
  }, [rules, location.search, loading]);

  useEffect(() => {
    setPermission(getUserAccessInfo("Workflow").permission || {});
  }, []);

  const getAllRules = async (sortBy, sortField) => {
    setLoading(true);
    const rr = await getAllWFRulesAPI(sortBy, sortField);
    if (rr.status) {
      setRules(rr.data);
      setTotalRecords(rr.data.length);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    } else {
      openToast(APP_DATA.messages.error_message, "error");
    }
    setLoading(false);
  };

  const activeDeactiveWFRules = async (e) => {
    let pl = {};
    const id = String(e.target.id).split("_")[1];
    if (e.target.checked) {
      pl = {
        active: true,
        updated_by: getUserInfo().user_id,
      };
    } else {
      pl = {
        active: false,
        updated_by: getUserInfo().user_id,
      };
    }
    const rupdated = await updateWFRuleAPI(id, pl);
    if (rupdated.status) {
      openToast(APP_DATA.messages.notification, "success");
    } else {
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const handleEditWFRules = (id, module) => {
    navigate(`/app/workflows/create?mode=edit&wfr=${id}&module=${module}`);
  };

  const handleSearch = async (qstring) => {
    clearSort();
    if (qstring.length) {
      setLoading(true);
      setFilter(true);
      const searched = await searchThroughAPIs("rules", qstring);
      if (searched.status) {
        setRules(searched.data.rules);
        setTotalRecords(searched.data.total_count);
      } else {
        setRules([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      setFilter(false);
      getAllRules();
    }
  };

  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleDeleteMenu = (id) => {
    try {
      openModal("deleteModal", {
        data: [id],
        redirect: "workflows",
        msgInfo: rules.find((r) => r.id === id),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const clearSort = () => {
    setSortInfo({
      sortBy: "",
      sortField: "",
      sortKey: "",
    });
  };

  const handleSort = (e, field, id) => {
    const element = document.getElementById(id);
    const sortBy = getSortBy(element);
    if (sortBy && field) {
      setSortInfo({ sortBy, sortField: field, sortKey: id });
    }
    getAllRules(sortBy, field);
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Workflow</h1>
          <span className="desc"></span>
        </div>
        <div className="header_rhs">
          <div className="search_bar">
            <input
              type="text"
              placeholder="Search workflow"
              onChange={(e) => handleDebouncedSearch(e.target.value)}
            />
            <span className="btn-ico-srch"></span>
          </div>
        </div>
      </div>

      <div className="crm-top-menu">
        <div className="menu-lft">
          <div className="hdr-btn-drpdwn mr15">
            <div
              className={
                permission.create
                  ? "lhs-prt crt-fld-btn"
                  : "lhs-prt crt-fld-btn disabled"
              }
              onClick={
                permission.create
                  ? () => navigate("/app/workflows/create?mode=create")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Workflow</span>
            </div>
          </div>
        </div>
        {rules?.length > 0 && permission?.view && (
          <div className="menu-rgt">
            <div className="total_count">
              <div className="total_title">Total workflow: </div>
              <div className="total_number">
                <strong>{totalRecords}</strong>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="crm-table cst-mrgn mtbl">
        <div className="crm-tb-hed">
          {rules?.length > 0 && permission.view && !loading && (
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                <div
                  id="wflowName"
                  className={`crm-td crm-td-wflowname  ${
                    sortInfo.sortKey === "wflowName"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span onClick={(e) => handleSort(e, "Name", "wflowName")}>
                    Workflow name
                  </span>
                </div>
              </div>
              <div className="crm-scroll-field">
                <div
                  id="col-2"
                  className={`crm-td r crm-td-entity ${
                    sortInfo.sortKey === "col-2"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span onClick={(e) => handleSort(e, "Module", "col-2")}>
                    Entity
                  </span>
                </div>
                <div
                  id="col-3"
                  className={`crm-td r crm-td-status ${
                    sortInfo.sortKey === "col-3"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span onClick={(e) => handleSort(e, "Active", "col-3")}>
                    Status
                  </span>
                </div>
                <div
                  id="col-4"
                  className={`crm-td r crm-td-createdat ${
                    sortInfo.sortKey === "col-4"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span onClick={(e) => handleSort(e, "Created date", "col-4")}>
                    Created at
                  </span>
                </div>
                <div
                  id="col-5"
                  className={`crm-td r crm-td-createdby ${
                    sortInfo.sortKey === "col-5"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span onClick={(e) => handleSort(e, "Created by", "col-5")}>
                    Created by
                  </span>
                </div>
                <div
                  id="col-6"
                  className={`crm-td r crm-td-lasttrigger ${
                    sortInfo.sortKey === "col-6"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span
                    onClick={(e) => handleSort(e, "Last triggered at", "col-6")}
                  >
                    Last triggered at
                  </span>
                </div>
                <div
                  id="col-7"
                  className={`crm-td r crm-td-triggercount ${
                    sortInfo.sortKey === "col-7"
                      ? sortInfo.sortBy === "desc"
                        ? "crm-sort sort-active"
                        : "crm-sort sort-active-up"
                      : ""
                  }`}
                >
                  <span
                    onClick={(e) => handleSort(e, "Triggered count", "col-7")}
                  >
                    Triggered count
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="crm-tb-bdy">
          {!loading ? (
            rules.length && permission.view ? (
              rules.map((r, i) => (
                <div className="crm-tb-rw" key={"wr-row" + i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-wflowname">
                      <span className="name wflow-name">{r.name}</span>
                      <div className="action-rw">
                        <i
                          className={
                            permission.edit ? "crm-edit" : "crm-edit disabled"
                          }
                          onClick={
                            permission.edit
                              ? () => handleEditWFRules(r.id, r.module)
                              : null
                          }
                        ></i>
                        <i
                          className={
                            permission.delete
                              ? "crm-delete-icon"
                              : "crm-delete-icon disabled"
                          }
                          onClick={
                            permission.delete
                              ? () => handleDeleteMenu(r.id)
                              : null
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="crm-scroll-field" key={"rule_list" + i}>
                    <div data-colpos="2" className="crm-td crm-td-entity">
                      <span>{r.module}</span>
                    </div>
                    <div data-colpos="3" className="crm-td crm-td-status">
                      <label className="switch">
                        <input
                          type="checkbox"
                          id={"switch_" + r.id}
                          defaultChecked={r.active}
                          disabled={!permission.edit}
                          onChange={
                            permission.edit
                              ? (e) => activeDeactiveWFRules(e)
                              : null
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div data-colpos="4" className="crm-td crm-td-createdat">
                      <span>{echo(r.created_at)}</span>
                    </div>
                    <div data-colpos="5" className="crm-td crm-td-createdby">
                      <span>{r.created_by_name}</span>
                    </div>
                    <div data-colpos="6" className="crm-td crm-td-lasttrigger">
                      <span>{echo(r.last_triggered_at)}</span>
                    </div>
                    <div data-colpos="7" className="crm-td crm-td-triggercount">
                      <span>{echo(r.triggered_count)}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoResults filter={filter} permission={!permission.view} />
            )
          ) : (
            <DataTableLoader mClass="crm-td crm-td-wflowname" cols="10000000" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
