import React, { forwardRef, useImperativeHandle, useState } from "react";

const SelectOperators = forwardRef((props, ref) => {
  const [wfOps, setWfOps] = useState(props.wfOps);

  useImperativeHandle(ref, () => ({
    updateOps(type) {
      const localOps = props.localOperators;
      setWfOps(localOps[type]);
    },
  }));

  const getOperatorDisplayName = (operator) => {
    let opName = "";
    if (props.wfOps.length) {
      props.wfOps.find((ops) => {
        if (ops.operator === operator) {
          opName = ops.display_name;
        }
      });
      return opName;
    }
    return opName;
  };
  return (
    <React.Fragment>
      <select
        data-forgi={props.j}
        data-forci={props.i}
        onChange={(e) => props.hcs(e)}
        id={"operator_" + props.i + props.j}
        className="crm-form-ctrl floating-select"
      >
        {props.mode === "edit" && props.remoteOps?.operator ? (
          <optgroup label="Remote settings">
            <option selected disabled>
              {getOperatorDisplayName(props.remoteOps?.operator)}
            </option>
          </optgroup>
        ) : null}

        {wfOps?.length ? (
          <optgroup label="Available operators">
            {wfOps.map((ops, l) => (
              <React.Fragment>
                {l === 0 ? (
                  <option disabled selected>
                    Select
                  </option>
                ) : null}
                <option
                  value={ops.operator}
                  key={"opt_ops" + l}
                  selected={
                    props.mode === "edit"
                      ? ops.operator === props.remoteOps?.operator
                      : false
                  }
                >
                  {ops.display_name}
                </option>
              </React.Fragment>
            ))}
          </optgroup>
        ) : (
          <option>Please select one property</option>
        )}
      </select>
      <label
        alt="Operators"
        htmlFor=""
        placeholder="Operators"
        className="floating-label"
      >
        Operators
      </label>
      <span
        id={"err_operator_" + props.i + props.j}
        className="crm-inp-error"
      ></span>
    </React.Fragment>
  );
});

export default SelectOperators;
