import React, { useEffect, useRef, useState } from "react";
import { APP_DATA } from "../../../app_data";
import { echo, forEach, getObjValue, meetingsFormatter } from "../../../utils";
import NoResults from "../../common/noResults";
import DataTableLoader from "../../common/dataTableLoader";
import createResizer from "../../../utils/resizerHandler";

export default function MeetingsTable(props) {
  const selectAllCheck = useRef(null);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  useEffect(() => {
    createResizer();
    unCheckRecords();
  }, [props.loading,props.updateData]);

  const unCheckRecords = () => {
    if (props.deleteIdsList.current?.length > 0) {
      props.deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      props.deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!props.deleteIdsList.current.includes(parseInt(e.target.id))) {
        props.deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (props.deleteIdsList.current?.length === props.updateData?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = props.deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        props.deleteIdsList.current.splice(i, 1);
      }
      if (props.deleteIdsList.current?.length !== props.updateData?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (props.deleteIdsList.current.length > 0) {
      props.setBtnDelete(true);
    } else {
      props.setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };
  return (
    <div className="tbl-wrpr mass-update">
      <div className="crm-table cst-mrgn mtbl mass-update">
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-fixed-field">
              {props.updateData?.length > 0 && (
                <div className="crm-td crm-td-title">
                  <input
                    type="checkbox"
                    ref={selectAllCheck}
                    className="crm-checkmark"
                    id="title"
                    onClick={(e) => handleSelectAll(e)}
                    disabled={props.updateData.length ? false : true}
                  />
                  <label
                    htmlFor="title"
                    // onClick={(e) => {
                    //   handleSort(e, "meeting_title", "meetingTit");
                    // }}
                  >
                    <span
                      className={
                        sortInfo.sortKey === "meeting_title"
                          ? "sort-highlight"
                          : ""
                      }
                      id="tit"
                    >
                      Title
                    </span>
                  </label>
                  <i
                    className={
                      sortInfo.sortKey === "meeting_title"
                        ? sortInfo.sortBy === "desc"
                          ? "sort sort-down"
                          : "sort sort-up"
                        : ""
                    }
                    id="meetingTit"
                    // onClick={(e) =>
                    //   handleSort(e, "meeting_title", "meetingTit")
                    // }
                  ></i>
                </div>
              )}
            </div>
            <div className="crm-scroll-field">
              {props.updateData?.length > 0 &&
                Object.keys(props.updateData[0]).map(
                  (heading, index) =>
                    !APP_DATA.hiddenColumns.meetings.includes(heading) && (
                      <div
                        id={`col-${index + 2}`}
                        className={`crm-td r  ${
                          sortInfo.sortKey === heading
                            ? sortInfo.sortBy === "desc"
                              ? "crm-sort sort-active"
                              : "crm-sort sort-active-up"
                            : ""
                        }`}
                        style={
                          heading === "meeting_link"
                            ? {
                                width: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }
                            : {}
                        }
                      >
                        <span
                        //   onClick={(e) =>
                        //     handleSort(e, heading, `col-${index + 2}`)
                        //   }
                        >
                          {APP_DATA.APIColumnMappings.meetings[heading]
                            ? APP_DATA.APIColumnMappings.meetings[heading]
                            : heading?.split("_").join(" ")}
                        </span>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="crm-tb-bdy">
          {!props.loading ? (
            props.updateData?.length > 0 ? (
              props.updateData.map((m, i) => (
                <div className="crm-tb-rw" key={"meetingList_" + i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-title">
                      <input
                        type="checkbox"
                        className="crm-checkmark ids"
                        id={m.meeting_id}
                        onChange={(e) => handleDeleteCheck(e)}
                      />
                      <label className="title" title="" htmlFor={m.meeting_id}>
                        <span>{m.meeting_title}</span>
                      </label>
                      <div className="action-rw"></div>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    {props.updateData?.length > 0 &&
                      Object.keys(props.updateData[0])?.map(
                        (heading, i) =>
                          !APP_DATA.hiddenColumns.meetings.includes(
                            heading
                          ) && (
                            <div
                              data-colpos={i + 2}
                              className={
                                heading === "meeting_link"
                                  ? "crm-td crm-td-link"
                                  : "crm-td"
                              }
                            >
                              {heading === "meeting_link" ? (
                                <span>
                                  <a>{echo(getObjValue(m, heading))}</a>
                                </span>
                              ) : (
                                <span>
                                  {!APP_DATA.formattingColumns.meetings.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(m, heading))
                                    : meetingsFormatter(
                                        heading,
                                        getObjValue(m, heading)
                                      )}
                                </span>
                              )}
                            </div>
                          )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <NoResults message={props.noResultMessage} />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-title"} module="meeting" />
          )}
        </div>
      </div>
    </div>
  );
}
