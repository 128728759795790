import React, { useEffect, useRef, useState } from "react";
import {
  forEach,
  overrideDefaultEvent,
  removeSessionConfigData,
  removeSessionStaticConfig,
  utf8_to_b64,
  getUserAccessInfo
} from "../../utils";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import InputText from "./inputs/inputText";
import InputTextArea from "./inputs/inputTextArea";
import InputPhone from "./inputs/inputPhone";
import InputDate from "./inputs/inputDate";
import InputDropdownSelect from "./inputs/inputDropdownSelect";
import InputCheckBox from "./inputs/inputCheckbox";
import {
  getSavedInputConfigAPI,
  saveInputConfigAPI,
} from "../../apis/customization";
import InputFieldMapper from "./fields/inputFieldMapper";
import { APP_DATA } from "../../app_data";
import StaticFieldsModuleMapper from "./staticFieldModuleMapper";
import { RightMenuLoader } from "../common/contentLoader";
import { updateEnumsByModuleAPI } from "../../apis/enumAPI";

export default function CreateCustomizationsView(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const { handleRSidebar, openToast } = useOutletContext();

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const targetModule = useRef(null);

  const [savedConfig, setSavedConfig] = useState([]);

  const [loading, setLoading] = useState(true);
  const [placeholder, setPlaceholder] = useState(["ph_1"]);
  const [previewEnable, setPreviewEnable] = useState(false);
  const [updatedConf, setUpdatedConf] = useState({ sf: 0, cf: 0 });

  const [permission, setPermission] = useState({});

  useEffect(() => {
    setPermission(getUserAccessInfo("Customizations").permission || {});
  }, []);

  const handleAddPlaceholder = () => {
    const tP = placeholder;
    const sufix = tP.length + 1;
    const newP = "ph_" + sufix;
    tP.push(newP);

    setPlaceholder((prev) => [...tP]);
  };

  useEffect(() => {
    const tM = String(urlSearch.get("target"));
    targetModule.current = tM;
    removeSessionConfigData();
    removeSessionStaticConfig();
  }, []);

  useEffect(() => {
    if (urlSearch.get("referrer") === "configure-fields") {
      let id = urlSearch.get("inputId");
      const type = String(id).split("_")[2];
      id = String(id).split("-")[1];
      id = String(id).split("_")[0] + "_" + String(id).split("_")[1];
      if (id && type) {
        configureAndRender(id, type);
      }
    } else if (urlSearch.get("referrer") === "create-fields") {
      setLoading(true);
      removeSessionConfigData();
      removeSessionStaticConfig();
      navigate({
        pathname: "/app/create-fields",
        search: createSearchParams({
          referrer: "create-customization-init",
          editMode: false,
          module: targetModule.current,
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
    } else if (urlSearch.get("referrer") === "create-customization-init") {
      let tempConf = [];
      getSavedInputConfigAPI(targetModule.current)
        .then((conf) => {
          if (
            conf.status &&
            conf.data.hasOwnProperty("config_fields") &&
            conf.data.config_fields.length
          ) {
            const result = conf.data.config_fields.reduce((r, a) => {
              r[a.row] = r[a.row] || [];
              r[a.row].push(a);
              return r;
            }, Object.create(null));
            for (const k in result) {
              tempConf.push(result[k]);
            }
            setSavedConfig(tempConf);
            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(true);
            }, 1500);
          } else {
            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(true);
            }, 1500);
          }
        })
        .catch((e) => {
          openToast(APP_DATA.messages.error_message, "error");
          navigate(-1);
        });
    }
  }, [location.search]);

  const getEmptyPlaceHolder = () => {
    let hasChild = 1;
    placeholder.map((_, i) => {
      const pos = ["phL", "phR"];
      pos.map((p) => {
        hasChild &= document
          .getElementById(p + "_" + parseInt(i + 1))
          .hasChildNodes();
      });
    });

    return hasChild;
  };

  const configureField = (e) => {
    if (e.target.dataset.link === "remove") {
      if (permission?.delete) {
        handleInputRemove(e);
        return;
      }
    }

    if (e.target.dataset.link === "edit") {
      handleRSidebar("inputConfig", {
        synthEvent: e,
        remoteConfig: savedConfig,
        removeCurrentInput: handleInputRemove,
      });
    }
  };

  const configureStaticDropdown = (e) => {
    handleRSidebar("inputConfig", {
      synthEvent: e,
      module: targetModule.current,
    });
  };

  const handleInputRemove = (e) => {
    let id = String(e.target.id).split("-")[1];
    let localConfigId = id;

    const localConfigData = window.sessionStorage.getItem(
      "inputConfig:link-" + localConfigId
    );
    if (localConfigData) {
      window.sessionStorage.removeItem("inputConfig:link-" + localConfigId);
    }
    id = String(id).split("_")[0] + "_" + String(id).split("_")[1];
    const removeTarget = document.getElementById("addedInput-" + id);
    removeTarget.remove();

    const ph = document.getElementById(id);
    ph.classList.add("drag-pos");
    return true;
  };

  const handleDragging = (e, type) => {
    overrideDefaultEvent(e);
    dragOverItem.current = e.target.id;
    const el = document.getElementById(dragOverItem.current);
    try {
      if (type === "over") {
        if (!el.hasChildNodes()) {
          el.classList.add("during-drag");
          el.classList.remove("drag-pos");
        }
      } else {
        if (!el.hasChildNodes()) {
          el.classList.remove("during-drag");
          el.classList.add("drag-pos");
        }
      }
    } catch (e) {
      return;
    }
  };

  const dragEnter = (e) => {
    // console.log(e.target.id);
  };

  const handleDragStart = (e, inputType) => {
    dragItem.current = String(e.target.id).split("_")[1];
  };

  const handleInputDrop = (e) => {
    overrideDefaultEvent(e);
    if (document.getElementById(dragOverItem.current).hasChildNodes()) {
      openToast("Can not override current input", "error");
      return;
    }

    const source = document.getElementById("inputType_" + dragItem.current);

    const clone = source.cloneNode(true);
    clone.id = "addedInput-" + dragOverItem.current;
    clone.style.display = "block";

    const next = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(next, (_, el) => {
      el.id = "link-" + dragOverItem.current + "_" + dragItem.current;
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });

    const target = document.getElementById(dragOverItem.current);
    target.classList.remove("drag-pos");
    target.classList.remove("during-drag");
    target.appendChild(clone);

    setTimeout(() => {
      const editLink = document.getElementById(
        "link-" + dragOverItem.current + "_" + dragItem.current
      );
      if (editLink.dataset.link === "edit") {
        editLink.click();
      }
    }, 200);

    //add new placeholder when no empty child
    if (getEmptyPlaceHolder()) {
      handleAddPlaceholder();
    }
  };

  const configureAndRender = (id, inputType) => {
    let next;
    const inputData = JSON.parse(
      window.sessionStorage.getItem(`inputConfig:${urlSearch.get("inputId")}`)
    );
    const source = document.getElementById("addedInput-" + id);
    if (!source) {
      removeSessionConfigData();
      return;
    }
    const clone = source.cloneNode(true);
    if (inputType == 1 || inputType == 4 || inputType == 3 || inputType == 7) {
      next = clone.querySelector("input");
    } else if (inputType == 8) {
      next = clone.querySelector("span");
    } else if (inputType == 2) {
      next = clone.querySelector("textarea");
    } else if (inputType == 6) {
      if (!inputData) return;
      next = clone.querySelector("select"); 
      while (next.firstChild) {
        next.remove(next.lastChild);
      }
      inputData.options.map((o) => {
        const op = document.createElement("option");
        op.value = o.value;
        op.innerText = o.name;
        next.appendChild(op);
      });
      
        const label = clone.querySelector("label");
        label.placeholder = inputData.label;
        label.innerText = inputData.label;
      
    } else {
      return;
    }
    const links = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(links, (_, el) => {
      el.id = urlSearch.get("inputId");
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });

    if (inputData) {
      if (inputType != 6 || inputType != 8 ||inputType!= 7) {
        next.placeholder = inputData.label;
      }
      if (inputType == 8) {
        if (clone.querySelector("input")) {
        }
        next.innerText = inputData.label;
      }
      if(inputType == 7){
        const label = clone.querySelector("label");
        label.innerText = inputData.label
      }
      const target = document.getElementById(id);
      target.removeChild(document.getElementById("addedInput-" + id));
      target.appendChild(clone);
    }
  };

  const makeEditableAndRender = (base_link, config) => {
    let next;
    let id = base_link;
    const type = String(id).split("_")[2];
    id = String(id).split("_")[1];
    let inputId = String(base_link).split("-")[1];
    const inputType = inputId[inputId.length - 1];
    inputId = inputId.substring(0, inputId.length - 2);
    const source = document.getElementById("inputType_" + type);
    const clone = source.cloneNode(true);
    clone.id = "addedInput-" + inputId;
    clone.style.display = "block";

    if (inputType == 1 || inputType == 4 || inputType == 3 || inputType == 7) {
      next = clone.querySelector("input");
    } else if (inputType == 8) {
      next = clone.querySelector("span");
    } else if (inputType == 2) {
      next = clone.querySelector("textarea");
    } else if (inputType == 6) {
      if (!config) return;
      next = clone.querySelector("select");
      while (next.firstChild) {
        next.remove(next.lastChild);
      }
      config.options.map((o) => {
        const op = document.createElement("option");
        op.value = o.value;
        op.innerText = o.name;
        next.appendChild(op);
      });
      const label = clone.querySelector("label");
      label.innerText = config.label;
    } else {
      return;
    }
    const links = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(links, (_, el) => {
      el.id = base_link;
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });
    setTimeout(() => {
      if (inputType != 6 || inputType != 8 || inputType!= 7 ) {
        next.placeholder = config.label;
      }
      if (inputType == 8) {
        if (clone.querySelector("input")) {
        }
        next.innerText = config.label;
      }
      if (inputType == 7) {
        const label = clone.querySelector("label");
        label.innerText = config.label;
      }
      const target = document.getElementById(inputId);
      target.classList.remove("drag-pos");
      target.appendChild(clone);
    }, 50);
  };

  const handleEditInputConfig = () => {
    setPreviewEnable(false);
    if (savedConfig.length) {
      savedConfig.map((conf) => {
        //first add the placeholder
        handleAddPlaceholder();
        conf.map((confType1) => {
          //create Element and rendet the input settings and
          //save the config in the session storage
          makeEditableAndRender(confType1.base_link, confType1);
          window.sessionStorage.setItem(
            `inputConfig:${confType1.base_link}`,
            JSON.stringify(confType1)
          );
        });
      });
    }
    setTimeout(() => {
      const divObj = document.getElementById("formId");
      divObj.scroll({ top: divObj.scrollHeight + 20, behavior: "smooth" });
    }, 100);
  };

  const changeDetector = (currentConfig = []) => {
    return !(
      JSON.stringify(savedConfig.flat()) === JSON.stringify(currentConfig)
    );
  };

  const handleCreateCustomField = async (e) => {
    if (!targetModule.current || loading) {
      return;
    }
    if (previewEnable) {
      openToast("Settings has been saved", "success");
      return;
    }
    let configStatus = { cf: 0, sf: 0 };
    document.getElementById(e.target.id).classList.add("btnLoader");

    //accumulating the input cofigurations for static fields
    const sf_configData = [];
    const sf_configKey = /staticInputConfig:/;
    for (let i = 0; i < window.sessionStorage.length; i++) {
      if (sf_configKey.test(String(window.sessionStorage.key(i)))) {
        const cData = JSON.parse(
          window.sessionStorage.getItem(window.sessionStorage.key(i))
        );
        sf_configData.push(cData);
      }
    }
    if (sf_configData.length) {
      let c = 0;
      sf_configData.map(async (conf, i) => {
        const enumUpdated = await updateEnumsByModuleAPI(
          conf["enums"],
          conf["module"],
          conf["field"]
        );
        if (enumUpdated.status) {
          c += 1;
        } else {
          c -= 1;
        }
      });
      configStatus = { ...configStatus, sf: c };
    }
    //accumulating the input cofigurations for customization
    const cf_configData = [];
    const cf_configKey = /inputConfig:/;
    for (let i = 0; i < window.sessionStorage.length; i++) {
      if (cf_configKey.test(String(window.sessionStorage.key(i)))) {
        const cData = JSON.parse(
          window.sessionStorage.getItem(window.sessionStorage.key(i))
        );
        cf_configData.push(cData);
      }
    }

    if (!changeDetector(cf_configData) && !updatedConf) {
      openToast("Settings has been saved", "success");
      document.getElementById(e.target.id).classList.remove("btnLoader");
      return;
    }

    //fist save all dropdown options then save the input conf
    const configRes = await saveInputConfigAPI(
      targetModule.current,
      cf_configData
    );
    if (configRes.status) {
      //remove residue if any
      if (!cf_configData.length) {
        setSavedConfig([]);
      }
      configStatus = { ...configStatus, cf: 1 };
    }

    if (configStatus.cf || configStatus.sf) {
      navigate({
        pathname: "/app/create-fields",
        search: createSearchParams({
          referrer: "create-fields",
          module: targetModule.current,
          tNode: utf8_to_b64(String(Date.now())),
        }).toString(),
      });
      setUpdatedConf(configStatus);
      openToast(APP_DATA.messages.notification, "success");
      document.getElementById(e.target.id).classList.remove("btnLoader");
    } else {
      openToast(APP_DATA.messages.error_message, "error");
      document.getElementById(e.target.id).classList.remove("btnLoader");
    }
  };

  return (
    <React.Fragment>
      {(permission.create) ? 
      <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i
            className="crm-back-btn"
            onClick={() => navigate("/app/customizations")}
          ></i>
          <h1 className="header_title">
            Customizations ({targetModule.current})
          </h1>
        </div>
        {!previewEnable ? (
          <div className="header_rhs">
            <button
              type="button"
              className="btn btn-secondary edit-optn disabled"
            >
              <i className="crm-edit"></i>Edit
            </button>
          </div>
        ) : (
          <div className="header_rhs">
            <button
              type="button"
              className={permission.edit ? "btn btn-secondary edit-optn":"btn btn-secondary edit-optn disabled"}
              onClick={permission.edit ? () => handleEditInputConfig():null}
            >
              <i className="crm-edit"></i>Edit
            </button>
          </div>
        )}
      </div>
      <div className="crm-fld-wrpr">
        <div className="add-fields">
          <h3>New fields</h3>
          <div className="field-type">
            <div
              className="second-col col"
              draggable="true"
              id="multi-line_2"
              onDragStart={(e) => handleDragStart(e, 2)}
            >
              <i className="multi-line"></i>
              <span>Multi Line</span>
              <InputTextArea />
            </div>
            <div
              className="first-col col"
              draggable="true"
              id="single-line_1"
              onDragStart={(e) => handleDragStart(e, 1)}
            >
              <i className="single-line"></i>
              <span>Single Line</span>
              <InputText />
            </div>
            <div
              className="col"
              draggable="true"
              id="phone-input_4"
              onDragStart={(e) => handleDragStart(e, 4)}
            >
              <i className="phone"></i>
              <span>Phone</span>
              <InputPhone />
            </div>
            <div
              className="col"
              draggable="true"
              id="pick-list_6"
              onDragStart={(e) => handleDragStart(e, 6)}
            >
              <i className="pick-list"></i>
              <span>Pick List</span>
              <InputDropdownSelect />
            </div>
            <div
              className="col"
              draggable="true"
              id="date-input_7"
              onDragStart={(e) => handleDragStart(e, 7)}
            >
              <i className="date"></i>
              <span>Date</span>
              <InputDate />
            </div>
            <div
              className="col"
              draggable="true"
              id="date-input_8"
              onDragStart={(e) => handleDragStart(e, 8)}
            >
              <i className="checkbox"></i>
              <span>Checkbox</span>
              <InputCheckBox />
            </div>
          </div>
        </div>
        <div className="fields-info">
          <form className="">
            <div className="crm-form-content" id="formId">
              {loading ? (
                <RightMenuLoader />
              ) : !previewEnable && placeholder.length ? (
                <React.Fragment>
                  <StaticFieldsModuleMapper
                    preview={previewEnable}
                    moduleName={targetModule.current}
                    functions={{
                      ConfigureStaticDropdown: configureStaticDropdown,
                    }}
                  />
                  {placeholder.map((_, index) => (
                    <React.Fragment>
                      <div className="crm-form-row">
                        {index === 0 ? <h3>Custom fields</h3> : null}
                        <div className="crm-form-grp">
                          <div
                            key={"droparea" + index}
                            id={"phL_" + parseInt(index + 1)}
                            onDragEnter={(e) => dragEnter(e)}
                            onDrop={(e) => handleInputDrop(e)}
                            onDragOver={(e) => handleDragging(e, "over")}
                            onDragLeave={(e) => handleDragging(e, "leave")}
                            className="crm-form-grp crm-frm-50 drag-pos"
                          ></div>
                          <div
                            key={"droparea" + index + 1}
                            id={"phR_" + parseInt(index + 1)}
                            onDragEnter={(e) => dragEnter(e)}
                            onDrop={(e) => handleInputDrop(e)}
                            onDragOver={(e) => handleDragging(e, "over")}
                            onDragLeave={(e) => handleDragging(e, "leave")}
                            className="crm-form-grp crm-frm-50 drag-pos"
                          ></div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StaticFieldsModuleMapper
                    preview={previewEnable}
                    moduleName={targetModule.current}
                    functions={{
                      ConfigureStaticDropdown: configureStaticDropdown,
                    }}
                  />
                  {savedConfig.length
                    ? savedConfig.map((conf, i) =>
                        conf.length > 1 ? (
                          <div className="crm-form-row" key={"cf_ff" + i}>
                            {i === 0 ? <h3>Custom fields</h3> : null}
                            <div className="crm-form-grp">
                              {conf.map((confType1, j) => (
                                <div
                                  key={"cf_cc" + j}
                                  className="crm-form-grp crm-frm-50"
                                >
                                  <InputFieldMapper
                                    type={confType1.type}
                                    inputProperties={confType1}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="crm-form-row">
                            <div className="crm-form-grp">
                              {i === 0 ? <h3>Custom fields</h3> : null}
                              {conf.map((confType2, j) => (
                                <div
                                  key={"cf_cc" + j}
                                  className="crm-form-grp crm-frm-50"
                                >
                                  <InputFieldMapper
                                    type={confType2.type}
                                    inputProperties={confType2}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )
                    : null}
                </React.Fragment>
              )}
            </div>

            <div className="crm-form-foot">
              <div className="crm-btn">
                <div className="crm-btn-left">
                  <button
                    id="btnSave"
                    type="button"
                    className="btn btn-primary lead-save"
                    data-action="save"
                    onClick={(e) => handleCreateCustomField(e)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary lead-savesec"
                    onClick={() => navigate("/app/customizations")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      </>
      :
      <div className="no-results-tblstng">
      <h2>You don't have the required access to create</h2>
      </div>
    }
    </React.Fragment>
  );
}
