import React, { useState, useEffect, useRef } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { getContractsLimitNoffset } from "../apis/contractAPIs";
import CanvasTableView from "../components/contracts/canvasTableView";
import ListTableView from "../components/contracts/listTableView";
import NewPagination from "../components/common/newPagination";
import { searchThroughAPIs } from "../apis";
import {
  debounceOptFunc,
  getUserAccessInfo,
  overrideDefaultEvent,
  getSortBy,
} from "../utils";
import { APP_DATA } from "../app_data";

export default function ContractsView() {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const { handleRSidebar, openModal } = useOutletContext();

  const pagination = useRef(null);

  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [permission, setPermission] = useState({
    create: false,
    view: false,
    edit: false,
    delete: false,
  });
  const [canvasView, setCanvasView] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setPageLimit] = useState(10);
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [filter,setFilter] = useState(false);

  useEffect(() => {
    getALLContracts();
    setPermission(getUserAccessInfo("Contracts")["permission"]||{});
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      clearSort();
    }
  }, [location.search]);

  const handlePaginatedFetchData = async (pageData) => {
    setLoading(true);
    setPageLimit(pageData.limit);

    //default get data
    const c = await getContractsLimitNoffset(
      pageData.offset,
      pageData.limit,
      false,
      sortInfo.sortBy,
      sortInfo.sortField
    );

    if (c.status && c.data.length) {
      const data = c.data;
      const groupArrayObject = data.reduce((group, arr) => {
        const { contracts_name } = arr;
        group[contracts_name] = group[contracts_name] ?? [];
        group[contracts_name].push(arr);
        return group;
      }, {});
      setContracts(groupArrayObject);
    } else {
      setContracts([]);
    }

    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const getALLContracts = async (sortBy, sortField) => {
    setLoading(true);
    pagination?.current?.resetDefaultPage();
    const c = await getContractsLimitNoffset(0, limit, true, sortBy, sortField);
    if (c.status && c.data.contracts.length) {
      const data = c.data.contracts;
      if (c.data?.hasOwnProperty("total_count")) {
        setTotalRecords(c.data.total_count);
      }
      const groupArrayObject = data.reduce((group, arr) => {
        const { contracts_name } = arr;
        group[contracts_name] = group[contracts_name] ?? [];
        group[contracts_name].push(arr);
        return group;
      }, {});
      setContracts(groupArrayObject);
    } else {
      setContracts([]);
    }
    setLoading(false);
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSearch = async (qstring) => {
    if (qstring.length) {
      clearSort();
      setFilter(true);
      pagination?.current?.resetDefaultPage();
      setLoading(true);
      const searched = await searchThroughAPIs("contracts", qstring);
      if (searched.status) {
        const data = searched.data.contracts;
        const groupArrayObject = data.reduce((group, arr) => {
          const { contracts_name } = arr;
          group[contracts_name] = group[contracts_name] ?? [];
          group[contracts_name].push(arr);
          return group;
        }, {});
        setContracts(groupArrayObject);
        setTotalRecords(searched.data.total_count);
      } else {
        setContracts([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      setFilter(false);
      getALLContracts();
    }
  };

  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleSort = (e, field, id) => {
    if (e.target.id === "cn") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);
    if (sortBy && field) {
      setSortInfo({ sortBy, sortField: field, sortKey: id });
      getALLContracts(sortBy, field);
    }
  };

  const functions = {
    handleDeleteContractFile: (data = []) => {
      openModal("deleteModal", { data: [...data], redirect: "contracts" });
    },
    handleSort,
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Contracts</h1>
        </div>
        <div className="header_rhs">
          {/* <div
            className="filter-icon"
            onClick={() => setCanvasView(canvasView)}
          >
            {canvasView ? (
              <i className="crm-action-listfilter"></i>
            ) : (
              <i className="crm-action-filterviews"></i>
            )}
          </div> */}
          <div className="search_bar">
            <input
              type="text"
              placeholder="Search contracts"
              onChange={(e) => handleDebouncedSearch(e.target.value)}
            />
            <span className="btn-ico-srch"></span>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        {permission.create ? (
          <div className="menu-lft">
            <div className="hdr-btn-drpdwn mr15">
              <div
                className="lhs-prt"
                onClick={() => handleRSidebar("uploadContracts")}
              >
                <i className="upload-icn"></i>
                <span>Upload Contract</span>
              </div>
            </div>
          </div>
        ) : null}
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total contracts: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
          {Object.keys(contracts)?.length > 0 && (
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
          )}
        </div>
      </div>
      {/* table section */}
      {canvasView ? (
        <CanvasTableView
          {...functions}
          data={contracts}
          loading={loading}
          permission={permission}
          sortInfo={sortInfo}
          filter={filter}
        />
      ) : (
        <ListTableView
          {...functions}
          data={contracts}
          loading={loading}
          permission={permission}
          sortInfo={sortInfo}
          filter={filter}
        />
      )}
    </React.Fragment>
  );
}
