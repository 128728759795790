import ReactDOM from "react-dom/client";
import MonthMapperObj from "../utils/MonthMapper";
import timezoneDB from "../app_data/timezoneData";
import moment from "moment";
import jwt_docode from "jwt-decode";
import { APP_DATA } from "../app_data";
import imageCompression from "browser-image-compression";
import getSymbolFromCurrency from "currency-symbol-map";
import { envInjector } from "../env";
import { deleteCachedRefresh } from "../apis/authAPIs";

const EMPTY_DATA_CHAR = "--";

export const requiredFieldError = (fieldId, module, value) => {
  let error_message = " cannot be blank";
  if (/^\s+$/.test(value)) {
    error_message = " cannot contain spaces";
  }
  if (
    APP_DATA.APIFieldMappings[module][fieldId] &&
    APP_DATA.APIColumnMappings[module + "s"][
      APP_DATA.APIFieldMappings[module][fieldId]
    ]
  ) {
    return (
      APP_DATA.APIColumnMappings[module + "s"][
        APP_DATA.APIFieldMappings[module][fieldId]
      ] + error_message
    );
  } else {
    return "This field" + error_message;
  }
};

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const compressImage = async (file, originalB64) => {
  if (file.size > APP_DATA.imageCompressionLevel) {
    if (file) {
      const options = {
        maxSizeMB: 0.2,
        useWebWorker: true,
        maxWidthOrHeight: 420,
      };
      const compressedFile = await imageCompression(file, options);
      return blobToBase64(compressedFile);
    }
  }
  return originalB64;
};

export const logoForExtension = (name) => {
  switch (name) {
    case "Zoom Meeting":
      return "https://www.idrive.com/crm/webconsole/assets/images/zoom_logo.svg";
    case "Microsoft Teams Meeting":
      return "https://www.idrive.com/crm/webconsole/assets/images/microsoft_team_logo.svg";
    case "Slack":
      return "https://www.idrive.com/crm/webconsole/assets/images/slack_logo.svg";
    case "Google Meet":
      return "https://www.idrive.com/crm/webconsole/assets/images/google_meet_logo.svg";
  }
};

export const unmountC = (domContainerNode) => {
  ReactDOM.unmountComponentAtNode(domContainerNode);
};

export const getKeyByValue = (obj, searchValue) => {
  for (let [key, value] of Object.entries(obj)) {
    if (value === searchValue) return key;
  }
};

export const forEach = (array, callback, scope) => {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]);
  }
};

export const removeSessionConfigData = () => {
  const configKey = /inputConfig:/;
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (configKey.test(String(window.sessionStorage.key(i)))) {
      window.sessionStorage.removeItem(window.sessionStorage.key(i));
    }
  }
};

export const removeSessionStaticConfig = () => {
  const configKey = /staticInputConfig:/;
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (configKey.test(String(window.sessionStorage.key(i)))) {
      window.sessionStorage.removeItem(window.sessionStorage.key(i));
    }
  }
};

export const removeSessionWebformData = () => {
  const configKey = /webformsInput:/;
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (configKey.test(String(window.sessionStorage.key(i)))) {
      window.sessionStorage.removeItem(window.sessionStorage.key(i));
    }
  }
};

export const getSessionConfigData = () => {
  const sd = [];
  const configKey = /inputConfig:/;
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (configKey.test(String(window.sessionStorage.key(i)))) {
      sd.push(
        JSON.parse(window.sessionStorage.getItem(window.sessionStorage.key(i)))
      );
    }
  }
  return sd;
};

export const getSessionWebformData = () => {
  const sd = [];
  const configKey = /webformsInput:/;
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (configKey.test(String(window.sessionStorage.key(i)))) {
      sd.push(
        JSON.parse(window.sessionStorage.getItem(window.sessionStorage.key(i)))
      );
    }
  }
  return sd;
};

export const removeSessionWFData = (re) => {
  const regEx = new RegExp(re + ":");
  for (let i = window.sessionStorage.length - 1; i >= 0; i--) {
    if (regEx.test(String(window.sessionStorage.key(i)))) {
      window.sessionStorage.removeItem(window.sessionStorage.key(i));
    }
  }
};

export const utf8_to_b64 = (str) => {
  return window.btoa(encodeURIComponent(str));
};

export const b64_to_utf8 = (str) => {
  return decodeURIComponent(window.atob(str));
};

const getLocale = (locale) => {
  return String(locale).toLocaleLowerCase() + "-" + String(locale).toUpperCase;
};

export const localeDateFormatter = (dateString, returnType = "") => {
  let formattedDate = "";
  let locale = "fr-CA";
  try {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString);
    if (returnType === "date") {
      formattedDate = d.toLocaleDateString(locale);
    } else if (returnType === "time") {
      formattedDate = d.toLocaleTimeString();
    } else if (returnType === "date-time") {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      formattedDate = d.toLocaleString("en-US", options);
    } else {
      formattedDate = d.toLocaleString();
    }
    return formattedDate;
  } catch (e) {
    console.log(e);
  }
};

export const timeFormatter = (dateString) => {
  try {
    let time = dateString.split(" ");
    time.splice(0, 1);
    time.join(" ");
    return convertUTCTime(time, true).format()?.split("T")[1];
  } catch (err) {
    return dateString;
  }
};

export const echoPrimary = (text) => {
  if (text === null || !text) {
    return "";
  }
  return text;
};

export const echo = (text, type = "text") => {
  if (text && text != undefined) {
    return text;
  } else {
    if (type === "number") {
      return 0;
    }
    return EMPTY_DATA_CHAR;
  }
};

export const findKey = (obj, value) => {
  return Object.keys(obj).find((k) => obj[k] === value);
};

export const isValidURL = (string) => {
  const re = new RegExp("^(http|https)://", "i");
  const match = re.test(string);
  if (match) {
    return false;
  } else {
    return true;
  }
};

export const monthMapper = (date) => {
  if (date.includes("T")) {
    date = date.split("T")[0];
  }
  //console.log("date",date)
  let month = date.split("-")[1];
  let year = date.split("-")[0];
  month = MonthMapperObj[parseInt(month)];
  year = year.split("");
  return month + " " + year[2] + year[3];
};

//this is being used for History tab
export const UTCConverterCreatedAt = (date) => {
  let datesec = date.split(" ")[0];
  let timesec = date.split(" ")[1];
  let month = datesec.split("-")[1];
  let year = datesec.split("-")[0];
  month = MonthMapperObj[parseInt(month)];
  let day = datesec.split("-")[2];
  let hrs = timesec.split(":")[0];
  let minutes = timesec.split(":")[1];
  let amPm = timesec.split(":")[0] > 11 ? "PM" : "AM";
  let time = hrs + ":" + minutes + " " + amPm;

  return [month + " " + day + "," + year, time];
};

export const overrideDefaultEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const UTCConverter = (date) => {
  let month = date.split("-")[1];
  let year = date.split("-")[0];
  let day = date.split("-")[2];
  return day + "-" + month + "-" + year;
};

export const activeLeftLinkStyle = {
  backgroundColor: "#fff",
  color: "#0071bc",
  borderRadius: "10px",
  width: "86%",
  marginLeft: "7%",
};

export const search = async (obj, obKey, qstring) => {
  return new Promise((resolve, reject) => {
    try {
      const result = obj.filter((dataObject) =>
        String(dataObject[obKey])
          .toLowerCase()
          .includes(String(qstring).toLowerCase())
      );
      resolve({ status: true, data: result });
    } catch (error) {
      reject({ status: false, data: [] });
    }
  });
};

export const debounceOTFunc = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const debounceOptFunc = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const getFileExt = (fileName) => {
  const extPattern = /\.[^.\\/:*?"<>|\r\n]+$/;
  const ext = fileName.match(extPattern);
  if (ext) {
    return ext[0];
  } else {
    return "";
  }
};

export const getFormattedFileName = (fileName) => {
  if (fileName.length > 20) {
    return (
      String(fileName).substring(0, 20) + "..." + String(fileName).slice(-10)
    );
  }
  return fileName;
};

export const fileSizeFromBytes = (numberOfBytes = 0) => {
  if (!numberOfBytes) {
    return "Invalid file";
  }
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const exponent = Math.min(
    Math.floor(Math.log(numberOfBytes) / Math.log(1024)),
    units.length - 1
  );
  const approx = numberOfBytes / 1024 ** exponent;
  return exponent === 0
    ? `${numberOfBytes} bytes`
    : `${approx.toFixed()} ${units[exponent]}`;
};

export const checkPasswordStrength = (password) => {
  const pattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/;
  if (pattern.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const inputFieldValidator = async (e, caller = "inapp") => {
  const number = /^\d{1,}$/;
  const amount = /^\d{1,10}(\.\d{2})?$/;
  const pinCodePattern = /^\d{1,10}$/;
  const phoneNumberPattern =
    /^([+-.\s]?\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,20}$/;
  const mobileNumberPattern =
    /^([+-.\s]?\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,20}$/;
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const namePatterns = /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~ ]{1,40}$/;

  const aplhaNumbericSpecial255 =
    /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~\n ]{1,255}$/;
  const aplhaNumbericSpecial100 =
    /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~ ]{1,100}$/;

  const nameNumber20 = /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~ ]{1,40}$/;
  return new Promise((resolve, reject) => {
    try {
      let v = {
        valid: true,
        error_message: "",
      };
      let switcher = e.target.id;
      if (caller === "outside") {
        switcher = e.target.dataset.real_id;
      }
      //this is done to avoid triggering validation messages
      // when input field is empty
      if (!e.target.value) {
        resolve(v);
        return;
      }
      switch (switcher) {
        //sign up form
        case "company_name":
        //leads fields
        case "leadRole":
        case "contact":
        case "leadTitle":
        //names
        //account fields
        case "accountName":
        // case "accountType":
        case "accountRole":
        // case "accountOwnership":
        // case "accountBusinessCategory":
        // case "accountBusinessStatus":
        //task fields
        /* falls through */
        // case "taskStatus":
        case "taskProirity":
        case "taskType":
        case "taskContact":
        case "taskAccounts":
        //meetings fields
        case "meetingName":
        case "meetingHost":
        case "meetingFrequency":
        case "meetingRepeatEvery":
        //contact fields
        /* falls through */
        case "contactRole":
        case "name":
        //group fields
        case "groupName":
        //profile fields
        case "profileName":
          if (!nameNumber20.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;
        //workflow
        case "event":
          break;
        case "contactOwner":
        case "leadOwner":
        case "taskOwner":
        case "accountOwner":
        case "dealOwner":
        case "assigneeOwner":
          //signup form
          if (!number.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Owner is not valid";
          }
          break;
        case "taskSubject":
          if (!aplhaNumbericSpecial100.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Source is not valid";
          }
          break;
        // case "contactLeadSource":
        //   if (!nameNumber20.test(e.target.value)) {
        //     v["valid"] = false;
        //     v["error_message"] = "Source is not valid";
        //   }
        //   break;
        case "firstName":
        case "userFirstName":
          if (!nameNumber20.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "First name is not valid";
          }
          break;
        case "lastName":
        case "userLastName":
          if (!nameNumber20.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Last name is not valid";
          }
          break;
        case "email":
        case "username":
          if (!emailPattern.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Email id is not valid";
          }
          break;

        //emails
        case "userEmail":
        case "companyEmail":
        case "accountEmail":
        case "contactEmail":
        case "companySenderEmail":
          if (!emailPattern.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Email id is not valid";
          }
          break;
        //phone
        case "leadPhone":
        case "workNumber":
        case "officeNumber":
        case "accountPhone":
        case "contactPhone":
        case "phone_number":
        case "userWorkPhone":
          if (!phoneNumberPattern.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Phone number is not valid";
          }
          break;
        //mobile
        //leads
        case "leadMobile":
        //accounts
        case "accountMobile":
        //contacts
        /* falls through */
        case "contactMobile":
        case "companyMobile":
        case "userMobile":
          if (!mobileNumberPattern.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Mobile number is not valid";
          }
          break;
        //websites
        //account
        case "accountWebsite":
        case "companyWebsite":
        case "contactWebsite":
          if (!aplhaNumbericSpecial255.test(e.target.value.length)) {
            v["valid"] = false;
            v["error_message"] = "Website link is not valid";
          }
          break;
        //linkedIn
        //account
        case "accountLinkedin":
        //contact
        /* falls through */
        case "contactLinkedin":
          if (!aplhaNumbericSpecial255.test(e.target.value.length)) {
            v["valid"] = false;
            v["error_message"] = "LinkedIn id is not valid";
          }
          break;
        //country
        case "accountCountry":
        case "userCountry":
        case "shippingCountry":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Country name is not valid";
          }
          break;
        //state
        case "accountState":
        case "userState":
        case "shippingState":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "State name is not valid";
          }
          break;
        //city
        //account
        case "accountCity":
        case "userCity":
        case "shippingCity":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "City name is not valid";
          }
          break;
        //pincode
        //account
        case "accountPincode":
        case "userZipCode":
          if (!pinCodePattern.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Zip code is not valid";
          }
        //contact
        /* falls through */
        case "shippingPincode":
          if (!number.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Zip code is not valid";
          }
          break;
        //Street
        //account
        case "accountStreet":
        //contact
        /* falls through */
        case "shippingStreet":
          if (!e.target.value.length > 0) {
            v["valid"] = false;
            v["error_message"] = "Street name is not valid";
          }
          break;
        case "billingCountry":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Country name is not valid";
          }
          break;
        case "billingState":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "State name is not valid";
          }
          break;
        case "billingCity":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "City name is not valid";
          }
          break;
        case "numbersSent":
          if (!number.test(e.target.value)) {
            console.log("here");
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;
        case "billingPincode":
          if (!number.test(e.target.value)) {
            console.log("here");
            v["valid"] = false;
            v["error_message"] = "Zip code is not valid";
          }
          break;
        case "billingStreet":
          if (!e.target.value.length > 0) {
            v["valid"] = false;
            v["error_message"] = "Street name is not valid";
          }
          break;
        case "companyLocation":
          if (!e.target.value.length > 0) {
            v["valid"] = false;
            v["error_message"] = "Location name is not valid";
          }
          break;

        case "companyLink":
          if (!e.target.value.length > 0) {
            v["valid"] = false;
            v["error_message"] = "Link is not valid";
          }
          break;
        //description
        //all decriptions are catched here
        case "groupDescription":
        case "meetingDescription":
        case "description":
          if (!aplhaNumbericSpecial255.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Description is not valid";
          }
          break;

        //Deals Fields
        case "dealName":
          if (!nameNumber20.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;

        case "dealAmount":
        case "expectedRevenue":
        case "budgetedCost":
        case "actualCost":
          if (!amount.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;

        // case "dealStage":
        //   if (!e.target.value) {
        //     v["valid"] = false;
        //     v["error_message"] = "Input is not valid";
        //   }
        //   break;

        case "dealNextStep":
          if (!e.target.value) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;

        // case "dealExpectedRevenue":
        //   if (!number.test(e.target.value)) {
        //     v["valid"] = false;
        //     v["error_message"] = "Input is not valid";
        //   }
        //   break;

        // case "dealType":
        //   if (!namePatterns.test(e.target.value)) {
        //     v["valid"] = false;
        //     v["error_message"] = "Input is not valid";
        //   }
        //   break;

        case "dealProbability":
          if (!number.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;

        case "dealStatus":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;

        // case "dealCampaignSource":
        //   if (!namePatterns.test(e.target.value)) {
        //     v["valid"] = false;
        //     v["error_message"] = "Input is not valid";
        //   }
        //   break;

        //customization input validations
        case "label":
          if (!namePatterns.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }
          break;
        case "password":
          if (!e.target.value) {
            v["valid"] = false;
            v["error_message"] = "Password can not be blank";
          }
          break;

        case "noteText":
          if (!aplhaNumbericSpecial255.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Note can contain a maximum of 255 characters";
          }
          // if (!e.target.value) {
          //   v["valid"] = false;
          //   v["error_message"] = "";
          // }
          if (
            e.target.value.length > 0 &&
            inputSpaceValidator(e.target.value)
          ) {
            v["valid"] = false;
            v["error_message"] = "Note cannot be blank";
          }
          break;
        case "formName":
          if (e.target.value.length > 50) {
            v["valid"] = false;
            v["error_message"] = "Character limit exceeded";
          }
          if (!nameNumber20.test(e.target.value)) {
            v["valid"] = false;
            v["error_message"] = "Input is not valid";
          }

        default:
          break;
      }
      resolve(v);
    } catch (e) {
      reject(false);
    }
  });
};

export const paginate = async (
  totalItems,
  currentPage = 1,
  pageSize = 10,
  maxPages = 100
) => {
  return new Promise((resolve, reject) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    resolve({
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    });
  });
};

export const filterFieldValidator = (e) => {
  const mobileNumberPattern = /^\d{10}$/;
  const emailPattern = /^[\w\-.]+@[\w\-]+\.[\w]{2,4}$/;
  const namePatterns = /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~ ]{1,40}$/;
  const phoneNumberPattern = /^\d{10}$/;
  const number = /^\d{1,}$/;
  const aplhaNumberic = /^[a-zA-Z0-9\. ]+$/;
  const durationPattern = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

  let v = {
    valid: true,
    error_message: "",
  };
  switch (e.target.id) {
    //leadsFilter
    case "leadNameValue":
    case "leadOwnerValue":
    case "sourceValue":
    case "firstNameValue":
    case "lastNameValue":
    case "leadStatusValue":
    case "titleValue":
    //accountFilter
    case "accountNameValue":
    case "accountOwnerValue":
    case "accountTypeValue":
    case "ownershipTypeValue":
    case "businessCategoryValue":
    case "businessStatusValue":
    case "roleValue":
    case "countryValue":
    case "stateValue":
    //contactsFilter
    case "contactOwnerValue":
    case "leadSourceValue":
    //dealFilter
    case "dealNameValue":
    case "stageValue":
    case "accountNameValue":
    case "contactNameValue":
    case "dealOwnerValue":
    case "typeValue":
    case "dealStatusValue":
    //taskFilter
    case "taskSubjectValue":
    case "taskOwnerValue":
    case "taskStatusValue":
    case "taskPriorityValue":
    case "taskReminderValue":
    //callFilter
    case "callSubjectValue":
    case "callTypeValue":
    case "relatedToValue":
    case "contactNameValue":
    //usersFilter
    case "firstNameValue":
    case "lastNameValue":
    case "userValue":
    case "reportingManagerValue":
    case "lastLoginValue":
    case "userStatusValue":
    //groupFilter
    case "groupNameValue":
    //recycleBinFilter
    case "nameValue":
    case "deletedByValue":
      if (!namePatterns.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
    case "amountValue":
    case "expectedRevenueValue":
      if (!number.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
    // case "callDurationValue":
    //   if (!durationPattern.test(e.target.value)) {
    //     v["valid"] = false;
    //     v["error_message"] = "Duration is not valid";
    //   }
    //   break;
    case "companyValue":
      if (!aplhaNumberic.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
    default:
      if (!namePatterns.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
  }
  return v;
};

export const customFilterFieldValidator = (e, ignore = true) => {
  const phoneNumberPattern =
    /^([+-.\s]?\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,20}$/;
  const mobileNumberPattern =
    /^([+-.\s]?\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,20}$/;
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const namePatterns = /^[a-zA-Z0-9!@#$%^&*()_+-=,.<>?/;:'"[\]{}|`~ ]{1,40}$/;
  const number = /^\d{1,}$/;
  const aplhaNumberic = /^[a-zA-Z0-9\. ]+$/;
  const durationPattern = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

  let v = {
    valid: true,
    error_message: "",
  };
  switch (e.target.id) {
    //leads
    case "lead_pin_value":
    //accounts
    case "account_pincode_value":
    //contacts
    case "contact_billing_pincode_value":
    case "contact_shipping_pincode_value":
    //deals
    case "deal_amount_value":
    case "deal_expected_revenue_value":
    //users
    case "user_zip_code_value":
      if (!number.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
    case "call_duration_value":
      if (!durationPattern.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Duration is not valid";
      }
      break;
    case "companyValue":
      if (!aplhaNumberic.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "company is not valid";
      }
      break;
    case "lead_mobile_value":
    case "account_mobile_value":
    case "account_phone_value":
    case "contact_phone_value":
    case "contact_mobile_value":
    case "user_mobile_value":
      if (!mobileNumberPattern.test(e.target.value)) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
    //email
    case "lead_email_value":
    case "account_email_value":
    case "contact_email_value":
    case "user_email_value":
      if (!emailPattern.test(e.target.value) && !ignore) {
        v["valid"] = false;
        v["error_message"] = "Email is not valid";
      }
      break;

    //date
    case "deal_closed_at_value":
    case "task_closing_date_value":
    case "created_at_value":
    case "call_start_date_value":
    case "meeting_from_value":
    case "meeting_to_value":
      break;
    default:
      if (
        e.target.datatype === "string" &&
        !namePatterns.test(e.target.value)
      ) {
        v["valid"] = false;
        v["error_message"] = "Input is not valid";
      }
      break;
  }
  return v;
};
export const convertToSeconds = (time) => {
  const t = time.split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +t[0] * 60 * 60 + +t[1] * 60 + +t[2];
  return seconds;
};

export const generateFilterPayload = (filter) =>
  filter.map((f) => ({
    field: f?.field,
    condition: f?.condition,
    value: f?.value,
  }));

const getMultipleInputs = (id) => {
  switch (id) {
    case "deletedTime": {
      const fromDate = document.getElementById("fromDate");
      const fromTime = document.getElementById("fromTime");
      if (!fromDate.value) {
        document.getElementById("err_fromDate").innerText =
          "Please enter valid input";
      }
      if (!fromTime.value) {
        document.getElementById("err_fromTime").innerText =
          "Please enter valid input";
      }
      if (fromDate.value) {
        document.getElementById("err_fromDate").innerText = "";
      }
      if (fromTime.value) {
        document.getElementById("err_fromTime").innerText = "";
      }
      if (fromDate.value && fromTime.value) {
        return { value: fromDate.value + "T" + fromTime.value };
      }
      return { value: "" };
    }
    default:
      return { value: "" };
  }
};

export const getFilterData = (arr) => {
  const multipleInputs = ["deletedTime"];

  return new Promise((res, rej) => {
    let valid = 0;
    const data = [];

    arr.forEach((c) => {
      let obj = {
        id: c,
        field: document.getElementById(c).name,
        condition: document.getElementById(c + "Condition").value,
        value: !multipleInputs.includes(c)
          ? document.getElementById(c + "Value").value
          : getMultipleInputs(c).value,
        fieldLabel: document.getElementById(c + "Label")?.textContent,
      };
      data.push(obj);
    });

    data.forEach((item) => {
      const e = {
        target: {
          id: item.id + "Value",
          value: !multipleInputs.includes(item.id)
            ? document.getElementById(item.id + "Value").value
            : getMultipleInputs(item.id).value,
        },
      };
      const validationResponse = filterFieldValidator(e);
      if (item.condition && item.value && validationResponse.valid) {
        valid += 1;
      }
      if (
        (!item.value && item.condition === "is_empty") ||
        (!item.value && item.condition === "is_not_empty")
      ) {
        valid += 1;
      }
      if (!item.condition) {
        if (document.getElementById("err_" + item.id + "Condition")) {
          document.getElementById("err_" + item.id + "Condition").innerText =
            "Please select valid input";
          document.getElementById(
            "err_" + item.id + "Condition"
          ).style.display = "block";
        }
      }
      if (item.condition) {
        document.getElementById("err_" + item.id + "Condition").innerText = "";
        document.getElementById("err_" + item.id + "Condition").style.display =
          "none";
      }
      if (
        (!item.value && item.condition !== "is_empty") ||
        (!item.value && item.condition !== "is_not_empty")
      ) {
        if (document.getElementById("err_" + item.id + "Value")) {
          document.getElementById("err_" + item.id + "Value").innerText =
            "Please select valid input";
        }
        document.getElementById("err_" + item.id + "Value").style.display =
          "block";
      }
      if (validationResponse.valid && item.value) {
        if (document.getElementById("err_" + item.id + "Value")) {
          document.getElementById("err_" + item.id + "Value").innerText = "";
          document.getElementById("err_" + item.id + "Value").style.display =
            "none";
        }
      }
    });

    if (valid === data.length) {
      const filterPayload = generateFilterPayload(data);
      res({ preservedFilter: data, appliedFilters: filterPayload });
    }
  });
};

export const getClassByStatus = (status) => {
  switch (status) {
    case "Planned":
      return "planned";
    case "Started":
      return "started";
    case "In progress":
      return "in-progress";
    case "Completed":
      return "completed";
    case "Closed":
      return "closed";
    case "Deferred":
      return "deferred";
    default:
      return "";
  }
};

export const UTCConverterMeeting = (fromDate, toDate) => {
  const date = moment(fromDate).format("ll")?.split(",") || [];
  const day = moment(fromDate).format("dddd")?.substring(0, 3);
  const fromTime = moment(fromDate).format("LT");
  const toTime = moment(toDate).format("LT");
  return `${day ? day : ""}, ${date[0] ? date[0] : ""}, ${
    fromTime ? fromTime : ""
  } - ${toTime ? toTime : ""}`;
};

export const dateFormatter = (date, format) => {
  if (!date) {
    return EMPTY_DATA_CHAR;
  } else {
    return date;
  }

  // switch (format) {
  //   case "leads":
  //     let dt = moment(date, "YYYY/MM/DD").date();
  //     let month = 1 + moment(date, "YYYY/MM/DD").month();
  //     return `${
  //       MonthMapperObj[month]
  //         ? MonthMapperObj[month]?.toUpperCase()
  //         : EMPTY_DATA_CHAR
  //     } ${dt ? dt : ""}`;
  //   case "dashboard":
  //   case "calls":
  //     return moment(date).format("ll");
  //   case "meetings": {
  //     return moment(date).format("lll");
  //   }
  //   case "history": {
  //     return moment(date).format("LT");
  //   }
  //   case "historyDate": {
  //     return moment(date).format("LL");
  //   }
  //   case "recycleBin": {
  //     return moment.utc(date).format("lll");
  //   }
  //   case "notes": {
  //     return moment(date).format("lll");
  //   }
  //   case "createdAt": {
  //     return moment(date).format("DD.MM.YYYY");
  //   }
  //   case "notification": {
  //     return moment(date).fromNow();
  //   }
  //   case "meetingReminder": {
  //     return moment.utc(date).format("LLLL");
  //   }
  // }
};

export const barChartDataParser = (analyticsData, duration) => {
  let defaultData;
  if (duration === "month") {
    defaultData = [
      { name: "January", value: 0 },
      { name: "February", value: 0 },
      { name: "March", value: 0 },
      { name: "April", value: 0 },
      { name: "May", value: 0 },
      { name: "June", value: 0 },
      { name: "July", value: 0 },
      { name: "August", value: 0 },
      { name: "September", value: 0 },
      { name: "October", value: 0 },
      { name: "November", value: 0 },
      { name: "December", value: 0 },
    ];
  } else if (duration === "week") {
    defaultData = [
      { name: "Sunday", value: 0 },
      { name: "Monday", value: 0 },
      { name: "Tuesday", value: 0 },
      { name: "Wednesday", value: 0 },
      { name: "Thursday", value: 0 },
      { name: "Friday", value: 0 },
      { name: "Saturday", value: 0 },
    ];
  } else {
    defaultData = [];
  }

  if (analyticsData?.length > 0) {
    const finalData = defaultData.map((it1) => {
      const result = analyticsData?.filter((it2) => it2.name === it1.name);
      if (result?.length > 0) {
        it1.value = result[0].value;
      }
      return it1;
    });
    return finalData;
  }
  return defaultData;
};

export const secondsToHms = (secs) => {
  if (secs) {
    return (
      ("0" + Math.floor(secs / 3600)).slice(-2) +
      ":" +
      ("0" + (Math.floor(secs / 60) % 60)).slice(-2) +
      ":" +
      ("0" + (secs % 60)).slice(-2)
    );
  }
  return EMPTY_DATA_CHAR;
};

export const findPositions = (first, second) => {
  const indicies = [];
  first.forEach((element, index) => {
    if (second.includes(element)) {
      indicies.push(index);
    }
  });
  return indicies;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const historyRowColours = ["orange", "red", "green"];

export const getUserAccessInfo = (module = null) => {
  try {
    const authData = JSON.parse(window.localStorage.getItem("a_d"));
    const decodedToken = jwt_docode(authData.access_token);
    let permission = {};
    if (authData) {
      if (decodedToken?.data) {
        const { user_access } = decodedToken.data;
        if (user_access && module) {
          permission = user_access.find((el) => el.module === module) || {};
        } else {
          permission = { ...decodedToken.data };
        }
      }
    }
    return permission;
  } catch (e) {
    console.log(e);
    handleLogout();
  }
};

const handleLogout = () => {
  if (window.localStorage.removeItem("a_d")) {
    window.localStorage.removeItem("a_d");
  }
  const url = new URL(envInjector.REACT_APP_DOMAIN_URL + "/auth/login");
  url.searchParams.set("referrer", "auth-login-sessionExpired");
  url.searchParams.set("tNode", utf8_to_b64(String(Date.now())));
  deleteCachedRefresh()
    .then(() => {
      window.location.replace(url.toString());
    })
    .catch(() => {
      window.location.replace(url.toString());
    });
};

export function organiseColumns(arr = [], properties = [], module = "") {
  switch (module) {
    default: {
      try {
        if (arr?.length > 0 && properties?.length > 0) {
          return arr.map((i) => {
            const newItem = {};
            Object.keys(i).map((key) => {
              if (properties.includes(key)) {
                properties.map((p) => {
                  newItem[p] = i[p];
                });
              }
            });
            return newItem;
          });
        }
        return arr;
      } catch (err) {
        console.log("Error while removing columns");
        return arr;
      }
    }
  }
}

export const getManagedColumns = (columnByModule) => {
  try {
    return JSON.parse(sessionStorage.getItem(columnByModule)) || [];
  } catch (err) {
    return [];
  }
};

export const getObjValue = (obj, prop) => {
  if (typeof obj[prop] !== "object") {
    return obj[prop];
  }
  return "";
};

export const leadsFormatter = (prop, value) => {
  switch (prop) {
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
  }
};

export const campaignFormatter = (prop, value, currency = "") => {
  let amount;
  switch (prop) {
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
    case "actual_cost":
    case "budgeted_cost":
    case "expected_revenue":
      amount = parseInt(value) >= 0 ? value : EMPTY_DATA_CHAR;
      if (currency && amount !== EMPTY_DATA_CHAR) {
        amount = currency + " " + amount;
      }
      return amount;
  }
};

export const contactsFormatter = (prop, value) => {
  switch (prop) {
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
  }
};

export const accountsFormatter = (prop, value) => {
  switch (prop) {
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
  }
};

export const amountFormatter = (value, currency) => {
  let amount = parseInt(value) >= 0 ? value : EMPTY_DATA_CHAR;
  if (currency && amount !== EMPTY_DATA_CHAR) {
    // amount = getCurrencySymbol(currency) + " " + amount;
    amount = currency + " " + amount;
  }
  return amount;
};

export const getCurrencySymbol = (currency) => {
  return getSymbolFromCurrency(currency);
};

export const dealsFormatter = (prop, value, currency = "") => {
  switch (prop) {
    case "deal_closed_at":
      return echo(localeDateFormatter(value, "date"));
    case "deal_amount":
    case "deal_expected_revenue":
      let dealAmount = parseInt(value) >= 0 ? value : EMPTY_DATA_CHAR;
      if (currency && dealAmount !== EMPTY_DATA_CHAR) {
        // dealAmount = getCurrencySymbol(currency) + " " + dealAmount;
        dealAmount = currency + " " + dealAmount;
      }
      return dealAmount;
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
    default:
      return EMPTY_DATA_CHAR;
  }
};

export const tasksFormatter = (prop, value) => {
  switch (prop) {
    case "task_closing_date":
      return echo(localeDateFormatter(value, "date"));
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
  }
};

export const callsFormatter = (prop, value) => {
  switch (prop) {
    case "call_duration":
      return echo(secondsToHms(value));
    case "call_start_date":
      return echo(dateFormatter(value, "calls"));
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
    default:
      return EMPTY_DATA_CHAR;
  }
};

export const meetingsFormatter = (prop, value) => {
  switch (prop) {
    case "meeting_from":
      return echo(dateFormatter(value, "meetings"));
    case "meeting_to":
      return echo(dateFormatter(value, "meetings"));
    case "created_at":
      return echo(dateFormatter(value, "createdAt"));
    case "meeting_duration":
      return echo(secondsToHms(value));
    default:
      return EMPTY_DATA_CHAR;
  }
};

export const userFormatter = (prop, value) => {
  switch (prop) {
    case "user_is_active":
      if (value === "") {
        return <span className="invited-status">Invited</span>;
      } else {
        return (
          <span
            className={value === true ? "success-status" : "invited-status"}
          >
            {value === false
              ? "Inactive"
              : value === true
              ? "Active"
              : echo(value)}
          </span>
        );
      }
    default:
      return null;
  }
};

export const recycleBinFormatter = (prop, value) => {
  switch (prop) {
    case "deleted_at":
      return echo(dateFormatter(value, "recycleBin"));
    default:
      return EMPTY_DATA_CHAR;
  }
};

export const calculateDuration = (start, end) => {
  try {
    const startTime = moment(start, "HH:mm:ss a"),
      endTime = moment(end, "HH:mm:ss a");
    const hrs = moment.utc(endTime?.diff(startTime)).format("HH");
    const min = moment.utc(endTime?.diff(startTime)).format("mm");
    const sec = moment.utc(endTime?.diff(startTime)).format("ss");
    return [hrs, min, sec].join(":");
  } catch (err) {
    return "";
  }
};

export const calculateMeetingDuration = (start, end) => {
  try {
    const s = Date.parse(start);
    const e = Date.parse(end);
    return Math.abs((e - s) / 1000);
  } catch (err) {
    return 0;
  }
};

export const getByValue = (obj, searchValue) => {
  for (let [key, value] of Object.entries(obj)) {
    if (value === searchValue) return key;
  }
};

export const getDetailsWithoutProp = (data, properties) => {
  let finalData = {};
  for (const t in data) {
    if (!properties.includes(t)) {
      finalData[t] = data[t];
    }
  }
  return finalData;
};

export const getSortBy = (element) => {
  let sortBy = "";
  if (element) {
    if (
      element.classList.contains("sort-active-up") ||
      element.classList.contains("sort-up")
    ) {
      sortBy = "desc";
    } else {
      sortBy = "asc";
    }
  }
  return sortBy;
};

export const columnNameFormatter = (columnName) => {
  if (columnName) {
    const column = columnName.split("_").join(" ");
    return column.charAt(0)?.toUpperCase() + column.slice(1);
  }
  return "";
};

export const sentenceCaseFormatter = (name) => {
  if (name) {
    return name.charAt(0)?.toUpperCase() + name.slice(1).toLowerCase();
  }
  return "--";
};

export const getDate = (dt) => {
  const date = new Date(dt);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

export const inputSpaceValidator = (value) => {
  if (typeof value === "string") {
    if (!value?.trim()) {
      return true;
    } else {
      return false;
    }
  }
};

export const compareArray = (a, b) => {
  // A function to compare if two arrays have the same elements regardless of their order
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

export const showAvatar = (str) => {
  const name = str.split(" ")[1];
  if (name) {
    return name.charAt(0)?.toUpperCase();
  }
  return "";
};

export const groupByProperty = (arr) => {
  try {
    return arr.reduce((acc, curr) => {
      if (!acc[curr["field_name"]]) {
        acc[curr["field_name"]] = [];
      }
      acc[curr["field_name"]] = curr["field_value"];
      return acc;
    }, {});
  } catch (err) {
    return {};
  }
};

export const groupExtensionByCategory = (arr) => {
  try {
    return arr.reduce((acc, curr) => {
      if (!acc[curr["category"]?.toLowerCase()]) {
        acc[curr["category"]?.toLowerCase()] = [];
      }
      acc[curr["category"]?.toLowerCase()].push(curr);
      return acc;
    }, {});
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const showValue = (value) => {
  if (value !== undefined) {
    return value;
  }
  return EMPTY_DATA_CHAR;
};

export const getLimit = (str) => {
  try {
    return str?.match(/[a-z]+|\d+/gi)?.join(" ");
  } catch (err) {
    return str;
  }
};

export const getChartData = (meter, limit) => {
  try {
    if (!isNaN(parseFloat(meter)) && !isNaN(parseFloat(limit))) {
      const percentage = (parseFloat(meter) / parseFloat(limit)) * 100;
      if (percentage?.toPrecision(2) < 1) {
        return 179;
      }
      return 180 - (percentage?.toPrecision(2) * 180) / 100;
    } else {
      return 180;
    }
  } catch (err) {
    return 180;
  }
};

export const dealExpectedRevenue = (dealAmount = 0, dealProbability = 0) => {
  if (isNaN(dealAmount)) {
    dealAmount = 0;
  }
  try {
    const percentage = (dealAmount * dealProbability) / 100;
    return percentage;
  } catch (err) {
    return 0;
  }
};

export const formatTimezoneDate = (date, format) => {
  try {
    return moment(date, format).format("YYYY-MM-DD");
  } catch (err) {
    return date;
  }
};

export const formatDefaultTimezoneDate = (date, format) => {
  try {
    return moment(date, "YYYY-MM-DD").format(format);
  } catch (err) {
    return date;
  }
};

export const getMinDateForToDate = (date, format) => {
  try {
    return new Date(formatTimezoneDate(date, format))
      ?.toISOString()
      ?.split("T")[0];
  } catch (err) {
    console.log(err);
  }
};

export const sortColumns = (arr, order) => {
  try {
    const columns = arr.sort((a, b) => {
      return order.indexOf(a.field) - order.indexOf(b.field);
    });
    return columns;
  } catch (err) {
    return arr;
  }
};

export const convertUTCTime = (value, _def) => {
  if (_def) {
    return new moment(value, "HH:mm:ss a");
  }
  const time = new moment(value, "HH:mm:ss").utc();
  return time?.format()?.slice(11);
};

export const getEmailTime = (value) => {
  if (value) {
    const time = new moment(
      value,
      `${
        getUserAccessInfo()?.user_time_format?.includes("HH") ? "HH" : "hh"
      }:mm:ss`
    );
    return time?.format()?.slice(11, 19);
  }
};

export const getTimeForMeeting = (value) => {
  const time = new moment(
    value,
    `${getUserAccessInfo()?.user_time_format?.includes("HH") ? "HH" : "hh"}:mm`
  );
  return time?.format()?.slice(11, 16);
};

export const get12Hours = () => {
  try {
    if (getUserAccessInfo()?.user_time_format?.includes("HH")) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return true;
  }
};

export const inputValueConverter = (v, type) => {
  try {
    switch (type) {
      case "numeric":
        const value = parseFloat(v);
        return value;
      default:
        return v;
    }
  } catch (err) {
    return v;
  }
};
