import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import BulkSelectProperties from "../components/bulk_update/bulkSelectProperties";
import { getMassUpdateDataAPI } from "../apis";
import { APP_DATA } from "../app_data";
import { ModuleDataTables } from "../components/bulk_update/moduleDataTables";
import { moduleEnums } from "../app_data/constants";
import { MASS_UPDATE } from "../app_data/massUpdate";

export default function BulkUpdate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, openToast, getExtraData } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);
  const moduleENUMS = new moduleEnums(urlSearch.get("target"));

  const moduleDataTables = useRef(null);
  const nonValueOps = useRef(["empty", "nonEmpty"]);

  const [module, setModule] = useState(null);
  const [enums, setEnums] = useState(moduleENUMS.enums);
  const [operators, setOperators] = useState(moduleENUMS.operators);
  const [moduleFields, setModuleFields] = useState(moduleENUMS.properties);

  const [hiddenMenu, setHiddenMenu] = useState(false);
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });

  const [placeholder, setPlaceholder] = useState([
    structuredClone(MASS_UPDATE),
  ]);
  const [searchParams, setSearchParams] = useState([]);

  useEffect(() => {
    const module = urlSearch.get("target");
    Promise.all([
      moduleENUMS.getEnums(),
      moduleENUMS.getProperties(),
      moduleENUMS.getOperators(),
    ])
      .then((d) => {
        setEnums(d[0]);
        setModuleFields(d[1]);
        setOperators(d[2]);
      })
      .catch(() => {
        openToast(APP_DATA.messages.error_message, "error");
        navigate(-1, { replace: true });
      });
    setModule(module);
  }, []);

  useEffect(() => {
    if (urlSearch.get("referrer") === "bulkUpdate") {
      moduleDataTables.current.handlePaginatedFetchData(pageData);
    }
  }, [location.search]);

  const handleAddPlaceholder = (index) => {
    const tP = [...placeholder];
    const newOptions = structuredClone(MASS_UPDATE);

    tP.splice(index + 1, 0, newOptions);
    setPlaceholder(() => [...tP]);
  };

  const hanldleRemovePlaceholder = (index) => {
    const tP = [...placeholder];
    tP.splice(index, 1);
    setPlaceholder([...tP]);
  };

  const handlePlaceholder = (e) => {
    const id = parseInt(String(e.target.id).split("_")[1]);
    if (e.target.dataset.type === "add") {
      handleAddPlaceholder(id);
    } else {
      hanldleRemovePlaceholder(id);
    }
  };

  const getClassNameForRow = (index) => {
    if (index === 0) {
      if (placeholder.length === 1) {
        return "crow last";
      }
      return "crow";
    } else if (placeholder.length > 1 && index === placeholder.length - 1) {
      return "crow subsequent last";
    } else {
      return "crow subsequent";
    }
  };

  const handelers = {
    selectProperty: (index, searchDataValue, fieldType = "string") => {
      const tp = structuredClone(placeholder);
      const newOptions = new Object(tp[index]);
      newOptions.fieldType = fieldType;
      newOptions.field = searchDataValue;
      newOptions.variablesInputs.enums = enums[searchDataValue] || [];
      newOptions.variablesInputs.operators = operators[fieldType];
      if (newOptions.value) {
        if (newOptions.fieldType === "picklist") {
          newOptions.value = [];
        } else if (newOptions.condition === "customRange") {
          newOptions.value = "";
        } else {
          newOptions.value = "";
        }
      }
      if (newOptions.condition) {
        newOptions.condition = "";
      }
      tp[index] = newOptions;
      setPlaceholder(() => [...tp]);
    },
    selectOperator: (index, value) => {
      const tp = structuredClone(placeholder);
      const newOptions = new Object(tp[index]);
      newOptions.condition = value;

      tp[index] = newOptions;
      setPlaceholder(() => [...tp]);
    },
    selectValues: (index, value) => {
      let tempV = "";

      if (placeholder[index].fieldType === "picklist") {
        tempV = [value];
      } else if (placeholder[index].fieldType === "numeric") {
        tempV = parseInt(value);
      } else {
        tempV = value;
      }

      const tp = structuredClone(placeholder);
      const newOptions = new Object(tp[index]);
      newOptions.value = tempV;

      tp[index] = newOptions;
      setPlaceholder(() => [...tp]);
    },
  };

  const getSearchedData = async (e) => {
    let finalValidation = 1;
    const tempSearchParams = [];
    document.getElementById(e.target.id).classList.add("btnLoader");
    placeholder.map((config, i) => {
      let filters = {};
      const mains = ["field", "value", "condition"];
      for (const f in config) {
        if (mains.includes(f)) {
          if (f === mains[1]) {
            if (nonValueOps.current.includes(config.condition)) {
              document.getElementById("err_" + f + "_" + i).style.display =
                "none";
              continue;
            }
          }
          if (config[f] === "") {
            finalValidation &= 0;
            document.getElementById("err_" + f + "_" + i).innerText =
              APP_DATA.errorMessage.requiredField;
            document.getElementById("err_" + f + "_" + i).style.display =
              "block";
          } else {
            document.getElementById("err_" + f + "_" + i).style.display =
              "none";
          }
        } else {
          continue;
        }
      }
      //value
      filters[mains[0]] = config.field;
      //field
      filters[mains[1]] = config.value;
      //condition
      filters[mains[2]] = config.condition;
      tempSearchParams.push(filters);
    });
    if (finalValidation) {
      moduleDataTables.current.setLoading(true);
      const updateData = await getMassUpdateDataAPI(
        module,
        tempSearchParams,
        pageData.limit,
        pageData.offset
      );
      if (updateData.status) {
        if (updateData.data["total_count"]) {
          moduleDataTables.current.setTableData({
            data: updateData.data[module],
            count: updateData.data["total_count"],
          });
        } else {
          moduleDataTables.current.setTableData({
            data: [],
            count: 0,
          });
          moduleDataTables.current.setNoResultMessage("No results found");
        }
        moduleDataTables.current.setLoading(false);
      }
      setSearchParams(tempSearchParams);
    }
    document.getElementById(e.target.id).classList.remove("btnLoader");
  };

  const handlePagination = async (pageData) => {
    const updateData = await getMassUpdateDataAPI(
      module,
      searchParams,
      pageData.limit,
      pageData.offset
    );
    if (updateData.status) {
      setPageData(pageData);
      if (updateData.data["total_count"]) {
        moduleDataTables.current.setTableData({
          data: updateData.data[module],
          count: updateData.data["total_count"],
        });
      } else {
        moduleDataTables.current.setTableData({
          data: [],
          count: 0,
        });
        moduleDataTables.current.setNoResultMessage("No results found");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <div className="header_title">
            <i
              className="crm-back-btn"
              data-original-title="Back"
              onClick={() => navigate("/app/" + module, { replace: true })}
            ></i>
            {module} - Mass Update
            {/* <i
              className="minfo-icon"
              data-placement="bottom"
              data-original-title="Mass update specific fields across multiple records within a module."
            ></i> */}
          </div>
        </div>
      </div>
      <div className="m-update">
        <div className="cndtn-box">
          <div className="hdng">
            <p className="hdr">
              Criteria <span className="crt-count">{placeholder.length}</span>
            </p>
            <div className="actn">
              <a onClick={() => setHiddenMenu(!hiddenMenu)}>
                {hiddenMenu ? "Show" : "Hide"}
              </a>
              <i className={`arw ${hiddenMenu ? "" : "open"} `}></i>
            </div>
          </div>
          <div className={`content ${hiddenMenu ? "hide" : ""}`}>
            {/* rows */}
            {module
              ? placeholder.map((_, index) => (
                  <div
                    className={getClassNameForRow(index)}
                    key={"cri-" + index}
                  >
                    <div className="count">
                      <span>{index + 1}</span>
                      {index > 0 ? <div className="operand">AND</div> : null}
                    </div>
                    <div className="crm-form-row ">
                      {/* properties */}
                      <BulkSelectProperties
                        i={index}
                        module={module}
                        functions={{ ...handelers }}
                        placeholder={placeholder[index]}
                        moduleFields={moduleFields.custom_filter}
                      />
                      <div className="icon-wrap">
                        {placeholder.length === 1 ? (
                          <i
                            data-type={"add"}
                            className="plus-icon"
                            id={"aLink_" + index}
                            onClick={(e) => handlePlaceholder(e)}
                          ></i>
                        ) : (
                          <React.Fragment>
                            <i
                              className="minus-icon"
                              id={"mLink_" + index}
                              data-type={"remove"}
                              onClick={(e) => handlePlaceholder(e)}
                            ></i>
                            <i
                              data-type={"add"}
                              className="plus-icon"
                              id={"aLink_" + index}
                              onClick={(e) => handlePlaceholder(e)}
                            ></i>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : null}

            <div className="btn-wrpr">
              <button
                id="btnSearch"
                type="button"
                className="btn btn-primary"
                onClick={(e) => getSearchedData(e)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <ModuleDataTables
        module={module}
        ref={moduleDataTables}
        data={{ placeholder, properties: [...moduleFields.bulk_update] }}
        functions={{ handlePagination, ...useOutletContext() }}
      />
    </React.Fragment>
  );
}
