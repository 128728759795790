import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { echo, forEach } from "../utils";
import { getUsersLimitNoffset } from "../apis/usersAPI";
import NoResults from "../components/common/noResults";
import DataTableLoader from "../components/common/dataTableLoader";
import createResizer from "../utils/resizerHandler";

export default function UsersDeactivatedView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal, openToast } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);

  const deleteIdsList = useRef([]);
  const selectAllCheck = useRef(null);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getDeactivatedUsers();

    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });

    deleteIdsList.current = [];
    if (urlSearch.get("referrer") === "delete") {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    createResizer();
  }, [users, location.search]);

  const getDeactivatedUsers = async () => {
    const users = await getUsersLimitNoffset(0, 0, true, "Deactivated Users");
    if (users.status) {
      setLoading(false);
      setUsers(users.data["users"]);
      setTotalRecords(users.data["total_count"]);
    }
  };

  const handleDeleteUser = async () => {
    openModal("deleteModal", {
      data: deleteIdsList.current,
      redirect: "deactivated-users",
      msgInfo: () => {
        if (deleteIdsList.current.length > 1) {
          return { multiDelete: true, user_fullname: "" };
        } else {
          const tu = users.find((u) => u.user_id === deleteIdsList.current[0]);
          return { multiDelete: false, user_fullname: tu.user_fullname };
        }
      },
    });
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === users?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== users?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleReactivateUser = (e) => {
    let dataObj = {};
    const tId = String(e.target.id).split("-")[1];
    if (tId === "btn") {
      dataObj = {
        data: deleteIdsList.current,
        lastLine: () => {
          if (deleteIdsList.current.length > 1) {
            return "reactivate selected users";
          } else {
            const u = users.find((u) => u.user_id === deleteIdsList.current[0]);
            return `reactivate ${u?.user_firstname + " " + u?.user_lastname}`;
          }
        },
      };
    } else {
      const uId = parseInt(e.target.dataset.user);
      dataObj = {
        data: [uId],
        lastLine: () => {
          const u = users.find((u) => u.user_id === uId);
          return `reactivate ${u?.user_firstname + " " + u?.user_lastname}`;
        },
      };
    }

    openModal("confirmation", {
      ...dataObj,
      redirect: "users",
      feature: "user-reactivation",
    });
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <i
            className="crm-back-btn"
            onClick={() => navigate("/app/users")}
          ></i>
          <div className="header_title">Deactivated Users</div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div className="crm-btngrp-wrpr users-btn">
            <button
              type="button"
              className={btnDelete ? "crm-btn-grp" : "crm-btn-grp disabled"}
            >
              <i className="crm-action-rctvtusr"></i>
              <span id="activate-btn" onClick={(e) => handleReactivateUser(e)}>
                Reactivate User
              </span>
            </button>
            <button
              onClick={() => handleDeleteUser()}
              className={btnDelete ? "crm-btn-grp" : "crm-btn-grp disabled"}
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total Users: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
        </div>
      </div>
      {/* table section */}
      <div className="crm-table cst-mrgn">
        <div className="crm-tb-hed">
          <div className="crm-tb-rw" id={"resizeMe"}>
            <div className="crm-fixed-field">
              <div className="crm-td crm-td-users">
                <input
                  type="checkbox"
                  className="crm-checkmark"
                  id="users_check"
                  ref={selectAllCheck}
                  onClick={(e) => handleSelectAll(e)}
                />
                <label htmlFor="users_check">
                  <span>Name</span>
                </label>
              </div>
            </div>
            <div className="crm-scroll-field">
              <div className="crm-td r crm-td-email" id="col-1">
                <span>Email</span>
              </div>
              <div className="crm-td r crm-td-role" id="col-2">
                <span>Role</span>
              </div>
              <div className="crm-td r crm-td-usrstatus" id="col-3">
                <span>Reactivate Action</span>
              </div>
            </div>
          </div>
        </div>
        <div className="crm-tb-bdy">
          {!loading ? (
            users.length ? (
              users.map((user, index) => (
                <React.Fragment key={"du-" + index}>
                  <div className="crm-tb-rw">
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-users">
                        <input
                          type="checkbox"
                          id={user.user_id}
                          className="crm-checkmark ids"
                          onChange={(e) => handleDeleteCheck(e)}
                        />
                        <label
                          title=""
                          className="user-name"
                          htmlFor={user.user_id}
                        >
                          <span>{echo(user.user_fullname)}</span>
                        </label>
                      </div>
                    </div>
                    <div className="crm-scroll-field">
                      <div data-colpos={1} className="crm-td crm-td-email">
                        <span>{echo(user.user_email)}</span>
                      </div>
                      <div data-colpos={2} className="crm-td crm-td-role">
                        <span>{echo(user.user_role)}</span>
                      </div>
                      <div data-colpos={3} className="crm-td crm-td-usrstatus">
                        <span className="actn-lnks">
                          <a
                            id={index + "activate-link"}
                            data-user={user.user_id}
                            className="reactivate-lnk"
                            onClick={(e) => handleReactivateUser(e)}
                          >
                            Reactivate
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <NoResults />
            )
          ) : (
            <DataTableLoader mClass={"crm-td crm-td-users"} module="user" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
