import React, { useEffect } from "react";
import LeadStaticFields from "./static_field_inputs/leadStaticFields";
import ContactStaticFields from "./static_field_inputs/contactStaticFields";
import DealStaticFields from "./static_field_inputs/dealStaticFields";
import AccountStaticFields from "./static_field_inputs/accountStaticFields";

export default function StaticFieldsModuleMapper(props) {
  const renderStaticFieldsSwitch = (moduleName) => {
    switch (moduleName) {
      case "leads":
        return <LeadStaticFields {...props} />;
      case "contacts":
        return <ContactStaticFields {...props} />;
      case "deals":
        return <DealStaticFields {...props} />;
      case "accounts":
        return <AccountStaticFields {...props} />;
      default:
        //add UI to unhandled modules
        break;
    }
  };

  return renderStaticFieldsSwitch(props.moduleName);
}
