import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="err-sctn">
        <i className="expr-icn"></i>
        <p className="title">Page Not Found</p>
        <p className="subtitle" href="login.html">
          Sorry, we can't find the page you're looking for!
        </p>
        <Link
          type="submit"
          className="btn btn-primary back-home"
          to="/app/dashboard"
        >
          Back to Home
        </Link>
      </div>
    </>
  );
};

export default NotFound;
