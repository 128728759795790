import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { getAccessTokenAPI, getCachedData, setCachedData } from "./authAPIs";
import { API_URL } from ".";
import { getUserAccessInfo } from "../utils";

export const getGroupsLimitOffset = async (
  offset = 0,
  limit = 10,
  count = false,
  groupFilter,
  createdBy,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/groups/offset?offset=${offset}&limit=${limit}&count=${count}&group_filter=${
            groupFilter ? groupFilter : "All Groups"
          }${createdBy ? `&created_by=${createdBy}` : ""}&group_field=${
            sortField ? sortField : "Created date"
          }${sortBy ? `&order_by=${sortBy}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewGroups = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/groups/", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        if (res?.data?.data?.group_users?.includes(getUserAccessInfo()?.user_id)) {
          getCachedData().then((c) => {
            setTimeout(() => {
              getAccessTokenAPI(c)
                .then(async (t) => {
                  setCachedData(c)
                    .then(async () => {
                      window.localStorage.setItem(
                        "a_d",
                        JSON.stringify({ access_token: t.data["access_token"] })
                      );
                      resolve(await APISuccessResponse(res));
                    })
                    .catch(() => {
                      resolve({ status: false, data: [] });
                    });
                })
                .catch(() => {
                  resolve({ status: false, data: [] });
                });
            }, 2000);
          });
        } else {
          resolve(await APISuccessResponse(res));
        }
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteGroup = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/groups/?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        getCachedData().then((c) => {
          setTimeout(() => {
            getAccessTokenAPI(c)
              .then(async (t) => {
                setCachedData(c)
                  .then(async () => {
                    window.localStorage.setItem(
                      "a_d",
                      JSON.stringify({ access_token: t.data["access_token"] })
                    );
                    resolve(await APISuccessResponse(res));
                  })
                  .catch(() => {
                    resolve({ status: false, data: [] });
                  });
              })
              .catch(() => {
                resolve({ status: false, data: [] });
              });
          }, 2000);
        });
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateGroupById = async (id, reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/groups/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        getCachedData().then((c) => {
          setTimeout(() => {
            getAccessTokenAPI(c)
              .then(async (t) => {
                setCachedData(c)
                  .then(async () => {
                    window.localStorage.setItem(
                      "a_d",
                      JSON.stringify({ access_token: t.data["access_token"] })
                    );
                    resolve(await APISuccessResponse(res));
                  })
                  .catch(() => {
                    resolve({ status: false, data: [] });
                  });
              })
              .catch(() => {
                resolve({ status: false, data: [] });
              });
          }, 2000);
        });
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getGroupById = (groupId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/groups/" + groupId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
