import React from "react";
import { useEffect } from "react";
export const RightMenuLoader = () => {
  useEffect(() => {
    document.getElementById("dom-body").style.overflow = "hidden";
    return () => {
      document.getElementById("dom-body").style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <div className="rhs-loader-wrp" style={{ display: "block" }}>
        <i className="rhs-loader"></i>
      </div>
    </>
  );
};
