import axios from "axios";
import { APISuccessResponse, getAuthToken } from ".";
import { API_URL } from ".";

export const getAllAccounts = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/accounts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccountsLimitNoffset = async (
  offset = 0,
  limit = 10,
  count = false,
  accountFilter,
  accountOwner,
  sortBy,
  sortField
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/accounts/offset?offset=${offset}&limit=${limit}&count=${true}&account_filter=${
            accountFilter ? accountFilter : "All Accounts"
          }${
            accountOwner ? `&account_owner=${accountOwner}` : ""
          }&account_field=${sortField ? sortField : "Created date"}${
            sortBy ? `&order_by=${sortBy}` : ""
          }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccountsByName = async (accountName) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/accounts/name/${accountName}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const createNewAccount = async (reqPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "/accounts", reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const deleteAccount = async (reqPayload, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "/accounts?deleted_by=" + userId, {
        data: reqPayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const getAccountById = (accountId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "/accounts/" + accountId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const updateAccountById = (id = 0, reqPayload = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + "/accounts/" + id, reqPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};

export const searchAPI = (page = "", reqPayload = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `${page}/name/${reqPayload}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      })
      .then(async (res) => {
        console.log(res);
        resolve(await APISuccessResponse(res));
      })
      .catch(async (e) => {
        console.log(e);
        resolve({ status: false, data: [] });
      });
  });
};
