import React, { useState, useEffect, useRef } from "react";
import {
  useOutletContext,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { APP_DATA } from "../app_data";
import { useOutside } from "../customHooks/useOutside";
import {
  echo,
  echoPrimary,
  forEach,
  getObjValue,
  getUserAccessInfo,
  recycleBinFormatter,
  utf8_to_b64,
  getSortBy,
  overrideDefaultEvent
} from "../utils";
import {
  getRecycleBinByModule,
  restoreRecycleBinByModule,
} from "../apis/recycleBinAPIs";
import NoResults from "../components/common/noResults";
import DataTableLoader from "../components/common/dataTableLoader";
import createResizer from "../utils/resizerHandler";

const RecycleBinView = () => {
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const customFilter = useRef(null);
  const binModuleRef = useRef(null);
  const deleteIdsList = useRef([]);
  const selectAllCheck = useRef(null);

  const [binModule, setBinModule] = useState(APP_DATA.recycleBinModules[0]);
  const [btnDelete, setBtnDelete] = useState(false);
  const [recycleBin, setRecycleBin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const { openModal } = useOutletContext();

  useEffect(() => {
    setPermission(getUserAccessInfo("Recyclebin")?.permission || {});
  }, []);

  useEffect(()=>{
   createResizer();
  },[recycleBin,loading])

  useEffect(() => {
    if (binModule?.value) {
      getRecycleBinData(binModule.value);
    }

    deleteIdsList.current.map((id) => {
      document.getElementById(id).checked = false;
    });
    deleteIdsList.current = [];
    if (
      urlSearch.get("referrer") === "delete" ||
      urlSearch.get("referrer") === "restore"
    ) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  const getRecycleBinData = async (module,sortBy,sortField) => {
    setLoading(true);
    try {
      const binData = await getRecycleBinByModule(module,sortBy,sortField);
      if (binData.status) {
        if (binData?.data?.length > 0) {
          setRecycleBin(binData.data);
        } else {
          setRecycleBin([]);
        }
      } else {
        setRecycleBin([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setRecycleBin([]);
    }
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(e.target.id)) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === recycleBin?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== recycleBin?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
    }
  };

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      } else {
        let fe = {
          target: {
            checked: e.target.checked,
            id: el.id,
          },
        };
        handleDeleteCheck(fe);
        el.checked = e.target.checked;
      }
    });
  };

  const binModuleSelect = (e) => {
    binModuleRef.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    if (value) {
      const module = APP_DATA.recycleBinModules.find((m) => m.value === value);
      setBinModule(module);
      binModuleRef.current.classList.remove("open");
      getRecycleBinData(module.value);

      //clearing checkboxes while switching module
      deleteIdsList.current.map((id) => {
        document.getElementById(id).checked = false;
      });
      deleteIdsList.current = [];
      setBtnDelete(false);
      selectAllCheck.current.checked = false;
      clearSort();
    }
  };

  const handleRestore = async () => {
    try {
      const restoredRecords = await restoreRecycleBinByModule(
        binModule.value,
        deleteIdsList.current
      );
      if (restoredRecords.status) {
        navigate({
          pathname: "/app/recyclebin",
          search: createSearchParams({
            referrer: "restore",
            tNode: utf8_to_b64(String(Date.now())),
          }).toString(),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useOutside(binModuleRef, () => {
    binModuleRef.current.classList.remove("open");
  });

  const clearSort = () => {
    setSortInfo({
      sortBy: "",
      sortField: "",
      sortKey: "",
    });
  };

  const clearCheckbox = () => {
    if (selectAllCheck?.current) {
      selectAllCheck.current.checked = 0;
    }

    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      setBtnDelete(false);
    }
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "wflowname") {
      return;
    }

    if (e.target.id === "rb") {
      overrideDefaultEvent(e);
    }

    clearCheckbox();

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    if (sortBy && field) {
      setSortInfo({ sortBy, sortField: field, sortKey: id });
    }
    getRecycleBinData(binModule.value, sortBy, field);
  
  };

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Recycle bin </h1>
        </div>
        <div className="header_rhs">
          <div className="crm-btngrp-wrpr users-btn">
            <button
              className={
                permission.delete && recycleBin?.length > 0
                  ? "crm-btn-grp btn-danger-outline"
                  : "crm-btn-grp btn-danger-outline disabled"
              }
              onClick={
                permission.delete && recycleBin?.length > 0
                  ? () => {
                      openModal("recycleBinDeleteModal", {
                        module: binModule.value,
                        operation: "emptybin",
                      });
                    }
                  : null
              }
            >
              <span>Empty Recycle Bin</span>
            </button>
          </div>
        </div>
      </div>
      <div className="trash-info" id="trash-info">
        <p className="hdr">
          View all the records that have been deleted from your IDrive CRM
          account.
        </p>
        <ul>
          <li>
            The deleted records will be retained in the Recycle Bin for 60 days
            before they are permanently deleted.
          </li>
          <li>
            Admins and users with admin rights are permitted to delete the
            records from the Recycle Bin.
          </li>
          <li>
            Users without admin rights are allowed to restore their own records
            from the Recycle Bin. However, non-admin users who have the
            necessary permissions can also restore the records of other users.
          </li>
        </ul>
      </div>
      <div className="sticky-wrap">
        <div className="crm-top-menu" id="boxHere">
          <div className="menu-lft">
            <div
              className={
                permission.view
                  ? "crm-custom-select-wrap"
                  : "crm-custom-select-wrap disabled"
              }
            >
              <div
                className="custom-select"
                onClick={permission.view ? binModuleSelect : null}
                ref={binModuleRef}
              >
                <div className="crm-custom-select-value">
                  <span title="Leads">{binModule?.name}</span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.recycleBinModules.map((r, i) => (
                    <span
                      className={`crm-custom-option ${
                        binModule.value === r.value ? "selected" : ""
                      }`}
                      key={i}
                      data-value={r.value}
                    >
                      {r.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="crm-btngrp-wrpr users-btn">
              <button
                className={
                  btnDelete && permission.create
                    ? "crm-btn-grp rstore-mod" /* btn-primary*/
                    : "crm-btn-grp rstore-mod disabled" /*btn-primary */
                }
                onClick={btnDelete && permission.create ? handleRestore : null}
              >
                <i className="rstore-icn"></i>
                <span>Restore</span>
              </button>
              <button
                className={
                  btnDelete && permission.delete
                    ? "crm-btn-grp btn-danger-outline"
                    : "crm-btn-grp btn-danger-outline disabled"
                }
                onClick={
                  btnDelete && permission.delete
                    ? () =>
                        openModal("recycleBinDeleteModal", {
                          deletedIds: deleteIdsList.current,
                          module: binModule.value,
                          operation: "delete",
                        })
                    : null
                }
              >
                <i className="delete-icn"></i>
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
        <div className="crm-table cst-mrgn">
          {recycleBin?.length > 0 && permission.view && !loading && (
            <div className="crm-tb-hed">
              <div className="crm-tb-rw" id={"resizeMe"}>
                <div className="crm-fixed-field">
                  <div className="crm-td crm-td-name">
                    <input
                      type="checkbox"
                      className="crm-checkmark"
                      id="wflowname"
                      ref={selectAllCheck}
                      onClick={(e) => handleSelectAll(e)}
                      disabled={recycleBin?.length ? false : true}
                    />
                    <label htmlFor="wflowname" onClick={(e) => {
                    handleSort(e, APP_DATA.sortKeyForRecycleBin[binModule?.value], "recyclebin");
                  }}>
                      <span
                       className={
                        sortInfo.sortKey === "recyclebin" ? "sort-highlight" : ""
                      }
                      id="rb"
                      >
                        {
                          APP_DATA.APIColumnMappings[binModule.value][
                            APP_DATA.recycleBinColumns[binModule.value][1]
                          ]
                        }
                      </span>
                    </label>
                    <i
                      className={
                        sortInfo.sortKey === "recyclebin"
                        
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="recyclebin"
                      onClick={(e) => handleSort(e, APP_DATA.sortKeyForRecycleBin[binModule?.value], "recyclebin")}
                    ></i>
                  </div>
                </div>
                <div className="crm-scroll-field">
                  <div
                    id="col-2"
                    className={`crm-td r crm-td-deletedby
                    ${
                      sortInfo.sortKey === "col-2"
                        ? sortInfo.sortBy === "desc"
                          ? "crm-sort sort-active"
                          : "crm-sort sort-active-up"
                        : ""
                    }`}
                  >
                    <span onClick={(e) => handleSort(e, "Deleted by", "col-2")}>
                      Deleted by
                    </span>
                  </div>
                  <div
                  id="col-3"
                  className={`crm-td r crm-td-deletedtime  
                ${
                  sortInfo.sortKey === "col-3"
                    ? sortInfo.sortBy === "desc"
                      ? "crm-sort sort-active"
                      : "crm-sort sort-active-up"
                    : ""
                }`}  
                >
                    <span onClick={(e) => handleSort(e, "Deleted date", "col-3")}>Deleted time</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="crm-tb-bdy">
            {loading ? (
              <DataTableLoader
                mClass={"crm-td crm-td-name"}
                module="recycle-bin"
              />
            ) : recycleBin?.length > 0 && permission.view ? (
              recycleBin.map((r, i) => (
                <div className="crm-tb-rw" key={i}>
                  <div className="crm-fixed-field">
                    <div className="crm-td crm-td-name">
                      <input
                        type="checkbox"
                        className="crm-checkmark ids"
                        id={getObjValue(
                          r,
                          APP_DATA.recycleBinColumns[binModule?.value][0]
                        )}
                        onChange={(e) => {
                          handleDeleteCheck(e);
                        }}
                      />
                      <label
                        htmlFor={getObjValue(
                          r,
                          APP_DATA.recycleBinColumns[binModule?.value][0]
                        )}
                      >
                        <span>
                          {binModule.value==="leads" ? 
                        echoPrimary(r.lead_firstname)+" "+echoPrimary(r.lead_lastname)
                          :getObjValue(
                            r,
                            APP_DATA.recycleBinColumns[binModule?.value][1]
                          ) || "--"}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="crm-scroll-field">
                    <div data-colpos="2" className="crm-td crm-td-deletedby">
                      <span>{echo(getObjValue(r, "deleted_by_name"))}</span>
                    </div>
                    <div data-colpos="3" className="crm-td crm-td-deletedtime">
                      <span>
                        {recycleBinFormatter(
                          "deleted_at",
                          getObjValue(r, "deleted_at")
                        )}
                      </span>
                    </div>
                    <div className="crm-td crm-action"></div>
                  </div>
                </div>
              ))
            ) : (
              <NoResults permission={!permission.view} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecycleBinView;
