import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import {
  echo,
  getUserAccessInfo,
  getSortBy,
  overrideDefaultEvent,
} from "../utils";
import { getProfiles } from "../apis/profileAPI";
import NoResults from "../components/common/noResults";
import { APP_DATA } from "../app_data";
import createResizer from "../utils/resizerHandler";
import DataTableLoader from "../components/common/dataTableLoader";

const ProfileView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const deleteIdsList = useRef([]);
  const selectAllCheck = useRef(null);

  const [profiles, setProfiles] = useState([]);
  const [profilePermission, setProfilePermission] = useState({});
  const [btnDelete, setBtnDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasScroll,setHasScroll] = useState(false);

  const { handleRSidebar, openModal } = useOutletContext();
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });

  const urlSearch = new URLSearchParams(location.search);

  useEffect(() => {
    fetchProfiles();
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      clearSort();
    }
  }, [location.search]);

  useEffect(() => {
    const element = document.querySelector(".crm-tb-bdy");
    if (element) {
      const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  }, [profiles]);


  useEffect(() => {
    createResizer();
  }, [profiles, location.search,loading]);

  useEffect(() => {
    const profiles = getUserAccessInfo("Profiles");
    if (profiles?.permission) {
      setProfilePermission(profiles.permission);
    } else {
      setProfilePermission({});
    }
  }, []);

  const fetchProfiles = (sortBy, sortField) => {
    setLoading(true);
    getProfiles(sortBy, sortField)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setProfiles(res.data);
        } else {
          setLoading(false);
          setProfiles([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setProfiles([]);
      });
  };

  const handleRename = (
    e,
    { profile_id, profile_name, profile_description }
  ) => {
    overrideDefaultEvent(e);
    handleRSidebar("renameProfile", {
      id: profile_id,
      profile: { profile_name, profile_description },
    });
  };

  const handleCreateAndClone = (e, id, profileName) => {
    overrideDefaultEvent(e);
    handleRSidebar("createProfile", {
      isCreate: e.target.id === "create" ? true : false,
      id: e.target.id === "clone" ? id : "",
      profileName: e.target.id === "clone" ? profileName : "",
    });
  };

  const handleViewUsers = (e, profile) => {
    const { profile_id, profile_name } = profile;
    overrideDefaultEvent(e);
    handleRSidebar("viewUsers", {
      id: profile_id,
      name: profile_name,
      permission: profilePermission,
    });
  };

  const handleDelete = (e, id) => {
    overrideDefaultEvent(e);
    try {
      openModal("deleteModal", {
        data: id,
        redirect: "profiles",
        msgInfo: profiles.find((p) => p.profile_id === id),
      });
    } catch (error) {}
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.id === "profileSort") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);
    const sortBy = getSortBy(element);
    if (id) {
      setSortInfo({ sortBy, sortField: id, sortKey: field });
      fetchProfiles(sortBy, id);
    }
    unCheckRecords();
  };

  return (
    <>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">
            Profiles <span className="desc"></span>
          </h1>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              profilePermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt grp-lhs"
              onClick={profilePermission?.create ? handleCreateAndClone : null}
            >
              <i className="plus-icn"></i>
              <span id="create">New Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="prf-info">
        <div className="crm-table prf-tbl cst-mrgn">
          {profilePermission?.view && profiles?.length > 0 && !loading && (
            <div className="crm-tb-hed">
              <div className="crm-tb-rw" id={"resizeMe"}>
                <div className="crm-td crm-td-prfname">
                  <span
                    className={
                      sortInfo.sortKey === "profile_name"
                        ? "sort-highlight"
                        : ""
                    }
                    id="profileSort"
                    onClick={(e) =>
                      handleSort(e, "profile_name", "Profile name")
                    }
                  >
                    Profile name
                  </span>
                  <i
                    className={
                      sortInfo.sortKey === "profile_name"
                        ? sortInfo.sortBy === "desc"
                          ? "sort sort-down"
                          : "sort sort-up"
                        : ""
                    }
                    style={{ marginTop: "5px" }}
                    id="Profile Name"
                    onClick={(e) =>
                      handleSort(e, "profile_name", "Profile name")
                    }
                  ></i>
                </div>
                <div id="col-2" className={`crm-td r crm-td-prfdesc`}>
                  <span
                    onClick={(e) => {
                      handleSort(
                        e,
                        "profile_description",
                        "Profile description"
                      );
                    }}
                    className={
                      sortInfo.sortKey === "profile_description"
                        ? "sort-highlight"
                        : ""
                    }
                  >
                    Profile description
                    <i
                      className={
                        sortInfo.sortKey === "profile_description"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      style={{ marginRight: "5px", marginBottom: "2px" }}
                      id="Profile Description"
                      onClick={(e) =>
                        handleSort(
                          e,
                          "profile_description",
                          "Profile description"
                        )
                      }
                    ></i>
                  </span>
                </div>
                <div
                  id="col-3"
                  className={`crm-td r crm-td-crtdby`}
                  onClick={(e) => handleSort(e, "created_by", "Created by")}
                >
                  <span
                    className={
                      sortInfo.sortKey === "created_by" ? "sort-highlight" : ""
                    }
                  >
                    Created by
                    <i
                      className={
                        sortInfo.sortKey === "created_by"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      style={{ marginRight: "5px", marginBottom: "2px" }}
                      id="Created by"
                      onClick={(e) => handleSort(e, "created_by", "Created by")}
                    ></i>
                  </span>
                </div>
                <div
                  id="col-4"
                  className={`crm-td r crm-td-modby`}
                  onClick={(e) => handleSort(e, "updated_by", "Updated by")}
                >
                  <span
                    className={
                      sortInfo.sortKey === "updated_by" ? "sort-highlight" : ""
                    }
                  >
                    Modified by
                    <i
                      className={
                        sortInfo.sortKey === "updated_by"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      style={{ marginRight: "5px", marginBottom: "2px" }}
                      id="Updated by"
                      onClick={(e) => handleSort(e, "updated_by", "Updated by")}
                    ></i>
                  </span>
                </div>
                <div className="crm-td crm-action"></div>
              </div>
            </div>
          )}
          <div className="crm-tb-bdy">
            {!loading ? (
              profilePermission?.view && profiles?.length > 0 ? (
                profiles?.map((p, index,arr) => (
                  <div key={index} className="crm-tb-rw">
                    <div className="crm-td crm-td-prfname">
                      {/* <input type="checkbox" className="crm-checkmark" id="group" /> */}
                      {/* <label htmlFor="group"> */}
                      <span>{echo(p?.profile_name)}</span>
                      {/* </label> */}
                    </div>
                    <div data-colpos="2" className="crm-td crm-td-prfdesc">
                      <span>{echo(p?.profile_description)}</span>
                    </div>
                    <div data-colpos="3" className="crm-td crm-td-crtdby">
                      <span>
                        {APP_DATA.restrictedProfiles.includes(p.profile_name)
                          ? "None"
                          : echo(p.created_by_name)}
                      </span>
                    </div>
                    <div data-colpos="4" className="crm-td crm-td-modby">
                      <span>
                        {APP_DATA.restrictedProfiles.includes(p.profile_name)
                          ? "None"
                          : echo(p.updated_by_name)}
                      </span>
                    </div>
                    <div className="crm-td crm-action">
                      <a className="crm-action-opt crm-action-settings" id="">
                        <ul
                          className={`crm-dropdown-action
                           ${ index >=arr.length-3 && profiles.length>2 && hasScroll ? "showdrpTop":""}
                          `}
                          id=""
                          onClick={(e) => {
                            overrideDefaultEvent(e);
                          }}
                        >
                          <li
                            className={
                              profilePermission?.view ? "" : "disabled"
                            }
                          >
                            <span
                              className={"crm-action-view"}
                              onClick={
                                profilePermission?.view
                                  ? () => {
                                      navigate("permissions", {
                                        state: {
                                          id: p.profile_id,
                                          profile_name: p.profile_name,
                                        },
                                      });
                                    }
                                  : null
                              }
                            >
                              View
                            </span>
                          </li>
                          {!APP_DATA.restrictedProfiles.includes(
                            p.profile_name
                          ) && (
                            <li
                              className={
                                profilePermission?.edit ? "" : "disabled"
                              }
                            >
                              <span
                                className={"crm-action-rename"}
                                onClick={
                                  profilePermission?.edit &&
                                  !APP_DATA.restrictedProfiles.includes(
                                    p.profile_name
                                  )
                                    ? (e) => {
                                        handleRename(e, p);
                                      }
                                    : null
                                }
                              >
                                Rename
                              </span>
                            </li>
                          )}
                          <li
                            className={
                              profilePermission?.create ? "" : "disabled"
                            }
                          >
                            <span
                              className={"crm-action-cloneicn"}
                              id="clone"
                              onClick={
                                profilePermission?.create
                                  ? (e) =>
                                      handleCreateAndClone(
                                        e,
                                        p.profile_id,
                                        p.profile_name
                                      )
                                  : null
                              }
                            >
                              Clone
                            </span>
                          </li>
                          {!APP_DATA.restrictedProfiles.includes(
                            p.profile_name
                          ) && (
                            <li
                              className={
                                profilePermission?.delete ? "" : "disabled"
                              }
                            >
                              <span
                                className={"crm-action-delete"}
                                onClick={
                                  profilePermission?.delete &&
                                  !APP_DATA.restrictedProfiles.includes(
                                    p.profile_name
                                  )
                                    ? (e) => handleDelete(e, p.profile_id)
                                    : null
                                }
                              >
                                Delete
                              </span>
                            </li>
                          )}
                        </ul>
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!profilePermission?.view}
                  filter={profiles?.length === 0 ? false : true}
                />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-prfname"}
                module={"profiles"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileView;
