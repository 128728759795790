// css based js object for the app

export const CSS_CLASS_DATA = {
  noResultIcon: {
    leads: "crm-no-leads",
    deals: "crm-no-deals",
    tasks: "crm-no-tasks",
    calls: "crm-no-calls",
    contacts: "crm-no-contacts",
    contracts:"crm-no-contracts",
    meetings: "crm-no-meetings",
    accounts: "crm-no-accounts",
    users: "crm-no-users",
    groups: "crm-no-groups",
    campaigns: "crm-no-campaign",
    templates: "crm-no-emailtemplate",
    storageUsage: "crm-no-storageusage",
    recyclebin: "crm-no-trash",
    extensions: "crm-no-extensions",
    contracts: "crm-no-contracts",
    customizations: "crm-no-customization",
    workflows: "crm-no-workflow",
    personal: "crm-no-personalstngs",
    company: "crm-no-cmpnydtls",
    profiles: "crm-no-accessctrl",
    analyticsMeetingStatus: "crm-no-mtngstatus",
    analyticsDealStatus: "crm-no-dealstatus",
    dashboardCalendar: "crm-no-calendar",
    commonDashboard: "crm-no-data-available",
    "web-forms": "crm-no-webforms",
    "deactivated-users":"crm-no-deactivatedusers"
  },
};
