import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getCallsLimitNoffset } from "../apis/callAPIs";
import {
  getDataForDateFilters,
  getDataForCustomFilters,
} from "../apis/customFilterAPI";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import { debounceOptFunc, getDate, search } from "../utils";
import NoResults from "../components/common/noResults";
import {
  echo,
  forEach,
  getSortBy,
  getObjValue,
  callsFormatter,
  organiseColumns,
  getManagedColumns,
  getUserAccessInfo,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import { useOutside } from "../customHooks/useOutside";
import Range from "../components/datepicker/range";
import createResizer from "../utils/resizerHandler";
import CustomFilter from "../components/filters/customFilter";
import { APP_DATA } from "../app_data";
import {
  searchAPI,
  searchThroughAPIs,
  getExportFileDateFilter,
  getExportFileCustomFilter,
  getExportFileFixedFilter,
  getExportFileSearchFilter,
  downloadCSVExportedFiles,
} from "../apis";
import { Tooltip } from "react-tooltip";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";

const MODULE_NAME = "calls";

export default function CallsView() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    openModal,
    openToast,
    closeModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const urlSearch = new URLSearchParams(location.search);

  const searchRef = useRef(null);
  const datePicker = useRef(null);
  const pagination = useRef(null);
  const deleteIdsList = useRef([]);
  const customFilter = useRef(null);
  const selectFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);

  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });

  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All Calls");
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [callsPermission, setCallsPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [filter, setFilter] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName,setDisplayName] = useState({});

  useEffect(() => {
    const callsFilter = JSON.parse(sessionStorage.getItem("callsFilter"));
    if (
      callsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(callsFilter);

      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: callsFilter,
      }));
      getCallsForCustomFilter(
        appliedFilters.true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (location.state?.filter?.value) {
      getCalls(location.state.filter.value);
      setSelectedFilter(location.state.filter.value);
      setFilter(true);
      document.getElementById("select-value").innerText =
        APP_DATA.fixedFilters.calls.find(
          (c) => c.value === location.state.filter.value
        )?.name;
      //we're navigating to clear state so that prev filter is not maintained
      navigate(location.pathname, {});
    } else {
      //clearing filters after create, edit ,clone or delete
      //as sometimes no records will create confusion to users
      // clearFixedFilter();
      // clearDateFilter();
      // clearAll();
      // searchRef.current.value = "";
      // clearSort();

      // getCalls();
      handlePaginatedFetchData(pageData);
    }
    deleteIdsList.current.map((id) => {
      document.getElementById(id).checked = false;
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
    // createResizer()
  }, [location.search]);

  useEffect(() => {
    const calls = getUserAccessInfo("Calls");
    if (calls?.permission) {
      setCallsPermission(calls.permission);
    } else {
      setCallsPermission({});
    }
    getModuleDataTypesAPI("calls")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName); 
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({});
      });
  }, []);

  useEffect(() => {
    createResizer();
  }, [calls, location.search,loading]);

  const getCalls = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);

    const calls = await getCallsLimitNoffset(
      0,
      pageData.limit,
      true,
      selectedFilter,
      APP_DATA.fixedFiltersOwnerFields.calls.includes(selectedFilter)
        ? getUserAccessInfo()?.user_id
        : "",
      sortBy,
      sortField
    );
    if (calls.status) {
      setCalls(
        organiseColumns(calls.data.calls, getManagedColumns("callsColumn"))
      );
      if (calls.data.hasOwnProperty("total_count")) {
        setTotalRecords(calls.data.total_count);
        if (!deleteIdsList.current.length > 0) {
          setBtnDelete(false);
        }
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === calls?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== calls?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      const searched = await searchThroughAPIs(
        "calls",
        qstring,
        0,
        pageData.limit
      );
      console.log(searched, "sdsdsdsd");
      if (searched.status) {
        setTotalRecords(searched.data.total_count);
        setCalls(
          organiseColumns(searched.data.calls, getManagedColumns("callsColumn"))
        );
      } else {
        setCalls([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getCalls();
      setFilter(false);
    }
  };

  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let e = {
          target: {
            checked: true,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = true;
      } else {
        let e = {
          target: {
            checked: false,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = false;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    //to uncheck select all and disable delete while going to next page
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const calls = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (calls.status) {
        if (calls?.data) {
          setCalls(organiseColumns(calls.datagetManagedColumns("callsColumn")));
        } else {
          setCalls([]);
        }
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const calls = await searchAPI(
          "calls",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (calls.status) {
          setTotalRecords(calls.data.total_count);
          setCalls(
            organiseColumns(calls.data.calls, getManagedColumns("callsColumn"))
          );
        } else {
          setCalls([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getCallsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getCallsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const calls = await getCallsLimitNoffset(
        pageData.offset,
        pageData.limit,
        true,
        selectedFilter,
        APP_DATA.fixedFiltersOwnerFields.calls.includes(selectedFilter)
          ? getUserAccessInfo()?.user_id
          : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (calls.status) {
        setTotalRecords(calls.data.total_count);
        setCalls(
          organiseColumns(calls.data.calls, getManagedColumns("callsColumn"))
        );
      } else {
        setCalls([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  const getCallsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setLoading(true);
    setFilter(true);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const calls = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (calls.status) {
        if (calls?.data?.calls?.length > 0) {
          setCalls(
            organiseColumns(calls.data.calls, getManagedColumns("callsColumn"))
          );
        } else {
          setCalls([]);
        }
        if (calls.data?.hasOwnProperty("total_count")) {
          setTotalRecords(calls.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setCalls([]);
      setLoading(false);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const getCallsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setLoading(true);
    setFilter(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const calls = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (calls.status) {
        if (calls?.data?.calls?.length > 0) {
          setCalls(
            organiseColumns(calls.data.calls, getManagedColumns("callsColumn"))
          );
        } else {
          setCalls([]);
        }
        if (calls.data?.hasOwnProperty("total_count")) {
          setTotalRecords(calls.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCalls([]);
      console.log("err", err);
    }
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      getCalls(value, sortInfo.sortBy, sortInfo.sortField);
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      clearAll();
      clearDateFilter();
      searchRef.current.value = "";
      unCheckRecords();
      setFilter(true);
    }
  };

  const handleDatePicker = (value) => {
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);

    if (value !== "Custom_Range") {
      openDatePicker(false);
      getCallsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
    clearFixedFilter();
    clearAll();
    searchRef.current.value = "";
    unCheckRecords();
    setFilter(true);
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getCallsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearDateFilter = (calls) => {
    if (datePickerFilter) {
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
      setFilter(false);
    }
    if (calls) {
      getCalls();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setSelectedFilter("All Calls");
      document.getElementById("select-value").innerText = "All calls";
      setFilter(false);
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    if (selectedFilter) {
      clearFixedFilter();
    }

    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));
    openFilterSidebar(false);
    getCallsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  const clearAll = (calls) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      setFilter(false);
      if (calls) {
        getCalls("", sortInfo.sortBy, sortInfo.sortField);
      }
    }

    if (sessionStorage.getItem("callsFilter")) {
      sessionStorage.removeItem("callsFilter");
    }
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getCallsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getCallsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getCallsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getCalls(selectedFilter);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("callsColumn")) {
      sessionStorage.removeItem("callsColumn");
    }
    getDataForManageColumns();
  };

  const handleEdit = (recordId, openEdit) => {
    handleAllMenuSideBar("vecCall", {
      id: recordId,
      openEdit,
    });
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "subject") {
      return;
    }

    if (e.target.id === "sub") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getCallsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getCallsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getCallsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getCalls(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Calls",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });
      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }
      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file.",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Calls</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered By: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong key={index}>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  callsPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                ref={selectFilter}
                onClick={callsPermission?.view ? handleFilterSelect : null}
              >
                <div className="crm-custom-select-value">
                  <span title="All Calls" id="select-value">
                    All calls
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.calls.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      data-name={item.name}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search calls"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!callsPermission?.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                callsPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                callsPermission?.view
                  ? () => {
                      openFilterSidebar(true);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                callsPermission?.view ? "date-filter" : "date-filter disabled"
              }
              id="daterange"
              onClick={
                callsPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              ></span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            <div
              id="date-picker"
              className="daterangepicker ltr show-ranges opensright range"
              style={{
                display: showDatePicker ? "block" : "none",
                top: "98.1406px",
                left: "auto",
                right: "0px",
              }}
              ref={datePicker}
            >
              <Range
                functions={{ handleDatePicker, handleCallback }}
                datePickerFilter={datePickerFilter}
                showCalendar={showDatePicker}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              callsPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                callsPermission?.create
                  ? () => handleRSidebar("createCall")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Log Call</span>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                btnDelete && callsPermission?.delete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                btnDelete && callsPermission?.delete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "calls",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? calls?.find((c) =>
                                deleteIdsList.current.includes(c.call_id)
                              )
                            : null,
                      })
                  : null
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>

            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                <span
                  className={`${
                    totalRecords && callsPermission.edit ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && callsPermission.edit
                      ? () =>
                          navigate("/app/calls/bulkupdate?mode=bu&target=calls")
                      : null
                  }
                >
                  Mass Update
                </span>
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && callsPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && callsPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
              </div>
            </button>
          </div>
        </div>
        {callsPermission?.view && calls?.length > 0 && Object.keys(displayName)?.length>0 && (
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total calls: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
        </div>)}
      </div>
      {/* table section */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {calls?.length > 0 && Object.keys(displayName)?.length>0 && callsPermission?.view && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                onClick={
                  callsPermission?.view
                    ? () =>
                        handleRSidebarManageColumns("manageColumns", {
                          applyManageColumn,
                          clearManageColumn,
                          columns: columnInfo,
                          module: "calls",
                          sessModule: "callsColumn",
                        })
                    : null
                }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id="resizeMe">
              <div className="crm-fixed-field">
                {calls?.length > 0 && Object.keys(displayName)?.length>0 && 
                !loading && callsPermission?.view && (
                  <div className="crm-td crm-td-sbjct">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        ref={selectAllCheck}
                        className="crm-checkmark"
                        id="subject"
                        onClick={(e) => handleSelectAll(e)}
                        disabled={calls.length ? false : true}
                      />
                      <label htmlFor="subject"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "call_subject"
                            ? "sort-highlight"
                            : ""
                        }
                        id="sub"
                        onClick={(e) => {
                          handleSort(e, "call_subject", "callSub");
                        }}
                      >
                        {/* Subject */}
                        {displayName["call_subject"]}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "call_subject"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="callSub"
                      onClick={(e) => handleSort(e, "call_subject", "callSub")}
                    ></i>
                  </div>
                )}
              </div>
              <div className="crm-scroll-field">
                {calls?.length > 0 && Object.keys(displayName)?.length>0 &&
                  callsPermission?.view && !loading &&
                  Object.keys(calls[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.calls.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.calls[heading]
                              ? APP_DATA.APIColumnMappings.calls[heading]
                              : heading?.split("_").join(" ")} */}
                          {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              callsPermission?.view && Object.keys(displayName).length>0 && calls.length > 0 ? (
                calls.map((c, i) => (
                  <div className="crm-tb-rw" key={i}>
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-sbjct">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={c.call_id}
                            onChange={(e) => {
                              handleDeleteCheck(e);
                            }}
                          />
                          <label
                            className="acnt-name"
                            title=""
                            htmlFor={c.call_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            callsPermission?.view
                              ? () => handleEdit(c.call_id)
                              : null
                          }
                        >
                          <span className="link">{echo(c.call_subject)}</span>
                        </div>

                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              callsPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              callsPermission?.edit
                                ? () => handleEdit(c.call_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        callsPermission?.view
                          ? () => handleEdit(c.call_id)
                          : null
                      }
                    >
                      {calls?.length > 0 &&
                        Object.keys(calls[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.calls.includes(heading) && (
                              <div data-colpos={i} className={`crm-td `}>
                                <span>
                                  {!APP_DATA.formattingColumns.calls.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(c, heading))
                                    : callsFormatter(
                                        heading,
                                        getObjValue(c, heading)
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!callsPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader mClass={"crm-td crm-td-sbjct"} module="call" />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"calls"}
            />
          )}
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
