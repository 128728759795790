import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const TextEditor = forwardRef((props, ref) => {
  const [dfValue, setDfValue] = useState(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  const placeholder = "Compose your email ...";
  const { quill, quillRef } = useQuill({ formats, modules, placeholder });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const tempEvent = {
          target: {
            id: props.id,
            value: quill.root.innerHTML,
            selectionStart: quill.selection.savedRange.index,
          },
        };
        props.functions.fc(tempEvent);
      });
      quill.on("selection-change", function (range) {
        if (range) {
          const tempEvent = {
            target: {
              id: props.id,
              value: quill.root.innerHTML,
              selectionStart: range.index,
            },
          };
          props.functions.cp(tempEvent);
        }
      });
    }
  }, [quill]);

  useEffect(() => {
    if (quill && props?.defaultValue) {
      quill.root.innerHTML = props?.defaultValue;
    }
    setDfValue(props?.defaultValue);
  }, [props?.defaultValue, dfValue]);

  useImperativeHandle(ref, () => ({
    updateEmailBody(updatedBody) {
      if (quill) {
        quill.root.innerHTML = updatedBody;
        const tempEvent = {
          target: {
            id: props.id,
            value: quill.root.innerHTML,
            selectionStart: quill.selection.savedRange.index,
          },
        };
        props.functions.fc(tempEvent);
      }
    },
    getInnerText() {
      if (quill) {
        return quill.root.innerText;
      }
      return "";
    },
  }));

  return (
    <React.Fragment>
      <div
        ref={quillRef}
        className="email-body"
        style={{ height: 250, backgroundColor: "#fff" }}
      />
    </React.Fragment>
  );
});

export default TextEditor;
