import React from "react";

export default function InputText() {
  return (
    <React.Fragment>
      <div
        className="crm-form-field"
        style={{ display: "none" }}
        id={"inputType_1"}
      >
        <input
          type="text"
          className="crm-form-ctrl floating-input"
          placeholder="single line"
        />
        {/* <label placeholder="field name" className="floating-label">
          field name<sup>*</sup>
        </label> */}
        <div className="crm-action">
          <a className="crm-action-opt crm-action-settings">
            <ul className="crm-dropdown-action ul" id="ul_1">
              <li>
                <span className="crm-action-edit" data-link="edit">
                  Edit Properties
                </span>
              </li>
              <li>
                <span className="crm-action-delete" data-link="remove">
                  Remove
                </span>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
