import React from "react";
import { useLocation } from "react-router-dom";
import { CSS_CLASS_DATA } from "../../app_data/css";
import { useNetworkState } from "@uidotdev/usehooks";

const getMessageByModule = (module) => {
  switch (module) {
    case "leads":
      return "You are yet to create a lead!";
    case "contacts":
      return "You are yet to create a contact!";
    case "accounts":
      return "You are yet to create an account!";
    case "deals":
      return "You are yet to create a deal!";
    case "tasks":
      return "You are yet to create a task!";
    case "calls":
      return "You are yet to create a call!";
    case "meetings":
      return "You are yet to create a meeting!";
    case "templates":
      return "You are yet to create a template!";
    case "users":
      return "You are yet to create a user!";
    case "groups":
      return "You are yet to create a group!";
    case "profiles":
      return " You are yet to create a profile!";
    case "workflows":
      return "You are yet to create a workflow!";
    case "customizations":
      return "You are yet to create a customization!";
    case "web forms":
      return "You are yet to create a web form!";
    case "recyclebin":
      return "No deleted records found!";
    case "campaigns":
      return "You are yet to create a campaign!";
    default:
      return `No ${module} found!`;
  }
};

export default function NoResults(props) {
  const location = useLocation();
  const network = useNetworkState();

  return (
    <React.Fragment>
      {network["online"] ? (
        <div className="no-results-tblstng">
          {!props.permission && (
            <span
              className={
                !props.filter
                  ? CSS_CLASS_DATA.noResultIcon[location.pathname.split("/")[2]]
                  : "crm-no-data-avlblebig"
              }
            ></span>
          )}
          {!props.permission ? (
            <>
              {!props.filter ? (
                <h2>
                  {props?.message
                    ? `${props.message}`
                    : getMessageByModule(
                        location.pathname.split("/")[2].replace("-", " ")
                      )}
                </h2>
              ) : (
                <>
                  <h2>No results found!</h2>
                  <p className="info">Try another search term</p>
                </>
              )}
            </>
          ) : (
            <>
              <span className="crm-no-access"></span>
              <h2>You don't have the required access to view this page!</h2>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="no-results-tblstng">
            <span className="crm-no-internet-connection"></span>
            <h2>Network Error detected, please check your connection.</h2>
            {/* <p>Trying to reconnct now...</p> */}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
