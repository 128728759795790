import React, { useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { APP_DATA } from "../../app_data";
import {
  forEach,
  overrideDefaultEvent,
  removeSessionWebformData,
  utf8_to_b64,
  getUserAccessInfo,
} from "../../utils";
import CustomizationLoader from "../common/customizationLoader";
import WebFormFieldMapper from "./fields/webformFieldMapper";
import {
  getSavedWebformsConfigByIdAPI,
  saveWebformConfigAPI,
  updateWebformAPI,
} from "../../apis/webformAPIs";
import LeadInputs from "./module_inputs/leadInputs";
import ContactInputs from "./module_inputs/contactInputs";
import { envInjector } from "../../env";
import CONSTANTS from "../../app_data/constants";

export default function CreateWebForms() {
  const { handleRSidebar, openToast, getUserInfo } = useOutletContext();

  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);

  const [mode, setMode] = useState(location.state["mode"]);
  const [fromName, setFormName] = useState(location.state["form_name"]);
  // const [module, setModule] = useState(
  //   APP_DATA.modules.find((m) => m.value === location.state["module"]).name
  // );
  const [webformId, setWebformId] = useState(location.state["created_webform"]);

  const dragItem = useRef(null);
  const embedModal = useRef(null);
  const dragOverItem = useRef(null);
  const targetModule = useRef(null);
  const realInputSettings = useRef({});

  // const btnEdit = useRef(null);

  const [savedConfig, setSavedConfig] = useState([]);

  const [loading, setLoading] = useState(true);
  const [internalMode, setInternalMode] = useState(null);
  const [placeholder, setPlaceholder] = useState(["ph_1"]);
  const [previewEnable, setPreviewEnable] = useState(true);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    removeSessionWebformData();
    targetModule.current = location.state["module"];
    setPermission(getUserAccessInfo("Web Forms").permission || {});
  }, []);

  useEffect(() => {
    if (
      location.state["mode"] === "create" &&
      urlSearch.get("referrer") === "create-web-form-init"
    ) {
      setTimeout(() => {
        setLoading(false);
        setPreviewEnable(false);
      }, 2000);
      removeSessionWebformData();
      setInternalMode("create-init");
    } else if (urlSearch.get("referrer") === "configure-webform") {
      let id = location.state["inputId"];
      const type = String(id).split("_")[2];
      id = String(id).split("-")[1];
      id = String(id).split("_")[0] + "_" + String(id).split("_")[1];
      if (id && type) {
        configureAndRender(id, type);
      }
    } else if (urlSearch.get("referrer") === "create-web-form-save") {
      let tempConf = [];
      setLoading(true);
      getSavedWebformsConfigByIdAPI(location.state["created_webform"])
        .then((conf) => {
          if (
            conf.status &&
            conf.data.hasOwnProperty("config_data") &&
            conf.data.config_data.length
          ) {
            const result = conf.data.config_data.reduce((r, a) => {
              r[a.row] = r[a.row] || [];
              r[a.row].push(a);
              return r;
            }, Object.create(null));
            for (const k in result) {
              tempConf.push(result[k]);
            }
            setSavedConfig(tempConf);

            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(true);
              setInternalMode("edit-saved");
            }, 1500);
          } else {
            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(false);
            }, 1500);
          }
        })
        .catch((e) => {
          openToast(APP_DATA.messages.error_message, "error");
          navigate(-1);
        });
    } else if (
      location.state["mode"] === "edit" &&
      urlSearch.get("referrer") === "create-web-form-init"
    ) {
      let tempConf = [];
      getSavedWebformsConfigByIdAPI(location.state["created_webform"])
        .then((conf) => {
          if (
            conf.status &&
            conf.data.hasOwnProperty("config_data") &&
            conf.data.config_data.length
          ) {
            const result = conf.data.config_data.reduce((r, a) => {
              r[a.row] = r[a.row] || [];
              r[a.row].push(a);
              return r;
            }, Object.create(null));
            for (const k in result) {
              tempConf.push(result[k]);
            }
            setSavedConfig(tempConf);

            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(true);
              setInternalMode("edit-saved");
            }, 200);
          } else {
            setTimeout(() => {
              setLoading(false);
              setPreviewEnable(false);
            }, 200);
          }
        })
        .catch((e) => {
          openToast(APP_DATA.messages.error_message, "error");
          navigate(-1);
        });
    }
  }, [location.search]);

  useEffect(() => {
    if (internalMode === "final-save" || internalMode === "edit-saved") {
      document.getElementById("edit_button").style.display = "block";
    }
  }, [internalMode]);

  const configureAndRender = (id, inputType) => {
    let next;
    const inputData = JSON.parse(
      window.sessionStorage.getItem(
        `webformsInput:${location.state["inputId"]}`
      )
    );
    const source = document.getElementById("addedInput-" + id);
    if (!source) {
      removeSessionWebformData();
      return;
    }
    const clone = source.cloneNode(true);
    if (inputType == 1 || inputType == 4 || inputType == 3 || inputType == 7) {
      next = clone.querySelector("input");
    } else if (inputType == 2) {
      next = clone.querySelector("textarea");
    } else if (inputType == 6) {
      if (!inputData) return;
      next = clone.querySelector("select");
      while (next.firstChild) {
        next.remove(next.lastChild);
      }
      inputData.options.map((o) => {
        const op = document.createElement("option");
        op.value = o.value;
        op.innerText = o.name;
        next.appendChild(op);
      });
    } else {
      return;
    }
    const links = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(links, (_, el) => {
      el.id = location.state["inputId"];
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });
    if (inputData) {
      if (inputType != 6) {
        next.disabled = 1;
        next.placeholder = inputData.label;
      }
      const target = document.getElementById(id);
      target.removeChild(document.getElementById("addedInput-" + id));
      target.appendChild(clone);
    }
  };

  const handleAddPlaceholder = (e) => {
    const tP = placeholder;
    const sufix = tP.length + 1;
    const newP = "ph_" + sufix;
    tP.push(newP);

    setPlaceholder((prev) => [...tP]);
  };

  const handleDragStart = (e, inputType) => {
    dragItem.current = String(e.target.id).split("_")[1];
    realInputSettings.current["real_id"] = String(
      e.target.dataset.real_input_id
    );
    realInputSettings.current["real_name"] = String(
      e.target.dataset.real_input_name
    );
  };

  const getEmptyPlaceHolder = () => {
    let hasChild = 1;
    placeholder.map((_, i) => {
      const pos = ["phL", "phR"];
      pos.map((p) => {
        hasChild &= document
          .getElementById(p + "_" + parseInt(i + 1))
          .hasChildNodes();
      });
    });

    return hasChild;
  };

  const updateInternalMode = (mode) => {
    setInternalMode(mode);
  };

  const configureField = (e) => {
    if (e.target.dataset.link === "remove") {
      handleInputRemove(e);
      return;
    }

    if (e.target.dataset.link === "edit") {
      handleRSidebar("webformInputConfig", {
        synthEvent: e,
        remoteConfig: savedConfig,
        removeCurrentInput: handleInputRemove,
        updateInternalMode: updateInternalMode,
      });
    }
  };

  const handleInputRemove = (e) => {
    let id = String(e.target.id).split("-")[1];
    let localConfigId = id;

    const localConfigData = window.sessionStorage.getItem(
      "webformsInput:link-" + localConfigId
    );
    if (localConfigData) {
      window.sessionStorage.removeItem("webformsInput:link-" + localConfigId);
    }
    id = String(id).split("_")[0] + "_" + String(id).split("_")[1];
    const removeTarget = document.getElementById("addedInput-" + id);
    removeTarget.remove();

    const ph = document.getElementById(id);
    ph.classList.add("drop-target");
    return true;
  };

  const handleInputDrop = (e) => {
    overrideDefaultEvent(e);
    if (document.getElementById(dragOverItem.current).hasChildNodes()) {
      openToast("Can not override current input", "error");
      return;
    }

    const source = document.getElementById("inputType_" + dragItem.current);

    const clone = source.cloneNode(true);
    clone.id = "addedInput-" + dragOverItem.current;
    clone.style.display = "block";

    const next = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(next, (_, el) => {
      el.id = "link-" + dragOverItem.current + "_" + dragItem.current;
      el.dataset.real_id = realInputSettings.current["real_id"];
      el.dataset.real_name = realInputSettings.current["real_name"];
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });
    if (
      dragItem.current == 1 ||
      dragItem.current == 4 ||
      dragItem.current == 3 ||
      dragItem.current == 7
    ) {
      clone.querySelector("input").disabled = true;
    } else if (dragItem.current == 2) {
      clone.querySelector("textarea").disabled = true;
    } else if (dragItem.current == 6) {
      clone.querySelector("select").disabled = true;
    }

    const target = document.getElementById(dragOverItem.current);
    target.classList.remove("drop-target");
    target.classList.remove("drop-target-back");
    target.appendChild(clone);

    setTimeout(() => {
      const editLink = document.getElementById(
        "link-" + dragOverItem.current + "_" + dragItem.current
      );
      if (editLink.dataset.link === "edit") {
        editLink.click();
      }
    }, 200);

    //add new placeholder when no empty child
    if (getEmptyPlaceHolder()) {
      handleAddPlaceholder();
    }
  };

  const handleDragging = (e, type) => {
    overrideDefaultEvent(e);
    dragOverItem.current = e.target.id;
    try {
      if (type === "over") {
        document
          .getElementById(dragOverItem.current)
          .classList.add("drop-target-back");
      } else {
        document
          .getElementById(dragOverItem.current)
          .classList.remove("drop-target-back");
      }
    } catch (e) {
      return;
    }
  };

  const dragEnter = (e) => {
    // console.log(e.target.innerHTML);
  };

  const makeEditableAndRender = (base_link, config) => {
    let next;
    let id = base_link;
    const type = String(id).split("_")[2];
    id = String(id).split("_")[1];
    let inputId = String(base_link).split("-")[1];
    const inputType = inputId[inputId.length - 1];
    inputId = inputId.substring(0, inputId.length - 2);
    const source = document.getElementById("inputType_" + type);
    const clone = source.cloneNode(true);
    clone.id = "addedInput-" + inputId;
    clone.style.display = "block";

    if (inputType == 1 || inputType == 4 || inputType == 3 || inputType == 7) {
      next = clone.querySelector("input");
    } else if (inputType == 2) {
      next = clone.querySelector("textarea");
    } else if (inputType == 6) {
      if (!config) return;
      next = clone.querySelector("select");
      while (next.firstChild) {
        next.remove(next.lastChild);
      }
      config.options.map((o) => {
        const op = document.createElement("option");
        op.value = o.value;
        op.innerText = o.name;
        next.appendChild(op);
      });
    } else {
      return;
    }
    const links = clone.querySelectorAll("div>div>a>ul>li>span");
    forEach(links, (_, el) => {
      el.id = base_link;
      el.dataset.real_id = config["real_id"];
      el.dataset.real_name = config["real_name"];
      el.addEventListener("click", (e) => {
        configureField(e);
      });
    });

    setTimeout(() => {
      if (inputType != 6) {
        next.disabled = 1;
        next.placeholder = config.label;
      }
      const target = document.getElementById(inputId);
      target.classList.remove("drop-target");
      target.appendChild(clone);
    }, 50);
  };

  const handleEditInputConfig = () => {
    if (savedConfig.length) {
      setPreviewEnable(false);
      savedConfig.map((conf) => {
        handleAddPlaceholder();
        conf.map((confType1) => {
          makeEditableAndRender(confType1.base_link, confType1);
          window.sessionStorage.setItem(
            `webformsInput:${confType1.base_link}`,
            JSON.stringify(confType1)
          );
        });
      });
      setMode("edit");
      document.getElementById("edit_button").style.display = "none";
    } else {
      openToast("Still loading, Please wait", "error");
      return;
    }
  };

  const changeDetector = (currentConfig = []) => {
    return !(
      JSON.stringify(savedConfig.flat()) === JSON.stringify(currentConfig)
    );
  };

  const getAllLocalFormConfig = () => {
    const configData = [];
    const configKey = /webformsInput:/;
    for (let i = 0; i < window.sessionStorage.length; i++) {
      if (configKey.test(String(window.sessionStorage.key(i)))) {
        const cData = JSON.parse(
          window.sessionStorage.getItem(window.sessionStorage.key(i))
        );
        configData.push(cData);
      }
    }
    return configData;
  };

  const handleCreateCustomField = async (e, caller = "btnSave") => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!targetModule.current) {
          return;
        }

        if (caller === "btnSave") {
          document.getElementById(e.target.id).classList.add("btnLoader");
        }
        const configData = getAllLocalFormConfig();

        let configRes;
        if (mode === "edit") {
          const formattedData = {
            name: fromName,
            module: targetModule.current,
            config_data: configData,
            updated_by: getUserInfo().user_id,
          };
          configRes = await updateWebformAPI(webformId, formattedData);
        } else {
          const formattedData = {
            name: fromName,
            module: targetModule.current,
            config_data: configData,
            created_by: getUserInfo().user_id,
          };
          configRes = await saveWebformConfigAPI(formattedData);
        }

        if (configRes.status) {
          navigate(
            location.pathname +
              "?" +
              createSearchParams({
                referrer: "create-web-form-save",
                tNode: utf8_to_b64(String(Date.now())),
              }).toString(),
            {
              state: {
                mode: location.state["mode"],
                module: targetModule.current,
                created_webform: configRes.data.id,
              },
            }
          );

          if (caller === "btnSave") {
            openToast(APP_DATA.messages.notification, "success");
            document.getElementById(e.target.id).classList.remove("btnLoader");
          }
          setInternalMode("final-save");
          setWebformId(configRes.data.id);
          resolve({ state: true, created_webform: configRes.data.id });
        } else {
          if (caller === "btnSave") {
            document.getElementById(e.target.id).classList.remove("btnLoader");
          }
          openToast(APP_DATA.messages.error_message, "error");
          resolve(false);
        }
      } catch (e) {
        console.log(e);
        reject({ state: false, error: e });
      }
    });
  };

  const handlePreviewForm = async () => {
    const configData = getAllLocalFormConfig();
    if (!configData.length && internalMode === "create-init") {
      openToast("Please add one form input to preview", "error");
      return;
    }
    if (internalMode === "edit-saved") {
      window.open(
        `/crm/webforms?cwfi=${utf8_to_b64(
          JSON.stringify({
            fid: location.state["created_webform"],
            module: location.state["module"],
          })
        )}&tNode=${utf8_to_b64(String(Date.now()))}`,
        "_blank"
      );
      return;
    }

    if (configData.length && internalMode === "config-save") {
      //urlSearch.get("referrer") != "create-web-form-init"
      handleCreateCustomField("e", "preview")
        .then((webform) => {
          setTimeout(() => {
            window.open(
              `/crm/webforms?cwfi=${utf8_to_b64(
                JSON.stringify({
                  fid: webform.created_webform,
                  module: location.state["module"],
                })
              )}&tNode=${utf8_to_b64(
                String(Date.now())
              )}&ci=${CONSTANTS.encryptAppData()}`,
              "_blank"
            );
          }, 1500);
        })
        .catch((e) => {
          if (!e.state) {
            openToast("Something went wrong, please try again", "error");
            navigate({
              pathname: location.pathname,
              search: createSearchParams({
                referrer: "create-web-form-error",
                module: targetModule.current,
                tNode: utf8_to_b64(String(Date.now())),
              }).toString(),
            });
          }
        });
    }
  };

  const handleEmbedModal = (option = "open") => {
    if (option === "close") {
      embedModal.current.style.display = "none";
    } else {
      embedModal.current.style.display = "block";
    }
  };

  const handleEmbedCode = () => {
    const configData = getAllLocalFormConfig();
    const currentTNode = utf8_to_b64(String(Date.now()));
    const appDomain = envInjector.REACT_APP_DOMAIN_URL;

    if (!configData.length && internalMode === "create-init") {
      openToast("Please add one form input to get embed", "error");
      return;
    }

    if (internalMode === "edit-saved") {
      const pLink = utf8_to_b64(
        JSON.stringify({
          fid: location.state["created_webform"],
          module: location.state["module"],
        })
      );
      const accessLink = `${appDomain}/crm/webforms?cwfi=${pLink}&tNode=${currentTNode}&ci=${CONSTANTS.encryptAppData()}`;
      const iframeLink = `<iframe  width="300" height="600" id="webform-${currentTNode}" src="${accessLink}"></iframe>`;
      document.getElementById("shared-link").value = accessLink;
      document.getElementById("embed-area").innerText = iframeLink;
      handleEmbedModal();
      return;
    }

    if (configData.length && internalMode === "config-save") {
      handleCreateCustomField("e", "preview")
        .then((webform) => {
          setTimeout(() => {
            const pLink = utf8_to_b64(
              JSON.stringify({
                fid: webform.created_webform,
                module: location.state["module"],
              })
            );
            const accessLink = `${appDomain}/crm/webforms?cwfi=${pLink}&tNode=${currentTNode}&ci=${CONSTANTS.encryptAppData()}`;
            const iframeLink = `<iframe width="300" height="600" id="webform-${currentTNode}" src="${accessLink}"></iframe>`;
            document.getElementById("embed-area").innerText = iframeLink;
            document.getElementById("shared-link").value = accessLink;

            handleEmbedModal();
          }, 1500);
        })
        .catch((e) => {
          if (!e.state) {
            openToast("Something went wrong, please try again", "error");
            navigate({
              pathname: location.pathname,
              search: createSearchParams({
                referrer: "create-web-form-error",
                module: targetModule.current,
                tNode: utf8_to_b64(String(Date.now())),
              }).toString(),
            });
          }
        });
    }
  };

  const copyCode = (dataId, btnId) => {
    var cp = document.getElementById(dataId);
    cp.select();
    cp.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(cp.value);
    const el = document.getElementById(btnId);
    el.innerText = "Copied";
    setTimeout(() => {
      el.innerText = "Copy";
    }, 10000);
  };

  return (
    <React.Fragment>
      {permission.create ? (
        <>
          <div className="crm-header-wrpr">
            <div className="header_lhs">
              <i
                className="crm-back-btn"
                onClick={() => navigate("/app/web-forms")}
              ></i>
              <h1 className="header_title">
                {fromName}
                <span className="desc">({location.state["module"]})</span>
              </h1>
            </div>
            <div className="header_rhs">
              <button
                style={{ display: "none" }}
                type="button"
                className={
                  permission.edit
                    ? "btn btn-secondary prvw-btn"
                    : "btn btn-secondary prvw-btn disabled"
                }
                id="edit_button"
                onClick={permission.edit ? () => handleEditInputConfig() : null}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-secondary prvw-btn"
                onClick={() => handleEmbedCode()}
              >
                Get Embed Code
              </button>
              <button
                type="button"
                className="btn btn-secondary prvw-btn"
                onClick={() => handlePreviewForm()}
              >
                Preview Form
              </button>
            </div>
          </div>
          <div className="crm-fld-wrpr">
            <div className="add-fields">
              <h3>Fields available for {location.state["module"]} module</h3>

              {String(location.state["module"]).toLocaleLowerCase() ===
              "leads" ? (
                <LeadInputs functions={{ handleDragStart }} />
              ) : (
                <ContactInputs functions={{ handleDragStart }} />
              )}
            </div>
            <div className="fields-info">
              <form className="">
                <div className="crm-form-content">
                  {/* <div className="form-toolbar">
                    <div className="form-editor">
                      <div className="col ftype">
                        <div className="drp-wrp">
                          <div className="drp-value">
                            <span className="">Arial</span>
                            <i className="arw down"></i>
                          </div>
                          <div className="options">
                            <span className="value selected">Arial</span>
                            <span className="value">Serif</span>
                            <span className="value">Times New Roman</span>
                            <span className="value">Georgia</span>
                            <span className="value">Tahoma</span>
                            <span className="value">Verdana</span>
                            <span className="value">Calibri</span>
                          </div>
                        </div>
                      </div>
                      <div className="col fsize">
                        <div className="drp-wrp">
                          <div className="drp-value">
                            <span className="">12</span>
                            <i className="arw down"></i>
                          </div>
                          <div className="options">
                            <span className="value selected">12</span>
                            <span className="value">13</span>
                            <span className="value">14</span>
                            <span className="value">15</span>
                            <span className="value">16</span>
                            <span className="value">17</span>
                          </div>
                        </div>
                      </div>
                      <div className="col fclr">
                        <span className="font-color"></span>
                      </div>
                      <div className="col bgclr">
                        <span className="bg-color"></span>
                      </div>
                      <div className="col algnlbl">
                        <div className="drp-wrp">
                          <div className="drp-value">
                            <span className="align-label"></span>
                            <i className="arw down align"></i>
                          </div>
                          <div className="options">
                            <span className="value selected align-label left"></span>
                            <span className="value align-label right"></span>
                            <span className="value align-label center"></span>
                          </div>
                        </div>
                      </div>
                      <div className="col frmwdth">
                        <span className="form-width"></span>
                        <div className="pform-width hide">
                          <input
                            type="text"
                            className="crm-form-ctrl"
                            autoComplete="off"
                            value="600"
                          />
                          <button type="button" className="btn btn-primary">
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {loading ? (
                    <CustomizationLoader />
                  ) : !previewEnable && placeholder.length ? (
                    placeholder.map((_, index) => (
                      <div className="crm-form-row" key={"dnd" + index}>
                        <div className="crm-form-grp">
                          <div
                            id={"phL_" + parseInt(index + 1)}
                            onDragEnter={(e) => dragEnter(e)}
                            onDrop={(e) => handleInputDrop(e)}
                            onDragOver={(e) => handleDragging(e, "over")}
                            onDragLeave={(e) => handleDragging(e, "leave")}
                            className="crm-form-grp crm-frm-50 drop-target"
                          ></div>
                          <div
                            id={"phR_" + parseInt(index + 1)}
                            onDragEnter={(e) => dragEnter(e)}
                            onDrop={(e) => handleInputDrop(e)}
                            onDragOver={(e) => handleDragging(e, "over")}
                            onDragLeave={(e) => handleDragging(e, "leave")}
                            className="crm-form-grp crm-frm-50 drop-target"
                          ></div>
                        </div>
                      </div>
                    ))
                  ) : (
                    savedConfig.map((conf, i) =>
                      conf.length > 1 ? (
                        <div className="crm-form-row" key={"configs" + i}>
                          <div className="crm-form-grp">
                            {conf.map((confType1, i) => (
                              <div className="crm-form-grp crm-frm-50">
                                <WebFormFieldMapper
                                  type={confType1.type}
                                  inputProperties={confType1}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="crm-form-row" key={"configsw" + i}>
                          <div className="crm-form-grp">
                            {conf.map((confType2, i) => (
                              <div className="crm-form-grp crm-frm-50">
                                <WebFormFieldMapper
                                  type={confType2.type}
                                  inputProperties={confType2}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>

                <div className="crm-form-foot">
                  <div className="crm-btn">
                    <div className="crm-btn-left">
                      <button
                        id="btnSave"
                        type="button"
                        className="btn btn-primary lead-save"
                        data-action="save"
                        onClick={(e) => handleCreateCustomField(e)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary lead-savesec"
                        onClick={() => navigate("/app/web-forms")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Copy embed code modal */}
          <React.Fragment>
            <div
              className="overlay embed-code"
              ref={embedModal}
              style={{ display: "none" }}
            >
              <div className="crm-pop-vt">
                <div className="crm-pop-hztl">
                  <div className="crm-popup crm-popup-md rhs-popvt">
                    <form>
                      <div className="crm-pop-header">
                        <h2>Embed Options</h2>
                        <a
                          className="close crm-close"
                          onClick={() => handleEmbedModal("close")}
                        ></a>
                      </div>
                      <div className="crm-popup-content">
                        <p className="code-info">
                          Please use the below code snippets to capture the
                          Leads from your websites.
                        </p>
                        <div className="crm-form-row">
                          <div className="src-code">
                            <div className="hdr">
                              <p className="title">
                                Embed in your website using iframe
                              </p>
                              <div className="cpy-wrpr">
                                <i className="cpy-icn"></i>
                                <span
                                  className="txt"
                                  id="btnCopyIframe"
                                  onClick={() =>
                                    copyCode("embed-area", "btnCopyIframe")
                                  }
                                >
                                  Copy
                                </span>
                              </div>
                            </div>
                            <div className="body">
                              <textarea
                                readOnly={true}
                                id="embed-area"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <p className="link-hdr">Share Link:</p>
                        <div className="shrlnk-input">
                          <div className="shrlnk-wrpr">
                            <input
                              type="text"
                              className="link"
                              readOnly={true}
                              id="shared-link"
                            />

                            <span className="copied-link">Link copied</span>
                          </div>
                          <span
                            className="copy-btn"
                            id="btnCopyLink"
                            onClick={() =>
                              copyCode("shared-link", "btnCopyLink")
                            }
                          >
                            Copy
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </>
      ) : (
        <div className="no-results-tblstng">
          <h2>You don't have the required access to create</h2>
        </div>
      )}
    </React.Fragment>
  );
}
