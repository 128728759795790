import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getAccountsLimitNoffset } from "../apis/accountAPIs";
import {
  getDataForCustomFilters,
  getDataForDateFilters,
} from "../apis/customFilterAPI";
import { getModuleDataTypesAPI } from "../apis/workflowAPIs";
import NoResults from "../components/common/noResults";
import {
  echo,
  getDate,
  forEach,
  getSortBy,
  getObjValue,
  debounceOptFunc,
  organiseColumns,
  getUserAccessInfo,
  getManagedColumns,
  accountsFormatter,
  overrideDefaultEvent,
  generateFilterPayload,
} from "../utils";
import { Tooltip } from "react-tooltip";
import Range from "../components/datepicker/range";
import CustomFilter from "../components/filters/customFilter";
import { useOutside } from "../customHooks/useOutside";
import {
  searchAPI,
  searchThroughAPIs,
  getExportFileDateFilter,
  getExportFileFixedFilter,
  downloadCSVExportedFiles,
  getExportFileCustomFilter,
  getExportFileSearchFilter,
} from "../apis";
import createResizer from "../utils/resizerHandler";
import { APP_DATA } from "../app_data";
import NewPagination from "../components/common/newPagination";
import DataTableLoader from "../components/common/dataTableLoader";
import DownloadViewer from "../components/common/downloadViewer";

const MODULE_NAME = "accounts";

export default function AccountsView() {
  const location = useLocation();
  const {
    openModal,
    openToast,
    closeModal,
    handleRSidebar,
    handleAllMenuSideBar,
    handleRSidebarManageColumns,
  } = useOutletContext();
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(location.search);

  const searchRef = useRef(null);
  const datePicker = useRef(null);
  const pagination = useRef(null);
  const deleteIdsList = useRef([]);
  const dropdownMenu = useRef(null);
  const customFilter = useRef(null);
  const selectFilter = useRef(null);
  const selectAllCheck = useRef(null);
  const exportDownloadViewer = useRef(null);

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnDelete, setBtnDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All Accounts");
  const [showDatePicker, openDatePicker] = useState(false);
  const [datePickerFilter, setDatePickerFilter] = useState("");
  const [showCustomFilter, openFilterSidebar] = useState(false);
  const [customFilterDetails, setCustomFilter] = useState({
    filter: "",
    searchQuery: "",
    appliedFilters: [],
    preservedFilter: [],
  });
  const [customRangeDate, setCustomRangeDate] = useState({ from: "", to: "" });
  const [accountsPermission, setAccountsPermission] = useState({});
  const [sortInfo, setSortInfo] = useState({
    sortBy: "",
    sortField: "",
    sortKey: "",
  });
  const [filter, setFilter] = useState(false);
  const [columnInfo, setColumnInfo] = useState([]);
  const [exportFile, setExportFile] = useState(null);
  const [dateFilterInfo, setDateFilterInfo] = useState("");
  const [pageData, setPageData] = useState({ limit: 10, offset: 0 });
  const [displayName,setDisplayName] = useState({})

  useEffect(() => {
    const accountsFilter = JSON.parse(sessionStorage.getItem("accountsFilter"));
    if (
      accountsFilter?.length > 0 &&
      !APP_DATA.operations.includes(urlSearch.get("referrer"))
    ) {
      setFilter(true);
      const appliedFilters = generateFilterPayload(accountsFilter);
      console.log(appliedFilters, "aa");
      setCustomFilter((prev) => ({
        ...prev,
        filter: "Custom",
        appliedFilters,
        preservedFilter: accountsFilter,
      }));
      getAccountsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else {
      //clearing filters after create, edit ,clone or delete
      //as sometimes no records will create confusion to users
      // clearFixedFilter();
      // clearDateFilter();
      // clearAll();
      // searchRef.current.value = "";
      // clearSort();

      // getAccounts();
      handlePaginatedFetchData(pageData);
    }
    deleteIdsList.current.map((id) => {
      if (document.getElementById(id)) {
        document.getElementById(id).checked = false;
      }
    });
    deleteIdsList.current = [];
    if (APP_DATA.operations.includes(urlSearch.get("referrer"))) {
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      if (!deleteIdsList.current.length > 0) {
        setBtnDelete(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const accounts = getUserAccessInfo("Accounts");
    if (accounts?.permission) {
      setAccountsPermission(accounts.permission);
    } else {
      setAccountsPermission({});
    }
    getModuleDataTypesAPI("accounts")
      .then((res) => {
        setColumnInfo(res.data);
        const displayName = res.data?.reduce((acc, curr) => {
          acc[curr.field] = curr.display_name;
          return acc;
        }, {});
        setDisplayName(displayName); 
      })
      .catch((err) => {
        setColumnInfo([]);
        setDisplayName({}) 
      });
  }, []);

  useEffect(() => {
    createResizer();
  }, [accounts, location.search,loading]);

  const getAccounts = async (selectedFilter, sortBy, sortField) => {
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    const accounts = await getAccountsLimitNoffset(
      0,
      pageData.limit,
      true,
      selectedFilter,
      selectedFilter === "My Accounts" ? getUserAccessInfo()?.user_id : "",
      sortBy,
      sortField
    );
    if (accounts.status) {
      setAccounts(
        organiseColumns(
          accounts.data.accounts,
          getManagedColumns("accountsColumn")
        )
      );
      if (accounts.data.hasOwnProperty("total_count")) {
        setTotalRecords(accounts.data.total_count);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };

  const handleDeleteCheck = (e) => {
    if (e.target.checked) {
      if (!deleteIdsList.current.includes(parseInt(e.target.id))) {
        deleteIdsList.current.push(parseInt(e.target.id));
      }
      if (deleteIdsList.current?.length === accounts?.length) {
        selectAllCheck.current.checked = true;
      }
    } else {
      const i = deleteIdsList.current.indexOf(parseInt(e.target.id));
      if (i > -1) {
        deleteIdsList.current.splice(i, 1);
      }
      if (deleteIdsList.current?.length !== accounts?.length) {
        selectAllCheck.current.checked = false;
      }
    }
    if (deleteIdsList.current.length > 0) {
      setBtnDelete(true);
    } else {
      setBtnDelete(false);
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
    }
  };

  const handleSearch = async (qstring) => {
    //reset
    clearAll();
    unCheckRecords();
    clearDateFilter();
    clearFixedFilter();
    clearSort();

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    setLoading(true);
    //setting search type and search query
    setCustomFilter((prev) => ({
      ...prev,
      filter: "search",
      searchQuery: qstring,
    }));

    if (qstring.length) {
      setFilter(true);
      //need to search with page limit and offset
      const searched = await searchThroughAPIs(
        "accounts",
        qstring,
        0,
        pageData.limit
      );
      if (searched.status) {
        setAccounts(
          organiseColumns(
            searched.data.accounts,
            getManagedColumns("accountsColumn")
          )
        );
        setTotalRecords(searched.data.total_count);
      } else {
        setAccounts([]);
        setTotalRecords(0);
      }
      setLoading(false);
    } else {
      getAccounts();
      setFilter(false);
    }
  };
  const handleDebouncedSearch = debounceOptFunc(handleSearch);

  const handleSelectAll = (e) => {
    const checkBoxes = document.querySelectorAll(".crm-checkmark.ids");

    forEach(checkBoxes, function (index, el) {
      if (e.target.checked) {
        let e = {
          target: {
            checked: true,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = true;
      } else {
        let e = {
          target: {
            checked: false,
            id: el.id,
          },
        };
        handleDeleteCheck(e);
        el.checked = false;
      }
    });
  };

  const handlePaginatedFetchData = async (pageData) => {
    const tempE = {
      target: {
        checked: false,
      },
    };
    //to uncheck select all and disable delete while going to next page
    setLoading(true);
    unCheckRecords();

    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const modules = location.pathname.split("/")[2] || "";
      const accounts = await getDataForCustomFilters(
        modules,
        false,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (accounts.status) {
        setAccounts(
          organiseColumns(accounts.data, getManagedColumns("accountsColumn"))
        );
      } else {
        setAccounts([]);
      }
    } else if (filter === "search") {
      //pagination with search
      if (searchQuery) {
        setFilter(true);
        setLoading(true);
        const accounts = await searchAPI(
          "accounts",
          searchQuery,
          pageData.offset,
          pageData.limit
        );

        if (accounts.status) {
          setTotalRecords(accounts.data.total_count);
          setAccounts(
            organiseColumns(
              accounts.data.accounts,
              getManagedColumns("accountsColumn")
            )
          );
        } else {
          setAccounts([]);
          setTotalRecords(0);
        }
        setLoading(false);
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getAccountsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getAccountsForDateFilter(
          datePickerFilter,
          false,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      const accounts = await getAccountsLimitNoffset(
        pageData.offset,
        pageData.limit,
        true,
        selectedFilter,
        selectedFilter === "My Accounts" ? getUserAccessInfo()?.user_id : "",
        sortInfo.sortBy,
        sortInfo.sortField
      );
      if (accounts.status) {
        setTotalRecords(accounts.data.total_count);
        setAccounts(
          organiseColumns(
            accounts.data.accounts,
            getManagedColumns("accountsColumn")
          )
        );
      } else {
        setAccounts([]);
        setTotalRecords(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setPageData(pageData);
    }, 50);
    handleSelectAll(tempE);
  };

  const getAccountsForCustomFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField
  ) => {
    setFilter(true);
    setLoading(true);

    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const accounts = await getDataForCustomFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField
      );
      if (accounts.status) {
        if (accounts?.data?.accounts?.length > 0) {
          setAccounts(
            organiseColumns(
              accounts.data.accounts,
              getManagedColumns("accountsColumn")
            )
          );
        } else {
          setAccounts([]);
        }
        if (accounts.data?.hasOwnProperty("total_count")) {
          setTotalRecords(accounts.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setAccounts([]);
      setLoading(false);
      openToast(APP_DATA.messages.error_message, "error");
    }
  };

  const getAccountsForDateFilter = async (
    filter,
    count = true,
    offset = 0,
    limit = 10,
    sortBy,
    sortField,
    from,
    to
  ) => {
    setFilter(true);
    setLoading(true);
    //whenever doing any filters / search
    //we're resetting the page number default to 1
    pagination?.current?.resetDefaultPage();

    const modules = location.pathname.split("/")[2] || "";
    try {
      const accounts = await getDataForDateFilters(
        modules,
        count,
        offset,
        limit,
        filter,
        sortBy,
        sortField,
        from,
        to
      );
      if (accounts.status) {
        if (accounts?.data?.accounts?.length > 0) {
          setAccounts(
            organiseColumns(
              accounts.data.accounts,
              getManagedColumns("accountsColumn")
            )
          );
        } else {
          setAccounts([]);
        }
        if (accounts.data?.hasOwnProperty("total_count")) {
          setTotalRecords(accounts.data.total_count);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAccounts([]);
      console.log("err", err);
    }
  };

  const handleFilterSelect = (e) => {
    selectFilter.current.classList.add("open");
    const value = e.target.getAttribute("data-value");
    const name = e.target.getAttribute("data-name");
    if (value) {
      setSelectedFilter(value);
      getAccounts(value, sortInfo.sortBy, sortInfo.sortField);
      document.getElementById("select-value").innerText = name;
      selectFilter.current.classList.remove("open");
      searchRef.current.value = "";
      clearAll();
      clearDateFilter();
      unCheckRecords();
      setFilter(true);
    }
  };

  const handleDatePicker = (value) => {
    clearFixedFilter();
    clearAll();
    searchRef.current.value = "";
    unCheckRecords();
    setDatePickerFilter(value);
    const dateInfo =
      [...APP_DATA.dateFilter].find((d) => d.value === value) || {};
    setDateFilterInfo(dateInfo);
    if (value !== "Custom_Range") {
      openDatePicker(false);
      getAccountsForDateFilter(
        value,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    }
  };

  const closeFilterSideBar = () => {
    openFilterSidebar(false);
  };

  const handleCallback = (start, end) => {
    const from = getDate(start._d);
    const to = getDate(end._d);
    setCustomRangeDate({ from, to });
    getAccountsForDateFilter(
      datePickerFilter,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField,
      from,
      to
    );
  };

  const clearDateFilter = (accounts) => {
    if (datePickerFilter) {
      setFilter(false);
      setDatePickerFilter("");
      setCustomRangeDate({ from: "", to: "" });
    }
    if (accounts) {
      getAccounts();
    }
  };

  const clearFixedFilter = () => {
    if (selectedFilter) {
      setFilter(false);
      setSelectedFilter("All Accounts");
      document.getElementById("select-value").innerText = "All accounts";
    }
  };

  const applyCustomFilter = ({ preservedFilter, appliedFilters }) => {
    openFilterSidebar(false);

    if (selectedFilter) {
      clearFixedFilter();
    }
    clearDateFilter();
    searchRef.current.value = "";
    unCheckRecords();

    setCustomFilter((prev) => ({
      ...prev,
      filter: "Custom",
      preservedFilter,
      appliedFilters,
    }));

    getAccountsForCustomFilter(
      appliedFilters,
      true,
      0,
      pageData.limit,
      sortInfo.sortBy,
      sortInfo.sortField
    );
  };

  useOutside(selectFilter, () => {
    selectFilter.current.classList.remove("open");
  });

  useOutside(datePicker, () => {
    openDatePicker(false);
  });

  const clearAll = (accounts) => {
    if (customFilterDetails?.filter) {
      setCustomFilter((prev) => ({
        ...prev,
        filter: "",
        preservedFilter: [],
        appliedFilters: [],
      }));
      setFilter(false);
      if (accounts) {
        getAccounts("", sortInfo.sortBy, sortInfo.sortField);
      }
      unCheckRecords();
    }
    if (sessionStorage.getItem("accountsFilter")) {
      sessionStorage.removeItem("accountsFilter");
    }
  };

  const getDataForManageColumns = async () => {
    const { filter, appliedFilters } = customFilterDetails;
    if (filter === "Custom") {
      getAccountsForCustomFilter(
        appliedFilters,
        true,
        0,
        pageData.limit,
        sortInfo.sortBy,
        sortInfo.sortField
      );
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        getAccountsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
      } else {
        getAccountsForDateFilter(
          datePickerFilter,
          true,
          0,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
      }
    } else {
      getAccounts(selectedFilter, sortInfo.sortBy, sortInfo.sortField);
    }
    unCheckRecords();
  };

  const applyManageColumn = () => {
    getDataForManageColumns();
  };

  const clearManageColumn = () => {
    if (sessionStorage.getItem("accountsColumn")) {
      sessionStorage.removeItem("accountsColumn");
    }
    getDataForManageColumns();
  };

  const handleEdit = (recordId, openEdit) => {
    handleAllMenuSideBar("vecAccount", {
      id: recordId,
      openEdit,
    });
  };

  const clearSort = () => {
    setSortInfo({ sortBy: "", sortField: "", sortKey: "" });
  };

  const handleSort = (e, field, id) => {
    if (e.target.htmlFor === "accounts") {
      return;
    }

    if (e.target.id === "accnt") {
      overrideDefaultEvent(e);
    }

    const element = document.getElementById(id);

    const sortBy = getSortBy(element);

    const fieldKey = columnInfo.find((c) => c.field === field);
    if (fieldKey) {
      setSortInfo({ sortBy, sortField: fieldKey.display_name, sortKey: field });
      const { filter, appliedFilters } = customFilterDetails;
      if (filter === "Custom") {
        getAccountsForCustomFilter(
          appliedFilters,
          true,
          0,
          pageData.limit,
          sortBy,
          fieldKey.display_name
        );
      } else if (datePickerFilter) {
        if (datePickerFilter !== "Custom_Range") {
          getAccountsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name
          );
        } else {
          getAccountsForDateFilter(
            datePickerFilter,
            true,
            0,
            pageData.limit,
            sortBy,
            fieldKey.display_name,
            customRangeDate?.from,
            customRangeDate?.to
          );
        }
      } else {
        getAccounts(selectedFilter, sortBy, fieldKey.display_name);
      }
      unCheckRecords();
    }
  };

  //export CSV APIs and settings
  const handleExportableFetchData = async (pageData) => {
    const { filter, appliedFilters, searchQuery } = customFilterDetails;
    if (filter === "Custom") {
      const resData = await getExportFileCustomFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        appliedFilters,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    } else if (filter === "search") {
      if (searchQuery) {
        const resData = await getExportFileSearchFilter(
          MODULE_NAME,
          searchQuery,
          pageData.offset,
          pageData.limit,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      }
    } else if (datePickerFilter) {
      if (datePickerFilter !== "Custom_Range") {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField
        );
        return resData;
      } else {
        const resData = getExportFileDateFilter(
          MODULE_NAME,
          pageData.offset,
          pageData.limit,
          datePickerFilter,
          sortInfo.sortBy,
          sortInfo.sortField,
          customRangeDate?.from,
          customRangeDate?.to
        );
        return resData;
      }
    } else {
      //default get data
      const resData = await getExportFileFixedFilter(
        MODULE_NAME,
        pageData.offset,
        pageData.limit,
        selectedFilter,
        getUserAccessInfo()?.user_id,
        sortInfo.sortBy,
        sortInfo.sortField
      );
      return resData;
    }
  };

  const handleExportableCustomFetchData = async (pageData) => {
    //default get data
    const resData = await getExportFileFixedFilter(
      MODULE_NAME,
      pageData.offset,
      pageData.limit,
      "All Accounts",
      getUserAccessInfo()?.user_id,
      sortInfo.sortBy,
      sortInfo.sortField
    );
    return resData;
  };

  const exportCSV = async () => {
    openModal("exportCsvModal", {
      ...exportCSVFunctions,
      moduleName: MODULE_NAME,
    });
  };

  const exportCSVFunctions = {
    exportInit: async (xParam, exportType) => {
      let exported;
      exportDownloadViewer.current.fetching({
        title: "Fetching data to export.",
        moduleName: MODULE_NAME,
      });
      if (exportType === "filter") {
        exported = await handleExportableFetchData(xParam);
      } else {
        exported = await handleExportableCustomFetchData(xParam);
      }
      if (exported.status && exported.data["file_name"]) {
        closeModal();
        setExportFile(exported.data["file_name"]);
        downloadExportedFile(exported.data["file_name"]);
      } else {
        closeModal();
        exportDownloadViewer.current.closeViwer();
        openToast(APP_DATA.messages.error_message, "error");
      }
    },
  };

  const downloadExportedFile = async (exportFile) => {
    exportDownloadViewer.current.preparing({
      title: "Preparing file to download.",
      moduleName: MODULE_NAME,
    });
    const csvFile = await downloadCSVExportedFiles(exportFile);
    if (csvFile.constructor.name === "Blob") {
      exportDownloadViewer.current.downloaded({
        title: "Your CSV file is ready for download. Save the file..",
        moduleName: MODULE_NAME,
      });
      const el = document.createElement("a");
      el.href = URL.createObjectURL(csvFile);
      el.download = exportFile;
      el.target = "_blank";
      el.click();
      setExportFile(null);
    } else {
      return;
    }
  };

  const unCheckRecords = () => {
    if (deleteIdsList.current?.length > 0) {
      deleteIdsList.current.map((id) => {
        if (document.getElementById(id)) {
          document.getElementById(id).checked = false;
        }
      });
      deleteIdsList.current = [];
      if (selectAllCheck?.current) {
        selectAllCheck.current.checked = 0;
      }
      setBtnDelete(false);
    }
  };

  return (
    <React.Fragment>
      <div className="crm-header-wrpr">
        <div className="header_lhs">
          <h1 className="header_title">Accounts</h1>
        </div>
        <div className="header_rhs">
          {customFilterDetails.preservedFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {customFilterDetails.preservedFilter?.length > 0 &&
                  customFilterDetails.preservedFilter
                    .slice(0, 3)
                    .map((f, index, arr) => (
                      <strong>
                        {index <=1 && f.fieldLabel}
                        {arr.length > 2 && index === 0 && <span>, </span>}
                        {arr.length === 2 && index === 0 && <span> and </span>}
                      </strong>
                    ))}
                {customFilterDetails.preservedFilter?.length > 2 &&    
                <span
                  onClick={() => {
                    openFilterSidebar(true);
                  }}
                >
                  {" "}
                  and <a>more</a>
                </span>}
              </div>
              <div className="clr-fld" onClick={() => clearAll(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          {datePickerFilter?.length > 0 && (
            <div className="fields-fltrinfo">
              <div className="fld-info">
                <span>Filtered by: </span>
                {echo(dateFilterInfo?.name)}
              </div>
              <div className="clr-fld" onClick={() => clearDateFilter(true)}>
                <span>
                  <a>Clear all</a>
                </span>
              </div>
            </div>
          )}
          <div className="drpdwn-src-row">
            <div className="crm-custom-select-wrap">
              <div
                className={
                  accountsPermission?.view
                    ? "custom-select"
                    : "custom-select disabled"
                }
                onClick={accountsPermission?.view ? handleFilterSelect : null}
                ref={selectFilter}
              >
                <div className="crm-custom-select-value">
                  <span title="All Accounts" id="select-value">
                    All accounts
                  </span>
                  <div className="arrow"></div>
                </div>
                <div className="crm-custom-opblock">
                  {APP_DATA.fixedFilters.accounts.map((item, i) => (
                    <span
                      className={`crm-custom-option ${
                        selectedFilter === item.value ? "selected" : ""
                      }`}
                      data-value={item.value}
                      data-name={item.name}
                      key={i}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="search_bar">
              <input
                type="text"
                placeholder="Search accounts"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                ref={searchRef}
                disabled={!accountsPermission?.view}
              />
              <span className="btn-ico-srch"></span>
            </div>
            <div
              className={
                accountsPermission?.view
                  ? "fields-filter"
                  : "fields-filter disabled"
              }
              id=""
              onClick={
                accountsPermission?.view
                  ? () => {
                      openFilterSidebar(!showCustomFilter);
                    }
                  : null
              }
            >
              <span
                id="c-filter"
                className="crm-action-field-filter cm-tooltip"
                data-original-title="Filter by Fields"
                data-placement="bottom"
              ></span>
              <Tooltip
                place={"left"}
                anchorId={"c-filter"}
                content="Custom Filters"
              />
            </div>
            <div
              className={
                accountsPermission?.view
                  ? "date-filter"
                  : "date-filter disabled"
              }
              id="daterange"
              onClick={
                accountsPermission?.view
                  ? () => {
                      openDatePicker(true);
                      openFilterSidebar(false);
                    }
                  : null
              }
            >
              <span
                className="crm-action-date-filter cm-tooltip"
                data-original-title="Days Filter"
                data-placement="bottom"
                id="t-daterange"
              ></span>
              <Tooltip
                anchorId={"t-daterange"}
                place={"left"}
                content="Date Filter"
              />
            </div>
            {
              <div
                id="date-picker"
                className="daterangepicker ltr show-ranges opensright range"
                style={{
                  display: showDatePicker ? "block" : "none",
                  top: "98.1406px",
                  left: "auto",
                  right: "0px",
                }}
                ref={datePicker}
              >
                <Range
                  functions={{ handleDatePicker, handleCallback }}
                  datePickerFilter={datePickerFilter}
                  showCalendar={showDatePicker}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <div className="crm-top-menu">
        <div className="menu-lft">
          <div
            className={
              accountsPermission?.create
                ? "hdr-btn-drpdwn mr15"
                : "hdr-btn-drpdwn mr15 disabled"
            }
          >
            <div
              className="lhs-prt"
              onClick={
                accountsPermission?.create
                  ? () => handleRSidebar("createAccount")
                  : null
              }
            >
              <i className="plus-icn"></i>
              <span>Create Account</span>
            </div>
            <div
              className="rhs-prt"
              onMouseLeave={() => (dropdownMenu.current.style.display = "none")}
            >
              <i
                className="drpdwn-icn"
                onMouseEnter={() =>
                  (dropdownMenu.current.style.display = "block")
                }
              ></i>

              <div className="drpdwn-values" ref={dropdownMenu}>
                <span
                  className="import-lead"
                  onClick={
                    accountsPermission?.create
                      ? () => handleRSidebar("importAccount")
                      : null
                  }
                >
                  Import Accounts
                </span>
              </div>
            </div>
          </div>
          <div className="crm-btngrp-wrpr">
            <button
              className={
                accountsPermission?.delete && btnDelete
                  ? "crm-btn-grp"
                  : "crm-btn-grp disabled"
              }
              onClick={
                accountsPermission?.delete && btnDelete
                  ? () =>
                      openModal("deleteModal", {
                        data: deleteIdsList.current,
                        redirect: "accounts",
                        msgInfo:
                          deleteIdsList.current.length === 1
                            ? accounts?.find((a) =>
                                deleteIdsList.current.includes(a.account_id)
                              )
                            : null,
                      })
                  : undefined
              }
            >
              <i className="delete-icn"></i>
              <span>Delete</span>
            </button>

            {/* new action button */}
            <button className="crm-btn-grp actions">
              <span>Actions</span>
              <i className="dropdown-icon"></i>
              <div className="drpdwn-values">
                <span
                  className={`${
                    totalRecords && accountsPermission.edit ? "" : "disabled"
                  }`}
                  onClick={
                    totalRecords && accountsPermission.edit
                      ? () =>
                          navigate(
                            "/app/accounts/bulkupdate?mode=bu&target=accounts"
                          )
                      : null
                  }
                >
                  Mass Update
                </span>
                {!exportFile ? (
                  <span
                    className={`${
                      totalRecords && accountsPermission.view ? "" : "disabled"
                    }`}
                    onClick={
                      totalRecords && accountsPermission.view
                        ? () => exportCSV()
                        : null
                    }
                  >
                    Export as CSV
                  </span>
                ) : (
                  <span>Loading...</span>
                )}
              </div>
            </button>
          </div>
        </div>
        {accountsPermission?.view && accounts?.length > 0 && Object.keys(displayName).length>0 && (
        <div className="menu-rgt">
          <div className="total_count">
            <div className="total_title">Total accounts: </div>
            <div className="total_number">
              <strong>{totalRecords}</strong>
            </div>
          </div>
            <NewPagination
              totalRecords={totalRecords}
              functions={{ handlePaginatedFetchData: handlePaginatedFetchData }}
              ref={pagination}
            />
        </div>)}
      </div>

      {/* table section */}
      <div className={`tbl-wrpr ${showCustomFilter ? "filter" : ""}`}>
        {accounts?.length > 0 && accountsPermission?.view && Object.keys(displayName).length>0 && (
          <>
            <div className="crm-action">
              <a
                id="m-managecolumn"
                className="crm-action-filter"
                onClick={
                  accountsPermission?.view
                    ? () =>
                        handleRSidebarManageColumns("manageColumns", {
                          applyManageColumn,
                          clearManageColumn,
                          columns: columnInfo,
                          module: "accounts",
                          sessModule: "accountsColumn",
                        })
                    : null
                }
              ></a>
            </div>
            <Tooltip
              place={"left"}
              anchorId={"m-managecolumn"}
              content="Manage columns"
            />
          </>
        )}
        <div
          className={`crm-table cst-mrgn ${showCustomFilter ? "filter" : ""} ${
            !totalRecords ? "noscrl" : ""
          } mtbl`}
        >
          <div className="crm-tb-hed">
            <div className="crm-tb-rw" id={"resizeMe"}>
              <div className="crm-fixed-field">
                {accounts?.length > 0 && accountsPermission?.view && Object.keys(displayName).length>0 && !loading && (
                  <div className="crm-td crm-td-acntname">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        ref={selectAllCheck}
                        id="accounts"
                        className="crm-checkmark"
                        onChange={(e) => handleSelectAll(e)}
                        disabled={accounts.length ? false : true}
                      />
                      <label htmlFor="accounts"></label>
                    </div>
                    <div className="name">
                      <span
                        className={
                          sortInfo.sortKey === "account_name"
                            ? "sort-highlight"
                            : ""
                        }
                        id="accnt"
                        onClick={(e) => {
                          handleSort(e, "account_name", "accntName");
                        }}
                      >
                        {/* Account name */}
                        {echo(displayName["account_name"])}
                      </span>
                    </div>
                    <i
                      className={
                        sortInfo.sortKey === "account_name"
                          ? sortInfo.sortBy === "desc"
                            ? "sort sort-down"
                            : "sort sort-up"
                          : ""
                      }
                      id="accntName"
                      onClick={(e) =>
                        handleSort(e, "account_name", "accntName")
                      }
                    ></i>
                  </div>
                )}
              </div>
              <div className="crm-scroll-field">
                {accounts?.length > 0 &&
                  accountsPermission?.view && Object.keys(displayName).length>0 && !loading &&
                  Object.keys(accounts[0]).map(
                    (heading, index) =>
                      !APP_DATA.hiddenColumns.accounts.includes(heading) && (
                        <div
                          id={`col-${index}`}
                          className={`crm-td r  ${
                            sortInfo.sortKey === heading
                              ? sortInfo.sortBy === "desc"
                                ? "crm-sort sort-active"
                                : "crm-sort sort-active-up"
                              : ""
                          }`}
                        >
                          <span
                            onClick={(e) =>
                              handleSort(e, heading, `col-${index}`)
                            }
                          >
                            {/* {APP_DATA.APIColumnMappings.accounts[heading]
                              ? APP_DATA.APIColumnMappings.accounts[heading]
                              : heading?.split("_").join(" ")} */}
                              {echo(displayName[heading])}
                          </span>
                        </div>
                      )
                  )}
                {/* {accounts?.length > 0 && accountsPermission?.view && (
                  <div className="crm-td r crm-action">
                    <a
                      id="m-managecolumn"
                      className="crm-action-filter"
                      onClick={
                        accountsPermission?.view
                          ? () =>
                              handleRSidebarManageColumns("manageColumns", {
                                applyManageColumn,
                                clearManageColumn,
                                columns: columnInfo,
                                module: "accounts",
                                sessModule: "accountsColumn",
                              })
                          : null
                      }
                    ></a>
                    <Tooltip
                      place={"left"}
                      anchorId={"m-managecolumn"}
                      content="Manage columns"
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="crm-tb-bdy">
            {!loading ? (
              accountsPermission?.view && Object.keys(displayName).length>0 && accounts?.length > 0 ? (
                accounts.map((a, i) => (
                  <div className="crm-tb-rw" key={i}>
                    <div className="crm-fixed-field">
                      <div className="crm-td crm-td-acntname">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            className="crm-checkmark ids"
                            id={a.account_id}
                            onChange={(e) => handleDeleteCheck(e)}
                          />
                          <label
                            className="acnt-name"
                            title=""
                            htmlFor={a.account_id}
                          ></label>
                        </div>
                        <div
                          className="name"
                          onClick={
                            accountsPermission?.view
                              ? () => handleEdit(a.account_id)
                              : null
                          }
                        >
                          <span className="link">{echo(a.account_name)}</span>
                        </div>

                        <div className="action-rw">
                          <i
                            id={"edit-title" + i}
                            className={
                              accountsPermission?.edit
                                ? "crm-edit"
                                : "crm-edit disabled"
                            }
                            onClick={
                              accountsPermission?.edit
                                ? () => handleEdit(a.account_id, "edit")
                                : null
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="crm-scroll-field"
                      onClick={
                        accountsPermission?.view
                          ? () => handleEdit(a.account_id)
                          : null
                      }
                    >
                      {accounts?.length > 0 &&
                        Object.keys(accounts[0])?.map(
                          (heading, i) =>
                            !APP_DATA.hiddenColumns.accounts.includes(
                              heading
                            ) && (
                              <div data-colpos={i} className={`crm-td `}>
                                <span>
                                  {!APP_DATA.formattingColumns.accounts.includes(
                                    heading
                                  )
                                    ? echo(getObjValue(a, heading))
                                    : accountsFormatter(
                                        heading,
                                        getObjValue(a, heading)
                                      )}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                ))
              ) : (
                <NoResults
                  permission={!accountsPermission?.view}
                  filter={filter}
                />
              )
            ) : (
              <DataTableLoader
                mClass={"crm-td crm-td-acntname"}
                module="account"
              />
            )}
          </div>
        </div>
      </div>
      {showCustomFilter && (
        <div className="crm-fieldsfltr" ref={customFilter}>
          {showCustomFilter && (
            <CustomFilter
              preservedFilter={customFilterDetails?.preservedFilter}
              functions={{ closeFilterSideBar, applyCustomFilter, clearAll }}
              columnInfo={columnInfo}
              module={"accounts"}
            />
          )}
        </div>
      )}

      {/* display download CSV progress */}
      <DownloadViewer ref={exportDownloadViewer} />
    </React.Fragment>
  );
}
